import { GET_SCREEN_INFO_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetScreenInfoRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link GetScreenInfoRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetScreenInfoRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetScreenInfoRequestMessage {
  return value.messageType === GET_SCREEN_INFO_REQUEST_MESSAGE_TYPE;
}
