import { ColorTheme } from '@app/common/types/ColorTheme';

import { joinStoragePath } from '@app/core/storage/browser/utils';

import { COLOR_THEME_ATTRIBUTE_NAME, COLOR_THEME_STORAGE_KEY } from './constants';

/**
 * Writes color theme to the document body attribute and local storage.
 */
export function writeColorTheme(theme: ColorTheme) {
  const value = theme.toString();

  document.body.setAttribute(COLOR_THEME_ATTRIBUTE_NAME, value);

  localStorage.setItem(joinStoragePath(COLOR_THEME_STORAGE_KEY), value);
}
