import { FieldServerErrors, GenericServerErrors } from '@app/common/types/validation';

import {
  Field,
  FieldComponent,
  FieldUiProps,
  WizardStepAlertMessage,
  WizardStepState,
} from '../types';

interface SetServerStateAction {
  readonly type: 'SetServerState';
  readonly data: Pick<WizardStepState, 'isFinal' | 'allowBackRedirect' | 'isTermination' | 'fields' | 'name' | 'id' | 'description'>;
}

interface SetFieldsErrorsAction {
  readonly type: 'SetFieldsErrors';
  readonly data: FieldServerErrors;
}

interface SetGenericErrorsAction {
  readonly type: 'SetGenericErrors';
  readonly data: GenericServerErrors;
}

interface SetUnhandledErrorsAction {
  readonly type: 'SetUnhandledErrors';
  readonly data: Error[];
}

interface SetAlertMessageAction {
  readonly type: 'SetAlertMessage';
  readonly data: WizardStepAlertMessage | null;
}

export interface SetLoadingAction {
  readonly type: 'SetLoading';
  readonly data: boolean;
}

interface SetTerminationStepAction {
  readonly type: 'SetTerminationStepAction';
  readonly data: any | null;
}

interface SetTerminationStepAction {
  readonly type: 'SetTerminationStepAction';
  readonly data: any | null;
}

interface SetFieldUiPropsAction<F extends Field = any, U extends FieldUiProps = {}> {
  readonly type: 'SetFieldUiProps';
  readonly data: [FieldComponent<F, U>, U];
}

export type WizardStepStateDispatchAction =
  | SetServerStateAction
  | SetLoadingAction
  | SetFieldsErrorsAction
  | SetGenericErrorsAction
  | SetUnhandledErrorsAction
  | SetAlertMessageAction
  | SetTerminationStepAction
  | SetFieldUiPropsAction;

export const DEFAULT_WIZARD_STATE: WizardStepState = {
  isFinal: false,
  fields: [],
  fieldErrors: [],
  genericErrors: [],
  unhandledErrors: [],
  alertMessage: null,
  terminationStepAction: null,
  loading: true,
  name: undefined,
  id: undefined,
  allowBackRedirect: false,
  isTermination: false,
};

export const setWizardStepState = (data: SetServerStateAction['data']): SetServerStateAction => ({
  type: 'SetServerState',
  data,
});
export const setWizardStepGenericErrors = (data: SetGenericErrorsAction['data']): SetGenericErrorsAction => ({
  type: 'SetGenericErrors',
  data,
});
export const setWizardStepUnhandledErrors = (data: SetUnhandledErrorsAction['data']): SetUnhandledErrorsAction => ({
  type: 'SetUnhandledErrors',
  data,
});
export const setWizardStepFieldsErrors = (data: SetFieldsErrorsAction['data']): SetFieldsErrorsAction => ({
  type: 'SetFieldsErrors',
  data,
});
export const setWizardStepAlertMessage = (data: SetAlertMessageAction['data']): SetAlertMessageAction => ({
  type: 'SetAlertMessage',
  data,
});
export const setWizardStepLoading = (data: boolean): SetLoadingAction => ({
  type: 'SetLoading',
  data,
});
// eslint-disable-next-line max-len
export const setWizardStepTerminationStepAction = (data: SetTerminationStepAction['data']): SetTerminationStepAction => ({
  type: 'SetTerminationStepAction',
  data,
});

export const setFieldUiProps = <F extends Field, U extends FieldUiProps>(data: SetFieldUiPropsAction<F, U>['data']): SetFieldUiPropsAction<F, U> => ({
  type: 'SetFieldUiProps',
  data,
});

export const stepStateReducer = (state: WizardStepState, action: WizardStepStateDispatchAction): WizardStepState => {
  switch (action.type) {
    case 'SetServerState':
      return {
        ...state,
        ...action.data,
      };
    case 'SetFieldsErrors':
      return {
        ...state,
        fieldErrors: action.data,
      };
    case 'SetGenericErrors':
      return {
        ...state,
        genericErrors: action.data,
      };
    case 'SetUnhandledErrors':
      return {
        ...state,
        unhandledErrors: action.data,
      };
    case 'SetAlertMessage':
      return {
        ...state,
        alertMessage: action.data,
      };
    case 'SetLoading':
      return {
        ...state,
        loading: action.data,
      };
    case 'SetTerminationStepAction':
      return {
        ...state,
        terminationStepAction: action.data,
      };
    case 'SetFieldUiProps':
      return {
        ...state,
        fieldsUiProps: new Map([
          ...(state.fieldsUiProps || []),
          [action.data[0], action.data[1]],
        ]),
      };
    default:
      return state;
  }
};
