import React, {
  useEffect, useLayoutEffect, useRef,
} from 'react';

import { AdServiceWidget, AdvertisementStatus } from '@app/queryTyping';

import { useDebouncedFunction } from '@app/common/utils/hooks/useDebouncedFunction';

import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';

import { useWrapperWidth } from '../deepTarget';
import { PureWidget, WidgetRenderer, WidgetType } from '../types';

import { useGetAdvertisementQuery } from './queries/queryTyping/getAdvertisement';
import { useSetAdvertisementStatusMutation } from './queries/queryTyping/setAdvertisementStatus';

const statusByMessage: Record<string, AdvertisementStatus> = {
  Dismiss: AdvertisementStatus.DECLINED,
  Accept: AdvertisementStatus.REFERRED,
  Pospone: AdvertisementStatus.POSTPONED,
  Present: AdvertisementStatus.PRESENTED,
};

const KEY_INSIGHT_MESSAGE = 'KeyInsight';

const DEFAULT_HEIGHT = '0px';

/**
 * AdService widget typeguard.
 * @param widget.
 * @returns true if widget is AdService widget.
 */
export function isAdServiceWidget(widget: WidgetType): widget is PureWidget<AdServiceWidget> {
  return widget.__typename === 'AdServiceWidget' && widget.providerId !== undefined;
}
export const AdServiceRenderer: WidgetRenderer<AdServiceWidget> = (
  { widget: { providerId } },
) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const { data } = useGetAdvertisementQuery({ variables: { providerId } });
  const [setAdvertisementStatus] = useSetAdvertisementStatusMutation();
  const { colorTheme } = useColorThemeContext();
  const [frameHeight, setHeight] = React.useState(DEFAULT_HEIGHT);

  const htmlString = data?.advertisement.html || '';
  const advertisementId = data?.advertisement?.id;

  const setIframeHeight = () => {
    setHeight(`${ref.current?.contentWindow?.document.documentElement.scrollHeight || 0}px`);
  };

  const onResize = useDebouncedFunction(() => {
    setHeight(DEFAULT_HEIGHT);
  }, 1000);

  const onMessage = (event: MessageEvent) => {
    const msg = event.data as [string, string] | undefined;
    if (msg?.[0] !== KEY_INSIGHT_MESSAGE) {
      return;
    }
    const status = statusByMessage[msg[1]];
    if (status) {
      setAdvertisementStatus({
        variables: {
          providerId,
          status,
          adId: data?.advertisement?.id || '',
        },
      });
    }
  };

  useLayoutEffect(() => {
    if (frameHeight === DEFAULT_HEIGHT) {
      setIframeHeight();
    }
  }, [frameHeight]);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      ref.current?.contentWindow?.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    const iframeDocument = ref.current?.contentWindow?.document;
    iframeDocument?.write(`<body data-theme=${colorTheme} data-ad-id=${advertisementId}>${htmlString}</body>`);
    iframeDocument?.close();
    if (advertisementId) {
      setAdvertisementStatus({
        variables: {
          providerId,
          status: AdvertisementStatus.PRESENTED,
          adId: advertisementId,
        },
      });
    }
  }, [htmlString, colorTheme]);

  const wrapperWidth = useWrapperWidth();

  return (
    <iframe
      ref={ref}
      title="Advertisement"
      style={{
        border: 'none',
        maxWidth: '100%',
        width: `${wrapperWidth}px`,
        height: frameHeight,
      }}
      onLoad={() => {
        ref.current?.contentWindow?.addEventListener('message', onMessage);
        setIframeHeight();
      }}
    />
  );
};
