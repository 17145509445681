export const isPageReloaded = (): boolean => {
  const windowPerformance = window.performance;
  const ENTRY_TYPE_NAVIGATION = 'navigation';
  const ENTRY_TYPE_RELOAD = 'reload';
  const ENTRY_TYPE_NAVIGATE = 'navigate';
  const ENTRY_TYPE_BACK_FORWARD = 'back_forward';
  if (windowPerformance) {
    // try to use Navigation Timing Level 2
    if (typeof windowPerformance.getEntriesByType === 'function') {
      // https://github.com/microsoft/TypeScript/issues/33866
      // As of March 2021 getEntriesByType types aren't added to TypeScript yet
      // TODO: Remove any[] when TypeScript will start support window.performance.getEntriesByType
      const perfEntries: any[] = windowPerformance.getEntriesByType(ENTRY_TYPE_NAVIGATION);
      const entry = perfEntries[0];
      if (entry) {
        return (
          entry.type === ENTRY_TYPE_RELOAD)
          // this hack (and similar block below based on legacy Performance API) allows us
          // to distinguish between navigation inside current site or navigation from 3rd party site
          // by manual changes of URL in address bar or by using browser bookmarks
          || (
            (
              entry.type === ENTRY_TYPE_NAVIGATE
              || entry.type === ENTRY_TYPE_BACK_FORWARD
            )
            && entry.unloadEventStart !== 0
            && entry.unloadEventEnd !== 0
          );
      }
    }

    // fallback to obsolete API
    const performanceNavigation = windowPerformance.navigation;
    const performanceTiming = windowPerformance.timing;
    if (performanceNavigation && performanceTiming) {
      return (
        performanceNavigation.type === performanceNavigation.TYPE_RELOAD)
        || (
          (
            performanceNavigation.type === performanceNavigation.TYPE_NAVIGATE
            || performanceNavigation.type === performanceNavigation.TYPE_BACK_FORWARD
          )
          && performanceTiming.unloadEventStart !== 0
          && performanceTiming.unloadEventEnd !== 0
        );
    }
  }
  return false;
};
