import React, { ReactElement } from 'react';

import Chart from '@ast/magma/components/icon/icons/Chart';
import CreditCard from '@ast/magma/components/icon/icons/CreditCard';
import DotsAlt from '@ast/magma/components/icon/icons/DotsAlt';
import Loan from '@ast/magma/components/icon/icons/Loan';
import Piggibank from '@ast/magma/components/icon/icons/Piggibank';
import Times from '@ast/magma/components/icon/icons/Times';
import UserCircle from '@ast/magma/components/icon/icons/UserCircle';
import Wallet from '@ast/magma/components/icon/icons/Wallet';

import { IconEx } from '@ast/magma/components/iconex';

import { BankAccountType } from '@app/queryTyping';

import { getAbsoluteURL, isAbsoluteURL, isRelativeURL } from './url';

/**
 * Get icon function to get icon component.
 */
export interface GetIcon {
  (src: string, className?: string): React.ReactElement | undefined;
}

/**
 * Create SVG icons provider with image source can be one of the following:
 * 1. Relative url.
 * 2. Absolute url.
 * 3. Mapped name. In this case mapping must be provided.
 * @param mapping Known image mapping. Oprtional.
 * @returns SVG icon react element.
 */
export const createIconProvider = (mapping?: Record<string, ReactElement>): GetIcon => (
  (src: string, className?: string): React.ReactElement | undefined => {
    let result: React.ReactElement | undefined;

    let iconSource = src;
    // Upgrade relative url to absolute
    if (isRelativeURL(iconSource)) {
      iconSource = getAbsoluteURL(iconSource);
    }

    // If souce is URL return iconex
    if (isAbsoluteURL(iconSource)) {
      result = (<IconEx src={iconSource} />);
    } else if (!mapping || !mapping[iconSource]) {
      console.error(`Icon is not found for: ${iconSource}`);
    } else {
      // Return local icon from source
      result = mapping[iconSource];
    }

    // eslint-disable-next-line no-nested-ternary
    return result ? className ? React.cloneElement(result, { className }) : result : undefined;
  });

// Will replace getAccountIcon & accountsDropDownItemMapping
const accountsDropDownItemMapping2: Record<string, ReactElement> = {
  [BankAccountType.CHECKING]: <Wallet />,
  [BankAccountType.CREDITLINE]: <CreditCard />,
  [BankAccountType.INVESTMENT]: <Chart />,
  [BankAccountType.LOAN]: <Loan />,
  [BankAccountType.SAVINGS]: <Piggibank />,

  // Temporary
  M2M: <UserCircle />,
};
export const getAccountIcon = createIconProvider(accountsDropDownItemMapping2);

const menuMapping: Record<string, ReactElement> = {
  Times: <Times />,
  DotsAlt: <DotsAlt />,
};

export const getMenuIcon = createIconProvider(menuMapping);
