import { ServerErrorTypes } from '@app/common/types/validation';
import { InvalidFormat } from '@app/queryTyping';
import { ServerErrorMessageResolver } from '../types';

export const invalidFormatServerErrorMessageResolver: ServerErrorMessageResolver<InvalidFormat> = {
  typeName: ServerErrorTypes.InvalidFormat,
  resolver: ({ expectedFormat, path }) => ({
    message: expectedFormat || '',
    path: path || '',
  }),
};
