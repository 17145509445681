import { CountryCodes } from '../utils/countries/types';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export const DEFAULT_COUNTRY = CountryCodes.US;
export const DEFAULT_LOCALES: string | string[] = 'en-US';
export const DEFAULT_DATE_TIME_FORMAT_OPTIONS: DateTimeFormatOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const SHORT_DATE_TIME_FORMAT: string = 'MMM D, YYYY';
export const DATEPICKER_DATE_FORMAT: string = 'MM/DD/YYYY';
