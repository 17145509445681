import { isFunction } from '@app/common/utils';

import {
  Field,
  FieldContext,
  FieldDataObject,
  FieldDefaultValue,
  Fields,
  FieldValuesType,
} from '../types';

import {
  getMultipleChoiceFieldDefaultValue,
  isMultipleChoiceCheckboxesField,
} from '../fields/MultipleChoice/Checkboxes/MultipleChoiceCheckboxesField';
import {
  isRadioGroupField,
  getRadioGroupFieldDefaultValue,
  getRadioGroupFieldName,
} from '../fields/RadioGroupField';
import {
  isSingleChoiceRadioButtonsField,
  getSingleChoiceFieldDefaultValue,
} from '../fields/SingleChoice/RadioButtons/SingleChoiceRadioButtonsField';

import { combineNames } from './combineNames';
import { isChallengeQuestionsField, isFieldInfoGroupField } from './fieldTypesCheck';

interface DetermineValueTypeFunction {
  (props: FieldContext): boolean,
}
interface CollectValueFunction<F> {
  (field: F, name: string): Record<string, FieldValuesType>
}

export type ValueCollector<F = any> = {
  identify: DetermineValueTypeFunction
  collect: CollectValueFunction<F>
  getName?: (field: F) => string,
  setDefaultValue: (field: F, defaultValue: FieldValuesType) => void,
  getSubFields?: (field: F) => Array<F>,
};

/**
 * Returns CF name
 */
const getName = (field: Field) => field.id;

/**
 * Value collector that would be applied for each CF to getting deffault values
 */
const valueCollectors: Array<ValueCollector> = [
  // string values
  {
    identify: ({ field }) => 'sValue' in field,
    collect: ({ sValue }, name) => ({ [name]: sValue || '' }),
    setDefaultValue: (field, defaultValue) => {
      // eslint-disable-next-line no-param-reassign
      field.sValue = (defaultValue as string).trim().substring(0, field.maxLength);
    },
  },
  // number values
  {
    identify: ({ field }) => 'nValue' in field,
    collect: (field, name) => ({ [name]: field.nValue }),
    // eslint-disable-next-line no-param-reassign
    setDefaultValue: (field, defaultValue) => { field.nValue = defaultValue; },
  },
  // boolean values
  {
    identify: ({ field }) => 'bValue' in field,
    collect: ({ bValue }, name) => ({ [name]: !!bValue }),
    // eslint-disable-next-line no-param-reassign
    setDefaultValue: (field, defaultValue) => { field.bValue = defaultValue; },
  },
  // currency values
  {
    identify: ({ field }) => 'cValue' in field,
    collect: ({ cValue }, name) => ({ [name]: cValue }),
    // eslint-disable-next-line no-param-reassign
    setDefaultValue: (field, defaultValue) => { field.cValue = defaultValue; },
  },
  // for complex CFs like Radio group,
  // we have to define specific algorithm for getting values,
  // cause default value may be keep on other custom properties
  {
    identify: isRadioGroupField,
    collect: (field, name) => ({ [name]: getRadioGroupFieldDefaultValue(field) }),
    getName: (field) => getRadioGroupFieldName('', field),
    setDefaultValue: () => { },
  },
  // for CFs like Checkbox group associated to single choice field
  {
    identify: isSingleChoiceRadioButtonsField,
    collect: (field, name) => ({ [name]: getSingleChoiceFieldDefaultValue(field) }),
    setDefaultValue: () => { },
  },
  // for CFs like Checkbox group associated to multiple choice field
  {
    identify: isMultipleChoiceCheckboxesField,
    collect: (field, name) => ({ [name]: getMultipleChoiceFieldDefaultValue(field) }),
    setDefaultValue: () => { },
  },
  {
    identify: ({ field }) => isChallengeQuestionsField(field),
    collect: (field: Fields['QuestionPoolsFieldGroup'], name) => {
      const values: Record<string, FieldValuesType> = {};
      field.questionPools.forEach(({ question, answer }) => {
        values[combineNames(name, question.id)] = question.selectedValue || '';
        values[combineNames(name, (answer as Fields['Text']).id)] = (answer as Fields['Text']).sValue || '';
      });

      return values;
    },
    getName,
    setDefaultValue: () => { },
  },
  // FieldInfoGroup
  {
    identify: ({ field }) => isFieldInfoGroupField(field),
    collect: () => ({}),
    getName,
    setDefaultValue: () => { },
    getSubFields: (field: Fields['FieldInfoGroup']) => (
      field.subFields as Field[]
    ),
  },
];

/**
 * Collect all scalars field values to key-value map
 * @todo: Currently this function is not suitable for collecting nested complex structures (CF Group fields, etc)
 * Need to be refactored (MPH-41672)
 */
// eslint-disable-next-line max-len
export const collectInitialData = (wizard: string, step: string, fields: readonly Field[], defaultFieldValues?: Map<string, FieldDefaultValue>): any => (

  fields.reduce((obj, field, index, theArray) => {
    valueCollectors.some((collector) => {
      // identify field or its value type
      if (collector.identify({ wizard: { name: wizard, step }, field })) {
        const name = isFunction(collector.getName)
          ? collector.getName(field)
          : getName(field);

        // set default values passed from outside
        if (defaultFieldValues) {
          const defaultFieldValue = defaultFieldValues.get(name);
          if (defaultFieldValue) {
            collector.setDefaultValue(theArray[index], defaultFieldValue.defaultValue);
          }
        }

        let innerObject: Record<string, any> = {};

        if (isFunction(collector.getSubFields)) {
          innerObject[name] = collectInitialData(wizard, step, collector.getSubFields(field), defaultFieldValues);
        } else {
          innerObject = collector.collect(field, name);
        }

        // collect initial field value
        Object.assign(obj, innerObject);
        return true;
      }

      return false;
    });

    return obj;
  }, {} as FieldDataObject)
);
