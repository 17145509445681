import { GET_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetIsAuthenticatedRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link GetIsAuthenticatedRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetIsAuthenticatedRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetIsAuthenticatedRequestMessage {
  return value.messageType === GET_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE;
}
