import * as React from 'react';

import { createGenericContext } from '@app/common/contexts/createGenericContext';
import { ColorTheme } from '@app/common/types/ColorTheme';

import { notifyColorThemeChange } from '@app/core/contexts/colorTheme/utils/notifyColorThemeChange';
import { readColorTheme } from '@app/core/contexts/colorTheme/utils/readColorTheme';
import { writeColorTheme } from '@app/core/contexts/colorTheme/utils/writeColorTheme';

export interface ColorThemeContext {
  colorTheme:ColorTheme;
  changeColorTheme:(value:ColorTheme) => void;
}

/**
 * Context to query application widgets.
 */
const [useColorThemeContext, ColorThemeContextProvider] = createGenericContext<ColorThemeContext>();

/**
 * Application widgets context provider.
 */
const ColorThemeProvider: React.FC = ({ children }) => {
  const [colorTheme, setColorTheme] = React.useState<ColorTheme>(readColorTheme());
  const changeColorTheme = React.useCallback((value:ColorTheme) => {
    writeColorTheme(value);
    notifyColorThemeChange(value);
    setColorTheme(value);
  }, []);

  const value = React.useMemo(
    () => ({ colorTheme, changeColorTheme }),
    [colorTheme, changeColorTheme],
  );

  return (
    <ColorThemeContextProvider value={value}>
      {children}
    </ColorThemeContextProvider>
  );
};

export { useColorThemeContext, ColorThemeProvider };
