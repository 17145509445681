import { secToMs } from '@app/common/utils/dateTime';
import { UserActivityDetector } from '../userActivityDetector/UserActivityDetector';
import { SESSION_EXPIRATION_FALLBACK_IN_MS } from './constants';
import { UserActivityInterpreter } from './UserActivityInterpreter';

export const getSessionExpirationInMs = (sessionExpirationInSec?: number) => (
  sessionExpirationInSec
    ? secToMs(sessionExpirationInSec)
    : SESSION_EXPIRATION_FALLBACK_IN_MS
);

export const getUserActivityInterpreter = (sessionExpirationInSecs?: number) => {
  // init user activity event observer;
  const userActivityDetector = UserActivityDetector.getInstance();

  // init user activity interpreter
  const instance = UserActivityInterpreter.getInstance();
  instance.init({
    userSessionExpirationInMs: getSessionExpirationInMs(sessionExpirationInSecs),
    userActivityDetector,
  });
  return instance;
};
