import { SUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE } from '../constants';
import { SubscribeLanguageCultureRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link SubscribeLanguageCultureRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isSubscribeLanguageCultureRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SubscribeLanguageCultureRequestMessage {
  return value.messageType === SUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE;
}
