/* eslint-disable i18next/no-literal-string */
import React from 'react';

import { useGetRecaptchaSettings } from '@app/common/components/ReCaptcha/useGetRecaptchaSettings';

import { useTranslation } from '@app/core/i18n';

import styles from './ReCaptchaPrivacyAndTermsLinks.pcss';

export const ReCaptchaPrivacyAndTermsLinks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      {t(
        'reCaptcha.privacyAndTermsMessage|The message that has to be displayed if the reCaptcha is enabled. It contains a link to the Privacy Policy and Terms of Service of Google.',
        'This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.',
      )}
    </div>
  );
};

export const ReCaptchaPrivacyAndTermsLinksContainer: React.FC = () => {
  const { isEnabled: isRecaptchaEnabled } = useGetRecaptchaSettings();
  if (!isRecaptchaEnabled) return null;
  return <ReCaptchaPrivacyAndTermsLinks />;
};
