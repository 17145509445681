import reactHtmlParser from 'react-html-parser';

import sanitizeHtml from 'sanitize-html';

export const ALLOWED_HTML_TAGS = ['b', 'i', 'em', 'strong', 'a', 'font', 'br', 'span', 'p', 'u', 'h1', 'h2', 'ul', 'li', 'div', 's', 'sup', 'center'];
const ALLOWED_HTML_ATTRS = {
  a: ['href', 'target', 'rel', 'aria-describedby'],
  font: ['color'],
  span: ['data-content'],
  div: ['class'],
  p: ['style'],
};

/**
 * Parse string into react elements collection.
 * Not allowed tags and attributes will be removed.
 * @param value html code.
 * @returns collection of react elements.
 */
export const parseHtml = (value:string) => {
  const clean = sanitizeHtml(value, {
    allowedTags: ALLOWED_HTML_TAGS,
    allowedAttributes: ALLOWED_HTML_ATTRS,
  });
  return reactHtmlParser(clean);
};
