import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { Dialog, DialogProps } from '@ast/magma/components/dialog';

import { OverlayWithMask } from '@app/common/components/Overlay/OverlayWithMask';
import { Portal } from '@app/common/components/Portal';
import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

import { TriggeredEventListenable, TriggeredEventNotifier } from './TriggeredEventNotifier';
import styles from './TriggeredEventWrapper.pcss';
import { TriggeredEventChallenge, TriggeredEventWizardBase } from './types';
import { useTriggeredEventsTranslation } from './useTriggeredEventsTranslation';
import { wizards } from './wizards';

export const TriggeredEventWrapper: React.FC = ({ children }) => {
  const [challengeRequired, setChallengeRequired] = useState<{ challengeId: TriggeredEventChallenge } | false>(false);
  const triggeredEventNotifier = TriggeredEventNotifier.getInstance();
  const isMobile = useIsMobile();
  const { t } = useTriggeredEventsTranslation();

  // subscribe to TE challenge-required event
  useEffect(() => {
    const listener = triggeredEventNotifier.addListener('challengeRequired', (data) => setChallengeRequired(data));
    return () => triggeredEventNotifier.removeListener('challengeRequired', listener);
  }, []);

  let WizardComponent: React.ComponentType<TriggeredEventWizardBase> | undefined;
  if (challengeRequired && challengeRequired?.challengeId) {
    const challengeId = challengeRequired?.challengeId;
    WizardComponent = wizards.get(challengeId);

    // eslint-disable-next-line i18next/no-literal-string
    if (!WizardComponent) throw new Error('Triggered event can\'t proceed without specified wizard');
  }

  const onActionHandler = (actionType: keyof TriggeredEventListenable, id: TriggeredEventChallenge) => () => {
    setChallengeRequired(false);
    // eslint-disable-next-line i18next/no-literal-string
    triggeredEventNotifier.notifyListeners(actionType, {
      challengeId: id,
    });
  };

  const getChallengeDialogProps = (challengeId: TriggeredEventChallenge): Partial<DialogProps> => {
    switch (challengeId) {
      case TriggeredEventChallenge.Marketing:
        return {
          className: classNames(styles.marketingDialog, styles.dialog),
        };
      case TriggeredEventChallenge.Disclosure:
        return {
          predefinedWidth: 'lg',
          className: classNames(styles.disclosuresDialog, styles.dialog),
        };
      default:
        return {
          predefinedWidth: 'sm',
          showClose: {
            // eslint-disable-next-line i18next/no-literal-string
            'aria-label': t(
              'buttons.close.text|Close Triggered Event dialog button',
              'Close Triggered Event',
            ),
          },
          className: styles.dialog,
        };
    }
  };

  return (
    <>
      {challengeRequired && challengeRequired.challengeId && (
        <Portal>
          <OverlayWithMask
            align="center"
            mask="dark"
            className={styles.overlayMask}
          >
            {WizardComponent && (
              <Dialog
                align={isMobile ? 'bottom' : 'center'}
                // eslint-disable-next-line i18next/no-literal-string
                mask="transparent"
                // eslint-disable-next-line i18next/no-literal-string
                onClose={onActionHandler('challengeCanceled', challengeRequired.challengeId)}
                {...getChallengeDialogProps(challengeRequired?.challengeId)}
              >
                <WizardComponent
                  challengeId={challengeRequired.challengeId}
                  // eslint-disable-next-line i18next/no-literal-string
                  onResolved={onActionHandler('challengePassed', challengeRequired.challengeId)}
                  // eslint-disable-next-line i18next/no-literal-string
                  onCancel={onActionHandler('challengeCanceled', challengeRequired.challengeId)}
                />
              </Dialog>
            )}
          </OverlayWithMask>
        </Portal>
      )}
      {children}
    </>
  );
};
