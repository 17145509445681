import React from 'react';
// causes circular DI, if import from configurable-wizards/
import { MultipleChoiceFieldFragment as MultipleChoiceField } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';
import {
  FieldComponent,
  FieldLocator,
  FieldContext,
} from '@app/common/configurable-wizards/types';
import { combineNames } from '@app/common/configurable-wizards/utils/combineNames';
import { isMultipleChoiceField } from '@app/common/configurable-wizards/utils/fieldTypesCheck';

import { GetDefaultValue, GetName } from '../../RadioGroupField';

import { useMultipleChoiceField } from './hooks';
import { MultipleChoiceCheckboxesComponent } from './MultipleChoiceCheckboxesComponent';

/**
 * Returns value of the default selected values
 */
export const getMultipleChoiceFieldDefaultValue: GetDefaultValue<MultipleChoiceField> = (field) => (
  field.selectedValues?.filter((item) => item?.value).map((item) => item?.value) || []
);

/**
 * Returns field name
 */
export const getMultipleChoiceFieldName: GetName<MultipleChoiceField> = (parent, field) => (
  combineNames(parent, field.id)
);

/**
 * Represents the MultipleChoiceFieldInfo configurable field.
 *
 * Contains a set of <Checkbox> components.
 * Does not select any value on appear.
 * Used at least for 'user loans' field of User Enrollment.
 */
export const MultipleChoiceCheckboxesFieldComponent: FieldComponent<MultipleChoiceField> = (props) => {
  const field = useMultipleChoiceField(props);

  return (
    <MultipleChoiceCheckboxesComponent
      containerProps={{ direction: 'column' }}
      {...field}
    />
  );
};

export const isMultipleChoiceCheckboxesField = ({ field }: FieldContext) => (
  isMultipleChoiceField(field)
);

export const MultipleChoiceCheckboxesFieldInfoLocator: FieldLocator = (context) => (
  isMultipleChoiceCheckboxesField(context)
    ? MultipleChoiceCheckboxesFieldComponent
    : undefined
);
