interface UserErrorProps {
  readonly critical:boolean;
  readonly message:string;
  readonly isRetryPossible?:boolean;
  readonly title?:string;
  readonly details?: string;
  readonly originalError?:Error;
  readonly type?: string;
}

/**
 * User error is error which text can be show to user as is.
 * It means it contains localized user friendly message.
 */
export class UserError extends Error implements UserErrorProps {
  /**
   * Title shown on top of message
   */
  title?: string;

  /**
   * May contain other related details, especially helpful for the technicians in troubleshooting
   */
  details?: string;

  /**
   * Error importance
   */
  critical: boolean;

  /**
   * Original system error. Optional.
   */
  originalError?: Error;

  /**
   * Indicates whether redoing the operation (which caused the error) may fix the error.
   * E.g. for the lockout error clicking on the "Try again" link will have no effect
   * because the user is already locked out and the operation can not succeed.
   * For other errors clicking on the "Try again" link may lead to the success result.
   */
  isRetryPossible: boolean;

  type?: string;

  constructor({
    critical,
    title,
    message,
    details,
    originalError,
    isRetryPossible = true,
    type,
  }: UserErrorProps) {
    super(message);

    this.critical = critical;
    this.title = title;
    this.originalError = originalError;
    this.details = details;
    this.isRetryPossible = isRetryPossible;
    this.type = type;
  }
}
