import { useMemo } from 'react';

import { DefaultFieldValues, Field } from '../types';
import { collectInitialData } from '../utils';

/**
 * Returns default values for wizard form fields.
 */
export const useDefaultValues = ({
  wizard,
  stepId,
  fields,
  defaultFieldValues,
}: {
  wizard: string;
  stepId?: string | null;
  fields: readonly Field[];
  defaultFieldValues?: DefaultFieldValues;
}) => useMemo(
  () => collectInitialData(wizard, stepId || '', fields, defaultFieldValues),
  [wizard, stepId, fields, defaultFieldValues],
);
