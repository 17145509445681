import { isObject, isString } from '@app/common/utils';

import { AgileSDKRequestMessage } from '../types';

import { AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX } from '../../constants';

/**
 * Checks if the value is an {@link AgileSDKRequestMessage}.
 */
export function isAgileSDKRequestMessage(value: unknown): value is AgileSDKRequestMessage<unknown> {
  return (
    isObject(value)
    && 'id' in value
    && isString(value.id)

    && 'messageType' in value
    && isString(value.messageType)
    && value.messageType.startsWith(AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX)

    && 'version' in value
    && isString(value.version)

    && 'payload' in value

    && 'payloadVersion' in value
    && isString(value.payloadVersion)
  );
}
