import { isNumber, isUndefined } from '@app/common/utils/assertion';
import { Listenable } from '@app/common/utils/listenable';
import { ObserverInterface } from '@app/common/utils/observable';
import { UserActivityDetector } from '../userActivityDetector/UserActivityDetector';
import { FROM_AWAY_TO_AUTOLOGOUT_INTERVAL_IN_MS } from './constants';
import { UserActivityInterpreterEvents } from './types';

interface UserActivityInterpreterArgs {
  readonly userSessionExpirationInMs: number;
  readonly userActivityDetector: UserActivityDetector;
}

/**
 * Interprets some user activity events into some user state.
 *
 * Example: based on the user doing nothing interprets that he is away..
 */
export class UserActivityInterpreter
  extends Listenable<UserActivityInterpreterEvents>
  implements ObserverInterface {
  private static instance: UserActivityInterpreter;

  private observable?: UserActivityDetector;

  private userAwaytimerRef?: number;

  private userSessionExpirationInMs?: number;

  private constructor() {
    super(new Map<keyof UserActivityInterpreterEvents, Set<Function>>([
      // eslint-disable-next-line i18next/no-literal-string
      ['page-closed', new Set()],
      // eslint-disable-next-line i18next/no-literal-string
      ['user-active', new Set()],
      // eslint-disable-next-line i18next/no-literal-string
      ['user-away', new Set()],
    ]));
  }

  /**
   * Returns the single class instance.
   */
  public static getInstance(): UserActivityInterpreter {
    if (!UserActivityInterpreter.instance) {
      UserActivityInterpreter.instance = new UserActivityInterpreter();
    }

    return UserActivityInterpreter.instance;
  }

  public init(args: UserActivityInterpreterArgs) {
    let settingsChanged = false;

    if (this.userSessionExpirationInMs !== args.userSessionExpirationInMs) {
      this.userSessionExpirationInMs = args.userSessionExpirationInMs;
      settingsChanged = true;
    }

    if (this.observable !== args.userActivityDetector) {
      if (this.observable) {
        this.observable.removeObserver(this);
      }

      this.observable = args.userActivityDetector;
      this.observable.registerObserver(this);
      settingsChanged = true;
    }

    if (settingsChanged) {
      this.resetUserAwayTrack();
    }
  }

  /**
   * Observer method that will be called when Observable broadcasts new data.
   * It means the user is still active when it was called.
   */
  public update(): void {
    this.resetUserAwayTrack();
    // eslint-disable-next-line i18next/no-literal-string
    this.notifyListeners('user-active', null);
  }

  private isUserAwayTrackStarted() {
    return isNumber(this.userAwaytimerRef);
  }

  private stopUserAwayTrack() {
    if (this.isUserAwayTrackStarted()) {
      window.clearTimeout(this.userAwaytimerRef);
    }
  }

  private startUserAwayTrack() {
    if (isUndefined(this.userSessionExpirationInMs)) { return; }

    this.userAwaytimerRef = window.setTimeout(() => {
      // eslint-disable-next-line i18next/no-literal-string
      this.notifyListeners('user-away', { msToLogout: FROM_AWAY_TO_AUTOLOGOUT_INTERVAL_IN_MS });
    }, this.userSessionExpirationInMs - FROM_AWAY_TO_AUTOLOGOUT_INTERVAL_IN_MS);
  }

  private resetUserAwayTrack() {
    this.stopUserAwayTrack();
    this.startUserAwayTrack();
  }
}
