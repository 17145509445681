import { getCookie, removeCookie, setCookies } from './cookieStorage';
import { StorageUpdateEvent } from './types';
import { joinStoragePath } from './utils';

export const SESSION_STORAGE_UPDATE_EVENT = 'ast.sessionStorageUpdate';

export function getSessionValue(key: string) {
  const innerKey = joinStoragePath(key);
  try {
    return window.sessionStorage.getItem(innerKey);
  } catch {
    return getCookie(innerKey);
  }
}

export function setSessionValue(key: string, value: string): void {
  const innerKey = joinStoragePath(key);
  try {
    window.sessionStorage.setItem(innerKey, value);
    // dispatch custom event about session storage changes.

    window.dispatchEvent(
      new CustomEvent(
        SESSION_STORAGE_UPDATE_EVENT,
        { detail: { key, value } },
      ) as StorageUpdateEvent,
    );
  } catch {
    setCookies(innerKey, value);
  }
}

export function removeSessionValue(key: string): void {
  const innerKey = joinStoragePath(key);
  try {
    window.sessionStorage.removeItem(innerKey);
    // dispatch custom event about session storage changes.
    window.dispatchEvent(
      new CustomEvent(
        SESSION_STORAGE_UPDATE_EVENT,
        { detail: { key, value: null } },
      ) as StorageUpdateEvent,
    );
  } catch {
    removeCookie(innerKey);
  }
}

export function clearSessionStorage(): void {
  try {
    window.sessionStorage.clear();
    // dispatch custom event about session storage changes.
    window.dispatchEvent(
      new CustomEvent(
        SESSION_STORAGE_UPDATE_EVENT,
        { detail: { key: null, value: null } },
      ) as StorageUpdateEvent,
    );
  } catch {
    console.error('Failed to clear session storage');
  }
}
