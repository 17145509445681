import { appSettings } from '@app/core/appSettings';

import { setAuthenticationToken } from '../authenticationToken';

import { setClientDeviceUID } from '../customAuthHeaders/clientDeviceUID';
import { setDSSessionId } from '../customAuthHeaders/dsSessionId';

import { TETHERED_LOGIN_API_BASE } from './const';
import { TETHERED_LOGIN_REDIRECT_URL_PARAM } from './tetheredLoginRedirect';
import { SharedSessionData } from './types';

export type APIResponse = SharedSessionData;

/**
 * API action path
 */
export const API_ACTION_URL = '/exchange';

/**
 * @private
 */
export const getApiUrl = () => (
  `${appSettings().apiEndpoint}${TETHERED_LOGIN_API_BASE}${API_ACTION_URL}`
);

/**
 * @private
 */
export const getTetheredLoginRedirectToken = (search: string) => {
  const urlParams = new URLSearchParams(search);
  const token = decodeURIComponent(urlParams.get(TETHERED_LOGIN_REDIRECT_URL_PARAM) || '');
  return token;
};

/**
 * @private
 */
export const removeTetheredLoginRedirectTokenFromUrl = (win: Window) => {
  const url = new URL(win.location.href);
  url.searchParams.delete(TETHERED_LOGIN_REDIRECT_URL_PARAM);
  win.history.replaceState({}, '', url.toString());
};

/**
 * @private
 */
export const getRequestBody = (token: string) => ({
  token,
});

/**
 * @private
 */
export const getRequestOptions = (body: any): RequestInit => ({
  method: 'POST',
  headers: {
    // eslint-disable-next-line i18next/no-literal-string
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(body),
});

/**
 * 1. Before the application is initialized, checks if there is a tethered logen redirection token in the url.
 * 2. If there is, calls the API to get the shared session parameters from iframe.
 * 3. Then sets the session parameters to the storage.
 */
export const processTetheredLoginRedirects = () => {
  const token = getTetheredLoginRedirectToken(window.location.search);

  // if there is no tethered login redirect token, act as usual, otherwise process tehtered login redirection
  if (!token) {
    return Promise.resolve();
  }

  // remove the token from the url after getting it
  removeTetheredLoginRedirectTokenFromUrl(window);

  // call the API to exchange the token for the session parameters and set them to the storage
  return new Promise<void>((resolve) => {
    const body = getRequestBody(token);
    const requestOptions = getRequestOptions(body);
    const apiUrl = getApiUrl();

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error calling the Tehtered login exchange token API');
        }
        return response.json();
      })
      .then(({
        authToken,
        clientDeviceUID,
        dsSessionID,
      }: APIResponse) => {
        // set the session parameters to the storage
        setAuthenticationToken(authToken);
        setDSSessionId(dsSessionID);
        setClientDeviceUID(clientDeviceUID);

        // resolve the promise
        resolve();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // Do not reject the promise, because it will break the application initialization,
        // instead, just proceed as usual
        resolve();
      });
  });
};
