import { VoidCallback } from '@app/common/utils/types';

import { ILoginNotifier } from '@app/core/authentication/login/LoginNotifier';
import { IInMemoryStorage } from '@app/core/storage/browser/InMemoryStorage';

import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import {
  AgileSDKAddress,
  AgileSDKMessageSender,
  AgileSDKRequest,
} from '../../utils/types';
import { isGetInMemoryStorageItemRequestMessage } from './assertions/isGetInMemoryStorageItemRequestMessage';
import { isSetInMemoryStorageItemRequestMessage } from './assertions/isSetInMemoryStorageItemRequestMessage';

import {
  DEFAULT_IN_MEMORY_STORAGE_ITEM_VALUE,
  GET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE,
  SET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE,
} from './constants';
import {
  GetInMemoryStorageItemRequestMessage,
  GetInMemoryStorageItemResponseMessage,
  SetInMemoryStorageItemRequestMessage,
  SetInMemoryStorageItemResponseMessage,
} from './types';

/**
 * Agile SDK service for in-memory storage.
 *
 * Can be used to store string data that can be shared between the SDK clients (e.g., authentication tokens, etc.).
 *
 * The data is stored in memory and is cleared when user logs out or the page is reloaded.
 */
export class InMemoryStorageAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly sender: AgileSDKMessageSender;

  private readonly unsubscribe: VoidCallback;

  constructor(
    private readonly storage: IInMemoryStorage,
    private readonly notifier: ILoginNotifier,
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    super(listener, new Set([
      GET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE,
      SET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE,
    ]));

    this.unsubscribe = this.notifier.subscribe(this.handleNotification.bind(this));

    this.sender = sender;
  }

  async init() {
    await super.init();
  }

  async dispose() {
    await super.dispose();

    this.unsubscribe();
  }

  /**
   * Handles the user login status changes.
   */
  handleNotification(isAuthenticated: boolean) {
    if (isAuthenticated) {
      return;
    }

    // Cleanup the storage when user logs out.
    this.storage.clear();
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    const { message, sender } = request;

    switch (true) {
      case isGetInMemoryStorageItemRequestMessage(message):
        return this.handleGet(message as GetInMemoryStorageItemRequestMessage, sender);

      case isSetInMemoryStorageItemRequestMessage(message):
        return this.handleSet(message as SetInMemoryStorageItemRequestMessage, sender);

      default:
        return false;
    }
  }

  async handleGet(message: GetInMemoryStorageItemRequestMessage, sender: AgileSDKAddress): Promise<boolean> {
    const { key } = message.payload;

    const value = this.storage.getItem(key) ?? DEFAULT_IN_MEMORY_STORAGE_ITEM_VALUE;

    const response: GetInMemoryStorageItemResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        value,
      },
    };

    this.sender(response, sender);

    return true;
  }

  async handleSet(message: SetInMemoryStorageItemRequestMessage, sender: AgileSDKAddress): Promise<boolean> {
    const { key, value } = message.payload;

    this.storage.setItem(key, value);

    const response: SetInMemoryStorageItemResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {},
    };

    this.sender(response, sender);

    return true;
  }
}
