import React, { ReactNode, useMemo } from 'react';
import { Trans } from 'react-i18next';

import classNames from 'classnames';

import { useBreakpoint } from '@ast/magma/breakpoints';

import Tools from '@ast/magma/components/icon/icons/Tools';

import { Card } from '@ast/magma/components/card';
import { DialogStatus } from '@ast/magma/components/dialog';
import { TextLink } from '@ast/magma/components/textlink';

import { uniqueId } from '@ast/magma/inner/utils/uniqueid';

import { isHostedInFrame } from '@app/common/utils/isHostedInFrame/isHostedInFrame';

import { useFISettingsContext } from '@app/core/contexts/fiSettings/FISettingsContext';
import { t } from '@app/core/i18n';

import styles from './MaintenanceModCard.pcss';

type GetStylesProps = { mobileAndFrame: string, mobile: string, desktop: string };
const useGetStyles = ({ mobileAndFrame, mobile, desktop }: GetStylesProps) => {
  const isMobile = useBreakpoint().device === 'mobile';
  const hostedInFrame = isHostedInFrame();

  if (isMobile) {
    if (hostedInFrame) {
      return mobileAndFrame;
    }
    return mobile;
  }

  return desktop;
};

interface ConditionalTextLinkProps {
  children: ReactNode,
  isLink: boolean,
  href: string| null,
  stableName: string,
}

const ConditionalTextLink = ({
  children, isLink, href, stableName,
}: ConditionalTextLinkProps) => {
  const rel = 'noopener noreferrer nofollow';

  if (isLink) {
    return (
      <TextLink
        data-stable-name={stableName}
        href={href || ''}
        className={styles.link}
        target="_top"
        rel={rel}
      >
        {children}
      </TextLink>
    );
  }

  return <span data-stable-name={stableName}>{children}</span>;
};

/**
 * Maintenance Mode card component to show user facing error inside parent container.
 * @param props
 */
export const MaintenanceModCard = () => {
  const cardTitleId = useMemo(() => uniqueId('maintenance-mode-title'), []);
  const cardContentId = useMemo(() => uniqueId('maintenance-mode-content'), []);

  const stylesForCardWrapper = useGetStyles({
    desktop: styles.centerAlign,
    mobile: styles.bottomAlign,
    mobileAndFrame: styles.noAlign,
  });

  const stylesForCard = useGetStyles({
    desktop: styles.dialogDesktop,
    mobile: styles.dialogMobile,
    mobileAndFrame: styles.dialogMobileAndFrame,
  });

  const isMobile = useBreakpoint().device === 'mobile';

  const fiSettings = useFISettingsContext();

  const siteHostUrl = fiSettings?.getIfConfigured('siteHostUrl');
  const customerHelpCenterUrl = fiSettings?.getIfConfigured('customerHelpCenterUrl');

  const showLinkToSiteHost = Boolean(siteHostUrl);
  const showLinkToHelpCenter = Boolean(customerHelpCenterUrl);

  return (
    <div
      data-stable-name="MaintenanceModeCard"
      className={classNames(
        styles.overlay,
        stylesForCardWrapper,
      )}
    >
      <Card
        role="alert"
        aria-labelledby={cardTitleId}
        aria-describedby={cardContentId}
        size="dialog"
        className={classNames(
          styles.dialog,
          stylesForCard,
        )}
        hasShadow={!isMobile}
        hasBorder={false}
      >
        <DialogStatus
          className={styles.status}
          icon={<Tools />}
          level="support"
        />

        <h5 id={cardTitleId} className={styles.title}>{t('maintenance-mode-card.title|Represent the title of maintenace mode card', 'Maintenance in progress')}</h5>

        <div id={cardContentId}>
          <div className={styles.message}>{t('maintenance-mode-card.message|Represent the message of maintenace mode card', 'We are currently making improvements to enhance experience. During this time, services will be unavailable. Thank you for your patience.')}</div>

          <span className={styles.message}>
            <Trans i18nKey="maintenance-mode-card.hint|A message with a link to try again">
              Once our maintenance is finished, you will be able to return to the login page by clicking
              {' '}
              <TextLink
                data-stable-name="LinkToLoginPage"
                className={styles.link}
                href="/"
                target="_top"
                rel="noopener noreferrer nofollow"
              >
                here
              </TextLink>
              .
            </Trans>
          </span>

          <br />

          <span className={styles.message}>
            <Trans i18nKey="maintenance-mode-card.hint-footer.support|Maintenance mode dialog with links">
              You can also return to our
              {' '}
              <ConditionalTextLink
                stableName="LinkToHost"
                href={siteHostUrl || ''}
                isLink={showLinkToSiteHost}
              >
                home page
              </ConditionalTextLink>
              {' '}
              or
              {' '}
              <ConditionalTextLink
                stableName="LinkToHelpCenter"
                href={customerHelpCenterUrl || ''}
                isLink={showLinkToHelpCenter}
              >
                contact us
              </ConditionalTextLink>
              {' '}
              if you need further assistance.
            </Trans>
          </span>
        </div>
      </Card>
    </div>
  );
};
