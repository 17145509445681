import { useEffect, useState } from 'react';

import { getUserActivityInterpreter } from '@app/core/userActivityInterpreter/utils';

import { UserAutoLogoutInterpreterEvents } from './types';
import { UserAutoLogoutInterpreter } from './UserAutoLogoutInterpreter';

interface AutoLogoutListener {
  (data: UserAutoLogoutInterpreterEvents['onautologout']): void
}

/**
 * Returns true when it's the time to auto-logout
 */
export const useAutoLogout = (sessionExpirationInSecs?: number) : boolean => {
  const [useAutoLogoutValue, setAutoLogout] = useState<boolean>(false);

  useEffect(() => {
    const autoLogoutInterpreter = UserAutoLogoutInterpreter.getInstance();
    autoLogoutInterpreter.init({
      userActivityInterpreter: getUserActivityInterpreter(sessionExpirationInSecs),
    });

    // listen user auto-logout event
    const logoutListener: AutoLogoutListener = ({ isAutologout }) => setAutoLogout(isAutologout);
    autoLogoutInterpreter.addListener('onautologout', logoutListener);

    return () => (
      autoLogoutInterpreter.removeListener('onautologout', logoutListener)
    );
  }, [sessionExpirationInSecs]);

  return useAutoLogoutValue;
};
