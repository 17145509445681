import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { isCustomEvent } from '@app/common/utils/assertion';
import { CacheManager } from '@app/common/utils/cacheManager';

import { client } from '@app/core/apolloClient';

/**
 * Event type which comes from window object to make react router to navigate url.
 */
export const NAVIGATE_URL_EVENT_TYPE = 'ast.navigateURL';

/**
 * Event type which comes from window object
 * to notify app, that it is initialized
 */
const APP_INITIALIZED_EVENT_TYPE = 'ast.appInitialized';

/**
 * Navigate URL event payload.
 */
type NavigateURL = {
  url: string
  resetCache?: boolean
};

/**
 * Navigate URL event.
 */
type NavigateURLEvent = CustomEvent<NavigateURL>;

/**
 * Typeguard to check if event is NavigateURLEvent type.
 * @param event common event
 * @returns true if event is NavigateURLEvent.
 */
export function isNavigateURLEvent(event:Event):event is NavigateURLEvent {
  return (isCustomEvent(event)
    && typeof event.detail === 'object'
    // eslint-disable-next-line i18next/no-literal-string
    && Object.prototype.hasOwnProperty.call(event.detail, 'url'));
}

/**
 * Hook to listen custom event and redirect react router to provided url.
 * Event to be sent from window object by outside script (WebView).
 */
export const useNavigateURLEvent = () => {
  const history = useHistory();

  /**
   * Navigate URL event listener.
   * @param event event
   */
  const handleOutsideRedirection = (event:Event) => {
    if (isNavigateURLEvent(event)) {
      if (event.detail.resetCache) {
        CacheManager.resetCache(client.cache, { broadcast: false });
      }
      history.push(event.detail.url);
    }
  };

  useEffect(() => {
    window.addEventListener(NAVIGATE_URL_EVENT_TYPE, handleOutsideRedirection);
    window.dispatchEvent(new CustomEvent(APP_INITIALIZED_EVENT_TYPE));
    return () => {
      window.removeEventListener(NAVIGATE_URL_EVENT_TYPE, handleOutsideRedirection);
    };
  }, []);
};
