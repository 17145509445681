import React, { useEffect, useMemo } from 'react';

import { WidgetType } from '@app/core/widgets/react/types';

import { ZoneName } from '../constants';

import { isConditionalWidget } from '../utils/isConditionalWidget';
import { renderConditionalWidget } from '../utils/renderConditionalWidget';
import { renderWidget } from '../utils/renderWidget';

import { useZoneWidgetData } from './useZoneWidgetData';

/**
 * Zone widgets result.
 */
export type ZoneWidgetsResult = {
  /**
   * Widgets to render.
   */
  widgets: (JSX.Element | null)[];

  /**
   * Original widgets data.
   */
  data: WidgetType[];

  /**
   * Indicates that widgets are loading.
   */
  loading: boolean;

  /**
   * Indicates that widgets all widgets are ready including conditional ones.
   */
  isCompleted: boolean;
};

/**
 * Resolves widgets for a specific zone, including global and page widgets.
 *
 * Additionally, it renders conditional widgets once they are ready.
 */
export const useZoneWidgets = (zoneName: ZoneName): ZoneWidgetsResult => {
  const [loading, setLoading] = React.useState(true);

  const { data, loading: dataLoading } = useZoneWidgetData(zoneName);

  const [state, setState] = React.useState({
    // List of rendered elements.
    elements: [] as (JSX.Element | null)[],
    // Total number of processed widgets.
    complete: 0,
  });

  const widgets = useMemo(() => state.elements.filter((item) => item !== null), [state]);

  const isCompleted = useMemo(() => state.complete === data.length && !loading, [data, state, loading]);

  useEffect(() => {
    if (dataLoading) {
      return;
    }

    let complete = 0;

    const elements = data.map((widget, index) => {
      // eslint-disable-next-line no-underscore-dangle
      const id = `${widget.__typename}${index}`;
      const isConditional = isConditionalWidget(widget);

      // Non-conditional widgets are ready to render immediately.
      if (!isConditional) {
        complete += 1;

        return renderWidget(id, widget, zoneName);
      }

      // Conditional widgets are added to list once ready.
      renderConditionalWidget(id, widget, zoneName)
        .then((element) => {
          setState((prevState) => ({
            elements: [
              ...prevState.elements.slice(0, index),
              element,
              ...prevState.elements.slice(index + 1),
            ],
            complete: prevState.complete + 1,
          }));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console, i18next/no-literal-string
          console.error('Error while rendering conditional widget:', error);
        });

      return null;
    });

    setState({
      elements,
      complete,
    });

    setLoading(false);
  }, [data, dataLoading, zoneName]);

  return {
    widgets,
    data,
    loading,
    isCompleted,
  };
};
