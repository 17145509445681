import React from 'react';
import { CompoundFieldFragment as CompoundField } from '../queries/queryTyping/all-fields';
import {
  FieldComponent,
  FieldLocator,
} from '../types';

export const FallbackFieldComponent: FieldComponent<CompoundField> = ({ field }) => (
  <div style={{ color: 'red' }}>
    <div>Could not render field.</div>
    <pre>
      {JSON.stringify(field, null, 2)}
    </pre>
  </div>
);

export const FallbackFieldLocator: FieldLocator = () => FallbackFieldComponent;
