import { GetFrameStylesProps, SupportedVariables } from './types';

// replace escape symbols
export const replaceSymbols = (s: String) => s.replace(/{{/g, '{').replace(/}}/g, '}');

// get current theme variables as string
export const getThemeVariables = () => {
  const styles = getComputedStyle(document.body);

  return Object.values(SupportedVariables).map(
    (key) => `${key}: ${styles.getPropertyValue(key)};`,
  ).join('');
};

// get basic frame styles for current theme
export const getFrameStyles = ({ variables, rootClassName }: GetFrameStylesProps) => `
  :root {
    ${variables}
  }

  body {
    margin: 0;
    color: var(--primary-text-color);
    background-color: var(--primary-background-color);
  }

  .${rootClassName} img {
    max-width: 100% !important;
  }
`;
