import React from 'react';

import { ColorThemeProvider } from '@app/core/contexts/colorTheme/ColorThemeContext';

import { LoginWizard } from '@app/widgets/user-login/LoginWizard';

import { TriggeredEventWrapper } from '../TriggeredEvents/TriggeredEventWrapper';

export const TetheredLoginFormApplication: React.FC = () => (
  <ColorThemeProvider>
    <TriggeredEventWrapper>
      <LoginWizard />
    </TriggeredEventWrapper>
  </ColorThemeProvider>
);
