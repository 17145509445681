/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetRecaptchaSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRecaptchaSettingsQuery = (
  { __typename?: 'Query' }
  & { recaptchaSettings: (
    { __typename?: 'RecaptchaSettingsConfiguration' }
    & Pick<Types.RecaptchaSettingsConfiguration, 'isEnabled' | 'apiUrl' | 'siteKeyV2' | 'siteKeyV3'>
  ) }
);


export const GetRecaptchaSettingsDocument = gql`
    query GetRecaptchaSettings {
  recaptchaSettings {
    isEnabled
    apiUrl
    siteKeyV2
    siteKeyV3
  }
}
    `;

/**
 * __useGetRecaptchaSettingsQuery__
 *
 * To run a query within a React component, call `useGetRecaptchaSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecaptchaSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecaptchaSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecaptchaSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetRecaptchaSettingsQuery, GetRecaptchaSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecaptchaSettingsQuery, GetRecaptchaSettingsQueryVariables>(GetRecaptchaSettingsDocument, options);
      }
export function useGetRecaptchaSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecaptchaSettingsQuery, GetRecaptchaSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecaptchaSettingsQuery, GetRecaptchaSettingsQueryVariables>(GetRecaptchaSettingsDocument, options);
        }
export type GetRecaptchaSettingsQueryHookResult = ReturnType<typeof useGetRecaptchaSettingsQuery>;
export type GetRecaptchaSettingsLazyQueryHookResult = ReturnType<typeof useGetRecaptchaSettingsLazyQuery>;
export type GetRecaptchaSettingsQueryResult = Apollo.QueryResult<GetRecaptchaSettingsQuery, GetRecaptchaSettingsQueryVariables>;