/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../queryTyping';

import { gql } from '@apollo/client';
export type ValidationUnion_UserFriendly_Fragment = (
  { __typename?: 'UserFriendly' }
  & UserFriendlyFragment
);

export type ValidationUnion_InvalidFormat_Fragment = (
  { __typename?: 'InvalidFormat' }
  & InvalidFormatFragment
);

export type ValidationUnionFragment = ValidationUnion_UserFriendly_Fragment | ValidationUnion_InvalidFormat_Fragment;

export type UserFriendlyFragment = (
  { __typename: 'UserFriendly' }
  & Pick<Types.UserFriendly, 'errorMessage' | 'path'>
);

export type InvalidFormatFragment = (
  { __typename: 'InvalidFormat' }
  & Pick<Types.InvalidFormat, 'expectedFormat' | 'path'>
);

export const UserFriendlyFragmentDoc = gql`
    fragment UserFriendly on UserFriendly {
  __typename
  errorMessage
  path
}
    `;
export const InvalidFormatFragmentDoc = gql`
    fragment InvalidFormat on InvalidFormat {
  __typename
  expectedFormat
  path
}
    `;
export const ValidationUnionFragmentDoc = gql`
    fragment ValidationUnion on ValidationUnion {
  ...UserFriendly
  ...InvalidFormat
}
    ${UserFriendlyFragmentDoc}
${InvalidFormatFragmentDoc}`;