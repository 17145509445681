import { Dict } from '@app/common/utils/types';

import { dispatchNativeAppRequestEvent } from './dispatchNativeAppRequestEvent';
import { appResponseTimeoutError } from './errors';
import { NativeAppResponse, NativeAppSuccessResponse, NativeAppErrorResponse } from './types';

/**
 * Request Native App function params.
 */
export interface RequestNativeAppParams {
  /**
   * Event ID to wait for in response.
   *
   * The native app should set this ID in its response to let the native
   * app request function to match it to the request and handle it properly.
   *
   * Any globally unique string can be used here.
   */
  eventId: string;
  /**
   * Request data.
   */
  requestPayload: Dict;
  /**
   * Request event type.
   *
   * The type of the custom event the native app is able to handle
   * ('ast.launchSSO', etc.).
   */
  requestEventType: string;
  /**
   * Response event type.
   *
   * The type of the custom event the function need to wait from the native app
   * in response ('ast.launchSSOResult', etc.).
   */
  responseEventType: string;
  /**
   * Response timeout.
   *
   * The timespan in milliseconds the native app must return response within.
   */
  responseTimeout?: number;
}

/**
 * Default response timeout.
 *
 * The default timeout the request native app function will wait
 * for the response from the native app.
 */
export const defaultResponseTimeout = 5000;

/**
 * Request Native App function.
 *
 * This function allows the web app to send an request to the native
 * app and wait for the response.
 *
 * The response event must have the type specified in response event
 * type. Any events of other types are ignored.
 *
 * The response event must have the event ID specified in event ID
 * parameter. Any events that have other IDs are ignored.
 *
 * When there is no response received under the specified timeout,
 * the error will returned.
 *
 * Usage:
 * ```tsx
 * const response = await requestNativeApp({
 *   eventId: '2fc9a844-82f7-47af-ab94-ff0bb8d6445f',
 *   requestPayload: {
 *     ssoOpenStrategy: 'OpenInIframe',
 *   },
 *   requestEventType: 'ast.launchSSO',
 *   responseEventType: 'ast.launchSSOResult',
 * });
 * ```
 *
 * @remarks This hook is intended to be used in embedded mode only.
 */
export function requestNativeApp({
  eventId,
  requestPayload,
  requestEventType,
  responseEventType,
  responseTimeout = defaultResponseTimeout,
}: RequestNativeAppParams) {
  return new Promise<NativeAppSuccessResponse>((resolve, reject) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    // The handler only fires once.
    const eventHandler = (event: CustomEvent<NativeAppResponse>) => {
      const response = { ...event.detail };

      if (response.eventId === eventId) {
        clearTimeout(timeoutId);

        window.removeEventListener(responseEventType, eventHandler as EventListener);

        // All the error native app responses are considered as errors.
        if (response.error) {
          reject(response);
        }

        resolve(response as NativeAppSuccessResponse);
      }
    };

    // The timeout only fires when no response received in time.
    timeoutId = setTimeout(() => {
      const response: NativeAppErrorResponse = {
        eventId,
        error: appResponseTimeoutError,
      };

      window.removeEventListener(responseEventType, eventHandler as EventListener);

      reject(response);
    }, responseTimeout);

    window.addEventListener(responseEventType, eventHandler as EventListener);

    // Once the both listener and timeout initialized everything
    // the function is ready to do request the app.
    dispatchNativeAppRequestEvent({
      type: requestEventType,
      detail: {
        eventId,
        ...requestPayload,
      },
    });
  });
}
