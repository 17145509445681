import { GET_BRANDING_INFO_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetBrandingInfoRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link GetBrandingInfoRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetBrandingInfoRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetBrandingInfoRequestMessage {
  return value.messageType === GET_BRANDING_INFO_REQUEST_MESSAGE_TYPE;
}
