import React from 'react';

import classNames from 'classnames';

import styles from './WizardStepWithHeader.pcss';

export interface WizardStepWithHeaderProps {
  readonly title?: string;
  readonly description?: string;
  readonly contentClassName?: string;
}

export const WizardStepWithHeader: React.FC<WizardStepWithHeaderProps> = ({
  title, description, contentClassName, children,
}) => (
  <>
    {title && (
      <h2 className={styles.title} data-stable-name="WizardStepTitle">
        {title}
      </h2>
    )}
    {description && (
      <p className={styles.description} data-stable-name="WizardStepDescription">
        {description}
      </p>
    )}
    <div className={classNames(styles.content, contentClassName)}>
      {children}
    </div>
  </>
);
