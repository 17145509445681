/**
 * Language culture changed event name.
 */
export const LANGUAGE_CULTURE_CHANGED_EVENT = 'ast.languageCultureChanged';

/**
 * Event fired when the language culture changes.
 *
 * Contains new culture id as a payload.
 */
export class LanguageCultureChangedEvent extends CustomEvent<string> {
  constructor(value: string) {
    super(LANGUAGE_CULTURE_CHANGED_EVENT, {
      detail: value,
    });
  }
}

declare global {
  interface WindowEventMap {
    [LANGUAGE_CULTURE_CHANGED_EVENT]: LanguageCultureChangedEvent;
  }
}
