import { ColorTheme } from '@app/common/types/ColorTheme';

import { ColorThemeChangedEvent } from './ColorThemeChangedEvent';

/**
 * Emits a custom event to notify other parts of the application about the change.
 */
export function notifyColorThemeChange(theme: ColorTheme) {
  window.dispatchEvent(new ColorThemeChangedEvent(theme));
}
