import fetchIntercept from 'fetch-intercept';

import { requestHandler, responseHandler, Tracing } from '@ast/opentelemetry';

import { appSettings } from '@app/core/appSettings';
import { setCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';
import { tracing } from '@app/core/opentelemetry/setupTracing';

export type InitSpanHandlersArgs = Record<string, {
  name: Flows,
  startOperationName: string,
  endOperationName: string
}>;

export const initSpanHandlers = (
  handlers: InitSpanHandlersArgs,
  apiEndpoint: string,
  setCorrSessionIdFn: Function,
  tracingFn: Tracing,
) => fetchIntercept.register({
  request(url: string, config: any) {
    return requestHandler(url, config, handlers, apiEndpoint, setCorrSessionIdFn, tracingFn);
  },

  response(response: any) {
    return responseHandler(response, handlers);
  },
});

export enum Flows {
  LoginToAccountsTransactions = 'from-login-to-transactions-history',
  LoginToBankAccounts = 'from-login-to-bank-accounts',
}

export const registerOpentelemetrySpans = () => {
  const handlers = {
    [Flows.LoginToAccountsTransactions]: {
      name: Flows.LoginToAccountsTransactions,
      startOperationName: 'PostLoginStep',
      endOperationName: 'GetTransactionsHistory',
    },
    [Flows.LoginToBankAccounts]: {
      name: Flows.LoginToBankAccounts,
      startOperationName: 'PostLoginStep',
      endOperationName: 'GetBankAccounts',
    },
  };

  return initSpanHandlers(handlers, appSettings().apiEndpoint, setCorrSessionId, tracing);
};
