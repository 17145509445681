import { isObject, isString } from '@app/common/utils/assertion';

import { SetInMemoryStorageItemParams } from '../types';

/**
 * Checks if the value is a {@link SetInMemoryStorageItemParams}.
 */
export function isSetInMemoryStorageItemParams(value: unknown): value is SetInMemoryStorageItemParams {
  return (
    isObject(value)

    && 'key' in value
    && isString(value.key)

    && 'value' in value
    && isString(value.value)
  );
}
