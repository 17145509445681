import { SUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE } from '../constants';
import { SubscribeColorThemeRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link SubscribeColorThemeRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isSubscribeColorThemeRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SubscribeColorThemeRequestMessage {
  return value.messageType === SUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE;
}
