/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateUserCustomDataMutationVariables = Types.Exact<{
  scope: Types.Scalars['String'];
  data: Types.Scalars['String'];
}>;


export type UpdateUserCustomDataMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCustomData: (
    { __typename?: 'UpdateUserCustomDataResult' }
    & Pick<Types.UpdateUserCustomDataResult, 'successful'>
    & { errors: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, warnings: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, infos: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>> }
  ) }
);


export const UpdateUserCustomDataDocument = gql`
    mutation UpdateUserCustomData($scope: String!, $data: String!) {
  updateUserCustomData(scope: $scope, data: $data) {
    successful
    errors {
      context
      field
      messages
      faultType
    }
    warnings {
      context
      field
      messages
      faultType
    }
    infos {
      context
      field
      messages
      faultType
    }
  }
}
    `;
export type UpdateUserCustomDataMutationFn = Apollo.MutationFunction<UpdateUserCustomDataMutation, UpdateUserCustomDataMutationVariables>;

/**
 * __useUpdateUserCustomDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserCustomDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCustomDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCustomDataMutation, { data, loading, error }] = useUpdateUserCustomDataMutation({
 *   variables: {
 *      scope: // value for 'scope'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserCustomDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCustomDataMutation, UpdateUserCustomDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCustomDataMutation, UpdateUserCustomDataMutationVariables>(UpdateUserCustomDataDocument, options);
      }
export type UpdateUserCustomDataMutationHookResult = ReturnType<typeof useUpdateUserCustomDataMutation>;
export type UpdateUserCustomDataMutationResult = Apollo.MutationResult<UpdateUserCustomDataMutation>;
export type UpdateUserCustomDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserCustomDataMutation, UpdateUserCustomDataMutationVariables>;