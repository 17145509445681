import { WizardConstants } from '@app/common/configurable-wizards';

import { I18N_NS } from '../constants';

export enum WizardSteps {
  UserName = 'UserName',
  SelectOneTimePinChannel = 'SelectOneTimePinChannel',
  SelectOneTimePinAddress = 'SelectOneTimePinAddress',
  ValidateOneTimePin = 'ValidateOneTimePin',
  EmailVerification = 'VerificationEmailRequest',
  QuickLogin = 'QuickLogin',
  UserAgreement = 'UserAgreement',
  // UI-only step
  QuickLoginSetup = 'QuickLoginSetup',
  LoginResult = 'LoginResult',
}

export const Constants: WizardConstants<WizardSteps> = {
  // eslint-disable-next-line i18next/no-literal-string
  I18N_NS,
  WIZARD_NAME: 'UserLogin',
};

export enum QuickLoginUserStatus {
  NoBiometricDevice = 'NoBiometricDevice',
  NoDevicesWithEnabledBiometrics = 'NoDevicesWithEnabledBiometrics',
  NoDevicesWithAllowedBiometrics = 'NoDevicesWithAllowedBiometrics',
  BiometricAuthenticationIsAvailable = 'BiometricAuthenticationIsAvailable',
}

export enum QuickLoginSetupMode {
  InstallationRequired = 'InstallationRequired',
  BiometricSetupRequired = 'BiometricSetupRequired',
}

export interface QuickLoginSetupState {
  readonly setupMode?: QuickLoginSetupMode;
}

export const LOGIN_MODE_KEY = 'LoginMode';

export enum AuthContextDataKeys {
  passkeyAuthConditional = 'PasskeyAuthConditional',
  recaptchaPassed = 'RecaptchaPassed',
}
