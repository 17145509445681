import React, { Ref } from 'react';

import classNames from 'classnames';

import { Button } from '@ast/magma';

import styles from './styles.pcss';

export type ToasButtonProps = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'look' | 'predefinedSize'>;

/**
 * Styled button for used inside toast notifications.
 * Features:
 * - Monohromic look.
 * - Outlined look
 * - Light and dark theme support
 * - Small predefined size
 */
export const ToasButton = React.forwardRef((
  { children, ...buttonProps }: ToasButtonProps,
  ref: Ref<HTMLButtonElement>,
) => (
  <Button
    {...buttonProps}
    look="outlined"
    predefinedSize="small"
    ref={ref}
    className={
        classNames(styles.toastButton, buttonProps.className)
      }
  >
    {children}
  </Button>
));
