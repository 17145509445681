export const noop = () => {};

export function debounce<T extends (...args: any[]) => any>(func: T, timeout: number): [T, () => void];
export function debounce(
  func: (...args: any[]) => any,
  timeout: number,
) {
  let timeoutRef: number | null;

  function clearTimer() {
    if (timeoutRef) {
      window.clearTimeout(timeoutRef);
      timeoutRef = null;
    }
  }

  return [
    (...args: any[]) => {
      clearTimer();
      timeoutRef = window.setTimeout(() => func(...args), timeout);
    },
    clearTimer,
  ];
}

/**
 * Wraps the passed functionto suppress the next function call
 * until the timeout of the previous call has been completed.
 * @example throttle(() => {}, 1000) // returns the wrapped fn, that may be performed not frequent than once in 1000 ms
 */
export function throttle<F extends Function>(func: F, timeout: number): F {
  let timeoutRef: number | null;

  const newFunc = (...args: any[]) => {
    if (timeoutRef == null) {
      timeoutRef = window.setTimeout(() => {
        func(...args);
        timeoutRef = null;
      }, timeout);
    }
  };

  return <any>newFunc;
}
