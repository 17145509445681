/* eslint-disable max-len */
import { AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX, AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX } from '../../constants';

export const GET_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LoginStatus.GetIsAuthenticated`;

export const SUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LoginStatus.SubscribeIsAuthenticated`;

export const UNSUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LoginStatus.UnsubscribeIsAuthenticated`;

export const IS_AUTHENTICATED_CHANGED_NOTIFICATION_MESSAGE_TYPE = `${AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX}.LoginStatus.IsAuthenticatedChanged`;
