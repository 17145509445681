/* eslint-disable class-methods-use-this */
import { calcBreakpoint } from '@ast/magma/breakpoints/useBreakpoint';

import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';

import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetScreenInfoRequestMessage } from './assertions/isGetScreenInfoRequestMessage';

import { GET_SCREEN_INFO_REQUEST_MESSAGE_TYPE } from './constants';
import { GetScreenInfoResponseMessage } from './types';

/**
 * Service for retrieving the basic screen information: device type, orientation, and if the web app is running
 * in embedded mode.
 */
export class ScreenInfoAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      GET_SCREEN_INFO_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.sender = sender;
  }

  async handle(request: AgileSDKRequest) {
    const { message, sender } = request;

    if (!isGetScreenInfoRequestMessage(message)) {
      return false;
    }

    // Compute screen dimensions the same way as Magma UI does.
    const width = window.innerWidth || window.screen.width;
    const height = window.innerHeight || window.screen.height;

    // Use Magma UI breakpoint to keep the same logic.
    const breakpoint = calcBreakpoint(width, height);

    const response: GetScreenInfoResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        device: breakpoint.device,
        orientation: breakpoint.orientation,
        isEmbedded: isEmbeddedMode(),
      },
    };

    this.sender(response, sender);

    return true;
  }
}
