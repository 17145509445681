import { GraphQLError } from 'graphql';
import { FetchResult, Observable } from '@apollo/client';
import { ErrorHandler, onError } from '@apollo/client/link/error';

import { isNotEmptyArray, isNotEmptyString, isUndefined } from '@app/common/utils';
import { ErrorCodes } from '@app/queryTyping';
import { TriggeredEventCanceledApolloError } from './TriggeredEventCanceledApolloError';
import { TriggeredEventNotifier } from './TriggeredEventNotifier';
import { ChallengeRequiredGraphQLErrorExtension } from './types';

const isTriggeredEventRequiredError = (error: GraphQLError) => (
  error.extensions?.code === ErrorCodes.TRIGGERED_EVENT_REQUIRED
);

const findTriggeredEventRequiredError = (errors?: readonly GraphQLError[]): GraphQLError | undefined => {
  if (isNotEmptyArray(errors)) {
    return errors.find(isTriggeredEventRequiredError);
  }

  return undefined;
};

// eslint-disable-next-line consistent-return
export const triggeredEventHandler: ErrorHandler = (data) => {
  const {
    forward, operation, graphQLErrors, response,
  } = data;

  const error = findTriggeredEventRequiredError(graphQLErrors);
  if (error) {
    const { challengeId, requestId } = error.extensions as ChallengeRequiredGraphQLErrorExtension;

    if (isNotEmptyString(challengeId)) {
      const triggeredEventNotifier = TriggeredEventNotifier.getInstance();
      // eslint-disable-next-line i18next/no-literal-string
      triggeredEventNotifier.notifyListeners('challengeRequired', { challengeId });

      // stop TE error propagation
      if (!isUndefined(response)) {
        // @ts-ignore
        response.errors = null;
      }

      // an observable that would be completed after passing/cancelation the TE challenge
      const observable = new Observable<FetchResult>((observer) => {
        // handling TE challenge success passing
        const challengePassedListener = triggeredEventNotifier.addListener('challengePassed', () => {
          // set additional http headers required to passing TE fronm the DS side
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              'x-te-request-id': requestId,
            },
          }));
          // make an initial request again after passing TE challenge
          const chainObservable = forward(operation);

          let isChallengesPassed = false;
          chainObservable.subscribe({
            error: (err) => {
              observer.error(err);
            },
            next: async (resp) => {
              if (findTriggeredEventRequiredError(resp.errors)) {
                // recursively init another TE challenge if required
                await triggeredEventHandler({
                  ...data,
                  graphQLErrors: resp.errors,
                });
              } else {
                isChallengesPassed = true;
                observer.next(resp);
              }
            },
            complete: () => {
              if (isChallengesPassed) {
                observer.complete();
              }
            },
          });
        });

        // handling TE challenge user cancelation
        const challengeCanceledListener = triggeredEventNotifier.addListener('challengeCanceled', () => {
          // throw TE canceled error
          observer.error(new TriggeredEventCanceledApolloError());
          observer.complete();
        });

        // this function is called when a client unsubscribes from localObservable
        return () => {
          triggeredEventNotifier.removeListener('challengePassed', challengePassedListener);
          triggeredEventNotifier.removeListener('challengeCanceled', challengeCanceledListener);
        };
      });

      return observable;
    }
  }
};

/**
 * The Apollo link to catch and handle TE chellenge-required exceptions
 */
export const triggeredEventApolloLink = onError(triggeredEventHandler);
