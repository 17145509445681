import { FieldErrors } from 'react-hook-form';
import { combineNames } from '@app/common/configurable-wizards/utils';
import { isReactHookFormFieldError } from './isReactHookFormFieldError';

/**
 * Returns the name of the first field mentioned in validation errors
 */
export function getFirstInvalidFieldName(errors: FieldErrors, fieldName = ''): string {
  if (isReactHookFormFieldError(errors)) {
    return fieldName;
  }

  const errorKeys = Object.keys(errors);
  if (errorKeys.length) {
    const firstErrorKey = errorKeys[0];
    return getFirstInvalidFieldName(errors[firstErrorKey], combineNames(fieldName, firstErrorKey));
  }

  return fieldName;
}
