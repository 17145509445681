import join from 'url-join';

import { PageURL } from '@app/core/widgets/pages';

import { CHANGE_PASSWORD_PATH, PROFILE_SECURITY_PATH } from '../const';

export function getUrlToMenuItem(menuItemId: string) {
  return join(PageURL.CONTACTS_AND_SETTINGS, menuItemId);
}

export function getUrlToField(menuItemId: string, fieldId: string) {
  return join(getUrlToMenuItem(menuItemId), fieldId);
}

export function getPathToPasswordChange() {
  return getUrlToField(PROFILE_SECURITY_PATH, CHANGE_PASSWORD_PATH);
}
