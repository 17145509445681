import { INVOKE_BACKEND_COMPONENT_REQUEST_MESSAGE_TYPE } from '../constants';
import { InvokeBackendComponentRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isInvokeBackendComponentParams } from './isInvokeBackendComponentParams';

/**
 * Checks if the value is a {@link InvokeBackendComponentRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isInvokeBackendComponentRequestMessage(value: AgileSDKRequestMessage<unknown>): value is InvokeBackendComponentRequestMessage {
  return (
    value.messageType === INVOKE_BACKEND_COMPONENT_REQUEST_MESSAGE_TYPE
    && isInvokeBackendComponentParams(value.payload)
  );
}
