import React from 'react';

import { PartialProps } from 'typescriptUtilityTypes';

import { WizardButtons } from '@app/common/components/configurable-wizards/wizard-buttons';

import { useUserLoginTranslation } from '../hooks/useUserLoginTranslation';

type WizardButtonsProps = React.ComponentProps<typeof WizardButtons>;
export type SubuserEnrollmentWizardButtonsProps =
  PartialProps<WizardButtonsProps, 'backTitle' | 'cancelTitle' | 'continueTitle' | 'direction'>;
export const LoginWizardButtons: React.FC<SubuserEnrollmentWizardButtonsProps> = (props) => {
  const { t } = useUserLoginTranslation();
  return (
    <WizardButtons
      backTitle={t(
        'wizard.buttons.back.title|Wizard\'s back button text', 'Back',
      )}
      cancelTitle={t(
        'wizard.buttons.cancel.title|Wizard\'s cancel button text', 'Cancel',
      )}
      continueTitle={t(
        'wizard.buttons.continue.title|Wizard\'s submit button text', 'Submit',
      )}
      direction="column"
      {...props}
    />
  );
};
