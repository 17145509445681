import { Widget } from '@app/queryTyping';

import { getDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';

import { PureWidget } from '../types';

import { HtmlClosableParams, PreservedSettings } from './types';

const STORAGE_KEY = 'ast.htmlWidget.settings';
const DEFAULT_SHOW_TIMES = 1;
const DEFAULT_WAIT_SESSIONS = 0;
const MAX_PRESERVED_SETTINGS = 10;
const ID_REGEX = /(?=data-closable-id="([\w]+)")/;
const SHOW_TIMES_REGEX = /(?=data-show-times="([\d]+))/;
const WAIT_REGEX = /(?=data-wait="([\d]+))/;

export const getClosableParams = (widget: PureWidget<Widget>) => {
  const html = widget.html || '';

  return {
    id: ID_REGEX.exec(html)?.[1] || '',
    showTimes: Number(SHOW_TIMES_REGEX.exec(html)?.[1]) || DEFAULT_SHOW_TIMES,
    waitSessions: Number(WAIT_REGEX.exec(html)?.[1]) || DEFAULT_WAIT_SESSIONS,
  } as HtmlClosableParams;
};

export const isClosableWidget = (widget: PureWidget<Widget>) => !!ID_REGEX.exec(widget.html || '')?.[1];

export const getPreservedSettings = () => JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]') as PreservedSettings[];
export const setPreservedSettings = (settingsList: PreservedSettings[]) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(settingsList));
};

export const closeWidget = (widget: PureWidget<Widget>) => {
  const settingsList = getPreservedSettings();
  const widgetParams = getClosableParams(widget);

  setPreservedSettings(
    settingsList.map((item) => {
      if (item.id === widgetParams.id) {
        return {
          ...item,
          closed: true,
          closedTimes: item.closedTimes + 1,
          untilNextShow: widgetParams.waitSessions,
        };
      }

      return item;
    }),
  );
};

export const checkEligibility = (widget: PureWidget<Widget>) => {
  const widgetParams = getClosableParams(widget);
  const sessionId = getDSSessionId().slice(0, 15);
  const settingsList = getPreservedSettings();

  const settings = settingsList.find((item) => item.id === widgetParams.id);

  if (!settings) {
    setPreservedSettings([
      {
        id: widgetParams.id!,
        closed: false,
        closedTimes: 0,
        lastSessionId: sessionId,
        untilNextShow: 0,
      },
      ...settingsList,
    ].slice(0, MAX_PRESERVED_SETTINGS));
    return true;
  }

  if (
    settings.closed
    && (settings.closedTimes >= widgetParams.showTimes
      || settings.lastSessionId === sessionId)
  ) {
    return false;
  }

  if (settings.closed && settings.lastSessionId !== sessionId) {
    setPreservedSettings(
      settingsList.map((item) => {
        if (item.id === widgetParams.id) {
          return {
            ...item,
            closed: settings.untilNextShow > 0,
            lastSessionId: sessionId,
            untilNextShow:
              settings.untilNextShow > 0 ? item.untilNextShow - 1 : 0,
          };
        }

        return item;
      }),
    );

    return settings.untilNextShow === 0;
  }

  return true;
};
