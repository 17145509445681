/* eslint-disable no-underscore-dangle */
/**
 * Migration is transformation function from version to version + 1.
 */
export type Migration = (prev:any) => any;

/**
 * Versioned storage is storage which supports version specified and full list of migrations to
 * read previos versions.
 */
export class VersionedSerializer<T> {
  version:number;

  migrations:Migration[];

  constructor(version:number = 1, migrations:Migration[] = []) {
    // eslint-disable-next-line i18next/no-literal-string
    if (version < 1) throw new Error('Version must start with 1.');
    this.migrations = migrations;
    this.version = version;
  }

  /**
   * Write data with current version.
   * @param value data to write.
   * @returns serialized data.
   */
  serialize(value:T):string {
    return JSON.stringify({
      saved: value,
      __version: this.version,
    });
  }

  /**
   * Read reviosly serilized data. If it was serilized in previous version when migrations
   * will be applyied to convert data to latest version format.
   * In case if some migration is not specfied in storage when method will throw an error.
   * @param saved serialized data.
   * @returns data with latest version
   */
  deserialize(saved:string):T {
    let { __version: version, saved: result } = JSON.parse(saved);
    while (version < this.version) {
      // eslint-disable-next-line no-plusplus
      const migrationIndex = version - 1;
      if (this.migrations.length <= migrationIndex) {
        throw new Error(`Migration from version "${version}" to "${version + 1}" is not provided.`);
      }
      result = this.migrations[migrationIndex](result);
      version += 1;
    }

    return result as T;
  }
}
