/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InvokeBackendComponentQueryVariables = Types.Exact<{
  componentName: Types.Scalars['String'];
  arguments: Array<Types.BackendComponentArgument> | Types.BackendComponentArgument;
}>;


export type InvokeBackendComponentQuery = (
  { __typename?: 'Query' }
  & { invokeBackendComponent: Types.Maybe<(
    { __typename: 'InvokeBackendComponentResult' }
    & Pick<Types.InvokeBackendComponentResult, 'result' | 'successful'>
    & { errors: Types.Maybe<Array<(
      { __typename: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, warnings: Types.Maybe<Array<(
      { __typename: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, infos: Types.Maybe<Array<(
      { __typename: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>> }
  )> }
);


export const InvokeBackendComponentDocument = gql`
    query InvokeBackendComponent($componentName: String!, $arguments: [BackendComponentArgument!]!) {
  invokeBackendComponent(componentName: $componentName, arguments: $arguments) {
    __typename
    result
    successful
    errors {
      context
      field
      messages
      faultType
      __typename
    }
    warnings {
      context
      field
      messages
      faultType
      __typename
    }
    infos {
      context
      field
      messages
      faultType
      __typename
    }
  }
}
    `;

/**
 * __useInvokeBackendComponentQuery__
 *
 * To run a query within a React component, call `useInvokeBackendComponentQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeBackendComponentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeBackendComponentQuery({
 *   variables: {
 *      componentName: // value for 'componentName'
 *      arguments: // value for 'arguments'
 *   },
 * });
 */
export function useInvokeBackendComponentQuery(baseOptions: Apollo.QueryHookOptions<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>(InvokeBackendComponentDocument, options);
      }
export function useInvokeBackendComponentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>(InvokeBackendComponentDocument, options);
        }
export type InvokeBackendComponentQueryHookResult = ReturnType<typeof useInvokeBackendComponentQuery>;
export type InvokeBackendComponentLazyQueryHookResult = ReturnType<typeof useInvokeBackendComponentLazyQuery>;
export type InvokeBackendComponentQueryResult = Apollo.QueryResult<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>;