/**
 * @TODO add isFalsy / isTruthy assertions
 */

import { ApolloError, isApolloError } from '@apollo/client';

import { UserError } from '../errors/UserError';

import { Dict } from './types';

/**
 * Return true if value is set to boolean true or string "true" value.
 * @param value html attribute value
 * @remark Do we have to also accept uppercase boolean string 'True'?
 */
export function isTruthy(value: any): value is boolean {
  return value === 'true' || value === true;
}

/**
 * Return true if value is set to boolean (true|false) or string ("true"|"false") value.
 */
export function isBoolean(value: any): value is boolean {
  return value === 'true' || value === 'false' || typeof value === 'boolean';
}

// Generic assertions
export function isDefined(value: any) {
  return typeof value !== 'undefined' && value !== undefined;
}

export function isUndefined(value: any): value is undefined {
  return typeof value === 'undefined' || value === undefined;
}

export function isNull(value: any): value is null {
  return value === null;
}

export function isNullOrUndefined(value: any) {
  return isNull(value) || isUndefined(value);
}

// Number assertions
/**
 * Returns true if value has a number type
 * @example isNumber(100) => true
 * @example isNumber(0.5) => true
 * @example isNumber(-100) => true
 * @example isNumber(NaN) => true
 * @example isNumber(Infinity) => true
 * @example isNumber('20') => false
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

/**
 * Returns true if it's any arithmetic number (including after type conversion)
 * @example isNumeric(100) => true
 * @example isNumeric('20') => true
 * @example isNumeric(NaN) => false
 * @example isNumeric(Infinity) => false
 */
export function isNumeric(value: any) {
  try {
    return value != null && value - parseFloat(value) + 1 >= 0;
  } catch (error) {
    return false;
  }
}

/**
 * Returns true if no remainder of the division
 * @example isMultipleOf(4, 2) -> true
 * @example isMultipleOf(4, 3) -> false
 */
export function isMultipleOf(divided: number, multiplyTo: number) {
  return divided % multiplyTo === 0;
}

// String assertions
/**
 * String type guard.
 * @example isNumber('something') => true
 * @example isNumber(0.5) => false
 * @example isNumber(-100) => false
 * @example isNumber(NaN) => false
 * @example isNumber(Infinity) => false
 * @param value value to test
 * @returns true if value is string
 */
export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNotEmptyString(value: any, allowOnlySpaces: boolean = true): value is string {
  if (isString(value)) {
    return (
      allowOnlySpaces
        ? value !== ''
        : value.trim().length !== 0
    );
  }
  return false;
}

// Function assertions
export function isFunction<T extends Function = Function>(
  value: unknown,
): value is T {
  return typeof value === 'function';
}

// Array assertions
export function isArray<T>(value: any): value is Array<T> {
  return Array.isArray(value);
}

export function isEmptyArray<T>(value: any): value is Array<T> {
  return isArray(value) && value.length === 0;
}

export function isNotEmptyArray<T>(value: any): value is Array<T> {
  return isArray(value) && value.length > 0;
}

// Object assertions
export function isObject(value: any): value is Dict {
  const type = typeof value;
  return (
    value != null
    && (type === 'object' || type === 'function')
    && !isArray(value)
  );
}

export function isEmptyObject(value: any) {
  return isObject(value) && Object.keys(value).length === 0;
}

/**
 * Checks if the specified object has a property with the specified name
 * of the specified type.
 *
 * It is common to use it with type guard functions, i.e.:
 * ```ts
 * const result = hasOwnPropertyOfType(user, 'name', isString);
 * ```
 *
 * It is also possible to test value over multiple types by combining multiple
 * type guards into a single one:
 * ```ts
 * const result = hasOwnPropertyOfType(user, 'name', (value) => isString(value) || isFunction(value));
 * ```
 * @param obj target object
 * @param key property key
 * @param guard property value type check function
 */
export const hasOwnPropertyOfType = (
  obj: unknown,
  key: string,
  guard: (value: unknown) => boolean,
) => (
  isObject(obj)
  && Object.prototype.hasOwnProperty.call(obj, key)
  && guard((obj as any)[key])
);

// Event assertions
/**
 * Custom event typeguard.
 * @param event event
 * @returns true if event is custom event
 */
export function isCustomEvent(event: Event): event is CustomEvent {
  return event instanceof CustomEvent;
}

// Errors assertions
/**
 * UserError type guard.
 */
export function isUserError(error: Error): error is UserError {
  return error instanceof UserError;
}

/**
 * Returns true if it's a lockout error
 * @todo use lockout error code instead of the message
 */
export function isError(error: any): error is Error {
  return error instanceof Error;
}

/**
 * Returns true if it's a lockout error
 * @todo use lockout error code instead of the message
 */
export function isLockoutError(error: Error): error is ApolloError {
  if (isApolloError(error)) {
    const { graphQLErrors } = error;
    return graphQLErrors.some(
      (graphQLError) => graphQLError.extensions?.message === 'You have been locked out.',
    );
  }

  return false;
}

/**
 * Checks if a settled promise result is a rejected result.
 *
 * @returns true if the result is a rejected promise result.
 */
export function isPromiseRejectedResult<T>(result: PromiseSettledResult<T>): result is PromiseRejectedResult {
  return result.status === 'rejected';
}
