import React, {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  Ref,
  useEffect,
} from 'react';

import { Spinner } from '@ast/magma/components/spinner';

import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import { useZoneWidgets } from './hooks/useZoneWidgets';

import { ZoneName } from './constants';

/**
 * Widget zone component properties.
 */
export interface WidgetZoneProps {
  /**
   * Widget zone name.
   */
  readonly zoneName: ZoneName;

  /**
   * Callback on widgets are rendered.
   */
  readonly onRenderWidgets?: (widgetCount: number) => void;
}

/**
 * Widget zone component.
 *
 * Renders widgets for a specific zone.
 */
export const WidgetZone = React.memo(forwardRef(
  (
    props: WidgetZoneProps & HTMLAttributes<HTMLDivElement>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { zoneName, onRenderWidgets, ...otherDivProps } = props;

    const zoneContainerRef: MutableRefObject<HTMLDivElement | null> = useForwardedRef<HTMLDivElement>(ref);
    const { widgets, loading } = useZoneWidgets(zoneName);

    useEffect(() => {
      if (loading) {
        return;
      }

      if (onRenderWidgets) {
        onRenderWidgets(widgets.length);
      }
    }, [widgets, loading]);

    return (
      <React.Suspense fallback={<Spinner size="md" />}>
        <div
          ref={zoneContainerRef}
          widget-zone={zoneName}
          {...otherDivProps}
          data-stable-name="WidgetZone"
        >
          {widgets}
        </div>
      </React.Suspense>
    );
  },
));
