import { ApolloError, ServerError, ServerParseError } from '@apollo/client';

import { ErrorTypes } from '@app/common/errors/errorProcessors/constants';

import { getFixedT } from '@app/core/i18n';

import { UserError } from '../UserError';

import { ErrorProcessor } from './types';

const t = getFixedT(null, 'app');

export const getServerErrorMessage = () => ({
  message: t('server-error.message|Internal server error',
    'The server encountered a temporary error and could not complete your request.'),
  title: t('server-error.title|Internal server error',
    'Internal server error'),
});

/**
 * Apollo server error required properties.
 */
export type ApolloServerError = Error & {
  response: Response;
  statusCode: number;
};

/**
 * Check if error is server error
 * */
function isApolloServerError(error: Error): error is ApolloServerError {
  return (error as ApolloServerError).statusCode !== undefined
         && (error as ApolloServerError).response !== undefined;
}

/**
 * Apollo network error processor always return user error.
 * @param error any error.
 */
export const networkErrorProcessor = (error:Error | ServerParseError | ServerError):UserError => {
  // Detect internal server error
  if (isApolloServerError(error) && error.statusCode === 500) {
    return new UserError({
      critical: true,
      message: getServerErrorMessage().message,
      title: getServerErrorMessage().title,
      originalError: error,
      type: ErrorTypes.InternalServerError,
    });
  }

  // Report all other errors as communication
  return new UserError({
    critical: true,
    title: t('communication-error.title|Communication error title', 'Network error'),
    message: t('communication-error.message|Communication error message', 'Unexpected communication error occurred.'),
  });
};

/**
 * Apollo error processor always return user error.
 * @param error any error.
 */
export const apolloErrorProcessor:ErrorProcessor = (error:Error):Error => {
  if (error instanceof ApolloError) {
    const apolloError = error as ApolloError;
    // Handle network error separately
    if (apolloError.networkError) {
      return networkErrorProcessor(apolloError.networkError);
    }

    return new UserError({
      critical: true,
      message: getServerErrorMessage().message,
      title: getServerErrorMessage().title,
      originalError: apolloError,
      type: ErrorTypes.InternalServerError,
    });
  }

  return error;
};
