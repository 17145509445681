/* eslint-disable class-methods-use-this */
import { LanguageCultureNotifier } from '@app/core/contexts/localizationContext/utils/LanguageCultureNotifier';

import { ValueNotifierAgileSDKService } from '../../utils/abstract/ValueNotifierAgileSDKService/ValueNotifierAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKAddress, AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetLanguageCultureRequestMessage } from './assertions/isGetLanguageCultureRequestMessage';
import { isSubscribeLanguageCultureRequestMessage } from './assertions/isSubscribeLanguageCultureRequestMessage';
import { isUnsubscribeLanguageCultureRequestMessage } from './assertions/isUnsubscribeLanguageCultureRequestMessage';

import {
  GET_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
  LANGUAGE_CULTURE_CHANGED_NOTIFICATION_MESSAGE_TYPE,
  SUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
  UNSUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
} from './constants';
import {
  GetLanguageCultureResponseMessage,
  LanguageCultureChangeNotificationMessage,
  SubscribeLanguageCultureResponseMessage,
  UnsubscribeLanguageCultureResponseMessage,
} from './types';

/**
 * Agile SDK service for the language culture tracking.
 *
 * Responds with the current language culture and allows to subscribe to language culture changes.
 */
export class LanguageCultureAgileSDKService extends ValueNotifierAgileSDKService<LanguageCultureNotifier> {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const notifier = new LanguageCultureNotifier();

    super(
      notifier,
      listener,
      new Set([
        GET_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
        SUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
        UNSUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE,
      ]),
    );

    this.sender = sender;
  }

  async init() {
    await super.init();

    this.notifier.init();
  }

  async dispose() {
    await super.dispose();

    this.notifier.dispose();
  }

  isValueRequest(request: AgileSDKRequest): boolean {
    return isGetLanguageCultureRequestMessage(request.message);
  }

  isSubscribeRequest(request: AgileSDKRequest): boolean {
    return isSubscribeLanguageCultureRequestMessage(request.message);
  }

  isUnsubscribeRequest(request: AgileSDKRequest): boolean {
    return isUnsubscribeLanguageCultureRequestMessage(request.message);
  }

  respondValueRequest(request: AgileSDKRequest): boolean {
    const response: GetLanguageCultureResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        languageCulture: this.notifier.value,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondSubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: SubscribeLanguageCultureResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: true,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondUnsubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: UnsubscribeLanguageCultureResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: false,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  notifyValueChange(target: AgileSDKAddress, subscriptionId: string): boolean {
    const notification: LanguageCultureChangeNotificationMessage = {
      version: '1.0',
      messageType: LANGUAGE_CULTURE_CHANGED_NOTIFICATION_MESSAGE_TYPE,
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        languageCulture: this.notifier.value,
      },
    };

    this.sender(notification, target);

    return true;
  }
}
