/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type ForceUserLogoutNotificationSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type ForceUserLogoutNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { forceUserLogoutNotification: (
    { __typename?: 'ForceUserLogoutNotification' }
    & Pick<Types.ForceUserLogoutNotification, 'reasonText'>
  ) }
);


export const ForceUserLogoutNotificationDocument = gql`
    subscription forceUserLogoutNotification {
  forceUserLogoutNotification {
    reasonText
  }
}
    `;

/**
 * __useForceUserLogoutNotificationSubscription__
 *
 * To run a query within a React component, call `useForceUserLogoutNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useForceUserLogoutNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForceUserLogoutNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useForceUserLogoutNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ForceUserLogoutNotificationSubscription, ForceUserLogoutNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ForceUserLogoutNotificationSubscription, ForceUserLogoutNotificationSubscriptionVariables>(ForceUserLogoutNotificationDocument, options);
      }
export type ForceUserLogoutNotificationSubscriptionHookResult = ReturnType<typeof useForceUserLogoutNotificationSubscription>;
export type ForceUserLogoutNotificationSubscriptionResult = Apollo.SubscriptionResult<ForceUserLogoutNotificationSubscription>;