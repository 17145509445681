import * as React from 'react';
import {
  GetApplicationWidgetsQuery,
  useGetApplicationWidgetsQuery,
} from '@app/core/widgets/queries/queryTyping/getApplicationWidgets';
import { useEffect } from 'react';
import { AUTH_TOKEN_UPDATE_EVENT } from '@app/core/authentication/authenticationToken';
import { createGenericContext } from '../../../common/contexts/createGenericContext';

/**
 * Context to query application widgets.
 */
const [useAppWidgetsContext, AppWidgetsContextProvider] = createGenericContext<GetApplicationWidgetsQuery | null>();

/**
 * Application widgets context provider.
 */
const AppWidgetsProvider: React.FC = ({ children }) => {
  const { data, error, refetch } = useGetApplicationWidgetsQuery();
  const authTokenUpdateHandler = React.useCallback(() => refetch(), [refetch]);

  useEffect(() => {
    window.addEventListener(AUTH_TOKEN_UPDATE_EVENT, authTokenUpdateHandler);
    return () => window.removeEventListener(AUTH_TOKEN_UPDATE_EVENT, authTokenUpdateHandler);
  }, [authTokenUpdateHandler]);

  if (error) throw error;

  return (
    <AppWidgetsContextProvider value={data ?? null}>
      {children}
    </AppWidgetsContextProvider>
  );
};

export { useAppWidgetsContext, AppWidgetsProvider };
