import { ObservableInterface, ObserverInterface } from './types';

/**
 * Represents a classic Observable design pattern, that lends itself to observe by external observers.
 */
export class Observable implements ObservableInterface {
  protected observers: Set<ObserverInterface>;

  private changed: boolean;

  constructor() {
    this.observers = new Set<ObserverInterface>();
    this.changed = true;
  }

  registerObserver(observer: ObserverInterface): void {
    this.observers.add(observer);
  }

  removeObserver(observer: ObserverInterface): void {
    this.observers.delete(observer);
  }

  /**
   * Notify observers and mark changed state to false
   */
  notifyObservers(data: any): void {
    if (this.changed) {
      this.observers.forEach((observer) => observer.update(data));
      this.changed = false;
    }
  }

  /**
   * Useful when we want to have more abilities to control notification frequency.
   * Therefore observers would be notified only we mark state as changed by that method
   */
  setChanged(): void {
    this.changed = true;
  }
}
