/* eslint-disable i18next/no-literal-string */
import {
  hasOwnPropertyOfType,
  isNumber,
  isObject,
  isString,
} from '@app/common/utils/assertion';

export const agileSDKPrefix = 'ast.sdk';

export const agileSDKRequestPrefix = `${agileSDKPrefix}.request`;

export const agileSDKResponsePrefix = `${agileSDKPrefix}.response`;

/**
 * Base Agile SDK request event data.
 */
export interface AgileSDKRequest {
  /**
   * Request ID.
   *
   * This ID is used to correlate request with the response.
   */
  id: string,
  /**
   * Request type.
   */
  type: string,
  /**
   * Protocol version.
   */
  version: number | string,
  /**
   * Client agent.
   *
   * Name and version of the library client uses to communicate.
   */
  client?: string,
  /**
   * Actual request data.
   */
  payload: unknown,
}

/**
 * Base Agile SDK response event data.
 */
export interface AgileSDKResponse {
  /**
   * Response ID.
   *
   * The request ID this response belongs to.
   */
  id: string,
  /**
   * Response type.
   */
  type: string,
  /**
   * Protocol version.
   */
  version: number | string,
  /**
   * Actual response data.
   */
  payload: unknown,
}

export type RequestHandler<
  T extends AgileSDKRequest = AgileSDKRequest,
  R extends AgileSDKResponse = AgileSDKResponse,
> = (request: T) => Promise<R>;

export function isAgileSDKRequest(data: unknown): data is AgileSDKRequest {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'id', isString)
    && hasOwnPropertyOfType(data, 'type', (value) => isString(value) && value.startsWith(agileSDKRequestPrefix))
    && hasOwnPropertyOfType(data, 'version', (value) => isString(value) || isNumber(value))
    && 'payload' in data
  );
}

export function isAgileSDKResponse(data: unknown): data is AgileSDKResponse {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'id', isString)
    && hasOwnPropertyOfType(data, 'type', (value) => isString(value) && value.startsWith(agileSDKResponsePrefix))
    && hasOwnPropertyOfType(data, 'version', (value) => isString(value) || isNumber(value))
    && 'payload' in data
  );
}
