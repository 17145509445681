/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SetAdvertisementStatusMutationVariables = Types.Exact<{
  providerId: Types.Scalars['String'];
  adId: Types.Scalars['String'];
  status: Types.AdvertisementStatus;
}>;


export type SetAdvertisementStatusMutation = (
  { __typename?: 'Mutation' }
  & { setAdvertisementStatus: (
    { __typename?: 'OperationResult' }
    & Pick<Types.OperationResult, 'success'>
    & { errors: Array<Types.Maybe<(
      { __typename?: 'UserFriendly' }
      & Pick<Types.UserFriendly, 'errorMessage'>
    ) | { __typename?: 'InvalidFormat' }>> }
  ) }
);


export const SetAdvertisementStatusDocument = gql`
    mutation SetAdvertisementStatus($providerId: String!, $adId: String!, $status: AdvertisementStatus!) {
  setAdvertisementStatus(providerId: $providerId, adId: $adId, status: $status) {
    success
    errors {
      ... on UserFriendly {
        errorMessage
      }
    }
  }
}
    `;
export type SetAdvertisementStatusMutationFn = Apollo.MutationFunction<SetAdvertisementStatusMutation, SetAdvertisementStatusMutationVariables>;

/**
 * __useSetAdvertisementStatusMutation__
 *
 * To run a mutation, you first call `useSetAdvertisementStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAdvertisementStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAdvertisementStatusMutation, { data, loading, error }] = useSetAdvertisementStatusMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      adId: // value for 'adId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetAdvertisementStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetAdvertisementStatusMutation, SetAdvertisementStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAdvertisementStatusMutation, SetAdvertisementStatusMutationVariables>(SetAdvertisementStatusDocument, options);
      }
export type SetAdvertisementStatusMutationHookResult = ReturnType<typeof useSetAdvertisementStatusMutation>;
export type SetAdvertisementStatusMutationResult = Apollo.MutationResult<SetAdvertisementStatusMutation>;
export type SetAdvertisementStatusMutationOptions = Apollo.BaseMutationOptions<SetAdvertisementStatusMutation, SetAdvertisementStatusMutationVariables>;