import { GET_COLOR_THEME_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetColorThemeRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link GetColorThemeRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetColorThemeRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetColorThemeRequestMessage {
  return value.messageType === GET_COLOR_THEME_REQUEST_MESSAGE_TYPE;
}
