import { useEffect } from 'react';

import { Field, setWizardStepState } from '..';
import { CurrentWizardStepFragment } from '../queries/queryTyping/current-wizard-step';
import { WizardStepStateDispatcher } from './types';

interface WizardStepSetDataDispatcher<T extends CurrentWizardStepFragment> {
  (
    dispatch: WizardStepStateDispatcher,
    step: T | null | undefined,
  ): void;
}

/**
 * Accepts wizard-step data and dispatches it to the state
 */
// eslint-disable-next-line max-len
export const setWizardStepData: WizardStepSetDataDispatcher<CurrentWizardStepFragment> = (dispatch, data) => {
  if (!data) { return; }

  const {
    allowBackRedirect,
    description,
    finalStep,
    fields,
    name,
    id,
    isTermination,
  } = data;

  dispatch(setWizardStepState({
    fields: (fields?.filter((f) => !!f) || []) as Field[],
    isFinal: finalStep,
    allowBackRedirect,
    description,
    name,
    id,
    isTermination,
  }));
};

/**
 * Hook: Accepts wizard-step data and dispatches it to the state
 */
// eslint-disable-next-line max-len
export const useWizardStepData: WizardStepSetDataDispatcher<CurrentWizardStepFragment> = (dispatch, data) => {
  useEffect(() => {
    setWizardStepData(dispatch, data);
  }, [dispatch, data]);
};
