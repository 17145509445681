import React, { FunctionComponent, HTMLAttributes } from 'react';

import styles from './CenterAlignContainer.pcss';

export interface ContainerProps
  extends HTMLAttributes<HTMLDivElement> {}

/**
 * Container centered on page.
 */
export const CenterAlignContainer: FunctionComponent<ContainerProps> = ({
  className,
  children,
}: ContainerProps) => (
  <div className={`${styles.container} ${className || ''}`}>{children}</div>
);
