import { useSessionStorageValue } from '@app/core/storage/browser/useStorageValue';
import { PageURL } from '@app/core/widgets/pages';

import { TOKEN_STORAGE_KEY, getAuthenticationToken } from '../authenticationToken';

/**
 * Returns true if user is logged in.
 * BUT if you expect that in your cmponent the login state might change - use useIsLoggedIn hook.
 * It will automatically update the component when the login state changes.
 */
export const isLoggedIn = (): boolean => !!getAuthenticationToken();
export const isLoginPage = (): boolean => window.location.pathname === PageURL.USER_LOGIN;
export const useIsLoggedIn = () => !!useSessionStorageValue(TOKEN_STORAGE_KEY);
