import React, { useEffect, useState } from 'react';

import { isMultipleOf, isUndefined } from '@app/common/utils';
import { usePrevious } from '@app/common/utils/hooks/usePrevious/usePrevious';

import styles from './CountdownAlert.pcss';

interface CountdownAlertProps {
  readonly countdownSeconds: number;
  readonly message: string;
  readonly countdownAction?: () => void;
}

export const CountdownAlert: React.FC<CountdownAlertProps> = ({
  countdownSeconds,
  // The message from a parent component. Looks like 'Smth will happen'.
  // The displayed message will look like 'Smth will happen in N seconds'.
  message,
  // The action to be executed when the countdown is over.
  countdownAction,
}) => {
  const secsInMinute = 60;
  const multiplier = 10;
  const messageTemplate = `${message} in {{ timeToLogout }}`;
  const [timeLeft, setTimeLeft] = useState(countdownSeconds);

  const prevSecsToLogout = usePrevious(countdownSeconds);

  // Returns true if it's a countdown starting
  const isCountdownJustStarting = () => (
    (isUndefined(prevSecsToLogout) || prevSecsToLogout === 0) && timeLeft !== 0
  );

  // Returns the remaining time message.
  // When the time is below one minute returns the number of seconds.
  // Otherwise, returns number of minutes.
  // E.g. 3 minutes/2 minutes/1 minute/59 seconds/.../1 second.
  const getMessage = () => {
    let quotient = Math.floor(timeLeft / secsInMinute);
    const remainder = timeLeft % secsInMinute;

    if (quotient === 0) {
      return remainder % multiplier === 1 ? `${remainder} second` : `${remainder} seconds`;
    }

    if (remainder > 0) {
      // eslint-disable-next-line no-plusplus
      quotient++;
    }

    return quotient % multiplier === 1 ? `${quotient} minute` : `${quotient} minutes`;
  };

  // Returns countdown message
  const getCurrentMessage = () => (
    timeLeft > 0
      // eslint-disable-next-line i18next/no-literal-string
      ? messageTemplate.replace('{{ timeToLogout }}', getMessage())
      : ''
  );

  const [webAccessabilityMessage, setWebAccessabilityMessage] = useState<string>(getCurrentMessage());

  useEffect(() => {
    const needToBeVoiced = () => (timeLeft >= secsInMinute
      ? timeLeft % secsInMinute === 0
      : isMultipleOf(timeLeft, 10));

    // On countdown starting, or once by 10 seconds, - update the accessibility message.
    // As a result, we will have the following sequence: 59, 50, 40..
    if (isCountdownJustStarting() || needToBeVoiced()) {
      setWebAccessabilityMessage(getCurrentMessage());
    }

    if (timeLeft === 0) {
      countdownAction?.();
    }
  }, [timeLeft]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <div
        className={styles.alert}
        data-stable-name="CountdownAlert"
        aria-atomic="false"
      >
        <span aria-hidden="true" data-stable-name="CountdownAlertMessage">
          {getCurrentMessage()}
        </span>
        {/* [MPH-44641]. "Phantom" element that contains audible text, used for screen readers */}
        <span
          className={styles.screenReaderMessage}
          data-stable-name="CountdownAlertAudibleMessage"
        >
          {webAccessabilityMessage}
        </span>
      </div>
    </>
  );
};
