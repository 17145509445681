import React, { HTMLAttributes } from 'react';

export enum HeadingLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

const availableHeadings = [
  // eslint-disable-next-line i18next/no-literal-string
  'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
];

export type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  readonly headingLevel: HeadingLevel
};

export const Heading : React.FC<HeadingProps> = ({
  headingLevel,
  children,
  ...otherProps
}) => {
  // eslint-disable-next-line i18next/no-literal-string
  if (!availableHeadings.includes(headingLevel)) throw new Error('Unknown heading tag');

  const HeadingTag: keyof JSX.IntrinsicElements = headingLevel;
  return (
    <HeadingTag
      {...otherProps}
    >
      {children}
    </HeadingTag>
  );
};
