import { v4 as uuidv4 } from 'uuid';

import { ValueNotifier } from '@app/common/utils/notifier/ValueNotifier';
import { VoidCallback } from '@app/common/utils/types';

import { RequestHandlerAgileSDKService } from '../RequestHandlerAgileSDKService';

import { AgileSDKRequestListener } from '../../AgileSDKRequestListener';
import { AgileSDKAddress, AgileSDKRequest } from '../../types';
import { isSubscriptionInfo } from './assertions/isSubscriptionInfo';

/**
 * Represents a window subscribed to the value notifier.
 */
export type ValueNotifierAgileSDKServiceSubscriber = Readonly<{
  /**
   * The window that subscribed to the value notifier.
   */
  source: WeakRef<Window>;

  /**
   * The origin of the window that subscribed to the login status changes.
   */
  origin: string;
}>;

/**
 * A base class for Agile SDK services that use a value notifier and allow to subscribe to value changes.
 */
export abstract class ValueNotifierAgileSDKService<T extends ValueNotifier<any>> extends RequestHandlerAgileSDKService {
  protected readonly notifier: T;

  protected subscribers: Map<string, ValueNotifierAgileSDKServiceSubscriber>;

  protected cancelSubscription: VoidCallback;

  protected readonly notificationCallback: VoidCallback;

  constructor(
    notifier: T,
    listener: AgileSDKRequestListener,
    messageTypes: Set<string>,
  ) {
    super(listener, messageTypes);

    this.notifier = notifier;

    this.subscribers = new Map();

    // Storing the callback reference in a field to avoid it being garbage collected, as it is passed to the notifier,
    // and the notifier stores callbacks in weak references, it is required to maintain the callback lifetime manually.
    this.notificationCallback = this.notify.bind(this);

    this.cancelSubscription = this.notifier.subscribe(this.notificationCallback);
  }

  async dispose() {
    await super.dispose();

    this.cancelSubscription();

    this.subscribers.clear();
  }

  cleanup() {
    const subscribers = new Map<string, ValueNotifierAgileSDKServiceSubscriber>();

    this.subscribers.forEach((subscriber, id) => {
      const source = subscriber.source.deref();

      if (!source) {
        return;
      }

      subscribers.set(id, subscriber);
    });

    this.subscribers = subscribers;
  }

  /**
   * Notifies the subscribers about the value change.
   */
  notify() {
    this.cleanup();

    this.subscribers.forEach((subscriber, id) => {
      const source = subscriber.source.deref();

      if (!source) {
        return;
      }

      const { origin } = subscriber;

      this.notifyValueChange({ source, origin }, id);
    });
  }

  /**
   * Adds a subscriber to the value changes.
   */
  subscribe(target: AgileSDKAddress) {
    const subscriber: ValueNotifierAgileSDKServiceSubscriber = {
      source: new WeakRef(target.source),
      origin: target.origin,
    };

    const id = uuidv4();

    this.subscribers.set(id, subscriber);

    return id;
  }

  /**
   * Removes a subscriber from the value changes.
   */
  unsubscribe(target: AgileSDKAddress, id: string) {
    const subscriber = this.subscribers.get(id);

    if (!subscriber) {
      return false;
    }

    // Only the same window can unsubscribe.
    if (subscriber.source.deref() !== target.source) {
      return false;
    }

    this.subscribers.delete(id);

    return true;
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    switch (true) {
      case this.isValueRequest(request): {
        return this.handleValueRequest(request);
      }

      case this.isSubscribeRequest(request): {
        return this.handleSubscribeRequest(request);
      }

      case this.isUnsubscribeRequest(request): {
        return this.handleUnsubscribeRequest(request);
      }

      default:
        return false;
    }
  }

  /**
   * Notifies subscriber about the value.
   */
  abstract respondValueRequest(request: AgileSDKRequest): boolean;

  /**
   * Notifies subscriber about created subscription.
   */
  abstract respondSubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean;

  /**
   * Notifies subscriber about cancelled subscription.
   */
  abstract respondUnsubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean;

  /**
   * Notifies the subscribers about the value change.
   */
  abstract notifyValueChange(target: AgileSDKAddress, subscriptionId: string): boolean;

  /**
   * Checks if the request is a value request.
   */
  abstract isValueRequest(request: AgileSDKRequest): boolean;

  /**
   * Checks if the request is a subscribe request.
   */
  abstract isSubscribeRequest(request: AgileSDKRequest): boolean;

  /**
   * Checks if the request is an unsubscribe request.
   */
  abstract isUnsubscribeRequest(request: AgileSDKRequest): boolean;

  /**
   * Handles the value request.
   */
  async handleValueRequest(request: AgileSDKRequest): Promise<boolean> {
    if (!this.isValueRequest(request)) {
      return false;
    }

    return this.respondValueRequest(request);
  }

  /**
   * Handles the subscribe request.
   */
  async handleSubscribeRequest(request: AgileSDKRequest): Promise<boolean> {
    if (!this.isSubscribeRequest(request)) {
      return false;
    }

    const subscriptionId = this.subscribe(request.sender);

    return this.respondSubscribeRequest(request, subscriptionId);
  }

  /**
   * Handles the unsubscribe request.
   */
  async handleUnsubscribeRequest(request: AgileSDKRequest): Promise<boolean> {
    if (!this.isUnsubscribeRequest(request)) {
      return false;
    }

    const { message: { payload } } = request;

    if (!isSubscriptionInfo(payload)) {
      return false;
    }

    const { subscriptionId } = payload;

    this.unsubscribe(request.sender, subscriptionId);

    return this.respondUnsubscribeRequest(request, subscriptionId);
  }
}
