import { isObject, isString } from '@app/common/utils/assertion';

import { ADD_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE } from '../constants';
import { AddUserCustomDataRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

function isAddUserCustomDataParameters(payload: unknown): boolean {
  return isObject(payload)
    && 'scope' in payload
    && isString(payload.scope)
    && 'data' in payload
    && isString(payload.data);
}

/**
 * Checks if the value is a {@link AddUserCustomDataRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isAddUserCustomDataRequestMessage(value: AgileSDKRequestMessage<unknown>): value is AddUserCustomDataRequestMessage {
  return value.messageType === ADD_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE
    && isAddUserCustomDataParameters(value.payload);
}
