import { ApolloError, isApolloError } from '@apollo/client';

/**
 * Check is error is 'Not authorized' error.
 * @param error potential 'Not authorized' error
 */
export const isNotAuthorizedError = (error:Error):boolean => {
  if (isApolloError(error)) {
    const { graphQLErrors } = error as ApolloError;
    if (graphQLErrors.length > 0 && graphQLErrors[0].extensions?.code === 'AUTH_NOT_AUTHORIZED') {
      return true;
    }
  }

  return false;
};
