import { SET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE } from '../constants';
import { SetInMemoryStorageItemRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isSetInMemoryStorageItemParams } from './isSetInMemoryStorageItemParams';

/**
 * Checks if the value is a {@link SetInMemoryStorageItemRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isSetInMemoryStorageItemRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SetInMemoryStorageItemRequestMessage {
  return (
    value.messageType === SET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE

    && isSetInMemoryStorageItemParams(value.payload)
  );
}
