import React, { ChangeEvent } from 'react';
import {
  Controller,
  FieldError,
  useFormContext,
} from 'react-hook-form';

import classNames from 'classnames';

import { FormField } from '@ast/magma/components/formfield';
import { FormFieldSet } from '@ast/magma/components/formfieldset';

import styles from './FormControl.pcss';
import {
  formControlValidationRulesMapper,
  FormControlValidationRuleResult,
} from './formControlValidation';

export enum FormControlType {
  CHECKBOX = 'checkbox',
  OTHER = 'other',
  RADIO = 'radio',
}

export type FormControlProps = {
  readonly children: React.ReactElement | React.ReactElement[]
  readonly className?: string
  /**
   * if we use checkboxes, value of control should be string[]
   */
  readonly controlType?: FormControlType
  readonly 'data-stable-name'?: string
  readonly getErrorMessage?: (error?: FieldError) => string
  readonly hidden?: boolean
  // Not reset value in form, after control has been unmounted
  readonly keep?: boolean
  readonly label?: string
  readonly name: string
  /**
   * callback fired when control has been removed from DOM.
   * "keep" attribute prevents callback calling.
   */
  readonly onRemoved?: () => void
  /**
   * text will be displayed below control
   */
  readonly optionalText?: string
  readonly validations?: FormControlValidationRuleResult[]
};

export const FormControl: React.FC<FormControlProps> = ({
  children,
  className,
  controlType,
  'data-stable-name': dataStableName,
  getErrorMessage,
  hidden,
  keep,
  label,
  name,
  onRemoved,
  optionalText,
  validations,
}) => {
  const {
    control,
    clearErrors,
    setValue,
  } = useFormContext();

  const validationRules = React.useMemo(
    () => formControlValidationRulesMapper(validations),
    [validations],
  );

  React.useEffect(
    () => {
      if (hidden && !keep) {
        /**
         * when we hide field,
         * we should reset value and validation state.
         */
        setValue(name, control.defaultValuesRef.current[name], { shouldValidate: false });
        clearErrors(name);
        if (onRemoved) onRemoved();
      }
    },
    [hidden, keep],
  );

  if (React.Children.count(children) === 0 || hidden) return null;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const cloned = (
          React.Children.map(children, (child: React.ReactElement) => {
            const checkedState = () => {
              if (controlType === FormControlType.CHECKBOX && !Array.isArray(field.value)) {
                // eslint-disable-next-line i18next/no-literal-string
                console.error('Value for checkbox should be array');
                return false;
              }
              if (controlType === FormControlType.CHECKBOX) {
                return field.value.includes(child.props.value);
              }
              if (controlType === FormControlType.RADIO) {
                return field.value === child.props.value;
              }
              return undefined;
            };

            const { ref, ...fieldPropsWithoutRef } = field; // field props overwrite child ref, that is not what we want
            return React.cloneElement(child, {
              ...fieldPropsWithoutRef,
              // eslint-disable-next-line i18next/no-literal-string
              'aria-invalid': (
                typeof getErrorMessage !== 'undefined'
                  ? Boolean(getErrorMessage(fieldState.error))
                  : fieldState.invalid
              ),
              checked: checkedState(),
              onBlur: () => {
                if (child.props.onBlur) child.props.onBlur();
                field.onBlur();
              },
              onChange: (event: any) => {
                if (child.props.onChange) child.props.onChange(event);
                if (event && typeof event === 'object' && event.defaultPrevented === true) return;

                const firedValue = (
                  // eslint-disable-next-line no-nested-ternary
                  controlType === FormControlType.CHECKBOX
                    ? (
                      (event as ChangeEvent<HTMLInputElement>).target.checked
                        ? [...field.value, (event as ChangeEvent<HTMLInputElement>).target.value]
                        : [...field.value].filter((item) => item !== event.target.value)
                    )
                    : event
                );
                field.onChange(firedValue);
              },
              value: (
                // we should not change value for checkboxes and radio buttons
                (
                  controlType
                  && ([FormControlType.CHECKBOX, FormControlType.RADIO].includes(controlType))
                  && child.props.value
                ) || field.value
              ),
            });
          })
        );

        if (React.Children.count(children) > 1) {
          return (
            <FormFieldSet
              assistive={optionalText}
              className={classNames(styles.field, className)}
              data-stable-name={dataStableName}
              error={
                typeof getErrorMessage !== 'undefined'
                  ? getErrorMessage(fieldState.error)
                  : fieldState.error?.message
                }
              legend={label}
              reserveErrorHeight={false}
            >
              { cloned }
            </FormFieldSet>
          );
        }

        return (
          <FormField
            assistive={optionalText}
            className={classNames(
              styles.field,
              controlType === FormControlType.CHECKBOX && styles.fieldCheckbox,
              className,
            )}
            data-stable-name={dataStableName}
            error={
              typeof getErrorMessage !== 'undefined'
                ? getErrorMessage(fieldState.error)
                : fieldState.error?.message
            }
            label={label}
            reserveErrorHeight={false}
          >
            { cloned[0] }
          </FormField>
        );
      }}
      rules={validationRules}
    />
  );
};

FormControl.defaultProps = {
  'data-stable-name': '',
  className: '',
  controlType: undefined,
  getErrorMessage: undefined,
  hidden: false,
  keep: false,
  label: undefined,
  optionalText: undefined,
  validations: undefined,
};
