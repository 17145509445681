import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from '@app/core/i18n';

import { Checkbox } from '@ast/magma/components/checkbox';
import { FormFieldSet } from '@ast/magma/components/formfieldset';

import { FieldBehaviorMode } from '@app/queryTyping';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';
import { combineNames } from '@app/common/configurable-wizards/utils/combineNames';

import { MultipleChoiceCheckboxesComponentProps } from './types';

export const MultipleChoiceCheckboxesComponent: React.FC<MultipleChoiceCheckboxesComponentProps> = ({
  field,
  look,
  name,
  choices,
  error,
  containerProps,
  getChangeHandler,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const isRequired = field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED;

  return (
    <FormFieldSet
      error={error}
      legend={field.label || ''}
      reserveErrorHeight={false}
      data-stable-name={`${field.id}FormFieldSet`}
    >
      <FlexContainer {...containerProps}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: isRequired && t(
              'multiple-choice.validation.required|Multiple choice validation message',
              'Please select at least one of the options',
            ),
          }}
          shouldUnregister
          render={({ field: controlField }) => (
            <>
              {choices.map((choice) => (
                <Checkbox
                  key={choice.id}
                  look={look}
                  value={choice.id}
                  label={choice.text || ''}
                  data-stable-name={combineNames(field.id, choice.id)}
                  checked={
                    // Restore checked state from values
                    controlField.value?.some((item: string) => choice.id === item)
                  }
                  defaultChecked={
                    // Initial checkbox checked state according to received data
                    field.selectedValues?.some((item) => choice.id === item?.value)
                  }
                  onChange={getChangeHandler(controlField)}
                />
              ))}
            </>
          )}
        />
      </FlexContainer>
    </FormFieldSet>
  );
};
