/* eslint-disable i18next/no-literal-string */
import { hasOwnPropertyOfType, isObject, isString } from '@app/common/utils/assertion';

import { AgileSDKRequest, agileSDKRequestPrefix, isAgileSDKRequest } from '../types';

export const invokeBackendComponentRequestType = `${agileSDKRequestPrefix}.invokeBackendComponentRequest`;

export interface InvokeBackendComponentRequestPayload {
  readonly name: string,
  readonly args: Record<string, any>,
}

export interface InvokeBackendComponentRequest extends AgileSDKRequest {
  readonly payload: InvokeBackendComponentRequestPayload,
}

export function isInvokeBackendComponentRequestPayload(data: unknown): data is InvokeBackendComponentRequestPayload {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'name', isString)
    && hasOwnPropertyOfType(data, 'args', isObject)
  );
}

export function isInvokeBackendComponentRequest(data: unknown): data is InvokeBackendComponentRequest {
  return (
    isAgileSDKRequest(data)
    && hasOwnPropertyOfType(data, 'type', (value) => value === invokeBackendComponentRequestType)
    && hasOwnPropertyOfType(data, 'payload', isInvokeBackendComponentRequestPayload)
  );
}
