import { CompromisedCredentialsStatus } from '@app/queryTyping';

import { SessionStorageDocument } from '@app/core/storage/browser/SessionStorageDocument';
import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

const CURRENT_VERSION = 1;
export const KEY = 'compromised-credentials';

export interface ICompromisedCredentialsStore {
  readonly status?: CompromisedCredentialsStatus;
  readonly userAttractedToAlert?: boolean;
  readonly linkedSessionToken?: string;
}

/**
 * This document contains the state of the compromised credentials alert keeped in the session storage.
 */
export class CompromisedCredentialsStore extends SessionStorageDocument<ICompromisedCredentialsStore> {
  private static inner?: CompromisedCredentialsStore;

  private constructor(node:string, version:number, migrations:Migration[] = []) {
    super(node, version, migrations);
  }

  public static get instance():CompromisedCredentialsStore {
    if (!this.inner) {
      this.inner = new CompromisedCredentialsStore(KEY, CURRENT_VERSION);
    }

    return this.inner;
  }

  private status?:CompromisedCredentialsStatus;

  private userAttractedToAlert?:boolean;

  private linkedSessionToken?:string;

  public getStatus(): CompromisedCredentialsStatus | undefined {
    return this.status;
  }

  public getUserAttractedToAlert(): boolean | undefined {
    return this.userAttractedToAlert;
  }

  public getLinkedSessionToken(): string | undefined {
    return this.linkedSessionToken;
  }

  public setStatus(value: CompromisedCredentialsStatus | undefined) {
    this.status = value;
    this.save();
  }

  public setUserAttractedToAlert(value: boolean) {
    this.userAttractedToAlert = value;
    this.save();
  }

  public setLinkedSessionToken(value: string | undefined) {
    this.linkedSessionToken = value;
    this.save();
  }

  public clear() {
    this.status = undefined;
    this.userAttractedToAlert = undefined;
    this.linkedSessionToken = undefined;
    this.save();
  }

  protected serialize():ICompromisedCredentialsStore {
    return {
      status: this.status as CompromisedCredentialsStatus,
      userAttractedToAlert: this.userAttractedToAlert,
      linkedSessionToken: this.linkedSessionToken,
    };
  }

  protected deserialize(deserialized:ICompromisedCredentialsStore) {
    this.status = deserialized.status;
    this.userAttractedToAlert = deserialized.userAttractedToAlert;
    this.linkedSessionToken = deserialized.linkedSessionToken;
  }
}
