/* eslint-disable class-methods-use-this */
import { ColorThemeNotifier } from '@app/core/contexts/colorTheme/utils/ColorThemeNotifier';

import { ValueNotifierAgileSDKService } from '../../utils/abstract/ValueNotifierAgileSDKService/ValueNotifierAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKAddress, AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetColorThemeRequestMessage } from './assertions/isGetColorThemeRequestMessage';
import { isSubscribeColorThemeRequestMessage } from './assertions/isSubscribeColorThemeRequestMessage';
import { isUnsubscribeColorThemeRequestMessage } from './assertions/isUnsubscribeColorThemeRequestMessage';

import {
  COLOR_THEME_CHANGED_NOTIFICATION_MESSAGE_TYPE,
  GET_COLOR_THEME_REQUEST_MESSAGE_TYPE,
  SUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE,
  UNSUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE,
} from './constants';
import {
  GetColorThemeResponseMessage,
  ColorThemeChangeNotificationMessage,
  SubscribeColorThemeResponseMessage,
  UnsubscribeColorThemeResponseMessage,
} from './types';

/**
 * Agile SDK service for the color theme tracking.
 *
 * Responds with the current color theme and allows to subscribe to color theme changes.
 */
export class ColorThemeAgileSDKService extends ValueNotifierAgileSDKService<ColorThemeNotifier> {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const notifier = new ColorThemeNotifier();

    super(
      notifier,
      listener,
      new Set([
        GET_COLOR_THEME_REQUEST_MESSAGE_TYPE,
        SUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE,
        UNSUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE,
      ]),
    );

    this.sender = sender;
  }

  async init() {
    await super.init();

    this.notifier.init();
  }

  async dispose() {
    await super.dispose();

    this.notifier.dispose();
  }

  isValueRequest(request: AgileSDKRequest): boolean {
    return isGetColorThemeRequestMessage(request.message);
  }

  isSubscribeRequest(request: AgileSDKRequest): boolean {
    return isSubscribeColorThemeRequestMessage(request.message);
  }

  isUnsubscribeRequest(request: AgileSDKRequest): boolean {
    return isUnsubscribeColorThemeRequestMessage(request.message);
  }

  respondValueRequest(request: AgileSDKRequest): boolean {
    const response: GetColorThemeResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        colorTheme: this.notifier.value,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondSubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: SubscribeColorThemeResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: true,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondUnsubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: UnsubscribeColorThemeResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: false,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  notifyValueChange(target: AgileSDKAddress, subscriptionId: string): boolean {
    const notification: ColorThemeChangeNotificationMessage = {
      version: '1.0',
      messageType: COLOR_THEME_CHANGED_NOTIFICATION_MESSAGE_TYPE,
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        colorTheme: this.notifier.value,
      },
    };

    this.sender(notification, target);

    return true;
  }
}
