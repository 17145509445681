import { AdServiceRenderer, isAdServiceWidget } from './adService';
import { CustomAppWidgetRenderer, isCustomAppWidget } from './customAppWidget';
import { DeepTargetRenderer, isDeepTargetWidget } from './deepTarget';
import { HtmlWidgetRenderer, isHtmlWidget } from './htmlWidget';
import {
  ImageWidgetRenderer,
  isImageWidget, isThemeImageWidget, ThemedImageRenderer,
} from './imageWidget';
import { isOrpeusWidget, OrpheusWidgetRenderer } from './orpheusWidget';
import { SSOWidgetRenderer, isSSOWidget } from './ssoWidget';
import { WidgetRenderer, WidgetType } from './types';

export const widgetsMap = new Map<(widget: WidgetType) => boolean, WidgetRenderer>([
  [isHtmlWidget, HtmlWidgetRenderer],
  [isOrpeusWidget, OrpheusWidgetRenderer],
  [isImageWidget, ImageWidgetRenderer],
  [isThemeImageWidget, ThemedImageRenderer],
  [isAdServiceWidget, AdServiceRenderer],
  [isDeepTargetWidget, DeepTargetRenderer],
  [isSSOWidget, SSOWidgetRenderer],
  [isCustomAppWidget, CustomAppWidgetRenderer],
]);
