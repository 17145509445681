import { Html5Entities } from 'html-entities';

import {
  GetEmbeddedResourcesQuery,
} from '@app/core/emdeddedResources/queries/queryTyping/getEmbeddedResources';

export function renderEmbeddedResources(
  data: GetEmbeddedResourcesQuery,
  renderLocation: string, container: Element,
): void {
  const embeddedResources = data?.embeddedResources
    ?.find((er) => er.renderLocation === renderLocation)
    ?.embeddedResources
    ?.map((_) => Html5Entities.decode(_.html))
    ?.reduce((prev, curr) => prev + curr) || '';

  if (!container) return;
  // eslint-disable-next-line no-param-reassign
  container.innerHTML = '';

  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = embeddedResources;

  const scripts = tempContainer.querySelectorAll('script');
  for (let script of scripts) {
    const el = document.createElement('script');
    for (let attr of script.attributes) {
      el.setAttribute(attr.name, attr.value)
    }
    el.innerHTML = script.innerHTML;
    if (!el.defer) {
      el.async = true;
    }
    container.appendChild(el);
    script.parentNode!.removeChild(script);
  }

  for (let node of tempContainer.childNodes) {
    container.appendChild(node.cloneNode(true));
  }
}
