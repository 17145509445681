import { ColorTheme } from '@app/common/types/ColorTheme';
import { ValueNotifier } from '@app/common/utils/notifier/ValueNotifier';
import { VoidCallback } from '@app/common/utils/types';

import { COLOR_THEME_CHANGED_EVENT } from './ColorThemeChangedEvent';
import { readColorTheme } from './readColorTheme';

/**
 * A notifier that tracks the selected color theme state.
 *
 * Notifies subscribers when the color theme changes.
 */
export class ColorThemeNotifier extends ValueNotifier<ColorTheme> {
  protected eventListener: VoidCallback;

  constructor() {
    super(readColorTheme());

    this.eventListener = this.handleEvent.bind(this);
  }

  init() {
    window.addEventListener(COLOR_THEME_CHANGED_EVENT, this.eventListener);
  }

  dispose() {
    super.dispose();

    window.removeEventListener(COLOR_THEME_CHANGED_EVENT, this.eventListener);
  }

  protected handleEvent() {
    this.value = readColorTheme();
  }
}
