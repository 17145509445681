import React, { useMemo } from 'react';

import Times from '@ast/magma/components/icon/icons/Times';

import { Button } from '@ast/magma/components/button';
import { Modal } from '@ast/magma/components/modal/modal';

import { useTranslation } from '@app/core/i18n';

import { IFRAME_VIEWPORT_OFFSET } from './constants';
import styles from './StoryPopup.pcss';

const POPUP_DEFAULT_WIDTH = 280;
const POPUP_DEFAULT_HEIGHT = 498;

export const StoryPopup: React.FC<{
  baseSrc: string;
  opened: boolean;
  deepTargetId: string;
  onClose: () => void;
}> = ({
  baseSrc, opened, onClose, deepTargetId,
}) => {
  const { t } = useTranslation();

  const src = useMemo(() => {
    const replacements = {
      'DEEP-TARGET-ID': deepTargetId,
      // only desktop sizes are used for request to deeptarget
      HEIGHT: POPUP_DEFAULT_HEIGHT.toString(),
      WIDTH: POPUP_DEFAULT_WIDTH.toString(),
    };

    // eslint-disable-next-line i18next/no-literal-string
    const regexp = new RegExp(Object.keys(replacements).join('|'), 'g');

    const url = new URL(
      baseSrc.replace(
        regexp,
        (matched) => replacements[matched as keyof typeof replacements],
      ),
    );
    // eslint-disable-next-line i18next/no-literal-string
    url.searchParams.append('adType', '3dstory');

    return url.toString();
  }, [baseSrc]);

  return opened ? (
    <Modal
      onClick={onClose}
      align="center"
      mask="dark"
    >
      <div className={styles.popupWrapper}>
        <Button
          look="icon"
          icon={<Times />}
          className={styles.closeButton}
          onClick={onClose}
          data-stable-name="StoryPopupCloseButton"
          aria-label={t(
            'storypopup.close-button.aria-label|Accessibility text for 3D story popup close button',
            'Close popup',
          )}
        />
        <iframe
          src={src}
          scrolling="no"
          style={{
            display: 'block',
            border: 'none',
            width: `${POPUP_DEFAULT_WIDTH + IFRAME_VIEWPORT_OFFSET}px`,
            height: `${POPUP_DEFAULT_HEIGHT + IFRAME_VIEWPORT_OFFSET}px`,
          }}
          title="3d story popup"
          data-stable-name="StoryPopupIframe"
        />
      </div>
    </Modal>
  ) : null;
};
