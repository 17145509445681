import { ValueNotifier } from '@app/common/utils/notifier/ValueNotifier';
import { VoidCallback } from '@app/common/utils/types';

import { readLanguageCulture } from '../LocalizationContext';

import { LANGUAGE_CULTURE_CHANGED_EVENT } from './LanguageCultureChangedEvent';

/**
 * A notifier that tracks the language culture.
 *
 * Notifies subscribers when the language culture changes.
 */
export class LanguageCultureNotifier extends ValueNotifier<string> {
  protected eventListener: VoidCallback;

  constructor() {
    super(readLanguageCulture());

    this.eventListener = this.handleEvent.bind(this);
  }

  init() {
    window.addEventListener(LANGUAGE_CULTURE_CHANGED_EVENT, this.eventListener);
  }

  dispose() {
    super.dispose();

    window.removeEventListener(LANGUAGE_CULTURE_CHANGED_EVENT, this.eventListener);
  }

  protected handleEvent() {
    this.value = readLanguageCulture();
  }
}
