import { isInvokeBackendComponentRequest } from './handlers/invokeBackendComponent/request';
import { isInvokeExternalApiIntegrationRequest } from './handlers/invokeExternalApiIntegration/request';

import { LegacyRequestMessage } from './types';

/**
 * Checks if the given value is a legacy request message and is imcompatible with the standard
 * exchange message format.
 *
 * See {@link LegacyRequestMessage} for more details.
 */
export function isLegacyRequestMessage(value: unknown): value is LegacyRequestMessage {
  return isInvokeBackendComponentRequest(value) || isInvokeExternalApiIntegrationRequest(value);
}
