import { observeElement } from '@app/common/utils/observeElement/observeElement';

import { tracing } from '@app/core/opentelemetry/setupTracing';
import { PerformanceMonitorService } from '@app/core/performanceMonitorService/PerformanceMonitorService';

type LoginToElementFlow = {
  start: string;
  end: string,
  description: string,
  unit: string
};

export const registerOpentelemetryMetrics = () => {
  const performanceMonitorService = new PerformanceMonitorService({
    storageAdapter: sessionStorage,
    storagePrefix: 'ast.otlp-metrics',
    timingAdapter: Date, // Can use performance.now if you don't need to detect page reloading
  });

  const metricsLabels = {
    loginToAccounts: 'fe.login_to_accounts.load.time.ms',
    loginToTransfers: 'fe.login_to_transfer.load.time.ms',
  };

  const loginToElementsList: Record<string, LoginToElementFlow> = {
    [metricsLabels.loginToAccounts]: {
      start: 'SignInButton',
      end: 'BalanceHistoryItemDropDownMenu',
      description: 'Login to accounts load time',
      unit: 'ms',
    },
    [metricsLabels.loginToTransfers]: {
      start: 'SignInButton',
      end: 'InternalForm',
      description: 'Login to transfers load time',
      unit: 'ms',
    },
  };

  function setupFlowsLoginToElement(flowName: string, flow: LoginToElementFlow) {
    observeElement(`[data-stable-name="${flow.start}"]`, (element) => {
      element.addEventListener('click', () => {
        performanceMonitorService.startMetric(flowName);
      });
    });

    observeElement(`[data-stable-name="${flow.end}"]`, () => {
      const duration = performanceMonitorService.endMetric(flowName);
      if (duration !== undefined) {
        const metric = tracing.getMeter().createHistogram(flowName, {
          description: flow.description,
          unit: flow.unit,
        });
        metric.record(duration);
      } else {
        console.error(`Cannot record metric for ${flowName} because the duration is undefined.`);
      }
    });
  }

  Object.entries(loginToElementsList).forEach(([flowName, flow]) => {
    setupFlowsLoginToElement(flowName, flow);
  });
};
