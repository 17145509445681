/**
 * User logged in event name.
 */
export const USER_LOGGED_IN_EVENT = 'ast.userLoggedIn';

/**
 * Event fired when user logs in.
 *
 * Contains no payload.
 */
export class UserLoggedInEvent extends CustomEvent<never> {
  constructor() {
    super(USER_LOGGED_IN_EVENT);
  }
}

declare global {
  interface WindowEventMap {
    [USER_LOGGED_IN_EVENT]: UserLoggedInEvent;
  }
}
