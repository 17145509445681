import React from 'react';
import styles from './FormSectionTitle.pcss';

export interface FormSectionTitleProps {
  title: string;
}

export const FormSectionTitle: React.FC<FormSectionTitleProps> = ({ title }) => (
  <strong className={styles.title}>
    {title}
  </strong>
);
