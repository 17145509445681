import React, {
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useContext,
} from 'react';

import classNames from 'classnames';

import { uniqueId } from '@ast/magma/inner/utils/uniqueid';

import { ContextUse, Context } from '../context';

import navStyles from '../nav/nav.pcss';

import styles from './tab.pcss';

export type BadgeCounter = {
  /**
   * Counter notification
   */
  readonly counter: number;
  /**
   * Aria label message for counter.
   */
  // eslint-disable-next-line i18next/no-literal-string
  readonly 'aria-label': string;
};

/**
 * Tab properties.
 */
export interface TabProps {
  /**
   * Unique independent ID to specify data stables and what ever
   */
  readonly id: string;

  /**
   * Label for navigation button
   */
  readonly label: string;

  readonly index?: number;

  /**
   * Icon before label
   */
  readonly iconBefore?: ReactElement

  /**
   * Icon after label
   */
  readonly iconAfter?: ReactElement

  /**
   * Counter notification
   */
  readonly counter?: BadgeCounter

  /**
   * Specific className for each tabButton
   */
  readonly tabBtnClassName?: string

  /**
   * Specific className for content tabPanel
   */
  readonly tabPanelClassName?: string

  readonly onKeyDown?:(event:React.KeyboardEvent<HTMLElement>) => void;
}

export interface TabPropsExt extends TabProps {
  /**
   * Tab navigation ID
   */
  readonly tabID: string

  /**
   * Tab panel ID
   */
  readonly tabPanelID: string
}

export const Tab: FC<TabProps> = (props) => {
  const context = ContextUse(useContext(Context));
  const tabID: string = useMemo(() => uniqueId('tab'), []);
  const tabPanelID: string = useMemo(() => uniqueId('tabPanel'), []);

  const {
    iconBefore,
    iconAfter,
    tabBtnClassName,
    tabPanelClassName,
    counter,
    children,
    onKeyDown,
  } = props;

  // Add custom class to icons, to modify color css prop
  const iconBeforeCloned = iconBefore

  && useMemo(() => React.cloneElement(iconBefore as ReactElement, {
    className: classNames(navStyles.tabListButtonIcon, navStyles.tabListButtonIconBefore),
  }), [iconBefore]);

  const iconAfterCloned = iconAfter
  && useMemo(() => React.cloneElement(iconAfter as ReactElement, {
    className: classNames(navStyles.tabListButtonIcon, navStyles.tabListButtonIconAfter),
  }), [iconAfter]);
  // END: icons modification

  useEffect(() => {
    const newProps = {
      ...props,
      tabID,
      tabPanelID,
    };

    if (iconBeforeCloned) {
      newProps.iconBefore = iconBeforeCloned;
    }

    if (iconAfterCloned) {
      newProps.iconAfter = iconAfterCloned;
    }

    const indexOfProps = context.tabs.current.findIndex((p: TabPropsExt) => p.tabID === tabID);
    if (indexOfProps > -1) {
      context.tabs.current.splice(indexOfProps, 1, newProps);
    } else {
      context.tabs.current.push(newProps);
    }
  }, [iconBefore, iconAfter, tabBtnClassName, tabPanelClassName, counter]);

  return (
    <section
      data-stable-name="TabPanel"
      className={classNames(
        styles.tabPanel,
        tabPanelClassName,
        // eslint-disable-next-line react/destructuring-assignment
        props.index !== context.current && styles.tabPanelHidden,
      )}
      id={tabPanelID}
      aria-labelledby={tabID}
      // eslint-disable-next-line react/destructuring-assignment
      aria-hidden={props.index !== context.current}
      onKeyDown={onKeyDown}
    >
      {children}
    </section>
  );
};
