import { useEffect, useMemo, useState } from 'react';

import { getRecaptchaScript } from './utils';

import './useReCaptchaV3.css';

export interface UseRecaptchaV3Props {
  readonly siteKey?: string;
  readonly bypass?: boolean;
}

export type UseRecaptchaV3Return = (action: string) => Promise<string>;

export const useRecaptchaV3 = ({ siteKey, bypass }: UseRecaptchaV3Props): UseRecaptchaV3Return => {
  const [loaded, setLoaded] = useState(false);

  const load = () => {
    let script: HTMLScriptElement | null = null;

    if (!bypass && siteKey) {
      script = getRecaptchaScript(siteKey);
      script.onload = () => setLoaded(true);
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
  };

  useEffect(load, [siteKey, bypass]);

  const executeRecaptcha = useMemo<(action: string) => Promise<string>>(
    () => (action: string) => new Promise((resolve, reject) => {
      try {
        if (!loaded) {
          reject(new Error('Recaptcha is not ready'));
          return;
        }

        // @ts-ignore
        if (window.grecaptcha) {
          // @ts-ignore
          window.grecaptcha.execute(siteKey, { action }).then(resolve);
        }
      } catch (e) {
        reject(e);
      }
    }), [loaded, siteKey]);

  return executeRecaptcha;
};
