import React, {
  Children,
  cloneElement,
  FunctionComponent,
  ReactElement,
  useEffect,
} from 'react';
import type { PropsWithChildren } from 'react';

import {
  useEligibilityMenuContext,
} from '@app/core/contexts/eligibilityMenuContext/EligibilityMenuItemsContext';

import {
  useGetNavigationMenuItemEligibilityStatusLazyQuery,
} from '@app/common/types/queries/queryTyping/getNavigationMenuItemEligibilityStatus';
import type { LinkMenuItem } from '@app/common/types/useMainMenuQuery';

interface MenuItemEligibilityProps {
  readonly menuItem: LinkMenuItem;
  readonly onAfterStatusCheck?: (id: string, isEligible: boolean) => void
}

export const MenuItemEligibility: FunctionComponent<PropsWithChildren<MenuItemEligibilityProps>> = ({
  menuItem,
  children,
  onAfterStatusCheck,
}) => {
  const {
    eligibilityMenuItems,
    setEligibilityMenuItems,
  } = useEligibilityMenuContext();
  const
    [getMenuItemStatus, {
      loading,
      data,
    }] = useGetNavigationMenuItemEligibilityStatusLazyQuery();

  const isEligible = Boolean(data?.menuItemEligibilityStatus?.isEligible);

  useEffect(() => {
    if (
      menuItem.isEligibilityCheckRequired && menuItem.eligibilityCheckTag
    ) {
      getMenuItemStatus({
        variables: {
          serviceName: menuItem.eligibilityCheckTag.serviceName ?? '',
          serviceType: menuItem.eligibilityCheckTag.serviceType,
        },
      });
    }
  }, [menuItem]);

  useEffect(() => {
    if (menuItem.isEligibilityCheckRequired) {
      if (onAfterStatusCheck) {
        onAfterStatusCheck(menuItem.id, isEligible);
      }

      setEligibilityMenuItems({
        ...eligibilityMenuItems,
        [menuItem.id]: { isEligible, rootMenuId: menuItem.id },
      });
    }
  }, [data]);

  const childrenWithProps = Children.map(children, (child) => {
    const item = child as ReactElement<PropsWithChildren<any>>;
    return !loading && isEligible
      ? children
      : cloneElement(item, {
        // eslint-disable-next-line i18next/no-literal-string
        style: { display: 'none' },
      });
  });

  if (!menuItem.isEligibilityCheckRequired) {
    return (<>{ children }</>);
  }

  return (<>{childrenWithProps}</>);
};
