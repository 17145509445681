import React from 'react';

import { TriggeredEventWizard } from '../TriggeredEventWizard';

import { TriggeredEventWizardBase } from '../../types';
import { Constants } from './challenges/OneTimePassword/constants';
import { steps } from './challenges/OneTimePassword/steps';

export const LayeredAuthenticationTriggeredEventWizard: React.FC<TriggeredEventWizardBase> = (props) => (
  <TriggeredEventWizard {...props} wizard={Constants.WIZARD_NAME} steps={steps} />
);
