import { useEffect } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';

import { Choice } from '@app/queryTyping';

import {
  getMultipleChoiceFieldDefaultValue,
  getMultipleChoiceFieldName,
} from '@app/common/configurable-wizards/fields/MultipleChoice/Checkboxes/MultipleChoiceCheckboxesField';
import { getFieldErrorMessage } from '@app/common/configurable-wizards/utils/fieldErrors';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { UseMultipleChoiceFieldResult, UseMultipleChoiceFieldProps } from './types';

export const useMultipleChoiceField = ({
  parent,
  field,
}: UseMultipleChoiceFieldProps): UseMultipleChoiceFieldResult => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const name = getMultipleChoiceFieldName(parent, field);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);
  const choices = (field.choiceList || []) as Choice[];

  useEffect(() => {
    /**
     * react-hook-form returns a string or boolean value in case of single checkbox
     * but in our case we need to keep an array.
     *
     * Because of that we setting value directly to avoid buggy behaviour.
     */
    setValue(name, getMultipleChoiceFieldDefaultValue(field), {
      shouldDirty: false,
      shouldTouch: false,
    });
  }, []);

  const getChangeHandler = (
    controlField: ControllerRenderProps,
  ) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // Manually control the value to avoid boolean result
    if (event.target.checked) {
      controlField.onChange([
        ...controlField.value,
        event.target.value,
      ]);
    } else {
      controlField.onChange(
        controlField.value.filter(
          (value: any) => value !== event.target.value,
        ),
      );
    }
  };

  return {
    field,
    name,
    error,
    choices,
    getChangeHandler,
  };
};
