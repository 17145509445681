import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ConstrainedTextField } from '@app/common/configurable-wizards';

import {
  FieldComponent,
  FieldLocator,
} from '../../types';
import {
  isConstrainedTextField,
} from '../../utils';

import { ConstrainedTextInput } from './ConstrainedTextInput';
import { useConstrainedTextField } from './hooks';

export const ConstrainedTextFieldComponent: FieldComponent<ConstrainedTextField> = ({
  parent,
  field,
  fieldProps: { onChange: onChangeHandler } = {},
}) => {
  const { register } = useFormContext();
  const { name, error, registerOptions } = useConstrainedTextField({ parent, field });
  const inputProps = register(name, registerOptions);

  return (
    <ConstrainedTextInput
      field={field}
      error={error}
      onChangeHandler={onChangeHandler}
      {...inputProps}
    />
  );
};

export const ConstrainedTextFieldLocator: FieldLocator = (context) => (
  isConstrainedTextField(context.field)
    ? ConstrainedTextFieldComponent
    : undefined
);
