/* eslint-disable no-underscore-dangle */
import { PlatformKind } from '@app/queryTyping';

import { useIsMobile } from '@app/common/utils/hooks/useIsMobile';

import { WidgetType } from '@app/core/widgets/react/types';

export const useHideWidgetForPlatform = (widget: WidgetType) => {
  const isMobile = useIsMobile();
  const platform = widget.__typename === 'Widget'
    || widget.__typename === 'ImageWidget'
    || widget.__typename === 'ThemeAwareImageWidget'
    ? widget.platform
    : PlatformKind.BOTH;

  return (
    (platform === PlatformKind.MOBILE && !isMobile)
    || (platform === PlatformKind.DESKTOP && isMobile)
  );
};
