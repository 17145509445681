import React, { useMemo } from 'react';
import { useTranslation } from '@app/core/i18n';

import classNames from 'classnames';

import { FieldBehaviorMode } from '@app/queryTyping';

import { MaskedTextFieldFragment } from '../../queries/queryTyping/all-fields';
import { FieldComponent, FieldLocator } from '../../types';
import { combineNames, isPhoneField } from '../../utils';

import styles from './PhoneField.pcss';
import { PhoneInput } from './PhoneInput';

export const PhoneField: FieldComponent<MaskedTextFieldFragment> = ({
  parent,
  field,
  fieldProps,
}) => {
  const { t } = useTranslation();
  const name = combineNames(parent, field.id);

  const fieldMask = useMemo(() => {
    const format = field.maskFormat.replace(/0/g, '#');
    return {
      format,
      pattern: format === '+###############'
        ? /\+\d{11,15}/
        : /\([2-9][0-8]\d\)[ -]?[2-9]\d{2}-\d{4}/,
    };
  }, [field.maskFormat]);

  const options = {
    required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
    pattern: {
      value: fieldMask.pattern,
      message: t(
        'phone-input.validation.pattern|Phone number input validation message',
        'Please enter a valid phone number',
      ),
    },
    minLength: {
      value: field.minLength,
      message: t(
        'phone-input.validation.minlength|Minimum length validation message for phone number',
        'Phone must be more than {{minLength}} characters long',
        { minLength: field.minLength },
      ),
    },
    maxLength: {
      value: field.maxLength,
      message: t(
        'phone-input.validation.maxlength|Maximum length validation message for phone number',
        'Phone must be not more than {{maxLength}} characters long',
        { maxLength: field.maxLength },
      ),
    },
  };

  return (
    (field.fieldBehaviorMode === FieldBehaviorMode.READONLY)
      ? (
        <div>
          <div
            className={classNames(styles.phoneLabel)}
            data-stable-name={`${field.id}ReadonlyLabel`}
          >
            {field.label}
          </div>
          <div data-stable-name={`${field.id}ReadonlyValue`}>
            {field.sValue}
          </div>
        </div>
      ) : (
        <PhoneInput
          name={name}
          options={options}
          format={fieldMask.format}
          minLength={field.minLength}
          maxLength={field.maxLength}
          label={field.label || ''}
          data-stable-name={`${field.id}Input`}
          defaultValue={field.sValue}
          {...fieldProps}
        />
      )
  );
};

export const PhoneFieldLocator: FieldLocator = ({ field }) => (
  isPhoneField(field)
    ? PhoneField
    : undefined
);
