import { useEffect, useState } from 'react';

import { SESSION_STORAGE_UPDATE_EVENT, getSessionValue } from '@app/core/storage/browser/sessionStorage';

import { StorageUpdateEvent } from './types';

/**
 * Returns current value from session storage and subscribes to changes
 * @param key - key to use for storage
 * @returns stored value or null
 */
export const useSessionStorageValue = (key: string): string | null => {
  const [storedValue, setStoredValue] = useState<string | null>(() => getSessionValue(key));

  useEffect(() => {
    const onUpdate = (event: Event) => {
      const { detail } = event as StorageUpdateEvent;
      if (detail.key === key && detail.value !== storedValue) {
        setStoredValue(detail.value);
      }
    };
    window.addEventListener(SESSION_STORAGE_UPDATE_EVENT, onUpdate);
    return () => {
      window.removeEventListener(SESSION_STORAGE_UPDATE_EVENT, onUpdate);
    };
  }, [key]);

  return storedValue;
}
/**
 * Returns current value from local storage and subscribes to changes
 * @param key - key to use for storage
 * @returns stored value or null
 */
export const useLocalStorageValue = (key: string): string | null => {
  throw new Error('Not implemented for local storage');
};
