import { ServerErrorTypes } from '@app/common/types/validation';
import { UserFriendly } from '@app/queryTyping';
import { ServerErrorMessageResolver } from '../types';

export const userFriendlyServerErrorMessageResolver : ServerErrorMessageResolver<UserFriendly> = {
  typeName: ServerErrorTypes.UserFriendly,
  resolver: ({ errorMessage, path }) => ({
    message: errorMessage || '',
    path: path || '',
  }),
};
