/* eslint-disable i18next/no-literal-string */
import './public-path';
import './core/dayjs';
import 'normalize.css';
import '@ast/magma/shared/var.css';
import '@ast/magma/shared/index.css';
import './core/styles/layout.css';
import './core/styles/scrollbar.css';
import './core/styles/calloutAlert.css';
import './core/styles/vars.css';
import { DropDownList } from '@ast/magma/components/dropdownlist';

import { checkIfMaintenanceModeError, genericErrorHandler } from '@app/common/errors/genericErrorHandler';

import { registerFetchAuthHeaders } from '@app/core/authentication/customAuthHeaders';
import { bootstrapApplication, bootstrapTetheredLoginApplication } from '@app/core/bootstrap';
import { initializeI18n, t } from '@app/core/i18n';
import { registerOpentelemetryMetrics } from '@app/core/opentelemetry/registerOpentelemetryMetrics';
import { registerOpentelemetrySpans } from '@app/core/opentelemetry/registerOpentelemetrySpans';

import {
  setDefaultHiddenAccountsToggle,
} from '@app/widgets/bank-accounts/accountLocalSettings/hiddenAccountsToggle';

import { disallowDuplicatedTab } from './core/authentication/disallowDuplicatedTab';
import { forceLogout } from './core/authentication/logout/forceLogout/forceLogout';
import { clearUserAuthData } from './core/authentication/logout/logout';
import { passSessionParametersToStorage } from './core/authentication/parametrizedLogin/processSessionParameters';
import { processTetheredLoginRedirects } from './core/authentication/tetheredLogin/processTetheredLoginRedirects';
import { isTetheredLogin } from './core/components/TetheredLoginApplication';
import { onBeforeAppInit } from './core/onBeforeAppInit';
import { initializeAgileSDK } from './core/sdk';
import { initializeBranding } from './core/styles/initializeBranding/initializeBranding';

import './core/trackUserEvents/setupTrackUserEvents';

/**
 * DOM loaded promise
 */
const loadPromise = new Promise<void>((resolve) => {
  if (document.readyState !== 'loading') {
    resolve();
  } else {
    document.addEventListener('DOMContentLoaded', () => resolve());
  }
});

/**
 * Define generic voice suffix for message when new dropdown list item has been selected
 */
DropDownList.defaultProps = {
  ...DropDownList.defaultProps,
  a11ySelectionMessage: t(
    'dropdownlist.selectedItem.message|Define generic voice suffix for message when dropdown list item selected',
    'has been selected',
  ),
};

/**
 * Main application initialization sequence
 */
const initializeMainApp = () => Promise.resolve()
  .then(processTetheredLoginRedirects)
  .then(passSessionParametersToStorage)
  .then(registerOpentelemetrySpans)
  .then(registerOpentelemetryMetrics)
  .then(() => loadPromise)
  .then(() => Promise.all([initializeI18n, initializeBranding()]))
  .then(forceLogout)
  .then(registerFetchAuthHeaders)
  .then(setDefaultHiddenAccountsToggle)
  .then(() => onBeforeAppInit(
    disallowDuplicatedTab,
  ))
  .then(initializeAgileSDK)
  .then(bootstrapApplication);

/**
 * Tethred-login application initialization sequence
 */
const initizeTetheredLoginApp = () => Promise.resolve()
  .then(clearUserAuthData)
  .then(() => initializeI18n)
  .then(() => loadPromise)
  .then(initializeBranding)
  .then(bootstrapTetheredLoginApplication);

/**
 * Common initialization sequence
 */
export const initialize = () => Promise.resolve()
  .then(() => {
    if (isTetheredLogin(window.location.pathname)) {
      return initizeTetheredLoginApp();
    }
    return initializeMainApp();
  })
  .catch((e) => {

    /**
     * In case of Maintenance Mode just redirect to root
     */
    if (checkIfMaintenanceModeError(genericErrorHandler(e))) {
      return;
    }

    window.location.assign('/error.html'); // generic error page
  });

initialize();
