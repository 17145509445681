import { getAuthenticationToken } from '@app/core/authentication/authenticationToken';

import { CompromisedCredentialsStore } from './CompromisedCredentialsSessionStore';

const returnIfSessionMatches = <T extends any>(value: T): T | undefined => {
  const linkedSessionToken = CompromisedCredentialsStore.instance.getLinkedSessionToken();
  const isSameSession = linkedSessionToken === getAuthenticationToken();
  return isSameSession ? value : undefined;
};

export const getStatusFromStore = () => {
  const status = CompromisedCredentialsStore.instance.getStatus();
  return returnIfSessionMatches(status);
};

export const getUserAttractedToAlertFromStore = () => {
  const userAttractedToAlert = CompromisedCredentialsStore.instance.getUserAttractedToAlert();
  return returnIfSessionMatches(userAttractedToAlert);
};
