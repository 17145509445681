import React from 'react';

import Times from '@ast/magma/components/icon/icons/Times';

import { Button } from '@ast/magma/components/button';
import dialogStyles from '@ast/magma/components/dialog/dialog.pcss';

import { FragmentContainer } from '@app/common/components/FragmentContainer/FragmentContainer';
import { WizardStep, ConnectedWizardStep } from '@app/common/configurable-wizards';
import { HtmlTextFieldInfoLocator } from '@app/common/configurable-wizards/fields/HtmlText/HtmlTextFieldInfo';

import { useTriggeredEventsTranslation } from '@app/core/components/TriggeredEvents/useTriggeredEventsTranslation';

import styles from './Marketing.pcss';

export const Marketing: React.FC<ConnectedWizardStep> = ({
  locators,
  ...props
}) => {
  const { t } = useTriggeredEventsTranslation();
  const stepLocators = [HtmlTextFieldInfoLocator, ...(locators || [])];

  return (
    <div className={styles.wrapper}>
      <WizardStep
        {...props}
        locators={stepLocators}
        contentContainer={FragmentContainer}
        fieldsContainer={FragmentContainer}
        beforeFieldsContent={() => (
          <div className={styles.header}>
            <Button
              type="submit"
              look="icon"
              className={dialogStyles.closeButton}
              aria-label={t(
                'marketing.buttons.close.text|Close Triggered Event Marketing dialog button',
                'Close Triggered Event',
              )}
            >
              <Times />
            </Button>
          </div>
        )}
      />
    </div>
  );
};
