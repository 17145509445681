import React from 'react';

import { TriggeredEventWizard } from '../TriggeredEventWizard';

import { TriggeredEventWizardBase } from '../../types';
import { Constants } from './challenges/MarketingSplash/constants';
import { steps } from './challenges/MarketingSplash/steps';

export const MarketingSplashTriggeredEventWizard: React.FC<TriggeredEventWizardBase> = (props) => (
  // eslint-disable-next-line i18next/no-literal-string
  <TriggeredEventWizard {...props} wizard={Constants.WIZARD_NAME} steps={steps} />
);
