import {
  Validate,
  ValidationValueMessage,
} from 'react-hook-form';

export enum ValidationRule {
  MAX = 'max',
  MAX_LENGTH = 'maxLength',
  MIN = 'min',
  MIN_LENGTH = 'minLength',
  PATTERN = 'pattern',
  REQUIRED = 'required',
}

type ValidationRuleOptions = {
  value: string | number | boolean | RegExp
  message: string
} | Validate<any>;

export type FormControlValidationRuleResult = {
  [name: string]: ValidationValueMessage
} | {
  validate: Record<string, Validate<any>>
};

export type FormControlValidationRule = (
  name: string,
  options: ValidationRuleOptions,
) => FormControlValidationRuleResult;

export const formControlValidationRulesMapper = (validations: FormControlValidationRuleResult[] = []) => (
  validations
    .reduce((rules, currentRule) => {
      if (currentRule.validate && rules.validate) {
        return {
          ...rules,
          validate: {
            ...rules.validate,
            ...currentRule.validate,
          },
        };
      }

      return {
        ...rules,
        ...currentRule,
      };
    }, {} as Record<string, any>)
);

const formControlValidationRule: FormControlValidationRule = (name, rule) => {
  if (typeof rule === 'function') {
    return {
      validate: {
        [name]: rule,
      },
    };
  }

  return { [name]: rule };
};

export const formControlMaxRule = (value: number, message: string) => (
  formControlValidationRule(ValidationRule.MAX, {
    value,
    message,
  })
);

export const formControlMaxLengthRule = (value: number, message: string) => (
  formControlValidationRule(ValidationRule.MAX_LENGTH, {
    value,
    message,
  })
);

export const formControlMinRule = (value: number, message: string) => (
  formControlValidationRule(ValidationRule.MIN, {
    value,
    message,
  })
);

export const formControlMinLengthRule = (value: number, message: string) => (
  formControlValidationRule(ValidationRule.MIN_LENGTH, {
    value,
    message,
  })
);

export const formControlPatternRule = (value: RegExp, message: string) => (
  formControlValidationRule(ValidationRule.PATTERN, {
    value,
    message,
  })
);

export const formControlRequiredRule = (message?: string) => (
  formControlValidationRule(ValidationRule.REQUIRED, {
    value: true,
    message: message || '',
  })
);

export const formControlCustomRule = (name: string, callback: Validate<any>) => (
  formControlValidationRule(name, callback)
);
