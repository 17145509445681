/* eslint-disable i18next/no-literal-string */
import { InvokeBackendComponentResult } from '@app/queryTyping';

import {
    hasOwnPropertyOfType,
    isArray,
    isBoolean,
    isObject,
} from '@app/common/utils/assertion';

import { AgileSDKResponse, agileSDKResponsePrefix, isAgileSDKResponse } from '../types';

export const invokeBackendComponentResponseType = `${agileSDKResponsePrefix}.invokeBackendComponentResponse`;

export interface InvokeBackendComponentResponsePayload extends InvokeBackendComponentResult {}

export interface InvokeBackendComponentResponse extends AgileSDKResponse {
  readonly payload: InvokeBackendComponentResponsePayload,
}

export function isInvokeBackendComponentResponsePayload(data: unknown): data is InvokeBackendComponentResponsePayload {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'result', isObject)
    && hasOwnPropertyOfType(data, 'successful', isBoolean)
    && hasOwnPropertyOfType(data, 'errors', (value) => !value || isArray(value))
    && hasOwnPropertyOfType(data, 'warnings', (value) => !value || isArray(value))
  );
}

export function isInvokeBackendComponentResponse(data: unknown): data is InvokeBackendComponentResponse {
  return (
    isAgileSDKResponse(data)
    && hasOwnPropertyOfType(data, 'type', (value) => value === invokeBackendComponentResponseType)
    && hasOwnPropertyOfType(data, 'payload', isInvokeBackendComponentResponsePayload)
  );
}
