/* eslint-disable max-classes-per-file */
import { Listenable } from '@app/common/utils/listenable';

import { emailChangedEventName } from './constants';

class EmailChangedNotifier extends Listenable<EmailChangedListener> {
  private static instance: EmailChangedNotifier;

  constructor() {
    super(new Map<keyof EmailChangedListener, Set<Function>>(
      [[emailChangedEventName, new Set()]],
    ));
  }

  public static getInstance() {
    if (!EmailChangedNotifier.instance) {
      EmailChangedNotifier.instance = new EmailChangedNotifier();
    }

    return EmailChangedNotifier.instance;
  }

  clearListeners<K extends keyof EmailChangedListener>(key : K) {
    this.listeners.get(key)?.clear();
  }

  notify(key : any, value: string) {
    this.notifyListeners(key, { value });
  }
}

interface EmailChangedListener {
  emailchanged: { value: string },
}

export { EmailChangedNotifier };
