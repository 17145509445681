/* eslint-disable max-len */
import { AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX, AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX } from '../../constants';

export const GET_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LanguageCulture.GetLanguageCulture`;

export const SUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LanguageCulture.SubscribeLanguageCulture`;

export const UNSUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.LanguageCulture.UnsubscribeLanguageCulture`;

export const LANGUAGE_CULTURE_CHANGED_NOTIFICATION_MESSAGE_TYPE = `${AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX}.LanguageCulture.LanguageCultureChanged`;
