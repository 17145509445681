import { GraphQLError } from 'graphql';

import { ApolloError } from '@apollo/client/errors';

import { ErrorCodes } from '@app/queryTyping';

export interface ErrorDetails {
  ErrorMessage: string;
  Path: string | null;
}

function parseDetails(details: string[]): ErrorDetails[] {
  return details.map<ErrorDetails>((detail):ErrorDetails => JSON.parse(detail) as ErrorDetails);
}

const excludedErrorCodes = [
  ErrorCodes.USER_LOCKOUT,
  ErrorCodes.WIZARD_STEPS_INCONSISTENCY,
  ErrorCodes.FORCE_USER_INFO_RESET,
  ErrorCodes.MAINTENANCE_MODE,
];

export const getUserErrors = (apolloError: ApolloError): string[] | null => {
  const errors = apolloError.graphQLErrors.reduce((res: string[], graphQLError: GraphQLError) => {
    if (graphQLError.extensions && !excludedErrorCodes.includes(graphQLError.extensions.code)) {
      const details: ErrorDetails[] = parseDetails(graphQLError.extensions.details);
      const messages = details.map(({ ErrorMessage }) => ErrorMessage);
      res.push(messages.join(' '));
    }
    return res;
  }, []);

  return errors.length > 0 ? errors : null;
};
