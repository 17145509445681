import { UNSUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE } from '../constants';
import { UnsubscribeColorThemeRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link UnsubscribeColorThemeRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isUnsubscribeColorThemeRequestMessage(value: AgileSDKRequestMessage<unknown>): value is UnsubscribeColorThemeRequestMessage {
  return value.messageType === UNSUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE;
}
