import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { createGenericContext } from '@app/common/contexts/createGenericContext';
import { LinkMenuItem, useMainMenuQuery } from '@app/common/types/useMainMenuQuery';

/**
 * Context to calculate currently selected item.
 */
const [
  useSelectedMainMenuItemContext,
  SelectedMainMenuItemContextProvider,
] = createGenericContext<LinkMenuItem | null>();

/**
 * MainMenu selected item context provider.
 * This context calculates which main menu item mostly matches the current URL path.
 */
const SelectedMainMenuItemContext: React.FC = ({ children }) => {
  const { allMenuItems, error } = useMainMenuQuery();
  const { pathname } = useLocation();

  const selectedPath:LinkMenuItem | null = useMemo(() => {
    let result:LinkMenuItem | null = null;
    const matchedPaths = allMenuItems.filter(
      (menuItem) => pathname.startsWith(menuItem.url!),
    );

    if (matchedPaths.length > 0) {
      [result] = matchedPaths.sort((a, b) => b.url!.localeCompare(a.url!));
    }

    return result;
  }, [allMenuItems, pathname]);

  if (error) throw error;

  return (
    <SelectedMainMenuItemContextProvider value={selectedPath ?? null}>
      {children}
    </SelectedMainMenuItemContextProvider>
  );
};

export { useSelectedMainMenuItemContext, SelectedMainMenuItemContext };
