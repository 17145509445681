import React from 'react';

/** Creates new React.Context in passed generik type */
export const createGenericContext = <T extends unknown>(contextId?: string) => {
  // Create a context with a generic parameter or undefined
  const genericContext = React.createContext<T | undefined>(undefined);

  // Check if the value provided to the context is defined or throw an error
  const useGenericContext = () => {
    const contextIsDefined = React.useContext(genericContext);
    if (contextIsDefined === undefined) {
      // eslint-disable-next-line i18next/no-literal-string
      const contextMEssage = contextId ? `Context ${contextId}` : 'useGenericContext';
      throw new Error(`${contextMEssage} must be used within a Provider which must initialize it.`);
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider, genericContext.Consumer] as const;
};
