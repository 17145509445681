import { isObject, isString } from '@app/common/utils/assertion';

import { ViewportSize } from '../types';

/**
 * Checks if the value is a {@link ViewportSize}.
 */
export function isViewportSize(value: unknown): value is ViewportSize {
  return (
    isObject(value)

    // The width must be a string if presented.
    && ('width' in value ? isString(value.width) : true)

    // The height must be a string if presented.
    && ('height' in value ? isString(value.height) : true)
  );
}
