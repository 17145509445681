/* eslint-disable class-methods-use-this */
import { ApolloError, isApolloError, ServerError } from '@apollo/client';

import { ErrorCodes } from '@app/queryTyping';

import { clearUserAuthData } from '@app/core/authentication/logout/logout';

import { basicErrorProcessors } from './errorProcessors/index';
import { ErrorProcessor } from './errorProcessors/types';

import { UserError } from './UserError';

/**
 * Create error handler which calls error processor only in case if error is not user friendly.
 * If error is already processed it returns as is.
 * @param processor error processor function.
 */
const createInternalErrorHandler = (processor:ErrorProcessor) => (
  error:Error,
) => ((error instanceof UserError) ? error : processor(error));

/**
 * Create error handler wich call a banch of error processors in order to handle error and
 * turn it to user facing error.
 * @param processors list of error processors.
 */
const createInternalErrorHandlers = (processors:ErrorProcessor[]) => (
  error:Error,
) => processors.reduce((
  handledError,
  processor,
) => (createInternalErrorHandler(processor)(handledError)), error);

/**
 * Function to get user error from any error using batch of error processors.
 * If no processors provided when generic error processor will return generic user errror.
 * @param error any error
 * @param processors optional list of error processors which
 * can try to recognise error and turn it to user facing error.
 */
export const genericErrorHandler = (error:Error, processors?:ErrorProcessor[]):UserError => {
  const actualProcessors = processors || [];
  actualProcessors.push(...basicErrorProcessors);
  const userError = createInternalErrorHandlers(actualProcessors)(error) as any as UserError;

  if (!userError.isRetryPossible) {
    clearUserAuthData();
  }

  return userError;
};

const isApolloServerError = (error: UserError['originalError']): error is ServerError => Boolean((error as any)?.result);

export const checkIfMaintenanceModeError = (error: UserError | ApolloError) => {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.some((gqError) => gqError.extensions?.code === ErrorCodes.MAINTENANCE_MODE);
  }

  if (isApolloServerError(error.originalError)) {
    const { errors } = error.originalError.result;

    if (Array.isArray(errors)) {
      return Boolean(errors.find((specificError) => specificError.extensions.code === ErrorCodes.MAINTENANCE_MODE));
    }

    if (typeof errors === 'object' && errors.extensions.code === ErrorCodes.MAINTENANCE_MODE) {
      return true;
    }
  }

  if (error.originalError && isApolloError(error.originalError)) {
    const { graphQLErrors } = error.originalError;

    return graphQLErrors.some((gqError) => gqError.extensions?.code === ErrorCodes.MAINTENANCE_MODE);
  }

  return false;
};
