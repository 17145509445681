import { ApolloCache } from '@apollo/client';

import { GQLFieldNames } from '../types/enum/gqlFieldNames';

export interface CacheManagerProps {
  bankAccountId?: string
  broadcast?: boolean
}

export class CacheManager {
  static resetCache<T>(
    cache: ApolloCache<T>,
    params: CacheManagerProps = {},
  ) : void {
    const {
      bankAccountId,
      broadcast,
    } = params;

    // this code deletes specific QUERY from the gql cache
    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: GQLFieldNames.TRANSACTIONS_HISTORY,
      broadcast,
    });

    cache.evict({
      id: 'ROOT_QUERY',
      // this is root field of GetUserBankAccountBalanceChangesQuery
      fieldName: GQLFieldNames.BANK_ACCOUNT_BALANCE_CHANGES,
      broadcast,
      ...(
        bankAccountId
          ? { args: { bankAccountId } }
          : {}
      ),
    });

    cache.evict({
      id: 'ROOT_QUERY',
      // this is root field of GetUserBankAccountSummaryQuery
      fieldName: GQLFieldNames.BANK_ACCOUNT_SUMMARY,
      broadcast,
      ...(
        bankAccountId
          ? { args: { bankAccountId } }
          : {}
      ),
    });

    cache.evict({
      id: 'ROOT_QUERY',
      // this is root field of GetUserBankAccountSummaryQuery
      fieldName: GQLFieldNames.USER_BANK_ACCOUNTS,
      broadcast,
    });

    cache.evict({
      id: 'ROOT_QUERY',
      // this is root field of GetTransactionsGraphQuery
      fieldName: GQLFieldNames.BANK_ACCOUNT_TRANSACTIONS_GRAPH,
      broadcast,
    });

    cache.evict({
      id: 'ROOT_QUERY',
      // this is root field of GetBankAccountBalancesGraphQuery
      fieldName: GQLFieldNames.BANK_ACCOUNT_BALANCE_GRAPH,
      broadcast,
    });

    cache.evict({
      id: 'ROOT_QUERY',
      fieldName: GQLFieldNames.TRANSFER_ACTIVITIES,
      broadcast,
    });

    cache.gc();
  }

  static evictFields<T>(
    cache: ApolloCache<T>,
    params: CacheManagerProps = {},
    fields: GQLFieldNames[] = [],
  ) : void {
    const {
      broadcast,
    } = params;

    fields.forEach((fieldName) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName,
        broadcast,
      });
    });

    cache.gc();
  }
}
