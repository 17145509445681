/**
 * User logged out event name.
 */
export const USER_LOGGED_OUT_EVENT = 'ast.userLoggedOut';

/**
 * Event fired when user logs out.
 *
 * Contains no payload.
 */
export class UserLoggedOutEvent extends CustomEvent<never> {
  constructor() {
    super(USER_LOGGED_OUT_EVENT);
  }
}

declare global {
  interface WindowEventMap {
    [USER_LOGGED_OUT_EVENT]: UserLoggedOutEvent;
  }
}
