/* eslint-disable i18next/no-literal-string */
import { InvokeExternalApiIntegrationResult } from '@app/queryTyping';

import {
  hasOwnPropertyOfType,
  isArray,
  isBoolean,
  isObject,
  isString
} from '@app/common/utils/assertion';

import { AgileSDKResponse, agileSDKResponsePrefix, isAgileSDKResponse } from '../types';

export const invokeExternalApiIntegrationResponseType = `${agileSDKResponsePrefix}.invokeExternalApiIntegrationResponse`;

export interface InvokeExternalApiIntegrationResponsePayload extends InvokeExternalApiIntegrationResult {}

export interface InvokeExternalApiIntegrationResponse extends AgileSDKResponse {
  /**
   * Payload version.
   */
  readonly payloadVersion?: string,
  /**
   * Actual request data.
   */
  readonly payload: InvokeExternalApiIntegrationResponsePayload,
}

export function isInvokeExternalApiIntegrationResponsePayload(data: unknown): data is InvokeExternalApiIntegrationResponsePayload {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'result', isObject)
    && hasOwnPropertyOfType(data, 'successful', isBoolean)
    && hasOwnPropertyOfType(data, 'errors', (value) => !value || isArray(value))
    && hasOwnPropertyOfType(data, 'warnings', (value) => !value || isArray(value))
  );
}

export function isInvokeExternalApiIntegrationResponse(data: unknown): data is InvokeExternalApiIntegrationResponse {
  return (
    isAgileSDKResponse(data)
    && hasOwnPropertyOfType(data, 'payloadVersion', isString)
    && hasOwnPropertyOfType(data, 'payload', isInvokeExternalApiIntegrationResponsePayload)
  );
}