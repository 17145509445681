/**
 * This is the list of root page URLS.
 */
export enum PageURL {
  BANK_ACCOUNT = '/bank-accounts',
  USER_LOGIN = '/user-login',
  USER_ENROLLMENT = '/user-enrollment',
  SUB_USER_ENROLLMENT = '/subuser-enrollment',
  USER_KIND = '/user-kind',
  USER_RETRIEVAL = '/user-retrieval',
  SECURE_ACCOUNT = '/secure-account',
  // this link is generated by DS
  EMAIL_VERIFICATION = '/email-verification',
  TRANSFERS_PAYMENTS = '/transfers-payments',
  ALERT_SETTINGS = '/alert-settings',
  MESSAGE_CENTER = '/message-center',
  CONTACTS_AND_SETTINGS = '/contacts-and-settings',
  CARD_CONTROL = '/card-control',
  FAMILY_AND_FRIENDS = '/family-and-friends',
  BUSINESS_BANKING = '/business-banking',
  ESTATEMENTS = '/estatements',
  OPEN_NEW_SHARES = '/open-new-shares',
  SUB_USER_APPROVAL = '/subuser-transfer-approval',
  TRANSFER_ACTIVITY = '/transfer-activity',
  CREDIT_SCORE = '/credit-score',
  PASSKEY_ONBOARDING = '/passkey-onboarding',
  TRIGGERED_EVENT = '/triggered-event',
}
