import { ValidationUnion } from '@app/queryTyping';
import {
  FieldServerError,
  GenericServerError,
  ServerErrors,
  UnifiedServerError,
} from '@app/common/types/validation';
import {
  userFriendlyServerErrorMessageResolver as userFriendly,
} from './validationTypes/userFriendlyServerValidationError';
import {
  invalidFormatServerErrorMessageResolver as invalidFormat,
} from './validationTypes/invalidFormatServerValidationError';

type UnifyErrorResolver = (error: any) => UnifiedServerError;

/**
 * Possible server errors and its resolvers
 */
const errorMessageResolvers: Record<string, UnifyErrorResolver> = {
  [userFriendly.typeName]: userFriendly.resolver,
  [invalidFormat.typeName]: invalidFormat.resolver,
};

/**
 * Convert server error to the common unified type
 */
export const unifyServerError = (error: ValidationUnion): UnifiedServerError => {
  // eslint-disable-next-line no-underscore-dangle
  const errorType = error.__typename || '';
  let resolver;

  if (errorType in errorMessageResolvers) {
    resolver = errorMessageResolvers[errorType];
  } else {
    resolver = () => ({ message: '' });
  }
  return resolver(error);
};

/**
 * Sort errors to generic and field categories and return it
 */
export const getServerErrors = (errors: Array<ValidationUnion | null>): ServerErrors => {
  const genericErrors: Array<GenericServerError> = [];
  const fieldErrors: Array<FieldServerError> = [];

  errors.forEach((error) => {
    if (!error) {
      return;
    }

    const { message, path } = unifyServerError(error);

    // mark as a field error if it contains field path name
    if (path) {
      fieldErrors.push({
        fieldId: path,
        message,
      });
    } else { // else mark as generic error
      genericErrors.push({
        message,
      });
    }
  });

  return {
    genericErrors: genericErrors.length ? genericErrors : null,
    fieldErrors: fieldErrors.length ? fieldErrors : null,
  };
};
