import React from 'react';
import { useTranslation } from '@app/core/i18n';
import { useDocumentTitle } from '@app/common/utils/hooks/useDocumentTitle';
import { isMultipleOf } from '@app/common/utils/assertion';

interface AutoLogoutCountdownDocumentTitleProps {
  readonly secsToLogout: number;
}

export const AutoLogoutCountdownDocumentTitle: React.FC<AutoLogoutCountdownDocumentTitleProps> = ({ secsToLogout }) => {
  const { t } = useTranslation();

  // Sequence, once per 4 seconds, rotate text from countdown to 'Attention!'
  const title = isMultipleOf(secsToLogout, 4)
    ? t(
      'user-auto-logout.tab-title.attention-text|Represents the auto-logout attention message that will be shown at the browser tab when the user is away',
      'Attention!',
    )
    : t(
      'user-auto-logout.tab-title.countdown-text|Represents the auto-logout countdown message that will be shown at the browser tab when the user is away',
      'Session expires in {{secsToLogout}}s.', { secsToLogout },
    );

  useDocumentTitle(title);
  return null;
};
