import React, { FunctionComponent, useEffect, useState } from 'react';

import { MessageReceiver } from '@ast/shared-message-receiver';

import { createGenericContext } from '@app/common/contexts/createGenericContext';

import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import {
  extractInitParametersFromLocalStorage,
} from '@app/core/authentication/parametrizedLogin/sessionParametersStorage';
import {
  CHANGE_CULTURE_ID_MESSAGE_TYPE,
  DEFAULT_FALLBACK_LANGUAGE,
  DEFAULT_FALLBACK_LANGUAGE_CULTURE,
  LANGUAGE_CULTURE_KEY,
  SEARCH_LANGUAGE_CULTURE_PARAM,
} from '@app/core/contexts/localizationContext/constants';

import { LanguageCultureChangedEvent } from './utils/LanguageCultureChangedEvent';

export interface ILocalizationSelectorContext {
  currentLanguageCulture: string | null;
  setCurrentLanguageCulture: (language: string) => void;
}

const [
  useLocalizationContext,
  LocalizationContextProvider,
] = createGenericContext<ILocalizationSelectorContext>();

export const setCurrentLanguageCultureToStorage = (cultureID: string) => {
  localStorage.setItem(LANGUAGE_CULTURE_KEY, cultureID);
};

export const getCurrentLanguageCultureFromStorage = () => localStorage.getItem(LANGUAGE_CULTURE_KEY) ?? null;

export const notifyCurrentCultureId = (cultureId: string) => {
  const messageReceiver = new MessageReceiver({
    source: window,
    origin: '*',
  });

  messageReceiver.postMessage({
    version: '1.0',
    messageType: CHANGE_CULTURE_ID_MESSAGE_TYPE,
    payloadVersion: '1.0',
    payload: {
      cultureId,
    },
  }).then();

  // Notify other parts of the application about the culture change.
  window.dispatchEvent(new LanguageCultureChangedEvent(cultureId));
};

export const getLanguageFromCulture = (culture: string) => culture?.split('-')[0] || DEFAULT_FALLBACK_LANGUAGE;

export const setHTMLLangAttribute = (culture: string) => {
  document.documentElement.lang = getLanguageFromCulture(culture);
};

export function readLanguageCulture() {
  const currentCultureFromUrl = new URLSearchParams(window.location.search);

  return (
    currentCultureFromUrl.get(SEARCH_LANGUAGE_CULTURE_PARAM)
    || (isEmbeddedMode() && extractInitParametersFromLocalStorage().cultureId)
    || getCurrentLanguageCultureFromStorage()
    || getLanguageFromCulture(window.navigator.language)
    || DEFAULT_FALLBACK_LANGUAGE_CULTURE
  );
}

export const LocalizationProvider: FunctionComponent = ({ children }) => {
  const [currentLanguageCulture, setCurrentLanguageCultureState] = useState<string>(readLanguageCulture());

  useEffect(() => {
    setHTMLLangAttribute(currentLanguageCulture);
    notifyCurrentCultureId(currentLanguageCulture);
    setCurrentLanguageCultureToStorage(currentLanguageCulture);
  }, [currentLanguageCulture]);

  return (
    <LocalizationContextProvider value={{
      currentLanguageCulture,
      setCurrentLanguageCulture: setCurrentLanguageCultureState,
    }}
    >
      {children}
    </LocalizationContextProvider>
  );
};

export {
  useLocalizationContext,
};
