import { isIOS } from '@ast/magma';

// lower versions than iOS 14.5/Safari 14.1 do not support flex gap
// https://webkit.org/blog/11648/new-webkit-features-in-safari-14-1/
const isIOS14And5OrHigher = (ua: string) => {
  const regex = /OS (\d+)_(\d+)_?(\d+)?/;
  const [, majorVersion, minorVersion] = regex.exec(ua) || [];
  return Number(majorVersion) > 14 || (Number(majorVersion) === 14 && Number(minorVersion) >= 5);
};

export const isFlexGapSupported = (ua: string) => !isIOS() || isIOS14And5OrHigher(ua);
