import { FieldType } from '@app/queryTyping';

import {
  Fields,
  Field,
} from '../types';

export const isBooleanField = (field: Field): field is Fields['Boolean'] => (
  field.type === FieldType.BOOLEANFIELDINFO
);

export const isEmailField = (field: Field): field is Fields['Email'] => (
  field.type === FieldType.EMAILFIELDINFO
);

export const isPhoneField = (field: Field): field is Fields['MaskedText'] => (
  field.type === FieldType.PHONEFIELDINFO
);

export const isErrorMessageField = (field: Field): field is Fields['ErrorMessage'] => (
  field.type === FieldType.ERRORMESSAGEFIELDINFO
);

export const isExternalLinkField = (field: Field): field is Fields['ExternalLink'] => (
  field.type === FieldType.EXTERNALLINK
);

export const isHiddenField = (field: Field): field is Fields['Hidden'] => (
  field.type === FieldType.HIDDENFIELDINFO
);

export const isLabelField = (field: Field): field is Fields['Label'] => (
  field.type === FieldType.LABELFIELDINFO
);

export const isMaskedTextField = (field: Field): field is Fields['MaskedText'] => (
  field.type === FieldType.MASKEDTEXTFIELDINFO
);

export const isMultipleChoiceField = (field: Field): field is Fields['MultipleChoice'] => (
  field.type === FieldType.MULTIPLECHOICEFIELDINFO
);

export const isNotificationField = (field: Field): field is Fields['Notification'] => (
  field.type === FieldType.NOTIFICATIONFIELDINFO
);

export const isPasswordField = (field: Field): field is Fields['Password'] => (
  field.type === FieldType.PASSWORDFIELDINFO
);

export const isSingleChoiceField = (field: Field): field is Fields['SingleChoice'] => (
  field.type === FieldType.SINGLECHOICEFIELDINFO
);

export const isSsnField = (field: Field): field is Fields['Ssn'] => (
  field.type === FieldType.SSNFIELDINFO
);

export const isUsernameField = (field: Field): field is Fields['Username'] => (
  field.type === FieldType.USERNAMEFIELDINFO
);

export const isTextField = (field: Field): field is Fields['Text'] => (
  field.type === FieldType.TEXTFIELDINFO
);

export const isConstrainedTextField = (field: Field): field is Fields['ConstrainedText'] => (
  field.type === FieldType.CONSTRAINEDTEXTFIELDINFO
);

export const isFieldInfoGroupField = (field: Field): field is Fields['FieldInfoGroup'] => (
  field.type === FieldType.FIELDINFOGROUP
);

export const isSelectableGroupFiledInfo = (field: Field): field is Fields['Text'] => (
  field.type === FieldType.SELECTABLEGROUPFIELDINFO
);

export const isFuzzyValueField = (field: Field): field is Fields['FuzzyValue'] => (
  field.type === FieldType.FUZZYVALUEFIELDINFO
);

export const isAgreementField = (field: Field): field is Fields['Agreement'] => (
  field.type === FieldType.AGREEMENTFIELDINFO
);

export const isZipCodeField = (field: Field): field is Fields['ZipCode'] => (
  field.type === FieldType.ZIPCODEFIELDINFO
);

export const isCurrencyField = (field: Field): field is Fields['Currency'] => (
  field.type === FieldType.CURRENCYFIELDINFO
);

export const isIntegerField = (field: Field): field is Fields['Integer'] => (
  field.type === FieldType.INTEGERFIELDINFO
);

export const isDateField = (field: Field): field is Fields['Date'] => (
  field.type === FieldType.DATEFIELDINFO
);

export const isTimeField = (field: Field): field is Fields['Time'] => (
  field.type === FieldType.TIMEFIELDINFO
);

export const isChallengeQuestionsField = (field: Field): field is Fields['QuestionPoolsFieldGroup'] => (
  field.type === FieldType.CHALLENGEQUESTIONSFIELDINFO
);

export const isHtmlTextFieldInfo = (field: Field): field is Fields['HtmlText'] => (
  field.type === FieldType.HTMLTEXTFIELDINFO
);
