import React, { useEffect, useRef } from 'react';

import { Button } from '@ast/magma/components/button';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import styles from './Buttons.pcss';

export const Buttons = ({
  onSubmitPasskey,
  setLoginMode,
  isPasskeyEnabled = false,
}: {
  onSubmitPasskey: (isAuthConditional?: boolean) => void,
  setLoginMode: () => void,
  // eslint-disable-next-line react/require-default-props
  isPasskeyEnabled?: boolean,
}) => {
  const { t } = useUserLoginTranslation();
  const hasCalledPasskeyAuth = useRef(false);

  useEffect(() => {
    if (isPasskeyEnabled && !hasCalledPasskeyAuth.current) {
      // We start conditional authentication
      // If it succeeds, we need to submit the wizard step with login mode set to 'passkey'
      onSubmitPasskey(true);
      hasCalledPasskeyAuth.current = true; // Mark as called
    }
  }, [isPasskeyEnabled]);

  return (
    <FlexContainer gap="small" direction="column">
      <Button
        type="submit"
        className={styles.signInButton}
        look={isPasskeyEnabled ? 'outlined' : 'primary'}
        predefinedSize="medium"
        data-stable-name="SignInButton"
        onClick={setLoginMode}
      >
        {t('login.steps.username-password.buttons.signin.text|Sign in button text', 'Sign in')}
      </Button>
      {isPasskeyEnabled && (
      <Button
        className={styles.signInButton}
        look="primary"
        predefinedSize="medium"
        data-stable-name="AuthButtonPasskey"
        onClick={() => onSubmitPasskey()}
      >
        {t('login.steps.username-password.buttons.auth-passkey.text|Auth button passkey', 'Sign in with a passkey')}
      </Button>
      )}
    </FlexContainer>
  );
};
