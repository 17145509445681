/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Trans } from 'react-i18next';

import { TextLink } from '@ast/magma/components/textlink';

import { useFISettingsContext } from '@app/core/contexts/fiSettings/FISettingsContext';

import { FooterProps } from '../FooterProps';

/**
 * Common error dialog footer uses FI settings to show proper support link
 */
export const CommonErrorDialogFooter = ({ reloadHref, isInternalError }: FooterProps) => {
  const fiSettings = useFISettingsContext();
  const supportUrl = fiSettings?.getIfConfigured('customerHelpCenterUrl');
  const supportHref = supportUrl ?? '';
  const getReloadHref = reloadHref ?? window.location.href;

  let contactUsLabel = (
    <Trans i18nKey="error-dialog.common-footer.support-link-configured|
      Generic error dialog common footer with contact link configured"
    >
      Please
      {' '}
      <TextLink href={getReloadHref}>
        try again
      </TextLink>
      {' '}
      later, or
      {' '}
      <TextLink
        href={supportHref}
        rel="noopener noreferrer nofollow"
        target="_blank"
      >
        contact us
      </TextLink>
      {' '}
      for help.
    </Trans>
  );

  let contactUsLabelNoLink = (
    <Trans i18nKey="error-dialog.common-footer.support-no-link-configured|
      Generic error dialog common footer with no contact link configured"
    >
      Please
      {' '}
      <TextLink href={getReloadHref}>
        try again
      </TextLink>
      {' '}
      later, or contact us for help.
    </Trans>
  );

  if (isInternalError) {
    contactUsLabel = (
      <Trans i18nKey="error-dialog.common-footer.support-link-configured.with-take-screenshot-label|
      Generic error dialog common footer with contact link configured with take screenshot label"
      >
        Please
        {' '}
        <TextLink href={getReloadHref}>
          try again
        </TextLink>
        {' '}
        later, or take a screenshot and
        {' '}
        <TextLink
          href={supportHref}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          contact us
        </TextLink>
        {' '}
        for help.
      </Trans>
    );
    contactUsLabelNoLink = (
      <Trans i18nKey="error-dialog.common-footer.support-no-link-configured.with-take-screenshot-label|
      Generic error dialog common footer with no contact link configured with take screenshot label"
      >
        Please
        {' '}
        <TextLink href={getReloadHref}>
          try again
        </TextLink>
        {' '}
        later, or take a screenshot and contact us for help.
      </Trans>
    );
  }

  return (
    <>
      <p>
        {
          supportHref ? contactUsLabel : contactUsLabelNoLink
        }
      </p>
    </>
  );
};
