/**
 * @todo review the necessity of that file
 */

import {
  FieldContext, FieldLocator, isLabelField,
} from '@app/common/configurable-wizards';
import { LabelAsPlainTextField } from '@app/common/configurable-wizards/fields/label/LabelAsPlainText';

export const isInfoFieldLabel = ({ field }: FieldContext) => (
  // eslint-disable-next-line i18next/no-literal-string
  isLabelField(field) && field.id === 'InfoField'
);

export const LabelAsPlainTextLocator: FieldLocator = (context) => (
  isInfoFieldLabel(context)
    ? LabelAsPlainTextField
    : undefined
);
