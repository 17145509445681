import React, { useRef } from 'react';
import { Trans } from 'react-i18next';

import ExclamationCircle from '@ast/magma/components/icon/icons/ExclamationCircle';

import { useToast } from '@ast/magma/components/toast';

import { ToasButton } from '@app/common/components/ToastButton';
import { noop } from '@app/common/utils';

export type UseCompomisedPasswordToastProps = {
  readonly onChangePasswordClick?: () => void;
  readonly onToastClose?: () => void;
};

/**
 * @private
 */
export const useCompomisedPasswordToast = ({
  onChangePasswordClick = noop,
  onToastClose = noop,
}: UseCompomisedPasswordToastProps) => {
  const { add: addToast, remove: removeToast } = useToast();
  const toasIdRef = useRef<string | null>(null);

  const show = () => {
    const id = addToast({
      // eslint-disable-next-line i18next/no-literal-string
      level: 'warn',
      // eslint-disable-next-line i18next/no-literal-string
      position: 'bottom',
      icon: <ExclamationCircle />,
      timeout: 0,
      // instead of plain text, we add html content in children
      title: '',
      canClose: true,
      children: (
        <Trans
          i18nKey="compromised-credentials.compromised-password-notification|The toast notification that appears right after the user logs in if their password is compromised"
        >
          <div>
            Your password appears on a list of known data breaches.
            We strongly encourage you to change it to keep your account secure.
          </div>
          <ToasButton
            style={{ marginTop: 'var(--space-sm)' }}
            onClick={() => {
              removeToast(id);
              onChangePasswordClick();
            }}
          >
            Change password
          </ToasButton>
        </Trans>
      ),
      onToastClose,
    });

    toasIdRef.current = id;
  };

  const hide = () => {
    removeToast(toasIdRef.current);
  };

  return { show, hide };
};
