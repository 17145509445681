/* eslint-disable max-len */
import { AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX } from '../../constants';

/**
 * The default value for the in-memory storage item.
 *
 * Returned when the key is not found in the storage.
 */
export const DEFAULT_IN_MEMORY_STORAGE_ITEM_VALUE = '';

/**
 * The message type for getting an in-memory storage item value.
 */
export const GET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.InMemoryStorage.GetItem`;

/**
 * The message type for setting an in-memory storage item value.
 */
export const SET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.InMemoryStorage.SetItem`;
