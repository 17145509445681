/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetApplicationWidgetsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetApplicationWidgetsQuery = (
  { __typename?: 'Query' }
  & { widgets: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'ZoneHolder' }
    & Pick<Types.ZoneHolder, 'zoneName'>
    & { items: Array<Types.Maybe<(
      { __typename?: 'Widget' }
      & Pick<Types.Widget, 'html' | 'platform'>
    ) | (
      { __typename?: 'ImageWidget' }
      & Pick<Types.ImageWidget, 'path' | 'platform'>
    ) | (
      { __typename?: 'ThemeAwareImageWidget' }
      & Pick<Types.ThemeAwareImageWidget, 'lightThemePath' | 'darkThemePath' | 'platform'>
    ) | { __typename?: 'PlacementWidget' } | { __typename?: 'Page' } | (
      { __typename?: 'AdServiceWidget' }
      & Pick<Types.AdServiceWidget, 'providerId'>
    ) | (
      { __typename?: 'CustomAppWidget' }
      & Pick<Types.CustomAppWidget, 'url' | 'width' | 'height' | 'frameAttrs'>
    ) | (
      { __typename?: 'DeepTargetWidget' }
      & Pick<Types.DeepTargetWidget, 'url' | 'width' | 'height' | 'mobileWidth' | 'mobileHeight' | 'is3DStoryWidget'>
    ) | { __typename?: 'Menu' } | (
      { __typename?: 'SsoWidget' }
      & Pick<Types.SsoWidget, 'providerId' | 'width' | 'height' | 'hideIfIneligible'>
    ) | { __typename?: 'ContentItem' } | { __typename?: 'LinkMenuItem' }>> }
  )>>> }
);


export const GetApplicationWidgetsDocument = gql`
    query GetApplicationWidgets {
  widgets {
    zoneName
    items {
      ... on Widget {
        html
        platform
      }
      ... on ImageWidget {
        path
        platform
      }
      ... on ThemeAwareImageWidget {
        lightThemePath
        darkThemePath
        platform
      }
      ... on AdServiceWidget {
        providerId
      }
      ... on DeepTargetWidget {
        url
        width
        height
        mobileWidth
        mobileHeight
        is3DStoryWidget
      }
      ... on SsoWidget {
        providerId
        width
        height
        hideIfIneligible
      }
      ... on CustomAppWidget {
        url
        width
        height
        frameAttrs
      }
    }
  }
}
    `;

/**
 * __useGetApplicationWidgetsQuery__
 *
 * To run a query within a React component, call `useGetApplicationWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationWidgetsQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationWidgetsQuery, GetApplicationWidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationWidgetsQuery, GetApplicationWidgetsQueryVariables>(GetApplicationWidgetsDocument, options);
      }
export function useGetApplicationWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationWidgetsQuery, GetApplicationWidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationWidgetsQuery, GetApplicationWidgetsQueryVariables>(GetApplicationWidgetsDocument, options);
        }
export type GetApplicationWidgetsQueryHookResult = ReturnType<typeof useGetApplicationWidgetsQuery>;
export type GetApplicationWidgetsLazyQueryHookResult = ReturnType<typeof useGetApplicationWidgetsLazyQuery>;
export type GetApplicationWidgetsQueryResult = Apollo.QueryResult<GetApplicationWidgetsQuery, GetApplicationWidgetsQueryVariables>;