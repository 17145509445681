import React from 'react';

import { FragmentContainer } from '@app/common/components/FragmentContainer/FragmentContainer';
import { WizardStep, WizardStepProps } from '@app/common/configurable-wizards';
import { fieldName } from '@app/common/configurable-wizards/fields/TermsAndConditions/TermsAndConditionsField';

import { DisclosuresWizardButtons } from './DisclosuresWizardButtons';

export const DisclosuresWizardStep: React.FC<WizardStepProps> = ({
  ...props
}) => (
  <WizardStep
    contentContainer={FragmentContainer}
    fieldsContainer={FragmentContainer}
    buttons={({ submit, form, cancel }) => (
      <DisclosuresWizardButtons
        cancel={() => {
          // bypass field validation and set value to false
          form.unregister(fieldName);
          form.register(fieldName, { value: false });

          submit();
          cancel!();
        }}
      />
    )}
    {...props}
  />
);
