import React from 'react';

import { FieldComponent, FieldContext, isLabelField } from '@app/common/configurable-wizards';
import { LabelFieldFragment } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';

export const LabelAsPlainTextField: FieldComponent<LabelFieldFragment> = ({
  field,
}) => (
  <span
    data-stable-name={`${field.id}LabelAsPlainText`}
  >
    {field.label || ''}
  </span>
);

export const LabelAsPlainTextFieldLocator = ({ field }: FieldContext) => (
  isLabelField(field) ? LabelAsPlainTextField : undefined
);