import * as React from 'react';
import { createGenericContext } from '../contexts/createGenericContext';
import { WizardStepState } from './types';

const [
  useWizardStepStateContext,
  WizardStepStateContextProvider,
] = createGenericContext<WizardStepState>();

interface WizardStepStateProviderProps {
  value: WizardStepState
}

/**
 * Represents the wizard step-state provider
 */
const WizardStepStateProvider: React.FC<WizardStepStateProviderProps> = ({ children, value }) => (
  <WizardStepStateContextProvider value={value}>
    {children}
  </WizardStepStateContextProvider>
);

export { useWizardStepStateContext, WizardStepStateProvider };
