import React, { HTMLAttributes } from 'react';

import styles from './IconTitleSubtitleControl.pcss';

export interface IconTitleSubtitleControlProps extends HTMLAttributes<HTMLDivElement> {
  readonly icon?: JSX.Element
  readonly title?: string
  readonly subtitle?: string
  readonly titleClassName?: string
}

export const IconTitleSubtitleControl: React.FC<IconTitleSubtitleControlProps> = (
  {
    icon,
    title,
    subtitle,
    titleClassName,
  }: IconTitleSubtitleControlProps,
) => (
  <div className={styles.container}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.content}>
      <span className={titleClassName || styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  </div>
);
