import React from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { NumericFormatProps } from 'react-number-format';

import { PartialButNot } from 'typescriptUtilityTypes';

import { FieldBehaviorMode } from '@app/queryTyping';

import { CFFormField } from '@app/common/configurable-wizards/fields/common/CFFormField';

export interface DateInputProps extends Omit<NumericFormatProps, 'defaultValue'> {
  controllerProps: PartialButNot<ControllerProps, 'render'>;
  readonly name: string;
  readonly value?: string;
  field: any;
  error: string
}

export const DateInput: React.FC<DateInputProps> = ({
  field,
  name,
  error,
  controllerProps,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={field.value || ''}
      shouldUnregister
      rules={{
        required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
      }}
      {...controllerProps}
      render={(renderProps) => (
        <CFFormField error={error}>
          {controllerProps.render(renderProps)}
        </CFFormField>
      )}
    />
  );
};
