/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { ThemeVariableFragment } from './ThemeVariable';
import { gql } from '@apollo/client';
import { ThemeVariableFragmentDoc } from './ThemeVariable';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetBrandingVariablesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBrandingVariablesQuery = (
  { __typename?: 'Query' }
  & { branding: (
    { __typename?: 'Branding' }
    & { lightThemeVariables: Array<(
      { __typename?: 'CssVariable' }
      & ThemeVariableFragment
    )>, darkThemeVariables: Array<(
      { __typename?: 'CssVariable' }
      & ThemeVariableFragment
    )> }
  ) }
);


export const GetBrandingVariablesDocument = gql`
    query GetBrandingVariables {
  branding {
    lightThemeVariables {
      ...ThemeVariable
    }
    darkThemeVariables {
      ...ThemeVariable
    }
  }
}
    ${ThemeVariableFragmentDoc}`;

/**
 * __useGetBrandingVariablesQuery__
 *
 * To run a query within a React component, call `useGetBrandingVariablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandingVariablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandingVariablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBrandingVariablesQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>(GetBrandingVariablesDocument, options);
      }
export function useGetBrandingVariablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>(GetBrandingVariablesDocument, options);
        }
export type GetBrandingVariablesQueryHookResult = ReturnType<typeof useGetBrandingVariablesQuery>;
export type GetBrandingVariablesLazyQueryHookResult = ReturnType<typeof useGetBrandingVariablesLazyQuery>;
export type GetBrandingVariablesQueryResult = Apollo.QueryResult<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>;