/* eslint-disable class-methods-use-this */
import { VoidCallback } from '@app/common/utils';

export type BaseNotifierSubscriber<T extends Function> = WeakRef<T>;

/**
 * A base notifier that allows to subscribe to notifications.
 *
 * Stores subscribers as weak references to prevent memory leaks and  automatically cleans up dead references
 * before notifying subscribers.
 *
 * Warning!
 *
 * This notifier stores subscribers as weak references, so make sure to keep a strong reference to the callback
 * while you are subscribed to the notifier. Otherwise, the callback may be garbage collected and you will not
 * receive notifications.
 *
 * ```ts
 * // Store the callback in a field to keep a strong reference to it.
 * this.callback = () => console.log('Notification received');
 * notifier.subscribe(this.callback);
 * ```
 *
 * This notifier calls subscribers synchronously, so make sure to avoid long-running operations in the subscriber
 * callbacks, and do not use to notify a large number of subscribers.
 */
export class BaseNotifier<T extends Function = VoidCallback> {
  protected subscribers: Set<BaseNotifierSubscriber<T>>;

  constructor() {
    this.subscribers = new Set();
  }

  dispose() {
    this.subscribers.clear();
  }

  notify() {
    this.cleanup();

    this.subscribers.forEach((subscriber) => {
      const callback = subscriber.deref();

      if (!callback) {
        return;
      }

      this.call(callback);
    });
  }

  protected call(callback: T) {
    try {
      callback();
    } catch (error) {
      // eslint-disable-next-line no-console, i18next/no-literal-string
      console.error('Error calling subscriber', error);
    }
  }

  protected cleanup() {
    const subscribers = new Set<BaseNotifierSubscriber<T>>();

    this.subscribers.forEach((subscriber) => {
      const callback = subscriber.deref();

      if (!callback) {
        return;
      }

      subscribers.add(subscriber);
    });

    this.subscribers = subscribers;
  }

  subscribe(callback: T) {
    const subscriber = new WeakRef(callback);

    this.subscribers.add(subscriber);

    // Return a function to unsubscribe.
    return () => {
      this.subscribers.delete(subscriber);
    };
  }
}
