/* eslint-disable class-methods-use-this */
import { ApolloClient } from '@apollo/client/core/ApolloClient';

import { AgileSDKUnexpectedError } from '../../errors/AgileSDKUnexpectedError';
import {
  InvokeBackendComponentDocument,
  InvokeBackendComponentQuery,
  InvokeBackendComponentQueryVariables,
} from '../../queries/queryTyping/invokeBackendComponent';
import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isInvokeBackendComponentRequestMessage } from './assertions/isInvokeBackendComponentRequestMessage';

import { INVOKE_BACKEND_COMPONENT_REQUEST_MESSAGE_TYPE } from './constants';
import { InvokeBackendComponentResponseMessage } from './types';

/**
 * Agile SDK service for invoking backend components.
 */
export class BackendComponentAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly client: ApolloClient<unknown>;

  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    client: ApolloClient<unknown>,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      INVOKE_BACKEND_COMPONENT_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.client = client;
    this.sender = sender;
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    const { message, sender } = request;

    if (!isInvokeBackendComponentRequestMessage(message)) {
      return false;
    }

    const { data, error } = await this.client.query<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>({
      query: InvokeBackendComponentDocument,
      fetchPolicy: 'no-cache',
      variables: {
        componentName: message.payload.name,
        arguments: Object.entries(message.payload.args).map(([name, value]) => ({ name, value })),
      },
    });

    if (error) {
      throw new AgileSDKUnexpectedError(error.message);
    }

    const payload = data.invokeBackendComponent;
    if (!payload) {
      // eslint-disable-next-line i18next/no-literal-string
      throw new AgileSDKUnexpectedError('No payload received.');
    }

    const response: InvokeBackendComponentResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        result: payload.result,
        successful: payload.successful,
        errors: payload.errors,
        warnings: payload.warnings,
        infos: payload.infos,
      },
    };

    this.sender(response, sender);

    return true;
  }
}
