import { getFixedT } from '@app/core/i18n';

import { NativeAppError } from './types';

const t = getFixedT(null, null);

/**
 * App response timeout error.
 *
 * The error that returned in the case when the requested native app
 * didn't provide within a specified timespan.
 */
export const appResponseTimeoutError: NativeAppError = {
  // eslint-disable-next-line i18next/no-literal-string
  domain: 'AST.WEB',
  // eslint-disable-next-line i18next/no-literal-string
  code: 'TIMEOUT',
  message: t(
    'native-app.errors.timeout.message|Native app event timeout error message',
    'No response from the app.',
  ),
};
