/* eslint-disable class-methods-use-this */
import { LoginNotifier } from '@app/core/authentication/login/LoginNotifier';

import { ValueNotifierAgileSDKService } from '../../utils/abstract/ValueNotifierAgileSDKService/ValueNotifierAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKAddress, AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetIsAuthenticatedRequestMessage } from './assertions/isGetIsAuthenticatedRequestMessage';
import { isSubscribeIsAuthenticatedRequestMessage } from './assertions/isSubscribeIsAuthenticatedRequestMessage';
import { isUnsubscribeIsAuthenticatedRequestMessage } from './assertions/isUnsubscribeIsAuthenticatedRequestMessage';

import {
  GET_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
  IS_AUTHENTICATED_CHANGED_NOTIFICATION_MESSAGE_TYPE,
  SUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
  UNSUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
} from './constants';
import {
  GetIsAuthenticatedResponseMessage,
  IsAuthenticatedChangeNotificationMessage,
  SubscribeIsAuthenticatedResponseMessage,
  UnsubscribeIsAuthenticatedResponseMessage,
} from './types';

/**
 * Agile SDK service for the login status tracking.
 *
 * Responds with the current user login status and allows to subscribe to login status changes.
 */
export class LoginStatusAgileSDKService extends ValueNotifierAgileSDKService<LoginNotifier> {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const notifier = new LoginNotifier();

    super(
      notifier,
      listener,
      new Set([
        GET_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
        SUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
        UNSUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE,
      ]),
    );

    this.sender = sender;
  }

  async init() {
    await super.init();

    this.notifier.init();
  }

  async dispose() {
    await super.dispose();

    this.notifier.dispose();
  }

  isValueRequest(request: AgileSDKRequest): boolean {
    return isGetIsAuthenticatedRequestMessage(request.message);
  }

  isSubscribeRequest(request: AgileSDKRequest): boolean {
    return isSubscribeIsAuthenticatedRequestMessage(request.message);
  }

  isUnsubscribeRequest(request: AgileSDKRequest): boolean {
    return isUnsubscribeIsAuthenticatedRequestMessage(request.message);
  }

  respondValueRequest(request: AgileSDKRequest): boolean {
    const response: GetIsAuthenticatedResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        isAuthenticated: this.notifier.value,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondSubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: SubscribeIsAuthenticatedResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: true,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  respondUnsubscribeRequest(request: AgileSDKRequest, subscriptionId: string): boolean {
    const response: UnsubscribeIsAuthenticatedResponseMessage = {
      id: request.message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isSubscribed: false,
      },
    };

    this.sender(response, request.sender);

    return true;
  }

  notifyValueChange(target: AgileSDKAddress, subscriptionId: string): boolean {
    const notification: IsAuthenticatedChangeNotificationMessage = {
      version: '1.0',
      messageType: IS_AUTHENTICATED_CHANGED_NOTIFICATION_MESSAGE_TYPE,
      payloadVersion: '1.0',
      payload: {
        subscriptionId,
        isAuthenticated: this.notifier.value,
      },
    };

    this.sender(notification, target);

    return true;
  }
}
