import { GraphQLErrorExtension } from '@app/common/errors/types';

/**
 * Enumeration of the possible Triggered Event Ids
 */
export enum TriggeredEventChallenge {
  OneTimePINLayeredAuth = 'OneTimePINLayeredAuth',
  Marketing = 'Marketing',
  Disclosure = 'Disclosure',
}

/**
 * Triggered Event GraphQL error extension
 */
export interface ChallengeRequiredGraphQLErrorExtension extends GraphQLErrorExtension {
  readonly challengeId: TriggeredEventChallenge;
  readonly requestId: string;
}

/**
 * Represents the arguments to the Triggered Event component
 */
export interface TriggeredEventWizardBase {
  readonly onResolved: () => void;
  readonly onCancel: () => void;
  readonly challengeId: TriggeredEventChallenge;
}
