import { isWizardIncorrectConfigurationError } from '@app/common/configurable-wizards/utils';

import { getUserErrors } from '../getGraphQLUserError';
import { UserError } from '../UserError';

import { ErrorProcessor } from './types';
import { getGenericErrorMessage } from '@app/common/errors/errorProcessors/genericErrorProcessor';

/**
 * Try to process known server errors.
 * If error is unknown then return it as is.
 * @param error potential wizard incorrect configuration error
 */
export const wizardIncorrectConfigurationErrorProcessor:ErrorProcessor = (error:Error):Error => {
  if (!isWizardIncorrectConfigurationError(error)) { return error; }

  const messages = getUserErrors(error);

  return new UserError({
    critical: true,
    title: getGenericErrorMessage().title,
    // eslint-disable-next-line max-len
    message: messages ? messages?.join(' ') : getGenericErrorMessage().message,
    originalError: error,
  });
};
