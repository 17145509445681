import { STORAGE_PATH_DELIMETER, STORAGE_PREFIX } from './constants';

/**
 * Add company\project prefix to storage node label path.
 * @param paths paths to store data into storage.
 * @returns result sting path.
 */
export const joinStoragePath = (...paths: string[]) => {
  // eslint-disable-next-line i18next/no-literal-string
  if (paths.length === 0 || paths.some((path) => !path)) throw new Error('Key path is not set');
  const result = (paths.join(STORAGE_PATH_DELIMETER));
  return result.startsWith(STORAGE_PREFIX) ? result : STORAGE_PREFIX + result;
};
