import React from 'react';

import { WizardButtons } from '@app/common/components/configurable-wizards/wizard-buttons';

import { useTriggeredEventsTranslation } from '../../useTriggeredEventsTranslation';

import styles from './DisclosuresWizardButtons.pcss';

type WizardButtonsProps = React.ComponentProps<typeof WizardButtons>;

export type DisclosuresWizardButtonsProps = Pick<WizardButtonsProps, 'cancel'>;

export const DisclosuresWizardButtons: React.FC<DisclosuresWizardButtonsProps> = (props) => {
  const { t } = useTriggeredEventsTranslation();

  return (
    <WizardButtons
      cancelTitle={t(
        'disclosures.buttons.cancel.text|Triggered events discosure cancel button text',
        'Decline',
      )}
      continueTitle={t(
        'disclosures.buttons.submit.text|Triggered events discosure submit button text',
        'Accept',
      )}
      className={styles.buttonsContainer}
      {...props}
    />
  );
};
