import { isPromiseRejectedResult } from '@app/common/utils';

import { client } from '@app/core/apolloClient';
import { appSettings } from '@app/core/appSettings';
import { LoginNotifier } from '@app/core/authentication/login/LoginNotifier';
import { InMemoryStorage } from '@app/core/storage/browser/InMemoryStorage';

import { BackendComponentAgileSDKService } from './services/BackendComponentAgileSDKService/BackendComponentAgileSDKService';
import { BrandingInfoAgileSDKService } from './services/BrandingInfoAgileSDKService/BrandingInfoAgileSDKService';
import { ColorThemeAgileSDKService } from './services/ColorThemeAgileSDKService/ColorThemeAgileSDKService';
import { ExternalAPIIntegrationAgileSDKService } from './services/ExternalAPIIntegrationAgileSDKService/ExternalAPIIntegrationAgileSDKService';
import { InMemoryStorageAgileSDKService } from './services/InMemoryStorageAgileSDKService/InMemoryStorageAgileSDKService';
import { LanguageCultureAgileSDKService } from './services/LanguageCultureAgileSDKService/LanguageCultureAgileSDKService';
import { LegacyAgileSDKService } from './services/LegacyAgileSDKService/LegacyAgileSDKService';
import { handlers as legacyHandlers } from './services/LegacyAgileSDKService/utils/handlers';
import { LoginStatusAgileSDKService } from './services/LoginStatusAgileSDKService/LoginStatusAgileSDKService';
import { ScreenInfoAgileSDKService } from './services/ScreenInfoAgileSDKService/ScreenInfoAgileSDKService';
import { URLAgileSDKService } from './services/URLAgileSDKService/URLAgileSDKService';
import { UserCustomDataAgileSDKService } from './services/UserCustomDataAgileSDKService/UserCustomDataAgileSDKService';
import { ViewportAgileSDKService } from './services/ViewportAgileSDKService/ViewportAgileSDKService';
import { AgileSDKService } from './utils/abstract/AgileSDKService';
import { AgileSDKRequestListener } from './utils/AgileSDKRequestListener';
import { sendAgileSDKMessage } from './utils/sendAgileSDKMessage';

/**
 * Initializes Agile SDK services to listen for window events and handle them.
 *
 * For instance, the Agile SDK services can be used to handle incoming messages from custom apps, running inside
 * of iframes, or embedded scripts to provide additional functionality or data to these extensions.
 */
export async function initializeAgileSDK() {
  // The current origin can be trusted to allow embedded scripts to communicate with the SDK.
  const currentOrigin = window.location.origin;

  // The API origin can be trusted to allow Custom Apps to communicate the SDK from.
  const apiOrigin = new URL(appSettings().apiEndpoint).origin;

  const allowedOrigins = new Set([
    currentOrigin,
    apiOrigin,
  ]);

  const listener = new AgileSDKRequestListener(allowedOrigins);

  listener.init();

  const sender = sendAgileSDKMessage;

  const services: AgileSDKService[] = [
    new LegacyAgileSDKService(allowedOrigins, legacyHandlers),

    new BackendComponentAgileSDKService(listener, client, sender),
    new BrandingInfoAgileSDKService(listener, client, sender),
    new ExternalAPIIntegrationAgileSDKService(listener, client, sender),

    new LoginStatusAgileSDKService(listener, sender),
    new LanguageCultureAgileSDKService(listener, sender),
    new ColorThemeAgileSDKService(listener, sender),

    new ScreenInfoAgileSDKService(listener, sender),
    new URLAgileSDKService(listener, sender),

    new UserCustomDataAgileSDKService(listener, client, sender),

    new ViewportAgileSDKService(listener, sender),
  ];

  // Register in-memory storage service.
  {
    const storage = new InMemoryStorage();

    const notifier = new LoginNotifier();

    notifier.init();

    const service = new InMemoryStorageAgileSDKService(storage, notifier, listener, sender);

    services.push(service);
  }

  // Initialize the services gracefully.
  const results = await Promise.allSettled(services.map((service) => service.init()));
  results.filter(isPromiseRejectedResult).forEach((result) => {
    // eslint-disable-next-line no-console, i18next/no-literal-string
    console.error('Failed to initialize Agile SDK service:', result.reason);
  });
}
