/* eslint-disable i18next/no-literal-string */
import { hasOwnPropertyOfType, isObject, isString } from '@app/common/utils/assertion';

import { AgileSDKRequest, agileSDKRequestPrefix, isAgileSDKRequest } from '../types';

export const invokeExternalApiIntegrationRequestType = `${agileSDKRequestPrefix}.invokeExternalApiIntegrationRequest`;

export interface InvokeExternalApiIntegrationRequestPayload {
  readonly integrationName: string,
  readonly args: Record<string, unknown>,
}

export interface InvokeExternalApiIntegrationRequest extends AgileSDKRequest{
  /**
   * Payload version.
   */
  readonly payloadVersion?: string,
  /**
   * Actual request data.
   */
  readonly payload: InvokeExternalApiIntegrationRequestPayload,
}

export function isInvokeExternalApiIntegrationRequestPayload(data: unknown): data is InvokeExternalApiIntegrationRequestPayload {
  return (
    isObject(data)
    && hasOwnPropertyOfType(data, 'integrationName', isString)
    && hasOwnPropertyOfType(data, 'args', isObject)
  );
}

export function isInvokeExternalApiIntegrationRequest(data: unknown): data is InvokeExternalApiIntegrationRequest {
  return (
    isAgileSDKRequest(data)
    && hasOwnPropertyOfType(data, 'payloadVersion', isString)
    && hasOwnPropertyOfType(data, 'payload', isInvokeExternalApiIntegrationRequestPayload)
  );
}
