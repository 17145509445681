import { UNSUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE } from '../constants';
import { UnsubscribeLanguageCultureRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link UnsubscribeLanguageCultureRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isUnsubscribeLanguageCultureRequestMessage(value: AgileSDKRequestMessage<unknown>): value is UnsubscribeLanguageCultureRequestMessage {
  return value.messageType === UNSUBSCRIBE_LANGUAGE_CULTURE_REQUEST_MESSAGE_TYPE;
}
