import React, { HTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';
import * as CSS from 'csstype';

import { ClassValue } from 'classnames/types';

import styles from './FlexContainer.pcss';
import { isFlexGapSupported } from './isFlexGapSupported';

/**
 * Supported gaps
 */
export type Gap = 'default' | 'extraSmall' | 'small' | 'large' | 'extraLarge' | 'none';

export interface FlexContainerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * flex direction CSS property
   */
  readonly direction?: CSS.Property.FlexDirection,
  /**
   * flex gap size type
   */
  readonly gap?: Gap,
  /**
   * flex wrap mode
   */
  readonly wrap?: boolean,
}

const flexGapCssValue = new Map<Gap, CSS.Property.Gap>([
  // eslint-disable-next-line i18next/no-literal-string
  ['default', 'var(--space)'],
  // eslint-disable-next-line i18next/no-literal-string
  ['extraSmall', 'var(--space-xxs)'],
  // eslint-disable-next-line i18next/no-literal-string
  ['small', 'var(--space-sm)'],
  // eslint-disable-next-line i18next/no-literal-string
  ['large', 'var(--space-lg)'],
  // eslint-disable-next-line i18next/no-literal-string
  ['extraLarge', 'var(--space-xl)'],
  // eslint-disable-next-line i18next/no-literal-string
  ['none', '0'],
]);

const getGapFallbackClasses = (gap: Gap, direction?: CSS.Property.FlexDirection): Array<string> => {
  if (!gap) return [];

  const classes: Array<string> = [];
  classes.push(styles[`${gap}GapFallback`]);

  switch (direction) {
    case 'column':
      classes.push(styles.verticalGapFallback);
      break;
    case 'column-reverse':
      classes.push(styles.verticalReverseGapFallback);
      break;
    case 'row':
      classes.push(styles.horizontalGapFallback);
      break;
    case 'row-reverse':
      classes.push(styles.horizontalReverseGapFallback);
      break;
    default:
      break;
  }

  return classes;
};

/**
 * Simple flex container
 * @example
 * vertical orientation with small gap
 * <FlexContainer direction="column" gap="small"  ... />
 * @example
 * horizontal orientation without gap
 * <FlexContainer direction="row" ... />
 */
export const FlexContainer: React.FC<FlexContainerProps> = ({
  direction,
  gap,
  wrap,
  children,
  ...htmlProps
}) => {
  const { className, style, ...restHTMLProps } = htmlProps;
  const supportFlexGap = useMemo(() => isFlexGapSupported(navigator.userAgent), []);

  const cssClasses = useMemo(() => {
    const classes: Array<ClassValue> = [
      className,
      styles.container,
      wrap && styles.wrap,
    ];

    if (gap && !supportFlexGap) {
      classes.push(...getGapFallbackClasses(gap, direction));
    }

    return classNames(...classes);
  }, [
    className,
    direction,
    gap,
  ]);

  return (
    <div
      style={{
        flexDirection: direction,
        gap: supportFlexGap ? flexGapCssValue.get(gap ?? 'default') : undefined,
        ...style,
      }}
      className={cssClasses}
      {...restHTMLProps}
    >
      {children}
    </div>
  );
};

FlexContainer.defaultProps = {
  direction: 'row',
  gap: 'default',
  wrap: true,
};
