import { Listenable } from '@app/common/utils/listenable';
import { TriggeredEventChallenge } from './types';

export interface TriggeredEventListenable {
  // eslint-disable-next-line i18next/no-literal-string
  'challengeRequired': { challengeId: TriggeredEventChallenge },
  // eslint-disable-next-line i18next/no-literal-string
  'challengePassed': { challengeId: TriggeredEventChallenge },
  // eslint-disable-next-line i18next/no-literal-string
  'challengeCanceled': { challengeId: TriggeredEventChallenge },
}

/**
 * @todo: consider the posibility to replace it by state manager
 */
export class TriggeredEventNotifier extends Listenable<TriggeredEventListenable> {
  private static instance: TriggeredEventNotifier;

  constructor() {
    super(new Map<keyof TriggeredEventListenable, Set<Function>>(
      [
        // eslint-disable-next-line i18next/no-literal-string
        ['challengeRequired', new Set()],
        // eslint-disable-next-line i18next/no-literal-string
        ['challengePassed', new Set()],
        // eslint-disable-next-line i18next/no-literal-string
        ['challengeCanceled', new Set()],
      ],
    ));
  }

  public static getInstance() {
    if (!TriggeredEventNotifier.instance) {
      TriggeredEventNotifier.instance = new TriggeredEventNotifier();
    }

    return TriggeredEventNotifier.instance;
  }

  clearListeners<K extends keyof TriggeredEventListenable>(key: K) {
    this.listeners.get(key)?.clear();
  }
}
