export const mockStorage: Storage = {
  getItem: () => null,
  setItem: () => null,
  removeItem: () => {},
  key: () => null,
  clear: () => {},
  length: 0,
};

/**
 * This proxy is used to prevent errors when accessing localStorage or sessionStorage
 * when the access is not allowed
 */
export const proxiedWindow = new Proxy(window, {
  get: (target, prop, ...rest) => {
    if (prop === 'localStorage' || prop === 'sessionStorage') {
      try {
        return target[prop];
      } catch (e) {
        console.warn(`Failed to get ${prop} from window. Returning mock storage.`);
        return mockStorage;
      }
    }

    return Reflect.get(target, prop, ...rest);
  },
});
