export const getCookie = (name: string): string | null => {
  const value = document.cookie
    .split('; ')
    .find((row) => row.startsWith(`${name}=`))
    ?.split('=')[1];

  return value || null;
};

interface CookieOptions {
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'strict' | 'lax' | 'none';
}

export const setCookie = (
  name: string,
  value: string,
  options?: CookieOptions,
): void => {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options) {
    if (options.expires) {
      let expires: string;

      if (typeof options.expires === 'number') {
        const date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
        expires = date.toUTCString();
      } else {
        expires = options.expires.toUTCString();
      }

      cookieString += `;expires=${expires}`;
    }

    if (options.path) {
      cookieString += `;path=${options.path}`;
    }

    if (options.domain) {
      cookieString += `;domain=${options.domain}`;
    }

    if (options.secure) {
      // eslint-disable-next-line i18next/no-literal-string
      cookieString += ';secure';
    }

    if (options.sameSite) {
      cookieString += `;samesite=${options.sameSite}`;
    }
  }

  document.cookie = cookieString;
};

export const removeCookie = (name: string, path?: string, domain?: string) => {
  let cookieString = `${encodeURIComponent(name)}=;expires=Thu, 01 Jan 1970 00:00:00 UTC`;

  if (path) {
    cookieString += `;path=${path}`;
  }

  if (domain) {
    cookieString += `;domain=${domain}`;
  }

  document.cookie = cookieString;
};
