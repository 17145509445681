import React, { useState } from 'react';

import { createGenericContext } from '@app/common/contexts/createGenericContext';

export type EligibilityMenuItems = Record<string, { isEligible: boolean, rootMenuId: string }>;
export interface EligibilityMenuContextContext {
  eligibilityMenuItems: EligibilityMenuItems;
  setEligibilityMenuItems:(value: EligibilityMenuItems) => void;
}
const [
  useEligibilityMenuContext,
  EligibilityMenuContextProvider,
] = createGenericContext<EligibilityMenuContextContext>();

const EligibilityMenuItemsContext: React.FC = ({ children }) => {
  const [eligibilityMenuItems, setEligibilityMenuItems] = useState<EligibilityMenuItems>({});

  return (
    <EligibilityMenuContextProvider value={{ eligibilityMenuItems, setEligibilityMenuItems }}>
      {children}
    </EligibilityMenuContextProvider>
  );
};

export { useEligibilityMenuContext, EligibilityMenuItemsContext };
