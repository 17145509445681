import React from 'react';

import { WidgetType } from '@app/core/widgets/react/types';

import { useHideWidgetForPlatform } from './useHideWidgetForPlatform';

/**
 * A common wrapper for widgets.
 */
export const WidgetWrapper: React.FC<{ widget: WidgetType, children: React.ReactNode; }> = ({
  children,
  widget,
}) => {
  const hide = useHideWidgetForPlatform(widget);

  if (hide) {
    return null;
  }

  return <div className="widget-container">{children}</div>;
};
