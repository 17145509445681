import { useEffect, useState } from 'react';

import { CompromisedCredentialsStatus } from '@app/queryTyping';

import { useCheckCompromisedCredentialsLazyQuery } from '@app/common/configurable-wizards/queries/queryTyping/compromised-credentials-result';
import { useGetSecuritySettingsLazyQuery } from '@app/common/configurable-wizards/queries/queryTyping/getSecuritySettings';
import { noop } from '@app/common/utils';

import { CompromisedCredentialsStore } from './CompromisedCredentialsSessionStore';
import { getStatusFromStore } from './utils';

/**
 * @private
 */
export const useCompromisedCredentialStatus = ({
  onCompleted = noop,
}: {
  onCompleted?: (status: CompromisedCredentialsStatus) => void;
}): (() => void) => {
  const [compromisedStatus, setCompromisedStatus] = useState<CompromisedCredentialsStatus>();

  useEffect(() => {
    if (compromisedStatus !== undefined) {
      onCompleted(compromisedStatus);
    }
  }, [compromisedStatus]);

  const [checkCompromisedPassword] = useCheckCompromisedCredentialsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ credentialsStatus: { status } }) => {
      CompromisedCredentialsStore.instance.setStatus(status);
      setCompromisedStatus(status);
    },
  });

  const [getSecuritySettings] = useGetSecuritySettingsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ securitySettings: { compromisedCredentialsCheckEnabled } }) => {
      if (compromisedCredentialsCheckEnabled) {
        checkCompromisedPassword();
      }
    },
  });

  // Callback to request the compromised credential status from the cache or the server (if not cached)
  return () => {
    const cachedPasswordStatus = getStatusFromStore();
    if (cachedPasswordStatus) {
      setCompromisedStatus(cachedPasswordStatus as CompromisedCredentialsStatus);
    } else {
      getSecuritySettings();
    }
  };
};
