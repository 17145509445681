import { isWizardStepsInconsistencyError } from '@app/common/configurable-wizards/utils/assertions';
import { getGenericErrorMessage } from '@app/common/errors/errorProcessors/genericErrorProcessor';

import { removeDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';

import { UserError } from '../UserError';

import { ErrorProcessor } from './types';

/**
 * Try to process known server errors.
 * If error is unknown then return it as is.
 * @param error potential wizard step inconsistency error
 */
export const wizardStepsInconsistencyErrorProcessor: ErrorProcessor = (error: Error): Error => {
  if (!isWizardStepsInconsistencyError(error)) { return error; }

  // clear session storage DS Session Id
  removeDSSessionId();

  const { graphQLErrors } = error;

  return new UserError({
    critical: true,
    title: getGenericErrorMessage().title,
    message: getGenericErrorMessage().message,
    // shed light on some details that will help in troubleshooting
    details: JSON.stringify({
      code: graphQLErrors[0].extensions?.code,
      message: graphQLErrors[0].extensions?.message,
    }),
    originalError: error,
  });
};
