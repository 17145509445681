import { useEffect, useRef } from 'react';
import { useNetworkInformation } from '@app/core/hooks/useNetworkInformation/useNetworkInformation';

/**
 * Translates the session expiration timeout into interval value consumeb by heartbeats.
 *
 * Calculation: what means 333.
 * Heartbeat interval decided to be 1/3 of session expiration timeout, e.g. 3 heartbeats within that duration.
 * Aligning measures we have: session_timeout [secs] * 1000 [msec per sec] / 3 [times] = 333.
 *
 * @param secs Session expiration timeout in seconds
 * @returns interval value in milliseconds
 */
export function getHeartbeatIntervalForSessionExpiration(secs: number | undefined): number | null {
  return secs ? (secs * 333) : null;
}

function clearHeartbeatInterval(id?: number) {
  if (id) {
    window.clearInterval(id);
  }
}

export const useHeartbeat = (
  isHeartbeatApplicable: () => boolean,
  callHeartbeatMutation: () => void,
  sessionExpirationInSecs: number | undefined,
) => {
  const intervalId = useRef<number>();
  const delay = getHeartbeatIntervalForSessionExpiration(sessionExpirationInSecs);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      if (isHeartbeatApplicable()) {
        callHeartbeatMutation();
      }
    }

    if (delay !== null) {
      // before starting the new interval, clear the old one if exists
      clearHeartbeatInterval(intervalId.current);

      intervalId.current = window.setInterval(tick, delay);
      return () => clearHeartbeatInterval(intervalId.current);
    }
  }, [delay]);

  const { isOnline } = useNetworkInformation();
  useEffect(() => {
    if (isOnline && isHeartbeatApplicable()) {
      callHeartbeatMutation();
    }
  }, [isOnline]);
};
