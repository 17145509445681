import { getCorrSpanId, getCorrTraceId } from '@app/core/authentication/customAuthHeaders/corrSessionId';
import { logger } from '@app/core/logger/setupLogger';

/**
 * Safely converts an object to a JSON string.
 * If the conversion fails, returns a fallback string.
 * */
const safeStringify = (obj: any, fallback: string = 'Error serializing object'): string => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    if (error instanceof TypeError) {
      // If there is a TypeError, do nothing
    }
    return fallback;
  }
};

export const logPasskeyError = (err: any, message: string) => {
  if (err.name !== 'AbortError' && err.name !== 'NotAllowedError') {
    logger.error(message + safeStringify(err), {
      corrTraceId: getCorrTraceId(),
      corrSpanId: getCorrSpanId(),
      errorException: err?.networkError?.statusCode,
    });
  }
};

export const logPasskeyDebug = (message: string, context?: any) => {
  logger.debug(message + safeStringify(context), {
    corrTraceId: getCorrTraceId(),
    corrSpanId: getCorrSpanId(),
  });
};
