/* eslint-disable class-methods-use-this */
import { ApolloError } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core/ApolloClient';

import { AgileSDKUnexpectedError } from '../../errors/AgileSDKUnexpectedError';
import {
  AddUserCustomDataDocument,
  AddUserCustomDataMutation,
  AddUserCustomDataMutationVariables,
} from '../../queries/queryTyping/addUserCustomData';
import {
  GetUserCustomDataDocument,
  GetUserCustomDataQuery,
  GetUserCustomDataQueryVariables,
} from '../../queries/queryTyping/getUserCustomData';
import {
  UpdateUserCustomDataDocument,
  UpdateUserCustomDataMutation,
  UpdateUserCustomDataMutationVariables,
} from '../../queries/queryTyping/updateUserCustomData';
import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import {
  AgileSDKAddress,
  AgileSDKMessageSender,
  AgileSDKRequest,
} from '../../utils/types';
import { isAddUserCustomDataRequestMessage } from './assertions/isAddUserCustomDataRequestMessage';
import { isGetUserCustomDataRequestMessage } from './assertions/isGetUserCustomDataRequestMessage';
import { isUpdateUserCustomDataRequestMessage } from './assertions/isUpdateUserCustomDataRequestMessage';

import {
  GET_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
  ADD_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
  UPDATE_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
} from './constants';
import {
  GetUserCustomDataRequestMessage,
  GetUserCustomDataResponseMessage,
  AddUserCustomDataRequestMessage,
  AddUserCustomDataResponseMessage,
  UpdateUserCustomDataRequestMessage,
  UpdateUserCustomDataResponseMessage,
} from './types';

/**
 * Agile SDK service for user custom data.
 */
export class UserCustomDataAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly client: ApolloClient<unknown>;

  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    client: ApolloClient<unknown>,
    sender: AgileSDKMessageSender,
  ) {
    super(listener, new Set([
      GET_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
      ADD_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
      UPDATE_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE,
    ]));

    this.client = client;
    this.sender = sender;
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    const { message, sender } = request;

    if (isGetUserCustomDataRequestMessage(message)) {
      return this.handleGet(message, sender);
    }

    if (isAddUserCustomDataRequestMessage(message)) {
      return this.handleAdd(message, sender);
    }

    if (isUpdateUserCustomDataRequestMessage(message)) {
      return this.handleUpdate(message, sender);
    }

    return false;
  }

  async handleGet(message: GetUserCustomDataRequestMessage, sender: AgileSDKAddress): Promise<boolean> {
    const { data, error } = await this.client.query<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>({
      query: GetUserCustomDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        scope: message.payload.scope,
      },
    });

    if (error) {
      throw new AgileSDKUnexpectedError(error.message);
    }

    const payload = data.customData;
    if (!payload) {
      // eslint-disable-next-line i18next/no-literal-string
      throw new AgileSDKUnexpectedError('No payload received.');
    }

    const response: GetUserCustomDataResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        data: payload.data,
        successful: payload.successful,
        errors: payload.errors,
        warnings: payload.warnings,
        infos: payload.infos,
      },
    };

    this.sender(response, sender);

    return true;
  }

  handleAdd(message: AddUserCustomDataRequestMessage, sender: AgileSDKAddress): Promise<boolean> {
    return this.handleMutation(async () => {
      const { data } = await this.client.mutate<AddUserCustomDataMutation, AddUserCustomDataMutationVariables>({
        mutation: AddUserCustomDataDocument,
        fetchPolicy: 'no-cache',
        variables: {
          scope: message.payload.scope,
          data: message.payload.data,
        },
      });

      const payload = data?.addUserCustomData;
      if (!payload) {
        // eslint-disable-next-line i18next/no-literal-string
        throw new AgileSDKUnexpectedError('No payload received.');
      }

      const response: AddUserCustomDataResponseMessage = {
        id: message.id,
        version: '1.0',
        payloadVersion: '1.0',
        payload: {
          successful: payload.successful,
          errors: payload.errors,
          warnings: payload.warnings,
          infos: payload.infos,
        },
      };

      this.sender(response, sender);

      return true;
    });
  }

  // eslint-disable-next-line max-len
  handleUpdate(message: UpdateUserCustomDataRequestMessage, sender: AgileSDKAddress): Promise<boolean> {
    return this.handleMutation(async () => {
      const { data } = await this.client.mutate<UpdateUserCustomDataMutation, UpdateUserCustomDataMutationVariables>({
        mutation: UpdateUserCustomDataDocument,
        fetchPolicy: 'no-cache',
        variables: {
          scope: message.payload.scope,
          data: message.payload.data,
        },
      });

      const payload = data?.updateUserCustomData;
      if (!payload) {
        // eslint-disable-next-line i18next/no-literal-string
        throw new AgileSDKUnexpectedError('No payload received.');
      }

      const response: UpdateUserCustomDataResponseMessage = {
        id: message.id,
        version: '1.0',
        payloadVersion: '1.0',
        payload: {
          successful: payload.successful,
          errors: payload.errors,
          warnings: payload.warnings,
          infos: payload.infos,
        },
      };

      this.sender(response, sender);

      return true;
    });
  }

  async handleMutation(mutation: () => Promise<boolean>) : Promise<boolean> {
    try {
      return await mutation();
    } catch (error) {
      if (error instanceof ApolloError) {
        throw new AgileSDKUnexpectedError(error.message);
      } else {
        throw error;
      }
    }
  }
}
