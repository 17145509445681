/**
 * @todo Find-out and develop alternative way to ignore some fields for serialization and submitting.
 * Would be fine to define it at the local CF implementation or using react-hook-form level.
 */

import {
  FieldScalarData,
  FieldDataObject,
  FieldArrayData,
  FieldData,
  StepPayload,
} from '../types';
import { combineNames } from './combineNames';

/**
 * key used for for ignoring fields from the serialization and submiting
 */
export const IGNORE_SERIALIZE_GROUP_KEY = '__IgnoreSerializeGroup__';

const serializeObject = (data: FieldDataObject, parent: string): StepPayload => (
  Object
    .keys(data)
    // exclude field from serialization and following submitting if it is included in the ignore group
    .filter((key) => key !== IGNORE_SERIALIZE_GROUP_KEY)
    .flatMap(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      (fieldId) => serialize(data[fieldId], combineNames(parent, fieldId)),
    )
);

const serializeArray = (data: FieldArrayData, parent: string): StepPayload => (
  data
    .flatMap(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      (field, idx) => serialize(field, `${parent}[${idx}]`),
    )
);

const serializeScalar = (data: FieldScalarData, parent: string): StepPayload => ([{
  fieldId: parent,
  value: data,
}]);

const serialize = (data: FieldData, parent: string): StepPayload => {
  if (Array.isArray(data)) {
    return serializeArray(data, parent);
  }
  if (typeof data === 'object' && data !== null) {
    return serializeObject(data as FieldDataObject, parent);
  }
  if (
    ['string', 'number', 'boolean'].includes(typeof data)
    || data === null
  ) {
    return serializeScalar(data, parent);
  }

  throw new Error('Unsupported field value.');
};

export default (data: FieldDataObject): StepPayload => serializeObject(data, '');
