export interface IPerformanceMonitorServiceOptions {
  storageAdapter: Storage;
  storagePrefix: string;
  timingAdapter: ITimingAdapter;
}

export interface ITimingAdapter {
  now(): number;
}

export class PerformanceMonitorService {
  private storage: Storage;

  private readonly storagePrefix: string;

  private timingAdapter: ITimingAdapter;

  constructor(options: IPerformanceMonitorServiceOptions) {
    this.storage = options.storageAdapter;
    this.storagePrefix = options.storagePrefix;
    this.timingAdapter = options.timingAdapter;
  }

  public startMetric(name: string): void {
    const startTime = this.timingAdapter.now();
    this.storage.setItem(this.getStorageKey(name), startTime.toString());
  }

  public endMetric(name: string): number | undefined {
    const endTime = this.timingAdapter.now();
    const startTimeStr = this.storage.getItem(this.getStorageKey(name));

    if (startTimeStr) {
      const startTime = parseFloat(startTimeStr);
      if (!isNaN(startTime)) {
        this.storage.removeItem(this.getStorageKey(name));
        return endTime - startTime;
      }
    }
    return undefined;
  }

  private getStorageKey(name: string): string {
    return `${this.storagePrefix}-${name}`;
  }
}
