import React from 'react';

import { useBreakpoint } from '@ast/magma/breakpoints';

import { FlexContainer, FlexContainerProps } from '@app/common/components/FlexContainer/FlexContainer';
import { noop } from '@app/common/utils/function';

import { BackButton } from './buttons/BackButton';
import { CancelButton } from './buttons/CancelButton';
import { ContinueButton } from './buttons/ContinueButton';

export interface ButtonsProps {
  readonly direction?: FlexContainerProps['direction'];
  readonly cancelTitle: string;
  readonly backTitle?: string;
  readonly continueTitle: string;
  readonly showBackButton?: boolean;
  readonly showContinueButton?: boolean;
  readonly showCancelButton?: boolean;
  readonly continueButtonDisabled?: boolean;
  readonly back?: () => void
  readonly cancel?: () => void
  readonly className?: string;
}

export const WizardButtons: React.FC<ButtonsProps> = ({
  direction,
  backTitle,
  cancelTitle,
  continueTitle,
  showBackButton,
  showContinueButton = true,
  showCancelButton = true,
  continueButtonDisabled = false,
  back,
  cancel,
  ...props
}) => {
  const isMobile = useBreakpoint().device === 'mobile';

  const renderBackButton = () => (showBackButton && backTitle
    ? <BackButton title={backTitle} back={back || noop} />
    : null);

  const renderContinueButton = () => (showContinueButton
    ? <ContinueButton title={continueTitle} disabled={continueButtonDisabled} />
    : null);

  const renderCancelButton = () => (showCancelButton
    ? <CancelButton title={cancelTitle} handler={cancel} />
    : null);

  const customDirection = direction || (isMobile ? 'column' : 'row');
  return (
    <FlexContainer
      direction={customDirection}
      gap="small"
      data-stable-name="WizardButtonsContainer"
      {...props}
    >
      {customDirection === 'column' ? (
        <>
          {renderContinueButton()}
          {renderCancelButton()}
          {renderBackButton()}
        </>
      ) : (
        <>
          {renderBackButton()}
          {renderCancelButton()}
          {renderContinueButton()}
        </>
      )}
    </FlexContainer>
  );
};
