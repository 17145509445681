import { t } from '@app/core/i18n';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { findFieldNode } from './findFieldNode';

/**
 * Finds out and returns field error object
 * @param fieldName - field name
 * @param errors - form errors
 * @param errorsMessages - list of possible error messages
 */
export const getFieldError = (
  fieldName: string,
  errors: FieldErrors<FieldValues>,
): FieldError | null => findFieldNode<FieldError>(fieldName, errors);

/**
 * Finds out and returns field error message based on the current form errors
 * @param fieldName - field name
 * @param errors - form errors
 * @param errorsMessages - list of possible error messages
 */
export const getFieldErrorMessage = (
  fieldName: string,
  errors: FieldErrors<FieldValues>,
  errorsMessages: Record<string, string>,
): string => {
  const fieldError = getFieldError(fieldName, errors);

  if (!fieldError) {
    return '';
  }

  // find local-defined error message
  if (fieldError.message) {
    return fieldError.message;
  }

  // otherwise, find error message from the passed list
  const error = Object.keys(errorsMessages).find(
    (err) => fieldError.type === err,
  );
  return errorsMessages[error || ''] || t(
    'unknown-field-error|Unknown field error for the configurable wizard', 'Unknown error...',
  );
};

/**
 * Presents current field error keys as set collection
 * @example
 * // returns Set(['errorRule1', 'errorRule2' ])
 * getFieldErrorKeys({ type: 'errorRule1', types: { errorRule2: 'msg..' } });
 */
export const getFieldErrorKeys = (error: FieldError | null) : Set<string> => {
  const errorsKeys = new Set<string>();

  if (!error) {
    return errorsKeys;
  }

  // add main validation error
  errorsKeys.add(error.type);

  // add other validation errors
  if (error?.types) {
    Object.keys(error.types).forEach(
      (key) => errorsKeys.add(key),
    );
  }

  return errorsKeys;
};
