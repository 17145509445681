import { ApolloError, isApolloError } from '@apollo/client';
import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import { isRetryPossibleAfterLockout } from '@app/core/authentication/lockout/lockout';
import { t } from '@app/core/i18n';

import { ErrorCodes } from '@app/queryTyping';

import { UserError } from '../UserError';

import { ErrorProcessor } from './types';

export const USER_LOCKOUT_ERROR_EVENT = 'ast.userLockoutError';

/**
 * Dispatches the error event for window.
 */
export const dispatchUserLockoutError = () => {
  window.dispatchEvent(new CustomEvent(USER_LOCKOUT_ERROR_EVENT));
};

/**
 * Process the receieved error.
 * Performs extra action for embedded mode.
 */
export const processUserLockoutError = () => {
  if (isEmbeddedMode()) {
    dispatchUserLockoutError();
  }
};

/**
 * Try to process known server errors.
 * If error is unknown then return it as is.
 * @param error potential user-lockout error
 */
export const userLockoutErrorProcessor: ErrorProcessor = (error: Error): Error => {
  if (isApolloError(error)) {
    const { graphQLErrors } = error as ApolloError;
    if (graphQLErrors.length > 0 && graphQLErrors[0].extensions?.code === ErrorCodes.USER_LOCKOUT) {
      processUserLockoutError();

      return new UserError({
        critical: true,
        title: t('common-errors.user-lockout.title|User lockout title', 'Account locked out'),
        message: t(
          'common-errors.user-lockout.message|User lockout message',
          'For security reasons, your account has been locked out.',
        ),
        originalError: error,
        isRetryPossible: isRetryPossibleAfterLockout(),
      });
    }
  }

  return error;
};
