import React from 'react';

import { FullscreenLayout } from '@app/common/layouts/FullscreenLayout/FullscreenLayout';

import { getNotFoundError } from '@app/core/widgets/pages';
import { isOrpeusWidget } from '@app/core/widgets/react/orpheusWidget';

import { ZoneName } from '../WidgetZone/constants';
import { useZoneWidgets } from '../WidgetZone/hooks/useZoneWidgets';

export const Page = React.memo(() => {
  const {
    loading,
    widgets,
    data,
    isCompleted,
  } = useZoneWidgets(ZoneName.Middle);

  // The page component must ensure if there will be no conditional widgets will appear before throwing
  // the page not found error. Otherwise, when a conditional widget will be ready, the page is already will be
  // locked with the error message.
  if (isCompleted && widgets.length === 0) {
    throw getNotFoundError();
  }

  // If there are no Orpheus widgets that, we need to render the page in fullscreen layout to avoid flickering.
  const needLayout = !data.find(isOrpeusWidget);

  return needLayout && !loading ? (
    <FullscreenLayout removeHeaderSection>
      {widgets}
    </FullscreenLayout>
  ) : <>{widgets}</>;
});
