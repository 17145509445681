/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetEmbeddedResourcesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmbeddedResourcesQuery = (
  { __typename?: 'Query' }
  & { embeddedResources: Array<(
    { __typename?: 'EmbeddedResourceContainer' }
    & Pick<Types.EmbeddedResourceContainer, 'renderLocation'>
    & { embeddedResources: Array<(
      { __typename?: 'EmbeddedResource' }
      & Pick<Types.EmbeddedResource, 'html'>
    )> }
  )> }
);


export const GetEmbeddedResourcesDocument = gql`
    query GetEmbeddedResources {
  embeddedResources {
    renderLocation
    embeddedResources {
      html
    }
  }
}
    `;

/**
 * __useGetEmbeddedResourcesQuery__
 *
 * To run a query within a React component, call `useGetEmbeddedResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmbeddedResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmbeddedResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmbeddedResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetEmbeddedResourcesQuery, GetEmbeddedResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmbeddedResourcesQuery, GetEmbeddedResourcesQueryVariables>(GetEmbeddedResourcesDocument, options);
      }
export function useGetEmbeddedResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmbeddedResourcesQuery, GetEmbeddedResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmbeddedResourcesQuery, GetEmbeddedResourcesQueryVariables>(GetEmbeddedResourcesDocument, options);
        }
export type GetEmbeddedResourcesQueryHookResult = ReturnType<typeof useGetEmbeddedResourcesQuery>;
export type GetEmbeddedResourcesLazyQueryHookResult = ReturnType<typeof useGetEmbeddedResourcesLazyQuery>;
export type GetEmbeddedResourcesQueryResult = Apollo.QueryResult<GetEmbeddedResourcesQuery, GetEmbeddedResourcesQueryVariables>;