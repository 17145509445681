import { TokenExpiredError } from './TokenExpiredError';

const nonRenderableErrors = [TokenExpiredError];

/**
 * Checks error type exit in collection
 * non renderable errors must be rethrown from processors,
 * and be inored with error render bootstrap
* @param {Error} error - error thrown form processors
* @returns {boolean } Is error type in collection
 */
export const isErrorNonRenderable = (error: Error): boolean => nonRenderableErrors.some((e) => error instanceof e);
