import { logout, logoutNoRedirect } from '@app/core/authentication/logout/logout';

import { needAnonimousLogout } from '../anonimousLogout/anonimousLogout';
import { needForceLogout } from '../forceLogout/needForceLogout';

// Types of logout
export enum LogoutType {
  REDIRECT,
  NO_REDIRECT,
  NO_LOGOUT,
}

// Logout methods by type
export const LogoutTypeMap = {
  [LogoutType.REDIRECT]: logout,
  [LogoutType.NO_REDIRECT]: logoutNoRedirect,
  [LogoutType.NO_LOGOUT]: null,
};

// Get logout type from strategy
export const getLogoutType = ():LogoutType => {
  if (needForceLogout()) return LogoutType.REDIRECT;
  if (needAnonimousLogout()) return LogoutType.NO_REDIRECT;

  return LogoutType.NO_LOGOUT;
};

// If logout strategy conditions met call mapped method
export const callLogoutType = async (): Promise<void> => {
  const logoutType = getLogoutType();
  const typeCall = LogoutTypeMap[logoutType];
  if (typeCall) {
    await typeCall();
  }
};
