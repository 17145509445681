import { WizardStepLocatorsMap } from '@app/common/configurable-wizards';

import { EmailVerification } from './steps/EmailVerification';
import { QuickLogin } from './steps/QuickLogin';
import { SelectOneTimePinAddress } from './steps/SelectOneTimePinAddress';
import { SelectOneTimePinChannel } from './steps/SelectOneTimePinChannel';
import { UserAgreement } from './steps/UserAgreement';
import { UsernamePassword } from './steps/UsernamePassword';
import { ValidateOneTimePin } from './steps/ValidateOneTimePin';

import { WizardSteps } from './constants';

export const steps: WizardStepLocatorsMap<WizardSteps> = new Map([
  [WizardSteps.UserName, UsernamePassword],
  [WizardSteps.SelectOneTimePinChannel, SelectOneTimePinChannel],
  [WizardSteps.SelectOneTimePinAddress, SelectOneTimePinAddress],
  [WizardSteps.ValidateOneTimePin, ValidateOneTimePin],
  [WizardSteps.EmailVerification, EmailVerification],
  [WizardSteps.QuickLogin, QuickLogin],
  [WizardSteps.UserAgreement, UserAgreement],
]);
