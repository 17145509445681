import { GET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetInMemoryStorageItemRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isGetInMemoryStorageItemParams } from './isGetInMemoryStorageItemParams';

/**
 * Checks if the value is a {@link GetInMemoryStorageItemRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetInMemoryStorageItemRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetInMemoryStorageItemRequestMessage {
  return (
    value.messageType === GET_IN_MEMORY_STORAGE_ITEM_REQUEST_MESSAGE_TYPE

    && isGetInMemoryStorageItemParams(value.payload)
  );
}
