/* eslint-disable i18next/no-literal-string */
import { appSettings } from '@app/core/appSettings';
import { LoggerClient } from '@ast/logger';

export const logger = LoggerClient;

const isApiLoggerEnabled = Boolean(appSettings().isApiLoggerEnabled === 'true') && appSettings().apiLoggerEndpoint;
export const getLoggerOptions = () => ({
  apiUrl: isApiLoggerEnabled
    ? appSettings().apiLoggerEndpoint
    : '',
  appName: 'ofm',
  appComponent: 'ofm-fe',
  execResourceId: window.navigator.userAgent,
  fiId: appSettings().loggerFiId,
  fiEnvironment: appSettings().loggerFiEnvironment,
  execEnvironment: appSettings().loggerExecEnvironment,
});

/*
 * @see More details about logs: https://cf.mfmnow.com/display/ARCH/Logging+Standard+Specification
 */
logger.init(getLoggerOptions());
