/* eslint-disable class-methods-use-this */
import { ApolloClient } from '@apollo/client/core/ApolloClient';

import { AgileSDKUnexpectedError } from '../../errors/AgileSDKUnexpectedError';
import {
  InvokeExternalApiIntegrationDocument,
  InvokeExternalApiIntegrationQuery,
  InvokeExternalApiIntegrationQueryVariables,
} from '../../queries/queryTyping/invokeExternalApiIntegration';
import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isInvokeExternalAPIIntegrationRequestMessage } from './assertions/isInvokeExternalAPIIntegrationRequestMessage';

import { INVOKE_EXTERNAL_API_INTEGRATION_REQUEST_MESSAGE_TYPE } from './constants';
import { InvokeExternalAPIIntegrationResponseMessage } from './types';

/**
 * Agile SDK service for invoking extrenal API integrations.
 */
export class ExternalAPIIntegrationAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly client: ApolloClient<unknown>;

  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    client: ApolloClient<unknown>,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      INVOKE_EXTERNAL_API_INTEGRATION_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.client = client;
    this.sender = sender;
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    const { message, sender } = request;

    if (!isInvokeExternalAPIIntegrationRequestMessage(message)) {
      return false;
    }

    // eslint-disable-next-line max-len
    const { data, error } = await this.client.query<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>({
      query: InvokeExternalApiIntegrationDocument,
      fetchPolicy: 'no-cache',
      variables: {
        integrationName: message.payload.name,
        arguments: Object.entries(message.payload.args).map(([name, value]) => ({ name, value })),
      },
    });

    if (error) {
      throw new AgileSDKUnexpectedError(error.message);
    }

    const payload = data.invokeExternalApiIntegration;
    if (!payload) {
      // eslint-disable-next-line i18next/no-literal-string
      throw new AgileSDKUnexpectedError('No payload received.');
    }

    const response: InvokeExternalAPIIntegrationResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        result: payload.result,
        successful: payload.successful,
        errors: payload.errors,
        warnings: payload.warnings,
        infos: payload.infos,
      },
    };

    this.sender(response, sender);

    return true;
  }
}
