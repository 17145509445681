/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetUserCustomDataQueryVariables = Types.Exact<{
  scope: Types.Scalars['String'];
}>;


export type GetUserCustomDataQuery = (
  { __typename?: 'Query' }
  & { customData: (
    { __typename?: 'GetUserCustomDataResult' }
    & Pick<Types.GetUserCustomDataResult, 'data' | 'successful'>
    & { errors: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, warnings: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, infos: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>> }
  ) }
);


export const GetUserCustomDataDocument = gql`
    query GetUserCustomData($scope: String!) {
  customData(scope: $scope) {
    data
    successful
    errors {
      context
      field
      messages
      faultType
    }
    warnings {
      context
      field
      messages
      faultType
    }
    infos {
      context
      field
      messages
      faultType
    }
  }
}
    `;

/**
 * __useGetUserCustomDataQuery__
 *
 * To run a query within a React component, call `useGetUserCustomDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCustomDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCustomDataQuery({
 *   variables: {
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useGetUserCustomDataQuery(baseOptions: Apollo.QueryHookOptions<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>(GetUserCustomDataDocument, options);
      }
export function useGetUserCustomDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>(GetUserCustomDataDocument, options);
        }
export type GetUserCustomDataQueryHookResult = ReturnType<typeof useGetUserCustomDataQuery>;
export type GetUserCustomDataLazyQueryHookResult = ReturnType<typeof useGetUserCustomDataLazyQuery>;
export type GetUserCustomDataQueryResult = Apollo.QueryResult<GetUserCustomDataQuery, GetUserCustomDataQueryVariables>;