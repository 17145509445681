import { client } from '@app/core/apolloClient';

import { RequestHandler } from '../types';

import {
  InvokeBackendComponentDocument,
  InvokeBackendComponentQuery,
  InvokeBackendComponentQueryVariables,
} from '../../../../../queries/queryTyping/invokeBackendComponent';

import { InvokeBackendComponentRequest, isInvokeBackendComponentRequest } from './request';
import { InvokeBackendComponentResponse, invokeBackendComponentResponseType } from './response';

/**
 * Supported request versions.
 */
export const supportedRequestVersions: Set<number | string> = new Set([1]);

/**
 * Handler response version.
 */
export const currentResponseVersion = 1;

/**
 * Invoke backend component handler calls Agile SDK backend components via GraphQL.
 *
 * This allows Custom Apps to perform the calls without the direct access to backend.
 */
// eslint-disable-next-line max-len
export const invokeBackendComponentHandler: RequestHandler<InvokeBackendComponentRequest, InvokeBackendComponentResponse> = async (request) => {
  if (!isInvokeBackendComponentRequest(request)) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('Bad invoke backend component request.');
  }

  if (!supportedRequestVersions.has(request.version)) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('Unsupported component request version.');
  }

  const { data, error } = await client.query<InvokeBackendComponentQuery, InvokeBackendComponentQueryVariables>({
    query: InvokeBackendComponentDocument,
    fetchPolicy: 'no-cache',
    variables: {
      componentName: request.payload.name,
      arguments: Object.entries(request.payload.args).map(([name, value]) => ({ name, value })),
    },
  });

  if (error) {
    throw error;
  }

  const payload = data.invokeBackendComponent;
  if (!payload) {
    // eslint-disable-next-line i18next/no-literal-string
    throw new Error('No payload received.');
  }

  return {
    id: request.id,
    type: invokeBackendComponentResponseType,
    version: currentResponseVersion,
    payload: {
      result: payload.result,
      successful: payload.successful,
      errors: payload.errors,
      warnings: payload.warnings,
      infos: payload.infos,
    },
  };
};
