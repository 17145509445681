import { isTruthy } from '@app/common/utils';

import { setAuthenticationToken } from '@app/core/authentication/authenticationToken';
import { setDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';
import { setEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import {
  extractInitParametersFromLocalStorage,
} from '@app/core/authentication/parametrizedLogin/sessionParametersStorage';
import { setCurrentLanguageCultureToStorage } from '@app/core/contexts/localizationContext/LocalizationContext';
import { setSessionValue } from '@app/core/storage/browser/sessionStorage';

import { setClientDeviceUID } from '../customAuthHeaders/clientDeviceUID';
import { isLoggedIn } from '../login/isLoggedIn';

/**
 * Admin login name storage key for Session Storage.
 */
export const ADMIN_LOGIN_KEY = 'admin-login';

/**
 * Gets URL parameters and puts them into Local and Session Storages.
 */
export const passSessionParametersToStorage = () => {
  const paramSettings = extractInitParametersFromLocalStorage();

  if (paramSettings.adminLogin) {
    setSessionValue(ADMIN_LOGIN_KEY, paramSettings.adminLogin);
  }

  // Get ds session id
  if (paramSettings.dssid) {
    setDSSessionId(paramSettings.dssid);
  }

  // Get client device id
  if (paramSettings.cduid) {
    setClientDeviceUID(paramSettings.cduid);
  }

  // Get embedded param
  if (isTruthy(paramSettings.embedded)) {
    // Enable embedded mode
    setEmbeddedMode();
  }

  // Get cultureId param
  if (paramSettings.cultureId) {
    // Set cultureId
    setCurrentLanguageCultureToStorage(paramSettings.cultureId);
  }

  // Currently user is not authenticated
  if (!isLoggedIn()) {
    //  Get auth token param
    if (paramSettings.authToken) {
      // Set auth token in session
      setAuthenticationToken(paramSettings.authToken);
    }
  }
};
