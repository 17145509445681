/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetNavigationMenuItemEligibilityStatusQueryVariables = Types.Exact<{
  serviceType: Types.Maybe<Types.ServiceType>;
  serviceName: Types.Scalars['String'];
}>;


export type GetNavigationMenuItemEligibilityStatusQuery = (
  { __typename?: 'Query' }
  & { menuItemEligibilityStatus: Types.Maybe<(
    { __typename?: 'MenuItemEligibilityStatus' }
    & Pick<Types.MenuItemEligibilityStatus, 'serviceType' | 'serviceName' | 'isEligible'>
  )> }
);


export const GetNavigationMenuItemEligibilityStatusDocument = gql`
    query getNavigationMenuItemEligibilityStatus($serviceType: ServiceType, $serviceName: String!) {
  menuItemEligibilityStatus(serviceType: $serviceType, serviceName: $serviceName) {
    serviceType
    serviceName
    isEligible
  }
}
    `;

/**
 * __useGetNavigationMenuItemEligibilityStatusQuery__
 *
 * To run a query within a React component, call `useGetNavigationMenuItemEligibilityStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavigationMenuItemEligibilityStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavigationMenuItemEligibilityStatusQuery({
 *   variables: {
 *      serviceType: // value for 'serviceType'
 *      serviceName: // value for 'serviceName'
 *   },
 * });
 */
export function useGetNavigationMenuItemEligibilityStatusQuery(baseOptions: Apollo.QueryHookOptions<GetNavigationMenuItemEligibilityStatusQuery, GetNavigationMenuItemEligibilityStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNavigationMenuItemEligibilityStatusQuery, GetNavigationMenuItemEligibilityStatusQueryVariables>(GetNavigationMenuItemEligibilityStatusDocument, options);
      }
export function useGetNavigationMenuItemEligibilityStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNavigationMenuItemEligibilityStatusQuery, GetNavigationMenuItemEligibilityStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNavigationMenuItemEligibilityStatusQuery, GetNavigationMenuItemEligibilityStatusQueryVariables>(GetNavigationMenuItemEligibilityStatusDocument, options);
        }
export type GetNavigationMenuItemEligibilityStatusQueryHookResult = ReturnType<typeof useGetNavigationMenuItemEligibilityStatusQuery>;
export type GetNavigationMenuItemEligibilityStatusLazyQueryHookResult = ReturnType<typeof useGetNavigationMenuItemEligibilityStatusLazyQuery>;
export type GetNavigationMenuItemEligibilityStatusQueryResult = Apollo.QueryResult<GetNavigationMenuItemEligibilityStatusQuery, GetNavigationMenuItemEligibilityStatusQueryVariables>;