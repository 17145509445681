import React from 'react';

import { FieldBehaviorMode } from '@app/queryTyping';

import { FieldComponent, FieldLocator } from '@app/common/configurable-wizards/types';
import { MaskedTextFieldFragment } from '@app/common/configurable-wizards/queries/queryTyping/all-fields';
import { combineNames, isMaskedTextField } from '@app/common/configurable-wizards/utils';
import { PhoneInput } from './PhoneInput';

export const PhoneExtensionFieldComponent: FieldComponent<MaskedTextFieldFragment> = ({
  parent,
  field,
}) => {
  const name = combineNames(parent, field.id);
  const fieldMask = field.maskFormat.replace(/0/g, '#');

  const options = {
    required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
  };

  return (
    <PhoneInput
      name={name}
      options={options}
      format={fieldMask}
      minLength={field.minLength}
      maxLength={field.maxLength}
      label={field.label || ''}
      data-stable-name={`${field.id}Input`}
      defaultValue={field.sValue}
    />
  );
};

export const PhoneExtensionFieldLocator: FieldLocator = ({ field }) => (
  isMaskedTextField(field) && field.id === 'Extension'
    ? PhoneExtensionFieldComponent
    : undefined
);
