import React, { useEffect } from 'react';

import { GetCurrentUserAccountInfoResult } from '@app/queryTyping';

import { createGenericContext } from '@app/common/contexts/createGenericContext';

import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { ContextProviderProps } from '@app/core/components/Application/ApplicationContexts';

import { useGetCurrentUserInfoLazyQuery } from './queries/queryTyping/getCurrentUserInfo';

const [
  useCurrentUserInfoContext,
  CurrentUserInfoContextProvider,
] = createGenericContext<GetCurrentUserAccountInfoResult | null>();

const CurrentUserInfoProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [getUserInfo, { data, error }] = useGetCurrentUserInfoLazyQuery();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      getUserInfo();
    }
  }, [isLoggedIn]);

  if (error) {
    throw error;
  }

  return (
    <CurrentUserInfoContextProvider value={data?.currentUserInfo || null}>
      {children}
    </CurrentUserInfoContextProvider>
  );
};

export { useCurrentUserInfoContext, CurrentUserInfoProvider };
