import { GraphQLError } from 'graphql';

import { ErrorCodes } from '@app/queryTyping';

import { isNotEmptyArray } from '@app/common/utils';

export const isForcePasswordResetError = (error: GraphQLError) => (
  error.extensions?.code === ErrorCodes.FORCE_USER_INFO_RESET
);

export const findForcePasswordResetError = (errors?: readonly GraphQLError[]): GraphQLError | undefined => {
  if (isNotEmptyArray(errors)) {
    return errors.find(isForcePasswordResetError);
  }

  return undefined;
};
