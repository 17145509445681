import { SET_LOCATION_REQUEST_MESSAGE_TYPE } from '../constants';
import { SetLocationRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isLocationData } from './isLocationData';

/**
 * Checks if the value is a {@link SetLocationRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isSetLocationRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SetLocationRequestMessage {
  return (
    value.messageType === SET_LOCATION_REQUEST_MESSAGE_TYPE
    && isLocationData(value.payload)
  );
}
