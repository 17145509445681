import { isLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import {
  isAnonymousPage,
  PageURL,
} from '@app/core/widgets/pages';

export const checkAnonimousLogout = (pagePath:string) => [
  isLoggedIn(),
  isAnonymousPage(pagePath as PageURL),
].every(Boolean);

/**
 * Extracts the first block of the URL path as the region.
 *
 * @param {string} path - The URL path.
 * @returns {string} The extracted region from the path.
 * @example clearPathToRegion('localhost:7007/subuser-enrollment/') => '/subuser-enrollment'
 * @example clearPathToRegion('localhost:7007/subuser-enrollment') => '/subuser-enrollment'
 * @example clearPathToRegion('localhost:7007/subuser-enrollment/enrollment-options/') => '/subuser-enrollment'
 * @example clearPathToRegion('localhost:7007/user-retrieval/next-region/') => '/subuser-enrollment'
 * @example clearPathToRegion('') => ''
 * @example clearPathToRegion(null) => ''
*/
export const clearPathToRegion = (path:string) : string => {
  const emptyResult = '';
  if (!path) { return emptyResult; }
  const paths = path.split('/');
  if (!paths.length) { return emptyResult; }
  return `/${paths[1]}`;
};

/**
 * Checking and logouting authorized users browsing anonimous pages
 * while leaving them on page path
 */
export const needAnonimousLogout = () => {
  const pagePath = clearPathToRegion(window.location.pathname);
  return checkAnonimousLogout(pagePath);
};
