import { AGILE_SDK_MESSAGE_TYPE_PREFIX } from '@app/core/sdk/constants';

import { AgileSDKFrameSize } from '../types';

export const AGILE_SDK_FRAME_SET_SIZE_EVENT = `${AGILE_SDK_MESSAGE_TYPE_PREFIX}.AgileSDKFrame.SetSize` as const;

export type AgileSDKFrameSetSizeEventDetail = {
  uid: string,
  size: Partial<AgileSDKFrameSize>,
};

export class AgileSDKFrameSetSizeEvent extends CustomEvent<AgileSDKFrameSetSizeEventDetail> {
  constructor(detail: AgileSDKFrameSetSizeEventDetail) {
    super(AGILE_SDK_FRAME_SET_SIZE_EVENT, { detail });
  }
}

declare global {
  interface WindowEventMap {
    [AGILE_SDK_FRAME_SET_SIZE_EVENT]: AgileSDKFrameSetSizeEvent;
  }
}
