import { isObject, isString } from '@app/common/utils/assertion';

import { InvokeExternalAPIIntegrationParams } from '../types';

/**
 * Checks if the value is a {@link InvokeExternalAPIIntegrationParams}.
 */
export function isInvokeExternalAPIIntegrationParams(
  value: unknown,
): value is InvokeExternalAPIIntegrationParams {
  const params = value as InvokeExternalAPIIntegrationParams;

  return (
    isObject(params)

    && 'name' in params
    && isString(params.name)

    && 'args' in params
    && isObject(params.args)
  );
}
