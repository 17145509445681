import { INVOKE_EXTERNAL_API_INTEGRATION_REQUEST_MESSAGE_TYPE } from '../constants';
import { InvokeExternalAPIIntegrationRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isInvokeExternalAPIIntegrationParams } from './isInvokeExternalAPIIntegrationParams';

/**
 * Checks if the value is a {@link InvokeExternalAPIIntegrationRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isInvokeExternalAPIIntegrationRequestMessage(value: AgileSDKRequestMessage<unknown>): value is InvokeExternalAPIIntegrationRequestMessage {
  return (
    value.messageType === INVOKE_EXTERNAL_API_INTEGRATION_REQUEST_MESSAGE_TYPE
    && isInvokeExternalAPIIntegrationParams(value.payload)
  );
}
