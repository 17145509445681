import { WizardConstants } from '@app/common/configurable-wizards';

export enum WizardSteps {
  SelectOneTimePinChannel = 'SelectOneTimePinChannel',
  SelectOneTimePinAddress = 'SelectOneTimePinAddress',
  ValidateOneTimePin = 'ValidateOneTimePin',
}

export const Constants: Omit<WizardConstants<WizardSteps>, 'I18N_NS'> = {
  WIZARD_NAME: 'layeredAuthentication',
};
