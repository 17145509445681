import {
  setSessionValue,
  getSessionValue,
  removeSessionValue,
} from '@app/core/storage/browser/sessionStorage';

export const SCSO_TOKEN_STORAGE_KEY = 'scso-token';

export const setScsoToken = (token: string): void => {
  setSessionValue(SCSO_TOKEN_STORAGE_KEY, token);
};

export const getScsoToken = () => getSessionValue(SCSO_TOKEN_STORAGE_KEY) ?? '';

export const resetScsoToken = (): void => {
  removeSessionValue(SCSO_TOKEN_STORAGE_KEY);
};

export default {
  setScsoToken,
  getScsoToken,
  resetScsoToken,
};
