import { invokeBackendComponentHandler } from './invokeBackendComponent/handler';
import { invokeBackendComponentRequestType } from './invokeBackendComponent/request';
import { invokeExternalApiIntegrationHandler } from './invokeExternalApiIntegration/handler';
import { invokeExternalApiIntegrationRequestType } from './invokeExternalApiIntegration/request';
import { RequestHandler } from './types';

export const handlers: Record<string, RequestHandler> = {
  [invokeBackendComponentRequestType]: invokeBackendComponentHandler as RequestHandler,
  [invokeExternalApiIntegrationRequestType]: invokeExternalApiIntegrationHandler as RequestHandler,
};
