import { ComponentType } from 'react';

import {
  DisclosuresTriggeredEventWizard,
} from './wizard/disclosures/DisclosuresTriggeredEventWizard';
import {
  LayeredAuthenticationTriggeredEventWizard,
} from './wizard/layeredAuthentication/LayeredAuthenticationTriggeredEventWizard';
import {
  MarketingSplashTriggeredEventWizard,
} from './wizard/marketingSplash/MarketingSplashTriggeredEventWizard';

import { TriggeredEventChallenge, TriggeredEventWizardBase } from './types';

export const wizards: Map<TriggeredEventChallenge, ComponentType<TriggeredEventWizardBase>> = new Map([
  [TriggeredEventChallenge.OneTimePINLayeredAuth, LayeredAuthenticationTriggeredEventWizard],
  [TriggeredEventChallenge.Marketing, MarketingSplashTriggeredEventWizard],
  [TriggeredEventChallenge.Disclosure, DisclosuresTriggeredEventWizard],
]);
