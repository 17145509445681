import React from 'react';

import { TabPropsExt } from '../tab';

/**
 * Create context to pass function for register tabs
 */
export interface ContextProps {
  readonly tabs: React.MutableRefObject<TabPropsExt[]>;
  readonly current?: number;
  readonly tabWidth?: string;
  /**
   * Update state in Tabs, set new current tab index
  */
  readonly updateCurrent: (tabIndex?: number) => void;

  readonly isContentInFocus: () => boolean;
}

export const Context = React.createContext<ContextProps>({
  tabs: {
    current: [],
  },
  updateCurrent: () => {},
  isContentInFocus: () => false,
});

export const ContextUse = (cntxt: ContextProps) => cntxt; // wrapper for jest test, to mock it
