import { generateRandomNumber } from '@app/common/utils/randomNumber';

export const WINDOW_NAME_PREFIX = '__orpheus:';

export const setWindowName = () => {
  window.name = WINDOW_NAME_PREFIX + generateRandomNumber();
};

export const isWindowNameSet = () => window.name && window.name.indexOf(WINDOW_NAME_PREFIX) !== -1;

export const unsetWindowName = () => {
  if (isWindowNameSet()) {
    window.name = '';
  }
};
