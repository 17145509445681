import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FieldBehaviorMode } from '@app/queryTyping';

import { PasswordControl } from '@app/common/components/controls/PasswordControl/PasswordControl';
import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { TextFieldFragment as TextField } from '../../queries/queryTyping/all-fields';
import { FieldComponent, FieldLocator } from '../../types';
import { combineNames, isPasswordField, getFieldErrorMessage } from '../../utils';
import { CFFormField } from '../common/CFFormField';

export const PasswordFieldComponent: FieldComponent<TextField> = ({
  parent,
  field,
}) => {
  const { register, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  return (
    <CFFormField error={error}>
      <PasswordControl
        label={field.label || ''}
        aria-invalid={!!error}
        autoComplete="current-password"
        data-stable-name={`${field.id}Input`}
        defaultValue={field.sValue || ''}
        {...register(name, {
          shouldUnregister: true,
          required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
        })}
      />
    </CFFormField>
  );
};

export const PasswordFieldLocator: FieldLocator = (context) => (
  isPasswordField(context.field)
    ? PasswordFieldComponent
    : undefined
);
