import { useGetRecaptchaSettingsQuery } from './queries/queryTyping/getRecaptchaSettings';

export function useGetRecaptchaSettings() {
  const { data: rData } = useGetRecaptchaSettingsQuery();
  const recaptchaSettings = rData?.recaptchaSettings;

  return {
    isEnabled: recaptchaSettings?.isEnabled,
    siteKeyV2: recaptchaSettings?.siteKeyV2,
    siteKeyV3: recaptchaSettings?.siteKeyV3,
  };
}
