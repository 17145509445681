export const eventsToTrack = new Set([
  // eslint-disable-next-line i18next/no-literal-string
  'mousemove',
  // eslint-disable-next-line i18next/no-literal-string
  'keypress',
  // eslint-disable-next-line i18next/no-literal-string
  'keyup',
  // eslint-disable-next-line i18next/no-literal-string
  'scroll',
  // eslint-disable-next-line i18next/no-literal-string
  'click',
  // eslint-disable-next-line i18next/no-literal-string
  'touchstart',
  // eslint-disable-next-line i18next/no-literal-string
  'unload',
]);
