import { apolloErrorProcessor } from './apolloErrorProcessor';
import { chunkErrorProcessor } from './chunkLoadErrorProcessor';
import { genericErrorProcessor } from './genericErrorProcessor';
import { userLockoutErrorProcessor } from './userLockoutErrorProcessor';
import { userTokenExpiredErrorProcessor } from './userTokenExpiredErrorProcessor';
import { wizardIncorrectConfigurationErrorProcessor } from './wizardIncorrectConfigurationErrorProcessor';
// eslint-disable-next-line import/extensions
import { wizardStepsInconsistencyErrorProcessor } from './wizardStepsInconsistencyErrorProcessor';

export const basicErrorProcessors = [
  userLockoutErrorProcessor,
  wizardStepsInconsistencyErrorProcessor,
  wizardIncorrectConfigurationErrorProcessor,
  userTokenExpiredErrorProcessor,
  apolloErrorProcessor,
  chunkErrorProcessor,
  genericErrorProcessor,
];
