import React, {
  useEffect, useState,
} from 'react';
import { useFormContext } from 'react-hook-form';

import UserCircle from '@ast/magma/components/icon/icons/UserCircle';

import { Input } from '@ast/magma/components/input';

import { FieldBehaviorMode } from '@app/queryTyping';

import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { combineNames, getFieldErrorMessage, isTextField } from '../utils';

import {
  FieldComponent, FieldContext, FieldLocator, FieldUiProps, TextField,
} from '..';
import { CFFormField } from './common/CFFormField';

export interface UsernameFieldUiProps extends FieldUiProps {
  readonly autoFocus?: boolean;
  readonly passkeyAutoFill?: boolean;
}

export const UsernameFieldComponent: FieldComponent<TextField, UsernameFieldUiProps> = ({
  parent,
  field,
  fieldUiProps,
}) => {
  const { register, watch, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  const [inputValue, setInputValue] = useState(field.sValue || '');

  useEffect(() => {
    const currentValue = field.sValue as string;

    if (currentValue) {
      setInputValue(currentValue);
    }
  }, [field.sValue]);

  const inputWatch = watch(name);

  useEffect(() => {
    setInputValue(inputWatch);
  }, [inputWatch]);

  const { onChange, ...inputProps } = register(name, {
    required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
  });

  const handleInputChange = (e: any) => {
    onChange(e);
    setInputValue(e.target.value);
  };

  return (
    <CFFormField error={error}>
      <Input
        leadingChild={<UserCircle />}
        maxLength={field.maxLength}
        label={field.label || ''}
        aria-invalid={!!error}
        autoComplete={fieldUiProps?.passkeyAutoFill ? 'username webauthn' : 'username'}
        data-stable-name={`${field.id}Input`}
        value={inputValue}
        onChange={handleInputChange}
        autoFocus={fieldUiProps?.autoFocus}
        {...inputProps}
      />
    </CFFormField>
  );
};

export const usernameFieldId = 'UserName';

export const isUsername = ({ field }: FieldContext) => (
  isTextField(field) && field.id === usernameFieldId
);

export const UsernameLocator: FieldLocator = (context) => (
  isUsername(context)
    ? UsernameFieldComponent
    : undefined
);
