import React, { ReactElement } from 'react';

import { MessagesCountProvider } from '@app/core/asyncNotifications/MessagesCountContext';
import { ScsoContextProvider } from '@app/core/authentication/ScsoContext';
import { AppWidgetsProvider } from '@app/core/contexts/appWidgets/AppWidgetsContext';
import { ColorThemeProvider } from '@app/core/contexts/colorTheme/ColorThemeContext';
import { CurrentUserInfoProvider } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { FISettingsProvider } from '@app/core/contexts/fiSettings/FISettingsContext';

export interface ContextProviderProps {
  readonly children: ReactElement;
}

/**
 * List of shared context providers.
 *
 * Keep in mind, the tree is built from the inside out,
 * it means that first array element will be deeply nested.
 */
const contextProviders = [
  ScsoContextProvider,
  CurrentUserInfoProvider,
  MessagesCountProvider,
  ColorThemeProvider,
  AppWidgetsProvider,
  FISettingsProvider,
];

/**
 * Compose providers to nested tree.
 */
const composeProviders = (
  providers: React.FC<ContextProviderProps>[],
  { children, ...props }: ContextProviderProps,
) => (
  providers.reduce((acc, Provider) => (
    <Provider {...props}>{acc}</Provider>
  ), children)
);

/**
 * Wrap children with contexts.
 */
export const ApplicationContexts: React.FC<ContextProviderProps> = (props) => composeProviders(
  contextProviders,
  props,
);
