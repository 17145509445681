import React from 'react';

import { ConnectedWizardStep, WizardStep } from '@app/common/configurable-wizards';
import {
  DeliveryChannelTypesFieldLocator,
} from '@app/common/configurable-wizards/fields/DeliveryChannelTypes';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/WizardStepWithHeader';

import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { LoginWizardButtons } from '../../LoginWizardButtons';

import styles from './index.pcss';

export const SelectOneTimePinChannel: React.FC<ConnectedWizardStep> = ({
  locators,
  ...restParams
}) => {
  const { t } = useUserLoginTranslation();
  const stepLocators = [DeliveryChannelTypesFieldLocator, ...(locators || [])];
  const { name } = useWizardStepStateContext();

  return (
    <WizardStepWithHeader
      title={name || ''}
      contentClassName={styles.content}
    >
      <WizardStep
        locators={stepLocators}
        {...restParams}
        buttons={({ back }) => (
          <LoginWizardButtons
            showBackButton={false}
            showContinueButton={false}
            cancelTitle={t(
              'select-one-time-pin-channel.wizard.buttons.cancel.title|Select One Time Pin Channel step cancel button title',
              'Back',
            )}
            back={back}
            direction="column"
          />
        )}
      />
    </WizardStepWithHeader>
  );
};
