import {
  setCookie as setCookieInternal,
  getCookie as getCookieInternal,
  removeCookie as removeCookieInternal,
} from '@app/common/utils/storage/cookie';

import { joinStoragePath } from './utils';

export function getCookie(key: string): string {
  const innerKey = joinStoragePath(key);
  return decodeURIComponent(getCookieInternal(innerKey) || '');
}

export function setCookies(key: string, value: string): void {
  const innerKey = joinStoragePath(key);
  setCookieInternal(innerKey, encodeURIComponent(value), {
    path: '/',
    secure: true,
    sameSite: 'strict',
  });
}

export function removeCookie(key: string): void {
  const innerKey = joinStoragePath(key);
  removeCookieInternal(innerKey, '/');
}
