import { PasskeyOnboardingPresentingProps } from './types';

export const getNextDateToPresentPasskeyOnbording = ({
  lastSkip,
  skipCount,
  deferralPeriodInDays,
} : PasskeyOnboardingPresentingProps) => {
  // If user has never skipped the onboarding, always show it.
  if (!skipCount) {
    return lastSkip ?? new Date();
  }

  if (!lastSkip) {
    return new Date();
  }

  const lastSkipDate = new Date(lastSkip);
  const daysToAdd = deferralPeriodInDays[skipCount - 1] ?? deferralPeriodInDays[deferralPeriodInDays.length - 1];
  lastSkipDate.setDate(lastSkipDate.getDate() + daysToAdd);
  return lastSkipDate;
};

export const isPasskeyOnbordingDeferral = (
  { currentDate, ...restProps } : PasskeyOnboardingPresentingProps & { currentDate?: Date },
) => {
  // If user has never skipped the onboarding, always show it.
  if (!restProps.skipCount || !restProps.lastSkip) {
    return false;
  }

  // otherwise, calculate the next show date and compare it with the current date.
  const nextPresentDate = getNextDateToPresentPasskeyOnbording(restProps);
  return (currentDate ?? new Date()) < nextPresentDate;
};

export const shouldShowPasskeyOnboarding = (
  isLoginWithPasskey: boolean,
  isPasskeyEnabled: boolean,
  passkeyOnboardingPresentingProps: PasskeyOnboardingPresentingProps,
  userEligibleForPasskey?: boolean,
) => (
  !isLoginWithPasskey
    && isPasskeyEnabled
    && !!userEligibleForPasskey
    && !isPasskeyOnbordingDeferral(passkeyOnboardingPresentingProps)
);
