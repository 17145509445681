import dayjs from 'dayjs';

import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

import {
  CHANGE_CULTURE_ID_MESSAGE_TYPE,
  DEFAULT_FALLBACK_LANGUAGE_CULTURE,
} from '@app/core/contexts/localizationContext/constants';

require('dayjs/locale/es');

// Extend dayjs library with plugin to work with duration
dayjs.extend(duration);

// Extend dayjs library with plugin to work with local formats
dayjs.extend(localizedFormat);

// Extend dayjs library with plugin to work with UTC
dayjs.extend(utc);

const messageListener = (event: MessageEvent) => {
  if (event?.data?.messageType === CHANGE_CULTURE_ID_MESSAGE_TYPE) {
    // set current locale for dayjs
    dayjs.locale(event?.data?.payload?.cultureId ?? DEFAULT_FALLBACK_LANGUAGE_CULTURE);
    // use only once because of reload page when change language, so we don't need to subscribe to this event more
    window.removeEventListener('message', messageListener);
  }
};

window.addEventListener('message', messageListener);
