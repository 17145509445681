/* eslint-disable i18next/no-literal-string */
import { ComponentType } from 'react';

import i18n, { t } from '@app/core/i18n';

import { getNotFoundError } from '../pages/pages';

/**
 * Widget component type.
 */
export type ReactWidgetFactory = () => Promise<{ default: ComponentType }>;

const namespaceByWidget = {
  AlertSettings: 'alert-settings',
  MessageCenter: 'message-center',
  ContactsAndSettings: 'contacts-and-settings',
  bankAccountList: 'bank-accounts',
  footer: 'footer',
  EmailVerification: 'email-verification',
  OrpheusLogin: 'user-login',
  OrpheusUserEnrollment: 'user-enrollment',
  OrpheusSubuserEnrollment: 'subuser-enrollment',
  UserKind: 'user-kind',
  OrpheusRetrieval: 'user-retrieval',
  SecureAccount: 'secure-account',
  transfers: 'make-transfer',
  'single-sign-on': 'single-sign-on',
  cardControl: 'card-control',
  familyAndFriends: 'family-and-friends',
  businessBanking: 'business-banking',
  estatements: 'estatements',
  openNewShares: 'open-new-shares',
  'custom-app': 'custom-app',
  SubUserTransferApproval: 'subuser-transfer-approval',
  transferActivities: 'transfer-activity',
  creditScore: 'credit-score',
  PasskeyOnboarding: 'passkey-onboarding',
  triggeredEvent: 'triggered-event',
} as Record<string, string>;

/**
 * Return factory which load widget component and it's translation resources in row.
 * @param name widget name used as namespace.
 * @param factory factory to load widget component.
 * @returns factory function which loads widget string and component and return component.
 */
const createLoadReactWidgetFactory = (name:string, factory:ReactWidgetFactory):ReactWidgetFactory => async () => {
  try {
    await i18n.loadNamespaces(namespaceByWidget[name]);
  } catch (error) {
    console.warn(error);
  }

  return factory();
};

/* eslint-disable i18next/no-literal-string */
export const getReactWidgetFactory = (widget: string): ReactWidgetFactory => {
  // !Keep the keys below alphabetically ordered.
  const map = new Map<string, ReactWidgetFactory>(
    [
      ['AlertSettings', () => import(
        /* webpackChunkName: "alert-settings" */
        '@app/widgets/alert-settings'
      )],
      ['MessageCenter', () => import(
        /* webpackChunkName: "message-center" */
        '@app/widgets/message-center'
      )],
      ['ContactsAndSettings', () => import(
        /* webpackChunkName: "contacts-and-settings" */
        '@app/widgets/contacts-and-settings'
      )],
      ['bankAccountList', () => import(
        /* webpackChunkName: "bank-accounts" */
        '@app/widgets/bank-accounts'
      )],
      ['EmailVerification', () => import(
        /* webpackChunkName: "email-verification" */
        '@app/widgets/email-verification'
      )],
      ['OrpheusLogin', () => import(
        /* webpackChunkName: "user-login" */
        '@app/widgets/user-login'
      )],
      ['OrpheusUserEnrollment', () => import(
        /* webpackChunkName: "user-enrollment" */
        '@app/widgets/user-enrollment'
      )],
      ['OrpheusSubuserEnrollment', () => import(
        /* webpackChunkName: "subuser-enrollment" */
        '@app/widgets/subuser-enrollment'
      )],
      ['UserKind', () => import(
        /* webpackChunkName: "user-kind" */
        '@app/widgets/user-kind'
      )],
      ['PasskeyOnboarding', () => import(
        /* webpackChunkName: "passkey-onboarding" */
        '@app/widgets/passkey-onboarding'
      )],
      ['OrpheusRetrieval', () => import(
        /* webpackChunkName: "user-retrieval" */
        '@app/widgets/user-retrieval'
      )],
      ['SecureAccount', () => import(
        /* webpackChunkName: "secure-account" */
        '@app/widgets/secure-account'
      )],
      ['transfers', () => import(
        /* webpackChunkName: "make-transfer" */
        '@app/widgets/make-transfer'
      )],
      ['transferActivities', () => import(
        /* webpackChunkName: "transfer-activity" */
        '@app/widgets/transfer-activity'
      )],
      ['cardControl', () => import(
        // webpackChunkName: "card-control"
        '@app/widgets/card-control'
      )],
      ['familyAndFriends', () => import(
        // webpackChunkName: "family-and-friends"
        '@app/widgets/family-and-friends'
      )],
      ['businessBanking', () => import(
        // webpackChunkName: "business-banking"
        '@app/widgets/business-banking'
      )],
      ['estatements', () => import(
        // webpackChunkName: "estatements"
        '@app/widgets/estatements'
      )],
      ['openNewShares', () => import(
        // webpackChunkName: "open-new-shares"
        '@app/widgets/open-new-shares'
      )],
      ['SubUserTransferApproval', () => import(
        // webpackChunkName: "subuser-transfer-approval"
        '@app/widgets/subuser-transfer-approval'
      )],
      ['creditScore', () => import(
        // webpackChunkName: "credit-score"
        '@app/widgets/credit-score'
      )],
      ['triggeredEvent', () => import(
        // webpackChunkName: "credit-score"
        '@app/widgets/triggered-event-standalone'
      )],
    ],
  );

  if (!map.has(widget)) {
    throw getNotFoundError();
  }

  return createLoadReactWidgetFactory(widget, map.get(widget)!);
};
