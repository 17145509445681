import { isObject, isString } from '@app/common/utils/assertion';

import { InvokeBackendComponentParams } from '../types';

/**
 * Checks if the value is a {@link InvokeBackendComponentParams}.
 */
export function isInvokeBackendComponentParams(value: unknown): value is InvokeBackendComponentParams {
  return (
    isObject(value)

    && 'name' in value
    && isString(value.name)

    && 'args' in value
    && isObject(value.args)
  );
}
