import React, { useEffect, useRef } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import {
  moveFocus, moveNextChildGenerator,
} from '@ast/magma/utils/focus';

export interface FormProviderWrapperProps {
  /**
   * react-hook-form useForm return options
   */
  formProviderProps: UseFormReturn
  /**
   * HTML form element options
   */
  formProps: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>
}

/**
 * Common component to place generic form-functionality
 */
export const FormProviderWrapper: React.FC<FormProviderWrapperProps> = ({
  children,
  formProps,
  formProviderProps,
}) => {
  const formRef = useRef(null);
  const fieldsRef = formProviderProps.control?.fieldsRef?.current;

  useEffect(() => {
    // return if form fields still aren't loaded
    if (!fieldsRef || Object.keys(fieldsRef).length === 0) {
      return;
    }

    // focus first form element
    const nextChild = moveNextChildGenerator(formRef.current, false);
    moveFocus(nextChild);
  }, [fieldsRef]);

  return (
    <FormProvider {...formProviderProps}>
      <form
        data-stable-name="FormProviderWrapper"
        {...formProps}
        ref={formRef}
      >
        {children}
      </form>
    </FormProvider>
  );
};
