import { useEffect, useState } from 'react';
import { msToSec } from '@app/common/utils/dateTime';
import { isNumber } from '@app/common/utils';
import { getUserActivityInterpreter } from '@app/core/userActivityInterpreter/utils';
import { UserAutoLogoutInterpreterEvents } from './types';
import { UserAutoLogoutInterpreter } from './UserAutoLogoutInterpreter';

interface CountdownListener {
  (data: UserAutoLogoutInterpreterEvents['onautologoutcountdown']): void
}

/**
 * Returns countdown in seconds if auto-logout process is started, or null if isn't
 */
export const useAutoLogoutCountdown = (sessionExpirationInSecs?: number) : number | null => {
  const [coutdown, setCountdown] = useState<number | null>(null);

  useEffect(() => {
    const autoLogoutInterpreter = UserAutoLogoutInterpreter.getInstance();
    autoLogoutInterpreter.init({
      userActivityInterpreter: getUserActivityInterpreter(sessionExpirationInSecs),
    });

    // listen user auto-logout countdown event
    const countdownListener: CountdownListener = ({ msToLogout }) => (
      setCountdown(
        isNumber(msToLogout) ? msToSec(msToLogout) : null,
      )
    );

    autoLogoutInterpreter.addListener('onautologoutcountdown', countdownListener);

    return () => (
      autoLogoutInterpreter.removeListener('onautologoutcountdown', countdownListener)
    );
  }, [sessionExpirationInSecs]);

  return coutdown;
};
