import { checkEligibility as checkEligibilitySSO } from '@app/core/eligibility/widgets/SsoWidget';
import { checkEligibility as checkEligibilityHtml } from '@app/core/widgets/react/htmlWidget/utils';
import { WidgetType } from '@app/core/widgets/react/types';

import { ZoneName } from '../constants';

import { renderWidget } from './renderWidget';

/**
 * Renders a conditional widget once it is ready.
 *
 * A conditional widget is a widget that is rendered after a certain condition is met.
 *
 * For instance, any eligibility checks or data fetching can be performed before rendering the widget.
 *
 * If the widget should not be rendered, it should return `null`.
 */
// eslint-disable-next-line max-len
export async function renderConditionalWidget(id: string, widget: WidgetType, zoneName: ZoneName): Promise<JSX.Element | null> {
  // eslint-disable-next-line no-underscore-dangle
  switch (widget.__typename) {
    case 'SsoWidget': {
      const isEligible = await checkEligibilitySSO(widget).catch((error) => {
        // eslint-disable-next-line no-console, i18next/no-literal-string
        console.error('Error while checking eligibility for SSO widget:', widget, error);
        return false;
      });

      if (!isEligible) {
        return null;
      }

      return renderWidget(id, widget, zoneName);
    }

    case 'Widget': {
      const isEligible = checkEligibilityHtml(widget);

      if (!isEligible) {
        return null;
      }

      return renderWidget(id, widget, zoneName);
    }

    default:
      // Render the widget immediately if it is not conditional.
      return renderWidget(id, widget, zoneName);
  }
}
