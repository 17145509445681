import React from 'react';

import {
  AlertProps,
  Alert,
} from '@ast/magma/components/alert';
import { NotificationLevel } from '@app/queryTyping';
import { isNotificationField } from '../utils';
import {
  FieldComponent,
  FieldLocator,
} from '../types';
import { NotificationFieldFragment as NotificationField } from '../queries/queryTyping/all-fields';
/**
 * TODO (MPH-37505) Refine ability and usefulness to render some default text if passed empty label
 */
export const NotificationFieldComponent: FieldComponent<NotificationField> = ({
  field,
}) => {
  const mapper: Record<NotificationLevel, AlertProps['level']> = {
    [NotificationLevel.CRITICAL]: 'error',
    [NotificationLevel.WARNING]: 'warning',
    [NotificationLevel.NOTICE]: 'info',
  };

  return (
    <Alert
      level={mapper[field.notificationLevel] || 'info'}
      data-stable-name={`${field.id}Notification`}
    >
      <div dangerouslySetInnerHTML={{ __html: field.label || '' }} />
    </Alert>
  );
};

export const NotificationFieldLocator: FieldLocator = (context) => (
  isNotificationField(context.field)
    ? NotificationFieldComponent
    : undefined
);
