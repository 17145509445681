/**
 * Finds an `iframe` that contains the specified `Window`.
 *
 * Useful when it is needed to find the `iframe` that posted the message.
 */
export function findFrameByWindow(source: Window) {
  const iframes = document.querySelectorAll('iframe');

  const iframe = Array.from(iframes).find((frame) => frame.contentWindow === source);

  return iframe;
}
