/* eslint-disable i18next/no-literal-string */
import { useEffect, useRef, MutableRefObject } from 'react';

import { ApolloError, isApolloError } from '@apollo/client';

import { VerifyAuthenticationParams } from '@app/queryTyping';

import { WizardStepStateDispatchAction, setWizardStepAlertMessage } from '@app/common/configurable-wizards';
import { SetLoadingAction } from '@app/common/configurable-wizards/state/stepState';
import { logPasskeyError } from '@app/common/utils/logPasskey';

import { client } from '@app/core/apolloClient';

import {
  convertGetOptions,
  convertGetCredential,
} from '@app/widgets/contacts-and-settings/fields/ProfileAndSecurity/Passkeys/webauthn-helpers';
import { useUserLoginTranslation } from '@app/widgets/user-login/hooks/useUserLoginTranslation';

import { GeneratePasskeyAuthOptionsDocument, GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables } from './queries/queryTyping/generate-passkey-auth-options';
import { useVerifyPasskeyAuthenticationMutation } from './queries/queryTyping/verify-passkey-authentication';

import { checkMediationAvailable } from './utils';

const buildVerificationVariables = (publicKeyCredential: Credential) : {
  variables: { verifyAuthenticationParams: VerifyAuthenticationParams } } => ({
  variables: {
    verifyAuthenticationParams: convertGetCredential(publicKeyCredential),
  },
});

interface UsePasskeyLoginProps {
  setWizardStepLoading?:(data: boolean) => SetLoadingAction;
  onApolloError: (error: ApolloError) => void;
  dispatch: (value: WizardStepStateDispatchAction) => void;
}

export const usePasskeyLogin = ({ setWizardStepLoading, onApolloError, dispatch }: UsePasskeyLoginProps) => {
  const { t } = useUserLoginTranslation();
  const abortControllerRef: MutableRefObject<AbortController | null> = useRef(null);

  const [verifyAuthentication] = useVerifyPasskeyAuthenticationMutation({
    onError: (error) => {
      if (isApolloError(error)) {
        onApolloError(error);
      }
      throw error;
    },
    onCompleted: (data) => {
      if (!data.verifyAuthentication!.success) {
        throw new Error(data.verifyAuthentication!.message);
      }
    },
  });

  const authenticate = async (isAuthConditional: boolean) => {
    let userIsEngaged = false;
    if (isAuthConditional) {
      const mediationAvailable = await checkMediationAvailable();

      // if browser does not support conditional flow for passkey auth - do nothing
      if (!mediationAvailable) {
        return;
      }
    }

    if (abortControllerRef.current) {
      // @ts-ignore
      abortControllerRef.current.abort({ message: 'Aborting previous passkey authentication request', name: 'AbortError' });
    }

    abortControllerRef.current = new AbortController();
    const abortSignal = abortControllerRef.current.signal;

    // eslint-disable-next-line max-len
    const { data: passkeyOptions, error } = await client.query<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>({
      query: GeneratePasskeyAuthOptionsDocument,
      fetchPolicy: 'no-cache',
    });
    if (error) {
      onApolloError(error);
      throw error;
    }

    if (passkeyOptions) {
      try {
        const publicKey = convertGetOptions(passkeyOptions.generateAuthOptions);

        const publicKeyCredential = await navigator.credentials.get({
          publicKey: publicKey as PublicKeyCredentialRequestOptions,
          // @ts-ignore
          mediation: isAuthConditional ? 'conditional' : 'required', // Default 'required' if not conditional
          signal: abortSignal,
        }) as PublicKeyCredential | null;
        userIsEngaged = true;

        if (publicKeyCredential) {
          // when user selects passkey for login
          // we need to show loader
          if (isAuthConditional && setWizardStepLoading) {
            dispatch(setWizardStepLoading(true));
          }
          const verificationVariables = buildVerificationVariables(publicKeyCredential);
          await verifyAuthentication(verificationVariables);
        }
      } catch (err: any) {
        logPasskeyError(err, `passkey login error: ${err?.message}`);

        if (!userIsEngaged && isAuthConditional) {
          // user was not engaged in the process, it is some internal error.
          // We should not display it to the user.
          throw err;
        }
        if (err.name === 'AbortError') {
          // Authentication aborted. Throw error to be caught and not to contiue login process;
          throw err;
        } if (!(err instanceof ApolloError)) {
          dispatch(setWizardStepAlertMessage({
            content: t('login.passkey.error.message|Login passkey error message', 'An error occurred while trying to authenticate with a passkey'),
            type: 'error',
          }));
        }

        throw err;
      }
    }
  };

  useEffect(() => () => {
    // when component unmounted, we need to abort conditional request, if it was not aborted previously
    if (abortControllerRef.current) {
      // @ts-ignore
      abortControllerRef.current.abort({ message: 'Component unmounted, aborting request.', name: 'AbortError' });
    }
  },
  []);

  return {
    startPasskeyAuth: () => authenticate(false),
    startPasskeyConditionalAuth: () => authenticate(true),
  };
};
