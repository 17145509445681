import React from 'react';

import { ErrorCard } from '@app/common/components/ErrorCard/ErrorCard';
import { CommonErrorDialogFooter } from '@app/common/components/ErrorDialog/CommonFooter/CommonFooter';
import { StaticErrorDialogFooter } from '@app/common/components/ErrorDialog/StaticFooter/StaticFooter';
import { MaintenanceModCard } from '@app/common/components/MaintenanceModeCard/MaintenanceModCard';
import { ErrorTypes } from '@app/common/errors/errorProcessors/constants';
import { checkIfMaintenanceModeError, genericErrorHandler } from '@app/common/errors/genericErrorHandler';

import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { PageURL } from '@app/core/widgets/pages';

import { Main } from '../Main/Main';

/**
 * Error page component properties.
 */
export interface ErrorPageProps {
  /**
   * Error to show.
   */
  error: Error;
  /**
   * Use static footer to prevent new errors to happen.
   */
  staticFooter: boolean;
}

/**
 * Error page component to show error dialog.
 * @param error error to show.
 * @returns error page component.
 */
export const ErrorPage = ({ error, staticFooter }:ErrorPageProps) => {
  const handledError = genericErrorHandler(error);
  const reloadHref = handledError.isRetryPossible ? window.location.href : (PageURL.USER_LOGIN as string);
  const isInternal = handledError.type === ErrorTypes.InternalServerError;
  const isMaintenanceMode = checkIfMaintenanceModeError(handledError);
  const isLoggedIn = useIsLoggedIn();

  return (
    <Main
      layoutId={isLoggedIn ? 'dashboard' : 'anonymous'}
    >
      {isMaintenanceMode ? (<MaintenanceModCard />) : (
        <ErrorCard
          error={handledError}
          footer={staticFooter
            ? <StaticErrorDialogFooter reloadHref={reloadHref} isInternalError={isInternal} />
            : <CommonErrorDialogFooter reloadHref={reloadHref} isInternalError={isInternal} />}
        />
      )}
    </Main>
  );
};
