import { UNSUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE } from '../constants';
import { UnsubscribeIsAuthenticatedRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link UnsubscribeIsAuthenticatedRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isUnsubscribeIsAuthenticatedRequestMessage(value: AgileSDKRequestMessage<unknown>): value is UnsubscribeIsAuthenticatedRequestMessage {
  return value.messageType === UNSUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE;
}
