import { AgileSDKRequestListener } from '../AgileSDKRequestListener';
import { AgileSDKRequest } from '../types';

import { AgileSDKService } from './AgileSDKService';

/**
 * Basic Agile SDK handler service that listens for incoming messages and reacts on them.
 */
// eslint-disable-next-line max-len
export abstract class RequestHandlerAgileSDKService<T extends AgileSDKRequest = AgileSDKRequest> extends AgileSDKService {
  protected readonly listener: AgileSDKRequestListener;

  protected readonly messageTypes: Set<string>;

  protected messageListener: (message: T) => Promise<boolean>;

  constructor(
    listener: AgileSDKRequestListener,
    messageTypes: Set<string>,
  ) {
    super();

    this.listener = listener;
    this.messageTypes = messageTypes;

    this.messageListener = (message) => this.handle(message);
  }

  /**
   * Handles the request.
   *
   * @param request Request to handle.
   */
  abstract handle(request: T): Promise<boolean>;

  async init() {
    // Listen for the specified message types.
    this.messageTypes.forEach((message) => {
      this.listener.addListener(message, this.messageListener as any);
    });
  }

  async dispose() {
    // Stop listening for the specified message types.
    this.messageTypes.forEach((message) => {
      this.listener.removeListener(message, this.messageListener as any);
    });
  }
}
