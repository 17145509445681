import { SEARCH_LANGUAGE_CULTURE_PARAM } from '@app/core/contexts/localizationContext/constants';
import { getCurrentLanguageCultureFromStorage } from '@app/core/contexts/localizationContext/LocalizationContext';

export const getCultureSearchParam = () => {
  const currentCulture = getCurrentLanguageCultureFromStorage();
  return currentCulture ? `?${SEARCH_LANGUAGE_CULTURE_PARAM}=${currentCulture}` : '';
};

// @ts-ignore
export const checkPasskeyAvaialble = () => Boolean(window.PublicKeyCredential);

// Check if the function exists on the browser - if it does, call it and return the result
export const checkMediationAvailable = async () => {
  if (
    window.PublicKeyCredential
    // @ts-ignore
    && PublicKeyCredential.isConditionalMediationAvailable
  ) {
    // @ts-ignore
    const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();

    return isCMA;
  }
  return false;
};
