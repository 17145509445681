import { encode } from 'url-safe-base64';

const V5_PREFIX = 'v5_';

/**
 * Generate 128bit crypto safe random key encoded into base 64 url safe string with prefix.
 * @returns UID key.
 */
export const generateUID = ():string => {
  // Generate 128bit (16 * 8bit array) random value
  const u8 = new Uint8Array(16);
  window.crypto.getRandomValues(u8);
  // Convert utf8 string into base 64 encoded string
  const base64Key = btoa(String.fromCharCode.apply(null, Array.from(u8)));
  // Encode url safe base 64 string
  const safeBase64Key = encode(base64Key);
  // Add prefix to result key
  return V5_PREFIX + safeBase64Key;
};
