import { isObject, isString } from '@app/common/utils/assertion';

import { UPDATE_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE } from '../constants';
import { UpdateUserCustomDataRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

function isUpdateUserCustomDataParameters(payload: unknown): boolean {
  return isObject(payload)
    && 'scope' in payload
    && isString(payload.scope)
    && 'data' in payload
    && isString(payload.data);
}

/**
 * Checks if the value is a {@link UpdateUserCustomDataRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isUpdateUserCustomDataRequestMessage(value: AgileSDKRequestMessage<unknown>): value is UpdateUserCustomDataRequestMessage {
  return value.messageType === UPDATE_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE
    && isUpdateUserCustomDataParameters(value.payload);
}
