import { isObject, isString } from '@app/common/utils/assertion';

import { GetInMemoryStorageItemParams } from '../types';

/**
 * Checks if the value is a {@link GetInMemoryStorageItemParams}.
 */
export function isGetInMemoryStorageItemParams(value: unknown): value is GetInMemoryStorageItemParams {
  return (
    isObject(value)

    && 'key' in value
    && isString(value.key)
  );
}
