/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type BiometricAuthenticationNotificationSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type BiometricAuthenticationNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { biometricAuthenticationNotification: (
    { __typename?: 'BiometricAuthenticationNotification' }
    & Pick<Types.BiometricAuthenticationNotification, 'passwordKey' | 'biometricAuthenticationResult'>
  ) }
);


export const BiometricAuthenticationNotificationDocument = gql`
    subscription biometricAuthenticationNotification {
  biometricAuthenticationNotification {
    passwordKey
    biometricAuthenticationResult
  }
}
    `;

/**
 * __useBiometricAuthenticationNotificationSubscription__
 *
 * To run a query within a React component, call `useBiometricAuthenticationNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBiometricAuthenticationNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiometricAuthenticationNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useBiometricAuthenticationNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BiometricAuthenticationNotificationSubscription, BiometricAuthenticationNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BiometricAuthenticationNotificationSubscription, BiometricAuthenticationNotificationSubscriptionVariables>(BiometricAuthenticationNotificationDocument, options);
      }
export type BiometricAuthenticationNotificationSubscriptionHookResult = ReturnType<typeof useBiometricAuthenticationNotificationSubscription>;
export type BiometricAuthenticationNotificationSubscriptionResult = Apollo.SubscriptionResult<BiometricAuthenticationNotificationSubscription>;