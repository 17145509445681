import { resetAuthenticationToken } from '@app/core/authentication/authenticationToken';
import { removeDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';
import { resetScsoToken } from '@app/core/authentication/scsoToken';
import { unsetWindowName, setWindowName } from '@app/core/windowName';

import { client } from '../../apolloClient';
import { LogoutDocument, LogoutMutation } from './queries/queryTyping/logout';

/**
 * Async call of backend logout mutation.
 * @returns redirect url string.
 */
const logoutMutation = (): Promise<string> => (
  client.mutate && client.mutate<LogoutMutation>({
    mutation: LogoutDocument,
  }).then((result) => result.data?.logout?.redirectUrl || '/'));

export const clearUserAuthData = () => {
  // clear session storage user token
  resetAuthenticationToken();
  // remove the SCSO session token if any
  resetScsoToken();
  // clear session storage DS Session Id
  removeDSSessionId();
};

// Clear data on logout
export const logoutClear = () => {
  clearUserAuthData();
  // clear window name to disable force logout
  unsetWindowName();
};

export const logout = async () => {
  // call server session logout
  let redirectUrl = '/';
  try {
    redirectUrl = await logoutMutation();
  } finally {
    logoutClear();
    // redirect to login page
    window?.location?.replace(redirectUrl);
  }
};

// Logout with no direct url redirect
export const logoutNoRedirect = async () => {
  // call server session logout
  await logoutMutation();
  logoutClear();
  setWindowName();
};
