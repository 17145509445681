import React from 'react';

import { ConnectedWizardStep } from '@app/common/configurable-wizards';
import {
  TermsAndConditionsFieldLocator,
} from '@app/common/configurable-wizards/fields/TermsAndConditions/TermsAndConditionsField';

import { DisclosuresWizardStep } from '../../../../DisclosuresWizardStep';

export const TermsAndConditions: React.FC<ConnectedWizardStep> = ({
  locators,
  ...props
}) => {
  const stepLocators = [TermsAndConditionsFieldLocator, ...(locators || [])];

  return (
    <DisclosuresWizardStep {...props} locators={stepLocators} />
  );
};
