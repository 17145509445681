import { useMemo, useEffect, FC } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  parent?: HTMLElement;
  className?: string;
}

/**
 * Renders children into a DOM node
 * that exists outside the DOM hierarchy of the parent component
 */
export const Portal: FC<PortalProps> = ({ children, parent, className }) => {
  // eslint-disable-next-line i18next/no-literal-string
  const container = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    // work out target in the DOM based on parent prop
    const target = parent || document.getElementById('app')! || document.body;
    if (!target) {
      // eslint-disable-next-line i18next/no-literal-string
      throw new Error('Portal container is not present in the DOM');
    }

    // will contain internal CSS classes (e.g. [styles.portal, ...])
    const classList:string[] = [];
    if (className) className.split(' ').forEach((item: any) => classList.push(item));
    classList.forEach((item) => container.classList.add(item));

    // append element to DOM
    target.appendChild(container);

    // on unmount function
    return () => {
      // remove element from DOM
      target.removeChild(container);
    };
  }, [container, parent, className]);

  return ReactDOM.createPortal(children, container);
};
