import React from 'react';

import { OverlaySpinner } from '../overlayspinner';

import styles from './WizardStepOverlaySpinner.pcss';

export interface WizardStepOverlaySpinnerProps {
  readonly loading: boolean,
}

export const WizardStepOverlaySpinner: React.FC<WizardStepOverlaySpinnerProps> = ({
  loading,
}) => (
  <OverlaySpinner
    visible={loading}
    position="absolute"
    align="center"
    containerClassName={styles.overlay}
  />
);

WizardStepOverlaySpinner.defaultProps = {
  loading: false,
};
