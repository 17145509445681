import React from 'react';

import { PlacementWidget } from '@app/queryTyping';

import { PureWidget, WidgetRenderer, WidgetType } from './types';
import {
  getReactWidgetFactory,
} from './widgetsList';

export function isOrpeusWidget(widget: WidgetType): widget is PureWidget<PlacementWidget> {
  return widget.__typename === 'PlacementWidget' && widget.placementType !== undefined;
}

export const OrpheusWidgetRenderer: WidgetRenderer<PlacementWidget> = React.memo((
  { widget },
) => {
  const componentFactory = getReactWidgetFactory(widget.placementType!);
  const Component = React.lazy(componentFactory);

  return (
    <div>
      <Component />
    </div>
  );
});
