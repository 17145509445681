import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { useTranslation } from '@app/core/i18n';

import { OverlaySpinner } from '@app/common/components/overlayspinner';

import { HtmlTextFieldFragment } from '../../queries/queryTyping/all-fields';
import { FieldComponent, FieldLocator } from '../../types';
import { isHtmlTextFieldInfo } from '../../utils';

import { ROOT_STYLES_CLASS } from './constants';
import styles from './HtmlTextFieldInfo.pcss';
import { getFrameStyles, getThemeVariables, replaceSymbols } from './utils';

export const HtmlTextFieldInfoComponent: FieldComponent<HtmlTextFieldFragment> = ({
  field: {
    htmlTextValue,
  },
}) => {
  const { t } = useTranslation();
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const [iframeSize, setIframeSize] = useState({
    width: 0,
    height: 0,
  });
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeBody = iframeRef.current?.contentWindow?.document.body;

  useEffect(() => {
    const frame = iframeRef.current && iframeRef.current.contentWindow;

    if (frame) {
      const frameStyles = getFrameStyles({
        variables: getThemeVariables(),
        rootClassName: ROOT_STYLES_CLASS,
      });

      // fill the frame with the contents
      frame.document.open();
      frame.document.write(`
        <style>${frameStyles}</style>
        ${replaceSymbols(htmlTextValue!)}
      `);
      frame.document.close();
    }
  }, [htmlTextValue]);

  useEffect(() => {
    if (iframeBody && isFrameLoaded) {
      const { scrollWidth } = iframeBody;

      // apply styles to fit images inside iframe
      iframeBody.classList.add(ROOT_STYLES_CLASS);

      // update iframe width, that captured before fitting
      setIframeSize((state) => ({
        ...state,
        width: scrollWidth,
      }));
    }
  }, [isFrameLoaded]);

  useLayoutEffect(() => {
    const scrollHeight = iframeBody?.scrollHeight;

    if (scrollHeight && scrollHeight !== iframeSize.height) {
      // after fitting, scrollHeight will be recalculated, updating iframe height
      setIframeSize({
        ...iframeSize,
        height: scrollHeight,
      });
    }
  }, [iframeSize]);

  return (
    <>
      <OverlaySpinner
        visible={!isFrameLoaded}
        position="absolute"
        align="center"
        containerClassName={styles.overlay}
      />
      <iframe
        ref={iframeRef}
        title={t('html-text-field-info.title|HTML text field frame title', 'HTML sources')}
        className={styles.frame}
        data-stable-name="MarketingSplashFrame"
        scrolling="auto"
        onLoad={() => setIsFrameLoaded(true)}
        style={{
          width: `${iframeSize.width}px`,
          height: `${iframeSize.height}px`,
        }}
      />
    </>
  );
};

export const HtmlTextFieldInfoLocator: FieldLocator = ({ field }) => (
  isHtmlTextFieldInfo(field)
    ? HtmlTextFieldInfoComponent
    : undefined
);
