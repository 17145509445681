/**
 * The prefix for the message type used by the Agile SDK.
 *
 * Any messages with types starting with other prefixes will be ignored.
 */
export const AGILE_SDK_MESSAGE_TYPE_PREFIX = 'ast.sdk' as const;

/**
 * The prefix for the request message type used by the Agile SDK.
 *
 * Any request messages with types starting with other prefixes will be ignored.
 */
export const AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX = `${AGILE_SDK_MESSAGE_TYPE_PREFIX}.request` as const;

/**
 * The prefix for the notification message type used by the Agile SDK.
 */
export const AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX = `${AGILE_SDK_MESSAGE_TYPE_PREFIX}.notification` as const;
