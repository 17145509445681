/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type SetPreferredCultureMutationVariables = Types.Exact<{
  preferredCulture: Types.Scalars['String'];
}>;


export type SetPreferredCultureMutation = (
  { __typename?: 'Mutation' }
  & { setPreferredCulture: (
    { __typename: 'SetPreferredCultureResult' }
    & { errors: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'messages' | 'faultType'>
    )>>, warnings: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'messages' | 'faultType'>
    )>> }
  ) }
);


export const SetPreferredCultureDocument = gql`
    mutation SetPreferredCulture($preferredCulture: String!) {
  setPreferredCulture(preferredCulture: $preferredCulture) {
    ... on SetPreferredCultureResult {
      __typename
      errors {
        messages
        faultType
      }
      warnings {
        messages
        faultType
      }
    }
    __typename
  }
}
    `;
export type SetPreferredCultureMutationFn = Apollo.MutationFunction<SetPreferredCultureMutation, SetPreferredCultureMutationVariables>;

/**
 * __useSetPreferredCultureMutation__
 *
 * To run a mutation, you first call `useSetPreferredCultureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferredCultureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferredCultureMutation, { data, loading, error }] = useSetPreferredCultureMutation({
 *   variables: {
 *      preferredCulture: // value for 'preferredCulture'
 *   },
 * });
 */
export function useSetPreferredCultureMutation(baseOptions?: Apollo.MutationHookOptions<SetPreferredCultureMutation, SetPreferredCultureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPreferredCultureMutation, SetPreferredCultureMutationVariables>(SetPreferredCultureDocument, options);
      }
export type SetPreferredCultureMutationHookResult = ReturnType<typeof useSetPreferredCultureMutation>;
export type SetPreferredCultureMutationResult = Apollo.MutationResult<SetPreferredCultureMutation>;
export type SetPreferredCultureMutationOptions = Apollo.BaseMutationOptions<SetPreferredCultureMutation, SetPreferredCultureMutationVariables>;