import { isObject } from '@app/common/utils';

import { AgileSDKResponse, agileSDKResponsePrefix, isAgileSDKRequest, RequestHandler } from './handlers/types';

/**
 * Request details
 */
export interface RequestDetails {
  /**
   * The window that produced the request.
   *
   * The response will be sent to this window.
   */
  source: Window,
  /**
   * The request event origin.
   */
  origin: string,
  /**
   * The request event data.
   */
  data: unknown,
}

/**
 * System error response type name.
 */
export const errorResponseType = `${agileSDKResponsePrefix}.error`;

/**
 * Current error response type version.
 */
export const errorResponseVersion = 1;

/**
 * System error response type.
 */
export interface ErrorResponse extends AgileSDKResponse {
  readonly payload: {
    readonly message?: string,
  },
}

/**
 * The message handler that routes window messages to the corresponding
 * Agile SDK request handlers.
 *
 * For example, the handler allows apps inside of iframes to invoke backend components
 * and receive data from the backend.
 */
// eslint-disable-next-line max-len
export async function handleMessage(request: RequestDetails, handlers: Record<string, RequestHandler>): Promise<boolean> {
  const { data } = request;

  if (!isAgileSDKRequest(data)) {
    return false;
  }

  const handler = handlers[data.type];
  if (!handler) {
    const response: ErrorResponse = {
      id: data.id,
      type: errorResponseType,
      version: errorResponseVersion,
      payload: {
        message: `No request handler was found for the message type "${data.type}".`,
      },
    };
    request.source.postMessage({ ...response }, request.origin);
    return false;
  }

  try {
    const response = await handler(data);
    // Send response to the source window.
    request.source.postMessage({ ...response }, request.origin);
  } catch (error: unknown) {
    // Send error response to the source window to notify the client.
    const response: ErrorResponse = {
      id: data.id,
      type: errorResponseType,
      version: errorResponseVersion,
      payload: {
        message: isObject(error) ? error.message : undefined,
      },
    };
    request.source.postMessage({ ...response }, request.origin);

    // Rethrow the error for further processing.
    throw error;
  }

  return true;
}
