import { GetDynamicWidgetQuery } from '@app/core/widgets/queries/queryTyping/getDynamicWidget';
import { WidgetType } from '@app/core/widgets/react/types';

import { GetApplicationWidgetsQuery } from '../../../widgets/queries/queryTyping/getApplicationWidgets';

import { getMiddleZones } from './getMiddleZones';

/**
 * Merges global and page widgets into a single object.
 */
export function mergeWidgets(globalWidgets: GetApplicationWidgetsQuery['widgets'], pageWidgetsData: GetDynamicWidgetQuery['pages']) {
  const pageWidgets = pageWidgetsData?.[0]?.widgets;

  const widgets: Record<string, WidgetType[]> = {
    header: [],
    footer: [],
    headerbanner: (pageWidgets?.header as WidgetType[]) || [],
    footerbanner: (pageWidgets?.footer as WidgetType[]) || [],
    rightside: (pageWidgets?.rightside as WidgetType[]) || [],

    ...getMiddleZones(pageWidgets?.middle as WidgetType[]),
  };

  return globalWidgets?.reduce((acc, widget) => {
    const zone = (widget?.zoneName?.toLowerCase())!;
    if (acc[zone]) {
      acc[zone] = (widget?.items as WidgetType[]).concat(acc[zone]);
    }
    return acc;
  }, widgets);
}
