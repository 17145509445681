import { ValueCallback } from '@app/common/utils';

import { BaseNotifier } from './BaseNotifier';

/**
 * A notifier that allows to subscribe to notifications with a value.
 *
 * Maintains a single value and notifies subscribers when it changes.
 *
 * See {@link BaseNotifier} for more details.
 */
export class ValueNotifier<T> extends BaseNotifier<ValueCallback<T>> {
  protected currentValue: T;

  constructor(initialValue: T) {
    super();

    this.currentValue = initialValue;
  }

  get value() {
    return this.currentValue;
  }

  set value(value: T) {
    if (this.currentValue === value) {
      return;
    }

    this.currentValue = value;
    this.notify();
  }

  call(callback: ValueCallback<T>) {
    try {
      callback(this.currentValue);
    } catch (error) {
      // eslint-disable-next-line no-console, i18next/no-literal-string
      console.error('Error calling subscriber', error);
    }
  }
}
