import { SsoWidget } from '@app/queryTyping';

import { client } from '@app/core/apolloClient';
import { PureWidget } from '@app/core/widgets/react/types';

import { IsEligibleServiceDocument, IsEligibleServiceQuery, IsEligibleServiceQueryVariables } from '../queries/queryTyping/isEligibleService';

/**
 * Perofrms an eligibility check for the SSO widget.
 *
 * Uses the `isEligibleService` query to check if the service is eligible.
 */
export async function checkEligibility(widget: PureWidget<SsoWidget>): Promise<boolean> {
  const { data, error } = await client.query<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>({
    fetchPolicy: 'cache-first',
    query: IsEligibleServiceDocument,
    variables: {
      serviceName: widget.providerId,
      serviceType: 'SSO',
    },
  });

  if (error) {
    throw error;
  }

  return data?.isEligibleService?.isEligible ?? false;
}
