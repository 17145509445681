import React from 'react';

import { PartialProps } from 'typescriptUtilityTypes';

import { WizardButtons } from '@app/common/components/configurable-wizards/wizard-buttons';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { useTriggeredEventsTranslation } from '../../useTriggeredEventsTranslation';

type WizardButtonsProps = React.ComponentProps<typeof WizardButtons>;
export type LayeredAuthenticationWizardButtonsProps =
  PartialProps<WizardButtonsProps, 'backTitle' | 'cancelTitle' | 'continueTitle' | 'direction' | 'showBackButton'>;

export const LayeredAuthenticationWizardButtons: React.FC<LayeredAuthenticationWizardButtonsProps> = (props) => {
  const { t } = useTriggeredEventsTranslation();
  const { allowBackRedirect } = useWizardStepStateContext();

  return (
    <WizardButtons
      backTitle={t(
        'layered-authentication.buttons.back.text|Layered authentication back button text',
        'Back',
      )}
      cancelTitle={t(
        'layered-authentication.buttons.cancel.text|Layered authentication cancel button text',
        'Cancel',
      )}
      continueTitle={t(
        'layered-authentication.buttons.submit.text|Layered authentication submit button text',
        'Submit',
      )}
      showBackButton={allowBackRedirect}
      showCancelButton={false}
      direction="column"
      {...props}
    />
  );
};
