/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetFiSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetFiSettingsQuery = (
  { __typename?: 'Query' }
  & { fiSettings: (
    { __typename?: 'FiSettings' }
    & FiSettingsFragment
  ) }
);

export type FiSettingsFragment = (
  { __typename?: 'FiSettings' }
  & Pick<Types.FiSettings, 'customerHelpCenterUrl' | 'faviconAdminUrl' | 'faviconUrl' | 'fullName' | 'mfmDownloadUrl' | 'mobileInterrupterDisplayPerDevice' | 'phoneNumber' | 'redirectToFiSiteAfterLogoff' | 'shortName' | 'siteHostUrl'>
  & { fiSupportedCultures: (
    { __typename?: 'FiSupportedCultures' }
    & { list: Array<Types.Maybe<(
      { __typename?: 'LocalizationCulture' }
      & Pick<Types.LocalizationCulture, 'code' | 'name'>
    )>> }
  ) }
);

export const FiSettingsFragmentDoc = gql`
    fragment FISettings on FiSettings {
  customerHelpCenterUrl
  faviconAdminUrl
  faviconUrl
  fullName
  mfmDownloadUrl
  mobileInterrupterDisplayPerDevice
  phoneNumber
  redirectToFiSiteAfterLogoff
  shortName
  siteHostUrl
  fiSupportedCultures {
    list {
      code
      name
    }
  }
}
    `;
export const GetFiSettingsDocument = gql`
    query GetFiSettings {
  fiSettings {
    ...FISettings
  }
}
    ${FiSettingsFragmentDoc}`;

/**
 * __useGetFiSettingsQuery__
 *
 * To run a query within a React component, call `useGetFiSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFiSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetFiSettingsQuery, GetFiSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFiSettingsQuery, GetFiSettingsQueryVariables>(GetFiSettingsDocument, options);
      }
export function useGetFiSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFiSettingsQuery, GetFiSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFiSettingsQuery, GetFiSettingsQueryVariables>(GetFiSettingsDocument, options);
        }
export type GetFiSettingsQueryHookResult = ReturnType<typeof useGetFiSettingsQuery>;
export type GetFiSettingsLazyQueryHookResult = ReturnType<typeof useGetFiSettingsLazyQuery>;
export type GetFiSettingsQueryResult = Apollo.QueryResult<GetFiSettingsQuery, GetFiSettingsQueryVariables>;