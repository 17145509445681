import { Observable } from '@app/common/utils/observable';
import { eventsToTrack } from './constants';

/**
 * @TODO Suppor activity detection when a user works in scope of iframes (SSO, etc)
 * It will help to save client device resources.
 */
export class UserActivityDetector extends Observable {
  private static instance: UserActivityDetector;

  /**
   * Private consructor to instantinate singleton instance.
   */
  private constructor() {
    super();

    const onUserActiveDebounced = (event: Event) => {
      this.setChanged();
      this.onUserActive(event);
    };

    eventsToTrack.forEach((eventToTrack) => {
      window.removeEventListener(eventToTrack, onUserActiveDebounced);
      window.addEventListener(eventToTrack, onUserActiveDebounced);
    });
  }

  /**
   * Returns the single class instance.
   */
  public static getInstance() {
    if (!UserActivityDetector.instance) {
      UserActivityDetector.instance = new UserActivityDetector();
    }

    return UserActivityDetector.instance;
  }

  private onUserActive(event: Event) {
    this.notifyObservers(event);
  }
}
