// todo: check if we can use isFrameHosted intead of apprach with session storage key
import { isTruthy } from '@app/common/utils/assertion';

import { getSessionValue, setSessionValue } from '@app/core/storage/browser/sessionStorage';

/**
 * Embedded mode session storage key.
 */
export const EMBEDDED_MODE_STORAGE_KEY = 'embedded';

/**
 * Set embedded mode.
 */
export const setEmbeddedMode = (): void => {
  setSessionValue(EMBEDDED_MODE_STORAGE_KEY, true.toString());
};

/**
 * Check if embedded mode is set.
 * @returns true is mode is set.
 */
export const isEmbeddedMode = (): boolean => isTruthy(getSessionValue(EMBEDDED_MODE_STORAGE_KEY));
