import {
  useEffect, DependencyList,
} from 'react';

/**
 * Hook fires callback when passed ref loses the focus into the iframe element (ONLY!).
 * Note: if you need track common lose focus, please use useFocusOut
 * @param ref reference to detect lose focus.
 * @param deps dependecy list to update callback handler values.
 */
// eslint-disable-next-line import/prefer-default-export
export function useIFrameFocusOut(
  handleFocusOut: () => void,
  deps?: DependencyList,
) {
  const handleBlur = () => {
    if (document.activeElement
        && document.activeElement.tagName === 'IFRAME') {
      handleFocusOut();
    }
  };

  useEffect(() => {
    // Bind the event listeners
    window.addEventListener('blur', handleBlur);

    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('blur', handleBlur);
    };
  }, deps);
}
