import React from 'react';

import { ConnectedWizardStep } from '@app/common/configurable-wizards';
import { DeliveryChannelTypesFieldLocator } from '@app/common/configurable-wizards/fields/DeliveryChannelTypes';
import { LayeredAuthenticationWizardStep } from '../../../../LayeredAuthenticationWizardStep';

export const ValidateOneTimePin: React.FC<ConnectedWizardStep> = ({
  locators,
  ...props
}) => {
  const stepLocators = [DeliveryChannelTypesFieldLocator, ...(locators || [])];
  return (
    <LayeredAuthenticationWizardStep {...props} locators={stepLocators} />
  );
};
