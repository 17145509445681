/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { gql } from '@apollo/client';
export type ThemeVariableFragment = (
  { __typename?: 'CssVariable' }
  & Pick<Types.CssVariable, 'name' | 'value'>
);

export const ThemeVariableFragmentDoc = gql`
    fragment ThemeVariable on CssVariable {
  name
  value
}
    `;