import { Migration, VersionedSerializer } from '@app/core/versionedSerializer/versionedSerializer';

import { joinStoragePath } from './utils';

/**
 * Abstract class to save\load data structures from\to storage.
 * All decendance must implement public data properties and
 * methods how to turn these properties into saved data and vise versa.
 * T - class instance to be serialized/deserialized. Should only contain basic type properties.
 */
export abstract class StorageDocument<T> {
  // Node key to store data in local storage.
  private node:string;

  // JSON serializer with version support.
  private serializer:VersionedSerializer<T>;

  private storage: Storage;

  /**
   * Class constrcutor.
   * @param node local storage node key to store data into.
   * @param version latest version of saved data.
   * @param migrations array of migrations to be applied to data saved in previous versions.
   * For example: current version is 5 and data saved in version 3. In this case
   * saved data must be passed to migrations 4 and 5 to convert data to latest version.
   */
  constructor(storage: Storage, node:string, version:number, migrations:Migration[] = []) {
    this.storage = storage;
    this.node = joinStoragePath(node);
    this.serializer = new VersionedSerializer<T>(version, migrations);
    this.load();
  }

  /**
   * Method to init class instance public properties with deserialized data.
   * @param deserialized deserialized data in latest version.
   */
  protected abstract deserialize(deserialized:T):void;

  /**
   * Method to convert public data properties into data structure which can be serialized into local storage.
   */
  protected abstract serialize():T;

  /**
   * Load serialized data from local storage and init class instance public properties.
   * Called once on class instance creation.
   */
  protected load():void {
    const saved = this.storage.getItem(this.node);
    if (saved) {
      const deserialized = this.serializer.deserialize(saved);
      this.deserialize(deserialized);
    }
  }

  /**
   * Convert public data properties to T class instance and serialize into local storage.
   * Must be called each time then public data property to be changed.
   */
  protected save() {
    this.storage.setItem(this.node, this.serializer.serialize(this.serialize()));
  }

  /**
   * Remove document from local storage.
   */
  public clear() {
    this.storage.removeItem(this.node);
  }

  /**
   * @returns deserialized (in raw JSON string format) data in latest version.
   */
  public getSerialized() {
    return this.storage.getItem(this.node);
  }
}
