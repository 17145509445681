import React, { useMemo } from 'react';

import { ContextProviderProps } from '@app/core/components/Application/ApplicationContexts';

import { useSessionStorageValue } from '../storage/browser/useStorageValue';
import { useIsLoggedIn } from './login/isLoggedIn';

import { TOKEN_STORAGE_KEY } from './authenticationToken';
import { SCSO_TOKEN_STORAGE_KEY } from './scsoToken';

/**
 * Describes the SCSO session context.
 * @prop isScso - set to true if SCSO mode is activated
 * @prop [adminLogin] - the login name of the admin who initiated the SCSO session
  */
export interface ScsoContextValue {
  readonly isScso: boolean;
  readonly adminLogin?: string;
}

export const ScsoContextPropsDefault: ScsoContextValue = {
  isScso: false,
};

export const ScsoContext = React.createContext<ScsoContextValue>(ScsoContextPropsDefault);

export const ScsoContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const isLoggedIn = useIsLoggedIn();
  const adminLogin = useSessionStorageValue('admin-login');
  const authToken = useSessionStorageValue(TOKEN_STORAGE_KEY);
  const scsoToken = useSessionStorageValue(SCSO_TOKEN_STORAGE_KEY);
  const scsoProps = useMemo(() => ({
    isScso: isLoggedIn && authToken === scsoToken,
    adminLogin: adminLogin ?? undefined,
  }), [isLoggedIn, authToken, scsoToken, adminLogin]);

  return (
    <ScsoContext.Provider value={scsoProps}>
      {children}
    </ScsoContext.Provider>
  );
};
