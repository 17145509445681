import { getSessionValue, setSessionValue, removeSessionValue } from '@app/core/storage/browser/sessionStorage';

import { generateUID } from './generateUID';

/**
 * DataSource session id key for browser session storage
 */
export const DS_SESSION_ID = 'ds-session-id';

export const setDSSessionId = (value: string) => {
  setSessionValue(DS_SESSION_ID, value);
};

/**
 * Get DataSource session id from session storage or generate new key
 * @returns DataSource session id
 */
export const getDSSessionId = (): string => {
  // Try to get value from session storage
  let result = getSessionValue(DS_SESSION_ID);

  // If value is not found - generate it and save to session storage
  if (!result) {
    result = generateUID();
    setDSSessionId(result);
  }

  return result;
};

export const removeDSSessionId = () => {
  removeSessionValue(DS_SESSION_ID);
};

export default {
  getDSSessionId,
  removeDSSessionId,
  DS_SESSION_ID,
};
