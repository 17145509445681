import React, { HTMLAttributes } from 'react';

import classNames from 'classnames';

import AngleDown from '@ast/magma/components/icon/icons/AngleDown';
import AngleUp from '@ast/magma/components/icon/icons/AngleUp';
import Empty from '@ast/magma/components/icon/icons/Empty';

import { AccordionItemProps } from '@ast/magma/components/accordion';
import { ListItem } from '@ast/magma/components/list/listitem/listitem';
import { TextItem } from '@ast/magma/components/list/textitem/textitem';

import ActionUtils from '@ast/magma/inner/utils/action';
import ArrayUtils from '@ast/magma/inner/utils/arrayutils';

import { MenuItem } from '@app/common/types/useMainMenuQuery';
import { getMenuIcon } from '@app/common/utils/iconsProvider';

import { useSelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';
import { useTranslation } from '@app/core/i18n';

import styles from './mobilemenuitem.pcss';

export const MobileMenuItemRenderer = ({
  data,
  itemProps,
  accordionProps,
}:AccordionItemProps,
handleItemSelect:() => void,
handleKeyDown: (event:React.KeyboardEvent<HTMLElement>) => void,
attributes:HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation('footer');
  const selectedMainMenuItem = useSelectedMainMenuItemContext();
  const interactionHandlers = (!accordionProps.disabled)
    ? ActionUtils.handleSelect(handleItemSelect) : {};

  const hasChildren = accordionProps.childField != null
    && ArrayUtils.notNullOrEmpty(data[accordionProps.childField]);

  const isSelected = !!data.url && selectedMainMenuItem?.url === data.url;

  if (data?.menuItems?.filter((item: MenuItem) => item.isVisible).length === 0) {
    return <div className={styles.empty}>{t('mobile.main.menu.item.empty-line|Label for empty menu item in mobile view', 'Empty Item')}</div>;
  }

  return (
    <div
      className={classNames(
        styles.container,
        !data.isVisible && styles.itemHidden,
        itemProps.itemLevel < 2 && styles.divider,
      )}
    >
      <ListItem
            // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
        tag="span"
        className={classNames(
          styles.listItem,
          (itemProps.itemLevel === 0) && styles.groupItem,
        )}
        aria-selected={isSelected}
            // eslint-disable-next-line react/jsx-props-no-spreading
        {...interactionHandlers}
        data-stable-name={`MobileMenuItem-${data?.id}`}
        onKeyDown={handleKeyDown}
      >
        <TextItem
          className={classNames(styles.textItem)}
          getItemIcon={(item) => (
            // eslint-disable-next-line no-nested-ternary
            item.icon
              ? getMenuIcon(item.icon)
              : itemProps.itemLevel === 0
                ? undefined : <Empty />)}
          getItemLabel={(item) => item.linkName}
          data={data}
        />
        {
            // eslint-disable-next-line no-nested-ternary
            hasChildren
              ? React.cloneElement(itemProps.itemExpanded ? <AngleUp /> : <AngleDown />, { className: styles.arrow })
              : undefined
          }
      </ListItem>
    </div>
  );
};
