import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isSetLocationRequestMessage } from './assertions/isSetLocationRequestMessage';

import { SET_LOCATION_REQUEST_MESSAGE_TYPE } from './constants';
import { SetLocationResponseMessage } from './types';

/**
 * Service to handle URL-related AgileSDK requests.
 *
 * Allows setting the location of the main window to redirect the user.
 */
export class URLAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      SET_LOCATION_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.sender = sender;
  }

  async handle(request: AgileSDKRequest) {
    const { message, sender } = request;

    if (!isSetLocationRequestMessage(message)) {
      return false;
    }

    // Send the response to the request before redirecting the user.
    const response: SetLocationResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {},
    };

    this.sender(response, sender);

    const { href } = message.payload;

    window.location.href = href;

    return true;
  }
}
