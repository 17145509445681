import { SET_VIEWPORT_SIZE_REQUEST_MESSAGE_TYPE } from '../constants';
import { SetViewportSizeRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

import { isViewportSize } from './isViewportSize';

/**
 * Checks if the value is a {@link SetViewportSizeRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetViewportRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SetViewportSizeRequestMessage {
  return (
    value.messageType === SET_VIEWPORT_SIZE_REQUEST_MESSAGE_TYPE

    && isViewportSize(value.payload)
  );
}
