import { client } from '../apolloClient';
import { SessionHeartbeatDocument, SessionHeartbeatMutation } from './queryTyping/sessionHeartbeat';
import { useHeartbeat } from './useHeartbeat';
import { isSessionHeartbeatApplicable } from './utils';

function callSessionHeartbeatMutation() {
  client.mutate<SessionHeartbeatMutation>({
    mutation: SessionHeartbeatDocument,
    fetchPolicy: 'no-cache',
  });
}

export const useUserSessionHeartbeat = (sessionExpirationInSecs: number | undefined) => {
  useHeartbeat(isSessionHeartbeatApplicable, callSessionHeartbeatMutation, sessionExpirationInSecs);
};
