/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type NewUserMessageNotificationSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NewUserMessageNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { newUserMessageNotification: (
    { __typename?: 'NewUserMessageNotification' }
    & Pick<Types.NewUserMessageNotification, 'totalCount' | 'alertsCount' | 'messagesCount'>
  ) }
);


export const NewUserMessageNotificationDocument = gql`
    subscription newUserMessageNotification {
  newUserMessageNotification {
    totalCount
    alertsCount
    messagesCount
  }
}
    `;

/**
 * __useNewUserMessageNotificationSubscription__
 *
 * To run a query within a React component, call `useNewUserMessageNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewUserMessageNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewUserMessageNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewUserMessageNotificationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NewUserMessageNotificationSubscription, NewUserMessageNotificationSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NewUserMessageNotificationSubscription, NewUserMessageNotificationSubscriptionVariables>(NewUserMessageNotificationDocument, options);
      }
export type NewUserMessageNotificationSubscriptionHookResult = ReturnType<typeof useNewUserMessageNotificationSubscription>;
export type NewUserMessageNotificationSubscriptionResult = Apollo.SubscriptionResult<NewUserMessageNotificationSubscription>;