import React from 'react';
import { FieldInfoGroupFieldFragment as FieldInfoGroupField } from '../queries/queryTyping/all-fields';
import { FieldComponent, FieldLocator, Field } from '../types';
import { combineNames, isFieldInfoGroupField } from '../utils';

export const FieldInfoGroupComponent: FieldComponent<FieldInfoGroupField> = ({
  parent,
  field,
  locator,
}) => {
  return (
    <>
      {field.subFields.map((item, index) => {
        const subField = item as Field;
        const Component = locator(subField);
        return (
          <Component
            parent={combineNames(parent, field.id)}
            field={subField}
            locator={locator}
            
            // Datasource may returns duplicate ids, in order to guarantee uniqueness we use concatenation for the key
            key={subField.id + index}
          />
        );
      })}
    </>
  );
};

export const FieldInfoGroupLocator: FieldLocator = (context) =>
  isFieldInfoGroupField(context.field) ? FieldInfoGroupComponent : undefined;
