import { combineNames, uncombineNames } from './combineNames';

/**
 * Finds value at the deep node based on field name
 * @example
 * // returns 'VALUE'
 * const = fieldName = 'parentName.childName';
 * const = fieldsMap = { parentName: { childName: 'VALUE' } };
 * findFieldNode(fieldName, fieldsMap)
 */
export const findFieldNode = <T = any>(fieldName: string, map: Record<string, any>): T | null => {
  const nameParts = uncombineNames(fieldName);

  const name = nameParts.shift() || '';
  const node = map[name];

  // node is not exist
  if (!node) {
    return null;
  }

  // combined name. Recursively go for the nested level
  if (nameParts.length > 0) {
    const combainedName = combineNames(...nameParts);
    return findFieldNode(combainedName, node);
  }

  // node was found
  return node;
};
