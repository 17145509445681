import React from 'react';

import { ConnectedWizardStep } from '@app/common/configurable-wizards';
import { DeliveryAddressIdFieldLocator } from '@app/common/configurable-wizards/fields/DeliveryAddressId';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { useTriggeredEventsTranslation } from '@app/core/components/TriggeredEvents/useTriggeredEventsTranslation';

import { LayeredAuthenticationWizardButtons } from '../../../../LayeredAuthenticationWizardButtons';
import { LayeredAuthenticationWizardStep } from '../../../../LayeredAuthenticationWizardStep';

export const SelectOneTimePinAddress: React.FC<ConnectedWizardStep> = ({
  locators,
  ...props
}) => {
  const stepLocators = [DeliveryAddressIdFieldLocator, ...(locators || [])];
  const { t } = useTriggeredEventsTranslation();
  const { allowBackRedirect } = useWizardStepStateContext();

  return (
    <LayeredAuthenticationWizardStep
      {...props}
      locators={stepLocators}
      buttons={({ cancel, back }) => (
        <LayeredAuthenticationWizardButtons
          showBackButton={allowBackRedirect}
          back={back}
          cancel={cancel}
          continueTitle={t(
            'layered-authentication.challenges.otp.select-address-step.buttons.submit.text|Layered authentication OTP challenge - "Select OTP Address" continue-button text',
            'Continue',
          )}
        />
      )}
    />
  );
};
