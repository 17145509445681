import React, {
  FC,
  useEffect,
  useState,
  CSSProperties,
} from 'react';
import styles from './indicator.pcss';

export interface IndicatorProps {
  /**
   * Offset from left side
   */
  readonly left: number;

  /**
   * Width of indicator, to fit width of tab button
   */
  readonly width: number
}

export const Indicator: FC<IndicatorProps> = (props) => {
  const [inlineStyles, setInlineStyles] = useState<CSSProperties>({
    left: 0,
    width: 0,
  });

  const {
    left,
    width,
  } = props;

  useEffect(() => {
    setInlineStyles({
      left,
      width,
    });
  }, [left, width]);

  return (
    <div style={inlineStyles} className={styles.tabIndicator} />
  );
};

Indicator.defaultProps = {
  left: 0,
  width: 0,
};
