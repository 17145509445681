/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetAdvertisementQueryVariables = Types.Exact<{
  providerId: Types.Scalars['String'];
}>;


export type GetAdvertisementQuery = (
  { __typename?: 'Query' }
  & { advertisement: (
    { __typename?: 'AdvertisementResult' }
    & Pick<Types.AdvertisementResult, 'html' | 'id'>
  ) }
);


export const GetAdvertisementDocument = gql`
    query GetAdvertisement($providerId: String!) {
  advertisement(providerId: $providerId) {
    html
    id
  }
}
    `;

/**
 * __useGetAdvertisementQuery__
 *
 * To run a query within a React component, call `useGetAdvertisementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertisementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertisementQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetAdvertisementQuery(baseOptions: Apollo.QueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
      }
export function useGetAdvertisementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
        }
export type GetAdvertisementQueryHookResult = ReturnType<typeof useGetAdvertisementQuery>;
export type GetAdvertisementLazyQueryHookResult = ReturnType<typeof useGetAdvertisementLazyQuery>;
export type GetAdvertisementQueryResult = Apollo.QueryResult<GetAdvertisementQuery, GetAdvertisementQueryVariables>;