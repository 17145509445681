import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';

import { hasReactNode } from '@app/common/components/AccountCard/AccountCard';
import { Heading, HeadingLevel } from '@app/common/components/Heading/Heading';

import styles from './SectionHeader.pcss';

export interface SectionHeaderProps extends HTMLAttributes<HTMLDivElement> {
  readonly after?: ReactNode;
  readonly before?: ReactNode;
  readonly className?: string;
  readonly titleId?: string;
  readonly contrastColor?: boolean;
  readonly headingLevel?: HeadingLevel;
}

export const SectionHeader: FunctionComponent<SectionHeaderProps> = ({
  children,
  after,
  before,
  className,
  titleId,
  headingLevel,
  contrastColor = true,
  ...divProps
}: SectionHeaderProps) => {
  const hasAfter = hasReactNode(after);
  const hasBefore = hasReactNode(before);
  const hasTitle = hasReactNode(children);
  return (
    <header
      className={classNames(styles.header, className, contrastColor && styles.contrastColor)}
      {...divProps}
      aria-labelledby={titleId}
    >
      <div className={classNames(styles.before)}>
        {hasBefore && before}
      </div>
      <Heading
        headingLevel={headingLevel ?? HeadingLevel.H1}
        className={classNames(styles.title)}
        id={titleId}
      >
        <span className={classNames(styles.titleWrapper)}>
          { hasTitle && children }
        </span>
      </Heading>
      <div className={classNames(styles.after)}>
        {hasAfter && after}
      </div>
    </header>
  );
};
