/** Possible server validation error types  */
export enum ServerErrorTypes {
  UserFriendly = 'UserFriendly',
  InvalidFormat = 'InvalidFormat',
}
export interface UnifiedServerError {
  readonly message: string;
  readonly path?: string;
}

export interface FieldServerError {
  readonly fieldId: string;
  readonly message: string;
}

export interface GenericServerError {
  readonly message: string;
}

export type FieldServerErrors = readonly FieldServerError[];
export type GenericServerErrors = readonly GenericServerError[];

export type ServerErrors = {
  genericErrors: Array<GenericServerError> | null,
  fieldErrors: Array<FieldServerError> | null,
};
