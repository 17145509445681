import { useEffect, useState } from 'react';

import { isReCaptchaError } from '@app/core/components/ReCaptcha/reCaptchaApolloLink';
import { ReCaptchaGraphQLErrorExtension } from '@app/core/components/ReCaptcha/types';

import { ReCaptchaVersionCodes } from '@app/queryTyping';

export const useReCaptchaValidationException = () => {
  const [exception, setException] = useState<{
    message: string;
    version: ReCaptchaVersionCodes
  }>();

  useEffect(() => {
    const listener = (msg: MessageEvent) => {
      if (isReCaptchaError(msg.data)) {
        setException({
          message: msg.data.message,
          version: (msg.data.extensions as ReCaptchaGraphQLErrorExtension).versionCode,
        });
      }
    };

    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return exception;
};
