/* eslint-disable class-methods-use-this */

/**
 * Basic Agile SDK service that only can be initialized and disposed.
 */
export abstract class AgileSDKService {
  /**
   * Service initialization method.
   *
   * Called once when the service is registered.
   */
  init(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Service disposal method.
   *
   * Called once when the service is unregistered.
   */
  dispose(): Promise<void> {
    return Promise.resolve();
  }
}
