/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { ValidationUnion_UserFriendly_Fragment, ValidationUnion_InvalidFormat_Fragment } from '../../../queries/queryTyping/validation-unions';
import { gql } from '@apollo/client';
import { ValidationUnionFragmentDoc } from '../../../queries/queryTyping/validation-unions';
export type WizardNextStepFragment = (
  { __typename: 'NextStep' }
  & Pick<Types.NextStep, 'wizardFinished' | 'nextStepId' | 'successSummary'>
  & { errors: Array<Types.Maybe<(
    { __typename?: 'UserFriendly' }
    & ValidationUnion_UserFriendly_Fragment
  ) | (
    { __typename?: 'InvalidFormat' }
    & ValidationUnion_InvalidFormat_Fragment
  )>> }
);

export const WizardNextStepFragmentDoc = gql`
    fragment WizardNextStep on NextStep {
  __typename
  wizardFinished
  nextStepId
  successSummary
  errors {
    ...ValidationUnion
  }
}
    ${ValidationUnionFragmentDoc}`;