import { appSettings } from '@app/core/appSettings';
import join from 'url-join';

/**
 * Return absolute URL including endpoing path.
 * @param relativeURL relative url.
 * @returns absolute url.
 */
export const getAbsoluteURL = (relativeURL:string):string => (join(appSettings().apiEndpoint, relativeURL));

/**
 * Return true if url is absolute.
 * @param url url.
 * @returns true if url is absolute.
 */
export const isAbsoluteURL = (url:string):boolean => (/^(?:[a-z]+:)?\/\//.test(url));

/**
 * Return true if url is relative.
 * @param url url.
 * @returns true if url is relative.
 */
export const isRelativeURL = (path:string):boolean => (/^\//.test(path));
