import { ApolloError, isApolloError } from '@apollo/client';
import { ErrorCodes, MovePreviousResult, NextStep } from '@app/queryTyping';
import { CurrentWizardStepFragment } from '../queries/queryTyping/current-wizard-step';

/**
 * Returns true if it's a wizard-step inconsistency error
 */
export function isWizardStepsInconsistencyError(error: Error): error is ApolloError {
  if (isApolloError(error)) {
    const { graphQLErrors } = error;
    return graphQLErrors.some(
      (graphQLError) => graphQLError.extensions?.code === ErrorCodes.WIZARD_STEPS_INCONSISTENCY,
    );
  }

  return false;
}

/**
 * Returns true if it's a wizard incorrect configuration error
 */
export function isWizardIncorrectConfigurationError(error: Error): error is ApolloError {
  if (isApolloError(error)) {
    const { graphQLErrors } = error;
    return graphQLErrors.some(
      (graphQLError) => graphQLError.extensions?.code === ErrorCodes.WIZARD_INCORRECT_CONFIGURATION,
    );
  }

  return false;
}

/**
 * Return true if an object has validation-union errors
 */
export const isHasErrors = ({ errors }: any) => (
  // eslint-disable-next-line no-underscore-dangle
  Array.isArray(errors) && errors.length
);

/**
 * Wizard next step type guard.
 */
export const isNextStep = (step: any): step is NextStep => (
  // eslint-disable-next-line no-underscore-dangle
  step.__typename === 'NextStep'
);

/**
 * Returns true if it's a CurrentWizardStep
 */
export function isCurrentWizardStep(data: any): data is CurrentWizardStepFragment {
  // eslint-disable-next-line no-underscore-dangle
  return data?.__typename === 'CurrentWizardStep';
}

/**
 * Returns true if it's a MovePreviousResult
 */
export function isMovePreviousResult(data: any): data is MovePreviousResult {
  // eslint-disable-next-line no-underscore-dangle
  return data?.__typename === 'MovePreviousResult';
}
