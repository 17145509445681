import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@ast/magma/components/checkbox';

import { FieldBehaviorMode } from '@app/queryTyping';

import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import {
  FieldComponent,
  FieldLocator,
} from '../types';
import {
  combineNames,
  getFieldErrorMessage,
  isBooleanField,
} from '../utils';

import { BooleanFieldFragment } from '../queries/queryTyping/all-fields';
import { CFFormField } from './common/CFFormField';

export const CheckboxFieldComponent: FieldComponent<BooleanFieldFragment> = ({
  parent,
  field,
}) => {
  const { register, formState: { errors } } = useFormContext();
  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  return (
    <CFFormField error={error}>
      <Checkbox
        aria-invalid={!!error}
        label={field.label || ''}
        data-stable-name={`${field.id}Checkbox`}
        defaultChecked={field.bValue}
        readOnly={field.fieldBehaviorMode === FieldBehaviorMode.READONLY}
        {...register(name)}
      />
    </CFFormField>
  );
};

export const isCheckboxField = (field: any): field is BooleanFieldFragment => (
  isBooleanField(field)
);

export const CheckboxFieldLocator: FieldLocator = ({ field }) => (
  isCheckboxField(field)
    ? CheckboxFieldComponent
    : undefined
);
