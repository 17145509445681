/**
 * In-memory storage interface.
 *
 * Can be used for dependency injection.
 */
export interface IInMemoryStorage extends Storage {}

/**
 * In-memory storage implementation.
 *
 * Can be used to store short-lived data in memory.
 */
export class InMemoryStorage implements IInMemoryStorage {
  private storage: Map<string, string> = new Map();

  get length(): number {
    return this.storage.size;
  }

  clear(): void {
    this.storage.clear();
  }

  setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }

  getItem(key: string): string | null {
    return this.storage.get(key) ?? null;
  }

  removeItem(key: string): void {
    this.storage.delete(key);
  }

  key(index: number): string | null {
    const keys = Array.from(this.storage.keys());

    return keys[index] ?? null;
  }
}
