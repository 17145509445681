import React, { useReducer, useEffect } from 'react';

import { createGenericContext } from '@app/common/contexts/createGenericContext';

import { ContextProviderProps } from '@app/core/components/Application/ApplicationContexts';
import {
  useNewUserMessageNotificationSubscription,
} from '@app/core/widgets/queries/queryTyping/newUserMessageNotificationSubscription';

import { useIsLoggedIn } from '../authentication/login/isLoggedIn';
import { asyncNotificationsReducer, AsyncNotification, initialState } from './utils/messagesReducer';

/**
 * Context which subscribe to new messages.
 */
const [
  useMessagesCountContext,
  MessagesCountContextProvider,
] = createGenericContext<AsyncNotification>();

/**
 * Messages and Alerts Provider Wrapper Component.
 */
const MessagesCountProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const isLoggedIn = useIsLoggedIn();
  const { data, error } = useNewUserMessageNotificationSubscription({
    skip: !isLoggedIn,
  });
  const [state, dispatch] = useReducer(asyncNotificationsReducer, initialState);

  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      payload: {
        TotalUnreadMessages: data?.newUserMessageNotification?.totalCount ?? 0,
        Messages: data?.newUserMessageNotification?.messagesCount ?? 0,
        Alerts: data?.newUserMessageNotification?.alertsCount ?? 0,
      },
    });
  }, [data]);

  if (error) {
    throw error;
  }

  return (
    <MessagesCountContextProvider value={state}>
      {children}
    </MessagesCountContextProvider>
  );
};

export { useMessagesCountContext, MessagesCountProvider };
