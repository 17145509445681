/* eslint-disable i18next/no-literal-string */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch } from 'react-router-dom';

import { BreakpointProvider } from '@ast/magma/breakpoints';

import { ToastContainer } from '@ast/magma/components/toast';

import { ApolloProvider } from '@apollo/client';

import { UserError, GenericErrorBoundary } from '@app/common/errors';

import { Application } from '@app/core/components/Application/Application';
import { LocalizationProvider } from '@app/core/contexts/localizationContext/LocalizationContext';

import { OverlaySpinner } from '../common/components/overlayspinner';
import { ApplicationContexts } from './components/Application/ApplicationContexts';
import { ErrorPage } from './components/ErrorPage/ErrorPage';
import { TetheredLoginFormApplication } from './components/TetheredLoginApplication/TetheredLoginApplication';
import { TriggeredEventWrapper } from './components/TriggeredEvents/TriggeredEventWrapper';
import { FISettingsProvider } from './contexts/fiSettings/FISettingsContext';
import { SessionSettingsProvider } from './contexts/sessionSettings/SessionSettingsContext';

import { client } from './apolloClient';
import { history } from './browserHistory';

const getContainer = () => document.getElementById('app');

/**
 * Create error page fallback method with
 * error dialog contaning error message and
 * common footer with support URL or simple static text footer.
 * @param error user facing error.
 */
const createErrorPageFallback = (staticFooter: boolean) => (error: UserError) => (
  <ErrorPage
    error={error}
    staticFooter={staticFooter}
  />
);

const Base: React.FC = ({ children }) => (
  <ApolloProvider client={client}>
    <BreakpointProvider>
      <Router history={history}>
        <Switch>
          { /* This error boundary intercepts FI settings provider errors and
            shows generic error page with static error message. */ }
          <GenericErrorBoundary fallback={createErrorPageFallback(true)}>
            <FISettingsProvider>
              <LocalizationProvider>
                { /* This error boundary intercepts all unhandled errors from application
                  shows generic error page with error message and links to FI support. */ }
                <GenericErrorBoundary fallback={createErrorPageFallback(true)}>
                  <React.Suspense fallback={<OverlaySpinner visible size="md" />}>
                    <SessionSettingsProvider>
                      <ToastContainer>
                        {children}
                      </ToastContainer>
                    </SessionSettingsProvider>
                  </React.Suspense>
                </GenericErrorBoundary>
              </LocalizationProvider>
            </FISettingsProvider>
          </GenericErrorBoundary>
        </Switch>
      </Router>
    </BreakpointProvider>
  </ApolloProvider>
);

/**
 * Render application root components.
 */
export const bootstrapApplication = () => {
  ReactDOM.render(
    <Base>
      <ApplicationContexts>
        <TriggeredEventWrapper>
          <Application />
        </TriggeredEventWrapper>
      </ApplicationContexts>
    </Base>,
    getContainer(),
  );
};

/**
 * Render tethered-login application root components.
 */
export const bootstrapTetheredLoginApplication = () => {
  ReactDOM.render(
    <Base>
      <TetheredLoginFormApplication />
    </Base>,
    getContainer(),
  );
};
