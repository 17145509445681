import React, {
  useEffect,
  useState,
  useRef,
  CSSProperties,
  useContext,
  useCallback,
} from 'react';

import classNames from 'classnames';

import { Badge } from '@ast/magma/components/badge';

import ActionUtils from '@ast/magma/inner/utils/action';
import useForwardedRef from '@ast/magma/utils/useForwardedRef';

import { ContextUse, Context } from '../context';
import { Indicator } from '../indicator';
import { TabPropsExt } from '../tab';

import styles from './nav.pcss';

const keyCodes: { [key: string]: number } = {
  ArrowLeft: -1,
  ArrowRight: 1,
};

export interface NavigationProps {
  onFocusContent: () => void;
}

/**
 * Generate tab navigation
 */
export default React.forwardRef((props:NavigationProps, tabListRef: React.Ref<HTMLDivElement>) => {
  const context = ContextUse(useContext(Context));
  const ref = useForwardedRef<HTMLDivElement>(tabListRef);
  const [indicatorStyle, setIndicatorStyle] = useState({
    left: 0,
    width: 0,
  });

  const buttonStyle: CSSProperties = {};
  if (context.tabWidth) {
    buttonStyle.width = context.tabWidth;
  }

  const focused = useRef<number|null>(null);
  if (typeof context.current === 'number') {
    focused.current = context.current;
  }

  const btnsRefs = useRef<HTMLElement[]>([]);

  const scrollIntoViewIfNeed = (element: HTMLElement): void => {
    const $wrapper = ref.current;

    if (!$wrapper) {
      return;
    }

    /**
     * Case, when tab button is outside of $wrapper' RIGHT edge
     * or not fully visible.
     */
    const outsideRightEdge = (element.offsetLeft + element.offsetWidth) - $wrapper.offsetLeft
      > ($wrapper.offsetWidth + $wrapper.scrollLeft);

    /**
     * Case when tab button is outside of $wrapper' LEFT edge
     * or not fully visible.
     */
    const outsideLeftEdge = element.offsetLeft < $wrapper.scrollLeft;

    let scrollLeftValue: number | null = null;

    if (outsideRightEdge) {
      scrollLeftValue = (
        element.offsetLeft - $wrapper.offsetWidth + element.offsetWidth
      );
    } else if (outsideLeftEdge) {
      scrollLeftValue = element.offsetLeft;
    }

    if (scrollLeftValue) {
      setTimeout(() => {
        // needs timeout for initial rendering and because of css prop scroll-behavior: smooth
        $wrapper.scroll((scrollLeftValue as number), 0);
      });
    }
  };

  const getDirectionProp = (currentTabIndex: number, nextTabIndex: number) => (
    currentTabIndex > nextTabIndex ? 'previousSibling' : 'nextSibling'
  );

  const keyDownHandler = useCallback((event: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (event.isDefaultPrevented()) return;

    const keyCode: string = event.key;
    const target: HTMLElement = event.target as HTMLElement;

    const currentTabIndex = parseInt(target.getAttribute('data-index') as string, 10);

    if (keyCodes[keyCode]) {
      const nextTabIndex = currentTabIndex + keyCodes[keyCode];
      const nextElementDirection = getDirectionProp(currentTabIndex, nextTabIndex);

      if (target[nextElementDirection]) {
        const nextEl = target[nextElementDirection] as HTMLElement;
        nextEl.focus();
        event.preventDefault();
      }
    } else if (keyCode === 'Escape') {
      if (context.current !== undefined) {
        context.updateCurrent(undefined);
      }
    } else if (keyCode === 'ArrowDown') {
      event.preventDefault();
      if (context.current !== currentTabIndex) {
        context.updateCurrent(currentTabIndex);
      } else {
        props.onFocusContent();
      }
    } else {
      ActionUtils.handleKeyboardSelect(() => {
        event.preventDefault();
        context.updateCurrent(currentTabIndex);
      })(event);
    }
  }, [context, props]);

  const initView = () => {
    const $btnsRefs = btnsRefs.current;
    const $current = context.current;

    if ($btnsRefs.length > 0) {
      if (typeof $current !== 'number') {
        setIndicatorStyle({ left: 0, width: 0 });

        const contentRef = context.isContentInFocus();

        if (typeof focused.current === 'number' && $btnsRefs[focused.current] && contentRef) {
          scrollIntoViewIfNeed($btnsRefs[focused.current]);
          $btnsRefs[focused.current].focus();
        }
      } else if (typeof $current === 'number' && $btnsRefs[$current]) {
        scrollIntoViewIfNeed($btnsRefs[$current]);
        setIndicatorStyle({
          left: $btnsRefs[$current].offsetLeft,
          width: $btnsRefs[$current].offsetWidth,
        });
      }
    }
  };

  useEffect(() => {
    if (btnsRefs.current.length === 0) {
      btnsRefs.current = [...(ref.current?.querySelectorAll('button') || [])];
      if (btnsRefs.current.length > 0) {
        initView();
      }
    }
  });

  useEffect(initView, [context.current]);

  return (
    context.tabs.current.length > 0
      ? (
        <div role="menubar" ref={ref} className={styles.tabList} data-stable-name="MenuBar">
          <div
            className={classNames(
              styles.tabContainer,
              (context.current !== undefined) && styles.selectedTabContainer,
            )}
          >
            { context.tabs.current.map((tab: TabPropsExt, idx: number) => (
              <button
                role="menuitem"
                type="button"
                className={classNames(
                  styles.tabListButton,
                  context.current === idx && styles.tabListButtonActive,
                  context.tabWidth && styles.tabListButtonWidth,
                  tab.tabBtnClassName,
                )}
                style={buttonStyle}
                key={tab.tabID}
                data-index={idx}
                aria-expanded={context.current === idx}
                aria-haspopup="menu"
                aria-controls={tab.tabPanelID}
                tabIndex={(context.current === idx || typeof context.current === 'undefined') ? 0 : -1}
                id={tab.tabID}
                onClick={() => context.updateCurrent(idx)}
                onKeyDown={keyDownHandler}
                data-stable-name={`MainMenuCategory-${tab.label?.replace(/\s+/g, '')}`}
              >
                { tab.iconBefore }
                <span className={styles.tabListButtonLabel}>
                  { tab.label }
                </span>
                { tab.counter
                  ? (
                    <Badge
                      color={context.current === idx ? 'primary' : 'default'}
                      max={9}
                      count={tab.counter.counter}
                      aria-label={tab.counter['aria-label']}
                      className={styles.tabButtonBadge}
                    />
                  )
                  : tab.iconAfter }
              </button>
            )) }
            <Indicator {...indicatorStyle} />
          </div>
        </div>
      )
      : null
  );
});
