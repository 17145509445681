/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { WizardMovePreviousResultFragment } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-move-previous-result';
import { gql } from '@apollo/client';
import { WizardMovePreviousResultFragmentDoc } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-move-previous-result';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type MovePreviousTriggeredEventStepMutationVariables = Types.Exact<{
  stepParams: Types.MovePreviousTriggeredEventStepParams;
}>;


export type MovePreviousTriggeredEventStepMutation = (
  { __typename?: 'Mutation' }
  & { movePreviousTriggeredEventStep: (
    { __typename?: 'MovePreviousResult' }
    & WizardMovePreviousResultFragment
  ) }
);


export const MovePreviousTriggeredEventStepDocument = gql`
    mutation MovePreviousTriggeredEventStep($stepParams: MovePreviousTriggeredEventStepParams!) {
  movePreviousTriggeredEventStep(
    movePreviousTriggeredEventStepParams: $stepParams
  ) {
    ...WizardMovePreviousResult
  }
}
    ${WizardMovePreviousResultFragmentDoc}`;
export type MovePreviousTriggeredEventStepMutationFn = Apollo.MutationFunction<MovePreviousTriggeredEventStepMutation, MovePreviousTriggeredEventStepMutationVariables>;

/**
 * __useMovePreviousTriggeredEventStepMutation__
 *
 * To run a mutation, you first call `useMovePreviousTriggeredEventStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovePreviousTriggeredEventStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movePreviousTriggeredEventStepMutation, { data, loading, error }] = useMovePreviousTriggeredEventStepMutation({
 *   variables: {
 *      stepParams: // value for 'stepParams'
 *   },
 * });
 */
export function useMovePreviousTriggeredEventStepMutation(baseOptions?: Apollo.MutationHookOptions<MovePreviousTriggeredEventStepMutation, MovePreviousTriggeredEventStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MovePreviousTriggeredEventStepMutation, MovePreviousTriggeredEventStepMutationVariables>(MovePreviousTriggeredEventStepDocument, options);
      }
export type MovePreviousTriggeredEventStepMutationHookResult = ReturnType<typeof useMovePreviousTriggeredEventStepMutation>;
export type MovePreviousTriggeredEventStepMutationResult = Apollo.MutationResult<MovePreviousTriggeredEventStepMutation>;
export type MovePreviousTriggeredEventStepMutationOptions = Apollo.BaseMutationOptions<MovePreviousTriggeredEventStepMutation, MovePreviousTriggeredEventStepMutationVariables>;