import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { makeVar, useReactiveVar } from '@apollo/client';

import { useGetV3DeepLinkMappingQuery } from '@app/common/queries/queryTyping/getV3DeepLinkMapping';

import { getAuthenticationToken } from '@app/core/authentication/authenticationToken';
import { isLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import { setScsoToken } from '@app/core/authentication/scsoToken';
import {
  ApplicationLayout,
  extractPagePath,
  getPageSettings,
  isAnonymousPage,
  PageURL,
} from '@app/core/widgets/pages';

export type HistoryState = { from: string, to: string };

const pagePathState = makeVar<{layoutId: ApplicationLayout, pageId: string | null }>({
  layoutId: 'anonymous',
  pageId: null,
});

export const usePagePath = () => useReactiveVar(pagePathState);

const DEFAULT_PAGE_PATH = PageURL.BANK_ACCOUNT;
const LOGIN_PAGE_PATH = PageURL.USER_LOGIN;

/**
 * Validate page id and return valid one.
 * @param pagePath initial page id.
 * @param loginPagePath login page id used for non authenticated users.
 * @param defaultPagePath default page id used if there is no page id.
 * @returns an object that consists of a layoit id and a valid page id.
 */
const validatePagePath = (
  pathName: string,
  pagePath: string,
  loginPagePath: string,
  defaultPagePath: string,
// eslint-disable-next-line no-nested-ternary
) => {
  const result = {
    pagePath: '',
    isScso: false,
  };
  // Continue with current page if it's anonymous and user is not authenticated
  if (!isLoggedIn()) {
    result.pagePath = isAnonymousPage(pagePath as PageURL) ? pagePath : loginPagePath;
    return result;
  }

  // Redirect to default if try to move on login or empty or SCSO
  if ((pagePath === loginPagePath) || (pagePath === '/') || (pagePath === '/scso')) {
    result.pagePath = defaultPagePath;
    result.isScso = pagePath === '/scso';
    return result;
  }

  result.pagePath = pagePath;
  return result;
};

/**
 * Resolve page path to get application layout and page ids.
 * @returns layout, page ids, SCSO request flag.
 */
export const useProccessPagePath = () => {
  const location = useLocation();
  const history = useHistory();
  // eslint-disable-next-line i18next/no-literal-string
  const { pageId, layoutId } = usePagePath();
  const [error, setError] = useState<Error | null>(null);
  const [toRedirect, setToRedirect] = useState<string | null>(null);
  const { data: v3DeepLinkData } = useGetV3DeepLinkMappingQuery({
    variables: {
    },
  });
  const { pathname, hash, search } = history.location;
  const currentPath = `${pathname}${search ?? ''}${hash ?? ''}`;

  useEffect(() => {
    if (!v3DeepLinkData?.v3DeepLinkMapping) {
      return;
    }

    const newPath = v3DeepLinkData
      ?.v3DeepLinkMapping
      ?.find((link) => link?.key === currentPath)?.value;

    const extractedPagePath = extractPagePath(location.pathname);
    const { pagePath: validPagePath, isScso } = validatePagePath(
      location.pathname, extractedPagePath, LOGIN_PAGE_PATH, DEFAULT_PAGE_PATH,
    );

    /**
     * The SCSO mode detection is path based. When we detect an SCSO request we save the current auth token
     * to the SessionStorage. The SCSO mode will last while the auth token in the LocalStorage
     * is equal to that SCSO token.
     */
    if (isScso) {
      setScsoToken(getAuthenticationToken() ?? '');
    }

    if (newPath || extractedPagePath !== validPagePath) {
      const historyState = {} as { to?: string, from: string };

      if (!isLoggedIn() && extractedPagePath !== '/') {
        Object.assign(historyState, { from: currentPath });
      }

      if (newPath) {
        Object.assign(historyState, { to: newPath });
      }

      if (historyState?.to && isLoggedIn()) {
        setToRedirect(historyState?.to);
        return;
      }

      history.replace(historyState?.to ?? validPagePath, historyState);
    } else {
      try {
        const { layout: newLayout, pageId: newPageId } = getPageSettings(validPagePath);
        if (newLayout !== layoutId) {
          pagePathState({ ...pagePathState(), layoutId: newLayout });
        }
        if (newPageId !== pageId) {
          pagePathState({ ...pagePathState(), pageId: newPageId });
        }
      } catch (newError:any) {
        setError(newError);
      }
    }
  }, [location.pathname, v3DeepLinkData]);

  if (error) throw error;

  return {
    layoutId,
    pageId,
    toRedirect,
  };
};
