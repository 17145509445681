import { ISource } from '@ast/shared-message-receiver';

import { Listenable, Listener } from '@app/common/utils/listenable/Listenable';

import { isAgileSDKRequestMessage } from './assertions/isAgileSDKRequestMessage';

import { AgileSDKRequest } from './types';

/**
 * Event map for the Agile SDK listener.
 *
 * The event map contains a set of event names and their corresponding request types.
 */
export type AgileSDKRequestListenerEventMap = Record<string, AgileSDKRequest>;

/**
 * Agile SDK listener that listens for Agile SDK request messages from the allowed origins.
 *
 * Any other messages will be ignored.
 */
export class AgileSDKRequestListener extends Listenable<AgileSDKRequestListenerEventMap> {
  protected readonly allowedOrigins: Set<string>;

  private messageListener: (message: MessageEvent<unknown>) => void;

  constructor(
    allowedOrigins: Set<string>,
  ) {
    super(new Map());

    this.allowedOrigins = allowedOrigins;

    this.messageListener = (message) => this.handleMessage(message);
  }

  // eslint-disable-next-line max-len
  addListener<K extends keyof AgileSDKRequestListenerEventMap>(event: K, listener: Listener<AgileSDKRequestListenerEventMap[K]>): Listener<AgileSDKRequestListenerEventMap[K]> {
    // Create a new set for the event if it does not exist.
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }

    const result = super.addListener(event, listener);

    return result;
  }

  init() {
    window.addEventListener('message', this.messageListener);
  }

  dispose() {
    window.removeEventListener('message', this.messageListener);
  }

  handleMessage(message: MessageEvent<unknown>) {
    const { source, origin, data } = message;

    if (!this.allowedOrigins.has(origin)) {
      return;
    }

    if (!isAgileSDKRequestMessage(data)) {
      return;
    }

    this.notifyListeners(data.messageType, {
      sender: {
        source: source as ISource,
        origin,
      },
      message: data,
    });
  }
}
