/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { CurrentWizardStepFragment } from '../../../../../common/configurable-wizards/queries/queryTyping/current-wizard-step';
import { gql } from '@apollo/client';
import { CurrentWizardStepFragmentDoc } from '../../../../../common/configurable-wizards/queries/queryTyping/current-wizard-step';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTriggeredEventStepQueryVariables = Types.Exact<{
  stateParams: Types.GetTriggeredEventParams;
}>;


export type GetTriggeredEventStepQuery = (
  { __typename?: 'Query' }
  & { triggeredEventStep: Types.Maybe<(
    { __typename?: 'CurrentWizardStep' }
    & CurrentWizardStepFragment
  )> }
);


export const GetTriggeredEventStepDocument = gql`
    query GetTriggeredEventStep($stateParams: GetTriggeredEventParams!) {
  triggeredEventStep(getTriggeredEventParams: $stateParams) {
    ...CurrentWizardStep
  }
}
    ${CurrentWizardStepFragmentDoc}`;

/**
 * __useGetTriggeredEventStepQuery__
 *
 * To run a query within a React component, call `useGetTriggeredEventStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTriggeredEventStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTriggeredEventStepQuery({
 *   variables: {
 *      stateParams: // value for 'stateParams'
 *   },
 * });
 */
export function useGetTriggeredEventStepQuery(baseOptions: Apollo.QueryHookOptions<GetTriggeredEventStepQuery, GetTriggeredEventStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTriggeredEventStepQuery, GetTriggeredEventStepQueryVariables>(GetTriggeredEventStepDocument, options);
      }
export function useGetTriggeredEventStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTriggeredEventStepQuery, GetTriggeredEventStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTriggeredEventStepQuery, GetTriggeredEventStepQueryVariables>(GetTriggeredEventStepDocument, options);
        }
export type GetTriggeredEventStepQueryHookResult = ReturnType<typeof useGetTriggeredEventStepQuery>;
export type GetTriggeredEventStepLazyQueryHookResult = ReturnType<typeof useGetTriggeredEventStepLazyQuery>;
export type GetTriggeredEventStepQueryResult = Apollo.QueryResult<GetTriggeredEventStepQuery, GetTriggeredEventStepQueryVariables>;