import { useEffect, useState } from 'react';

interface NetworkInformation {
  readonly isOnline: boolean;
}

/**
 * Returns true if the network is online or false if it isn't
 *
 * @todo Use Network Information API instead of the current approach,
 * when it will be released and supported by a major list of the browsers.
 * https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
 * @todo Add additional useful information that would be available after releasing the Network Information API
 */
export const useNetworkInformation = (): NetworkInformation => {
  const [networkInformation, setNetworkInformation] = useState<NetworkInformation>({
    isOnline: navigator.onLine,
  });

  useEffect(() => {
    const onStatusChanged = (e: Event) => setNetworkInformation({
      isOnline: e.type === 'online',
    });

    window.addEventListener('offline', onStatusChanged);
    window.addEventListener('online', onStatusChanged);

    return () => {
      window.removeEventListener('offline', onStatusChanged);
      window.removeEventListener('online', onStatusChanged);
    };
  }, []);

  return networkInformation;
};
