import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  FieldComponent,
  FieldLocator,
} from '../types';
import {
  combineNames,
  isHiddenField,
} from '../utils';
import { HiddenFieldFragment as HiddenField } from '../queries/queryTyping/all-fields';

export const HiddenFieldComponent: FieldComponent<HiddenField> = ({
  parent,
  field,
}) => {
  const {
    register,
  } = useFormContext();
  return (
    <input
      type="hidden"
      defaultValue={field.sValue || ''}
      data-stable-name={`${field.id}Hidden`}
      {...register(combineNames(parent, field.id))}
    />
  );
};

export const HiddenFieldLocator: FieldLocator = (context) => (
  isHiddenField(context.field)
    ? HiddenFieldComponent
    : undefined
);
