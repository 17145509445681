import { GraphQLError } from 'graphql';

import { ApolloLink } from '@apollo/client';
import { getSessionValue } from '@app/core/storage/browser/sessionStorage';

import { ErrorCodes } from '@app/queryTyping';

import { isNotEmptyArray } from '@app/common/utils';

export const RECAPTCHA_TOKEN_KEY = 'recaptcha-token';
export const RECAPTCHA_VERSION_KEY = 'recaptcha-version';
export const RECAPTCHA_OPERATIONS = ['PostLoginStep'];

export const RECAPTCHA_TOKEN_HEADER = 'recaptcha-token';
export const RECAPTCHA_VERSION_HEADER = 'recaptcha-version';

export const isReCaptchaError = (error: GraphQLError) => (
  error.extensions?.code === ErrorCodes.RECAPTCHA
);

export const findReCaptchaError = (errors?: readonly GraphQLError[]): GraphQLError | undefined => {
  if (isNotEmptyArray(errors)) {
    return errors.find(isReCaptchaError);
  }

  return undefined;
};

/**
 * The Apollo link to catch and handle reCaptcha exceptions
 */
export const reCaptchaApolloLink = new ApolloLink((operation, forward) => {
  if (RECAPTCHA_OPERATIONS.includes(operation.operationName)) {
    const token = getSessionValue(RECAPTCHA_TOKEN_KEY);
    const version = getSessionValue(RECAPTCHA_VERSION_KEY);

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        [RECAPTCHA_TOKEN_HEADER]: token,
        [RECAPTCHA_VERSION_HEADER]: version,
      },
    }));
  }

  return forward(operation);
});
