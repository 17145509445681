import { LocalSettings } from '@app/core/storage/browser/LocalSettings';

import { generateUID } from './generateUID';

export const CLIENT_DEVICE_UID_STORAGE_KEY = 'cduid';

export const setClientDeviceUID = (value: string) => {
  LocalSettings.instance.saveClientDeviceID(value);
};

/**
 * Get clinet device unique id from local storage or generate new one if empty.
 * @returns clinet device unique id.
 */
export const getClientDeviceUID = (): string => {
  let result: string | undefined;

  result = LocalSettings.instance.getClientDeviceID();

  // Ensure that uid value stored in local storage.
  if (!result) {
    // Generate new uid if stoage value is empty
    result = generateUID();
    // Save new value in storage
    setClientDeviceUID(result);
  }

  return result!;
};
