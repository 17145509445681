import { SEARCH_LANGUAGE_CULTURE_PARAM } from '@app/core/contexts/localizationContext/constants';

export const getCultureFromURL = (search: string) => {
  const urlParams = new URLSearchParams(search);
  const culture = urlParams.get(SEARCH_LANGUAGE_CULTURE_PARAM) ?? '';
  return culture;
};

export const removeCultureFromURL = (win: Window) => {
  const url = new URL(win.location.href);
  url.searchParams.delete(SEARCH_LANGUAGE_CULTURE_PARAM);
  win.history.replaceState({}, '', url.toString());
};
