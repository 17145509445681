import { CheckboxFieldLocator } from './fields/CheckboxField';
import { ConstrainedTextFieldLocator } from './fields/ConstrainedTextField';
import { DateFieldLocator } from './fields/Date/DateField';
import { FallbackFieldLocator } from './fields/Fallback';
import { FieldInfoGroupLocator } from './fields/FieldInfoGroup';
import { HiddenFieldLocator } from './fields/Hidden';
import { LabelAsPlainTextFieldLocator } from './fields/label/LabelAsPlainText';
import { NotificationFieldLocator } from './fields/Notification';
import { PasswordFieldLocator } from './fields/Password/Password';
import { PhoneFieldLocator } from './fields/Phone';
import { PinFieldFieldLocator } from './fields/PIN/PinField';
import { TextFieldLocator } from './fields/TextInput';

import {
  Field, FieldComponent,
  FieldLocator,
  LocatorResult,
} from './types';

export const createLocator = (
  wizard: string,
  step: string,
  locators: readonly FieldLocator[] = [],
) => {
  const fieldLocators = [
    // special wizard/step fields
    ...locators,

    // general fields
    LabelAsPlainTextFieldLocator,
    HiddenFieldLocator,
    NotificationFieldLocator,
    PinFieldFieldLocator,
    PhoneFieldLocator,
    PasswordFieldLocator,
    TextFieldLocator,
    ConstrainedTextFieldLocator,
    FieldInfoGroupLocator,
    DateFieldLocator,
    CheckboxFieldLocator,

    // fallback field MUST be the last
    FallbackFieldLocator,
  ];

  return <T extends Field>(field: T): FieldComponent<T> => {
    const component = fieldLocators.reduce<LocatorResult>(
      (s, l) => s || l({
        wizard: {
          name: wizard,
          step,
        },
        field,
      }),
      undefined,
    );
    return component as unknown as FieldComponent<T>;
  };
};
