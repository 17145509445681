import { useEffect } from 'react';
import { debounce } from '../../function';

/**
 * Hook will wait for timeout before raise callback function.
 * @param callback callback to raise after timeout.
 * @param timeout timeout in msec.
 */
export function useDebouncedFunction<T extends (...args: any[]) => any>(func: T, timeout: number): T;
export function useDebouncedFunction(
  func: (...args: any[]) => any,
  timeout: number,
) {
  const [debouncedFunc, clearTimer] = debounce(func, timeout);

  // Clear timer on component unmount.
  useEffect(() => clearTimer);
  return debouncedFunc;
}
