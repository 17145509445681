import { useEffect } from 'react';
import {
  ApolloError, QueryHookOptions, QueryResult,
} from '@apollo/client';

import { setWizardStepLoading, setWizardStepUnhandledErrors } from '@app/common/configurable-wizards';
import { CurrentWizardStepFragment } from '@app/common/configurable-wizards/queries/queryTyping/current-wizard-step';
import { WizardStepStateDispatcher } from '@app/common/configurable-wizards/state/types';
import { setWizardStepData } from '@app/common/configurable-wizards/state/useWizardStepData';
import { BASE_QUERY_OPTIONS } from '../constants';
import { isCurrentWizardStep } from '../utils';

/**
 * Generic hook to query a wizard step
 * @param query - An initial Apollo useQuery hook
 * @param options - Apollo useQuery hook options
 * @param dispatch - wizard step state disptacher
 * @returns Apollo useQuery return type
 */
export function useGetWizardStepQuery<
  Q extends (options: QueryHookOptions<any, any>) => QueryResult,
  P extends Parameters<Q>[0] | null,
  >(query: Q, options: P, dispatch: WizardStepStateDispatcher): ReturnType<Q> {
  const queryResult = query({
    ...BASE_QUERY_OPTIONS,
    ...options,
    onCompleted: (data: CurrentWizardStepFragment) => {
      // keep all wizard step data
      const currentWizardStep = Object.values(data).find(isCurrentWizardStep);
      if (isCurrentWizardStep(currentWizardStep)) {
        setWizardStepData(dispatch, currentWizardStep);
      }

      // call external onCompleted callback
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
    },
    onError: (error: ApolloError) => {
      dispatch(setWizardStepUnhandledErrors([error]));

      // call external onError callback
      if (options?.onError) {
        options.onError(error);
      }
    },
  });

  useEffect(() => {
    dispatch(setWizardStepLoading(queryResult.loading));
  }, [queryResult.loading]);

  return queryResult as ReturnType<Q>;
}
