/**
 * Base Agile SDK error class.
 *
 * Used to represent and catch errors thrown by the Agile SDK.
 *
 * The error code and message are normally sent to Agile SDK consumers.
 */
export class AgileSDKError extends Error {
  readonly code: string;

  constructor(code: string, readonly message: string) {
    super(message);

    this.code = code;
  }
}
