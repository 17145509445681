import React from 'react';

import { FormField, FormFieldProps } from '@ast/magma/components/formfield';

/**
 * CFFormField - is the extension of <FormField /> for the usage at configurable fields.
 */
export const CFFormField: React.FC<FormFieldProps> = ({
  children,
  ...formFieldProps
}) => (
  <FormField
    reserveErrorHeight={false}
    {...formFieldProps}
  >
    {children}
  </FormField>
);
