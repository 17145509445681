import React, { useEffect, useState } from 'react';

import { Overlay } from '@ast/magma/components/overlay';

import { ColorTheme } from '@app/common/types/ColorTheme';

type ComponentType = typeof Overlay;
type OverlayWithMaskProps = React.ComponentProps<ComponentType>;

export const OverlayWithMask: React.FC<OverlayWithMaskProps> = (props) => {
  const [theme, setTheme] = useState<ColorTheme>(document.body.getAttribute('data-theme') as ColorTheme);

  useEffect(() => {
    const bodyMutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'data-theme') {
          setTheme((mutation.target as HTMLElement).getAttribute('data-theme') as ColorTheme);
        }
      });
    });

    bodyMutationObserver.observe(document.body, { attributes: true });

    return () => bodyMutationObserver.disconnect();
  }, []);
  return (
    <Overlay
      mask={theme === 'dark' ? 'dark' : 'light'}
      {...props}
    />
  );
};
