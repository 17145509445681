import { SUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE } from '../constants';
import { SubscribeIsAuthenticatedRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

/**
 * Checks if the value is a {@link SubscribeIsAuthenticatedRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isSubscribeIsAuthenticatedRequestMessage(value: AgileSDKRequestMessage<unknown>): value is SubscribeIsAuthenticatedRequestMessage {
  return value.messageType === SUBSCRIBE_IS_AUTHENTICATED_REQUEST_MESSAGE_TYPE;
}
