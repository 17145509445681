/* eslint-disable no-underscore-dangle */
import { LocalStorageDocument } from '@app/core/storage/browser/LocalStorageDocument';
import { Migration } from '@app/core/versionedSerializer/versionedSerializer';
import { SessionParameters } from './types';

const CURRENT_VERSION = 1;
const LOCAL_SETTINGS_NODE = 'app.init-config';

/**
 * Storage for application initialization parameters passed via URL parameters.
 * Used by:
 *  - to pass init parameters from the MFM application before starting the web application in WebView.
 *  - to pass init parameters to establish an SCSO session.
 */
export class SessionParametersStorage extends LocalStorageDocument<SessionParameters> {
  /**
   * Singleton methods
   */

  private static inner?:SessionParametersStorage;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node:string, version:number, migrations:Migration[] = []) {
    super(node, version, migrations);
  }

  public static get instance():SessionParametersStorage {
    if (!this.inner) {
      this.inner = new SessionParametersStorage(LOCAL_SETTINGS_NODE, CURRENT_VERSION);
    }

    return this.inner;
  }

  /**
   * Public interface methods
   */

  private _clientDeviceID?:string;

  public get clientDeviceID(): string | undefined {
    return this._clientDeviceID;
  }

  private _sessionId?:string;

  public get sessionId(): string | undefined {
    return this._sessionId;
  }

  private _authToken?:string;

  public get authToken(): string | undefined {
    return this._authToken;
  }

  private _embeddedMode?:string;

  public get embeddedMode(): string | undefined {
    return this._embeddedMode;
  }

  private _adminLogin?:string;

  public get adminLogin(): string | undefined {
    return this._adminLogin;
  }

  private _cultureId?:string;

  public get cultureId(): string | undefined {
    return this._cultureId;
  }

  /**
   * Abstract methods implementation
   */

  /**
   * Save public data properties into serializable data structure.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize(): SessionParameters {
    return {
      cduid: this._clientDeviceID,
      dssid: this._sessionId,
      authToken: this._authToken,
      embedded: this._embeddedMode,
      adminLogin: this._adminLogin,
      cultureId: this._cultureId,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized: SessionParameters) {
    this._clientDeviceID = deserialized.cduid;
    this._sessionId = deserialized.dssid;
    this._authToken = deserialized.authToken;
    this._embeddedMode = deserialized.embedded;
    this._adminLogin = deserialized.adminLogin;
    this._cultureId = deserialized.cultureId;
  }
}

/**
 * Read and clear local storage init parameters.
 * @returns init parameters.
 */
export const extractInitParametersFromLocalStorage = (): SessionParameters => {
  // Read init parameters
  const result: SessionParameters = {
    embedded: SessionParametersStorage.instance.embeddedMode,
    authToken: SessionParametersStorage.instance.authToken,
    cduid: SessionParametersStorage.instance.clientDeviceID,
    dssid: SessionParametersStorage.instance.sessionId,
    adminLogin: SessionParametersStorage.instance.adminLogin,
    cultureId: SessionParametersStorage.instance.cultureId,
  };

  // Clear local storage
  SessionParametersStorage.instance.clear();
  return result;
};
