import { AgileSDKError } from './AgileSDKError';

/**
 * Error code for unexpected errors.
 */
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';

/**
 * Default message for unexpected errors.
 */
export const DEFAULT_UNEXPECTED_ERROR_MESSAGE = 'Unexpected error.';

/**
 * Error thrown when an unexpected error occurs.
 */
export class AgileSDKUnexpectedError extends AgileSDKError {
  constructor(message: string = DEFAULT_UNEXPECTED_ERROR_MESSAGE) {
    super(UNEXPECTED_ERROR, message);
  }
}
