import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

import { LocalStorageDocument } from './LocalStorageDocument';

const CURRENT_VERSION = 1;
export const LOCAL_SETTINGS_NODE = 'document';

export interface ILocalSettings {
  readonly clientDeviceID?: string;
}

/**
 * This document contains root application local storage settings.
 */
export class LocalSettings extends LocalStorageDocument<ILocalSettings> {
  /**
   * Singleton methods
   */

  private static inner?:LocalSettings;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node:string, version:number, migrations:Migration[] = []) {
    super(node, version, migrations);
  }

  public static get instance():LocalSettings {
    if (!this.inner) {
      this.inner = new LocalSettings(LOCAL_SETTINGS_NODE, CURRENT_VERSION);
    }

    return this.inner;
  }

  /**
   * Public interface methods
   */

  private clientDeviceID?:string;

  public getClientDeviceID(): string | undefined {
    return this.clientDeviceID;
  }

  public saveClientDeviceID(value:string | undefined) {
    this.clientDeviceID = value;
    this.save();
  }

  /**
   * Abstract methods implementation
   */

  /**
   * Save public data properties into serializable data stracture.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize():ILocalSettings {
    return {
      clientDeviceID: this.clientDeviceID,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized:ILocalSettings) {
    this.clientDeviceID = deserialized.clientDeviceID;
  }
}
