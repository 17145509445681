import React from 'react';

import { WizardStep, WizardStepProps } from '@app/common/configurable-wizards';
import { WizardStepWithHeader } from '@app/common/configurable-wizards/WizardStepWithHeader';

import { useTriggeredEventsTranslation } from '../../useTriggeredEventsTranslation';

import { LayeredAuthenticationWizardButtons } from './LayeredAuthenticationWizardButtons';

export const LayeredAuthenticationWizardStep: React.FC<WizardStepProps> = ({
  ...props
}) => {
  const { t } = useTriggeredEventsTranslation();

  return (
    <WizardStepWithHeader
      title={t(
        'layered-authentication.title|Header text of the layered authentication dialog',
        'Authentication required',
      )}
    >
      <WizardStep
        buttons={({ cancel, back }) => (
          <LayeredAuthenticationWizardButtons
            back={back}
            cancel={cancel}
          />
        )}
        {...props}
      />
    </WizardStepWithHeader>
  );
};
