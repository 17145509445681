function ensureEndsWithDot(str: string): string {
  return str.endsWith('.') ? str : `${str}.`;
}

export function endWithDot(input: string | string[]): string {
  if (Array.isArray(input)) {
    return input.length > 0 ? ensureEndsWithDot(input[0]) : '';
  }
  return ensureEndsWithDot(input);
}
