import React, { useMemo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Radio } from '@ast/magma/components/radio';

import { FieldInfoGroup } from '@app/queryTyping';

import { FlexContainer } from '@app/common/components/FlexContainer/FlexContainer';
import {
  FieldComponent,
  FieldLocator,
  FieldContext,
  Field,
} from '@app/common/configurable-wizards/types';
import { combineNames } from '@app/common/configurable-wizards/utils/combineNames';
import { isSelectableGroupFiledInfo } from '@app/common/configurable-wizards/utils/fieldTypesCheck';

import { SelectableGroupFieldFragment as SelectableGroupField } from '../queries/queryTyping/all-fields';

import styles from './RadioGroupField.pcss';

type FieldGroupKey = Capitalize<keyof Pick<SelectableGroupField, 'selectedFieldGroupId'>>;

/**
 * Constant radio-group input name
 */
export const RADIO_GROUP_NAME: FieldGroupKey = 'SelectedFieldGroupId';

/**
 * Returns value of the default selected group
 */
export const getRadioGroupFieldDefaultValue: GetDefaultValue<SelectableGroupField> = (field) => {
  const groups = (field.fieldGroups || []) as FieldInfoGroup[];
  return field.selectedFieldGroupId || (groups.length ? groups[0]?.id : '');
};

/**
 * Returns field name
 */
export const getRadioGroupFieldName: GetName<SelectableGroupField> = (parent, field) => (
  combineNames(parent, field.id, RADIO_GROUP_NAME)
);

export const RadioGroupField: FieldComponent<SelectableGroupField> = ({
  parent,
  field,
  locator,
}) => {
  const { watch, setValue } = useFormContext();
  const groups = (field.fieldGroups || []) as FieldInfoGroup[];
  const radioGroupName = getRadioGroupFieldName(parent, field);
  const defaultRadioGroupId = getRadioGroupFieldDefaultValue(field);
  const currentRadioGroupId = watch(radioGroupName) || defaultRadioGroupId;

  useEffect(() => {
    setValue(radioGroupName, defaultRadioGroupId);
  }, []);

  const fieldGroups = useMemo(() => (
    groups
      .filter((group) => group.id === currentRadioGroupId)
      .map((group) => {
        const Component = locator(group);
        return (
          <Component
            parent={combineNames(parent, field.id)}
            field={group}
            locator={locator}
            key={group.id}
            data-stable-name={`${group.id}GroupContainer`}
          />
        );
      })
  ), [field, currentRadioGroupId]);

  return (
    <FlexContainer
      direction="column"
      data-stable-name="RadioButtonsSelectableGroupFieldInfo"
    >
      <div role="radiogroup" aria-labelledby={`${field.id}Description`}>
        <fieldset>
          <legend id={`${field.id}Description`} className={styles.description}>{field.label}</legend>
          <FlexContainer>
            <Controller
              name={radioGroupName}
              render={(renderProps) => (
                <>
                  {groups.map((radioGroup) => (
                    <Radio
                      key={radioGroup.id}
                      label={radioGroup.label || ''}
                      data-stable-name={`${radioGroup.id}Radio`}
                      {...renderProps.field}
                      value={radioGroup.id}
                      defaultChecked={defaultRadioGroupId === radioGroup.id}
                    />
                  ))}
                </>
              )}
            />
          </FlexContainer>
        </fieldset>
      </div>
      {fieldGroups}
    </FlexContainer>
  );
};

export const isRadioGroupField = ({ field }: FieldContext) => (
  isSelectableGroupFiledInfo(field)
);

export const RadioGroupFieldLocator: FieldLocator = (context) => (
  isRadioGroupField(context)
    ? RadioGroupField
    : undefined
);

export interface GetDefaultValue<F extends Field> {
  (field: F): any;
}

export interface GetName<F extends Field> {
  (parent: string, field: F): string;
}
