import React, { useState } from 'react';

import Eye from '@ast/magma/components/icon/icons/Eye';
import EyeSlash from '@ast/magma/components/icon/icons/EyeSlash';

import { Button, ButtonProps } from '@ast/magma';

export interface ComponentProps {
  readonly initallyShown?: boolean;
  readonly onShowHidePassword?: (shown: boolean) => void;
}

type MagmaButtonProps = Pick<ButtonProps, 'containerClassName'>;
type HtmlButtonProps = Exclude< React.ButtonHTMLAttributes<HTMLButtonElement>, 'title' | 'aria-label' | 'onClick' | 'type'>;
export type ShowHidePasswordButtonProps = ComponentProps & MagmaButtonProps & HtmlButtonProps;

export const ShowHidePasswordButton = React.forwardRef<HTMLButtonElement, ShowHidePasswordButtonProps>(({
  initallyShown = false,
  onShowHidePassword = () => { },
  ...buttonProps
}, ref) => {
  const [isPasswordShown, setPasswordShown] = useState<boolean>(initallyShown);

  const onShowHidePasswordClick = () => {
    const isShown = !isPasswordShown;
    setPasswordShown(isShown);
    onShowHidePassword(isShown);
  };

  return (
    <Button
      data-stable-name="ShowHidePasswordConfirmButton"
      {...buttonProps}
      ref={ref}
      look="icon"
      type="button"
      onClick={onShowHidePasswordClick}
      icon={
        isPasswordShown ? <EyeSlash data-stable-name="EyeSlashIcon" /> : <Eye data-stable-name="EyeIcon" />
      }
    />
  );
});
