import { getSessionValue, setSessionValue } from '@app/core/storage/browser/sessionStorage';

import { isTruthy } from '@app/common/utils';

export const TOGGLE_STORAGE_KEY = 'hidden-accounts-toggle';

export const setHiddenAccountsToggle = (toggle: boolean) => setSessionValue(TOGGLE_STORAGE_KEY,
  toggle
    ? true.toString()
    : false.toString());

export const getHiddenAccountsToggle = (): boolean => isTruthy(getSessionValue(TOGGLE_STORAGE_KEY));

export const setDefaultHiddenAccountsToggle = async (): Promise<void> => setHiddenAccountsToggle(false);
