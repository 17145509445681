/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type IsEligibleServiceQueryVariables = Types.Exact<{
  serviceName: Types.Scalars['String'];
  serviceType: Types.Scalars['String'];
}>;


export type IsEligibleServiceQuery = (
  { __typename?: 'Query' }
  & { isEligibleService: Types.Maybe<(
    { __typename: 'IsEligibleServiceResult' }
    & Pick<Types.IsEligibleServiceResult, 'isEligible'>
  )> }
);


export const IsEligibleServiceDocument = gql`
    query IsEligibleService($serviceName: String!, $serviceType: String!) {
  isEligibleService(serviceName: $serviceName, serviceType: $serviceType) {
    __typename
    isEligible
  }
}
    `;

/**
 * __useIsEligibleServiceQuery__
 *
 * To run a query within a React component, call `useIsEligibleServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEligibleServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEligibleServiceQuery({
 *   variables: {
 *      serviceName: // value for 'serviceName'
 *      serviceType: // value for 'serviceType'
 *   },
 * });
 */
export function useIsEligibleServiceQuery(baseOptions: Apollo.QueryHookOptions<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>(IsEligibleServiceDocument, options);
      }
export function useIsEligibleServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>(IsEligibleServiceDocument, options);
        }
export type IsEligibleServiceQueryHookResult = ReturnType<typeof useIsEligibleServiceQuery>;
export type IsEligibleServiceLazyQueryHookResult = ReturnType<typeof useIsEligibleServiceLazyQuery>;
export type IsEligibleServiceQueryResult = Apollo.QueryResult<IsEligibleServiceQuery, IsEligibleServiceQueryVariables>;