/* eslint-disable max-len */
import { AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX, AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX } from '../../constants';

export const GET_COLOR_THEME_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.ColorTheme.GetColorTheme`;

export const SUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.ColorTheme.SubscribeColorTheme`;

export const UNSUBSCRIBE_COLOR_THEME_REQUEST_MESSAGE_TYPE = `${AGILE_SDK_REQUEST_MESSAGE_TYPE_PREFIX}.ColorTheme.UnsubscribeColorTheme`;

export const COLOR_THEME_CHANGED_NOTIFICATION_MESSAGE_TYPE = `${AGILE_SDK_NOTIFICATION_MESSAGE_TYPE_PREFIX}.ColorTheme.ColorThemeChanged`;
