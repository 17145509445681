import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from '@app/core/i18n';

import { Input } from '@ast/magma/components/input';

import { FieldBehaviorMode } from '@app/queryTyping';

import { useCommonValidationMessages } from '@app/common/valiation/commonValidationMessages';

import { FieldComponent, FieldLocator } from '../types';
import { combineNames, getFieldErrorMessage, isTextField } from '../utils';

import { TextFieldFragment as TextField } from '../queries/queryTyping/all-fields';
import { CFFormField } from './common/CFFormField';

import styles from './TextInput.pcss';

export const TextFieldComponent: FieldComponent<TextField> = ({
  parent,
  field,
  fieldProps,
}) => {
  const onBlurHandler = fieldProps?.onBlur;
  const { t } = useTranslation();

  const {
    register,
    unregister,
    formState: { errors },
  } = useFormContext();

  const name = combineNames(parent, field.id);
  const commonValidationMessages = useCommonValidationMessages();
  const error = getFieldErrorMessage(name, errors, commonValidationMessages);

  useEffect(() => () => unregister(name), []);

  const { onBlur, ...regRest } = register(name, {
    required: field.fieldBehaviorMode === FieldBehaviorMode.REQUIRED,
    maxLength: field.maxLength,
    minLength: {
      value: field.minLength,
      message: t(
        'field.minLength|Validation message for min length',
        'Field length should be at least {{minLength}} symbols',
        { minLength: field.minLength },
      ),
    },
  });

  return (
    <CFFormField error={error}>
      <>
        {fieldProps?.showDescription && field?.description && (
          <span className={styles.description}>{field.description}</span>
        )}

        <Input
          /* Don't use minLength here because the Input uses inner validation onInput */
          maxLength={field.maxLength}
          label={field.label || ''}
          aria-invalid={!!error}
          data-stable-name={`${field.id}Input`}
          defaultValue={field.sValue ?? ''}
          onBlur={(e) => {
            if (onBlurHandler) {
              onBlurHandler(e);
            }
            onBlur(e);
          }}
          {...regRest}
        />
      </>
    </CFFormField>
  );
};

export const TextFieldLocator: FieldLocator = (context) => (
  isTextField(context.field)
    ? TextFieldComponent
    : undefined
);
