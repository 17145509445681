/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetV3DeepLinkMappingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetV3DeepLinkMappingQuery = (
  { __typename?: 'Query' }
  & { v3DeepLinkMapping: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'DeepLinkMappingPair' }
    & Pick<Types.DeepLinkMappingPair, 'key' | 'value'>
  )>>> }
);


export const GetV3DeepLinkMappingDocument = gql`
    query GetV3DeepLinkMapping {
  v3DeepLinkMapping {
    key
    value
  }
}
    `;

/**
 * __useGetV3DeepLinkMappingQuery__
 *
 * To run a query within a React component, call `useGetV3DeepLinkMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetV3DeepLinkMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetV3DeepLinkMappingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetV3DeepLinkMappingQuery(baseOptions?: Apollo.QueryHookOptions<GetV3DeepLinkMappingQuery, GetV3DeepLinkMappingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetV3DeepLinkMappingQuery, GetV3DeepLinkMappingQueryVariables>(GetV3DeepLinkMappingDocument, options);
      }
export function useGetV3DeepLinkMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetV3DeepLinkMappingQuery, GetV3DeepLinkMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetV3DeepLinkMappingQuery, GetV3DeepLinkMappingQueryVariables>(GetV3DeepLinkMappingDocument, options);
        }
export type GetV3DeepLinkMappingQueryHookResult = ReturnType<typeof useGetV3DeepLinkMappingQuery>;
export type GetV3DeepLinkMappingLazyQueryHookResult = ReturnType<typeof useGetV3DeepLinkMappingLazyQuery>;
export type GetV3DeepLinkMappingQueryResult = Apollo.QueryResult<GetV3DeepLinkMappingQuery, GetV3DeepLinkMappingQueryVariables>;