import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { CompromisedCredentialsStatus } from '@app/queryTyping';

import { getAuthenticationToken } from '@app/core/authentication/authenticationToken';

import { getPathToPasswordChange } from '@app/widgets/contacts-and-settings/utils/paths';

import { CompromisedCredentialsStore } from './CompromisedCredentialsSessionStore';
import { useCompomisedPasswordToast } from './useCompomisedPasswordToast';
import { useCompromisedCredentialStatus } from './useCompromisedCredentialStatus';
import { getUserAttractedToAlertFromStore } from './utils';

const isShowToast = (status: CompromisedCredentialsStatus) => (
  status === CompromisedCredentialsStatus.COMPROMISED
);

/**
 * The abstracted hook to check if the user's password is compromised after login.
 * If so, it will display a warning alert to the user.
 */
export const useCompromisedCredentialCheckAfterLogin = (isLoggedIn: boolean) => {
  const history = useHistory();

  const { show: showToast } = useCompomisedPasswordToast({
    onChangePasswordClick: () => {
      history.push(getPathToPasswordChange());
    },
    onToastClose: () => {
      CompromisedCredentialsStore.instance.setUserAttractedToAlert(true);
    },
  });

  const requestStatus = useCompromisedCredentialStatus({
    onCompleted: (status: CompromisedCredentialsStatus) => {
      if (isShowToast(status)) { showToast(); }
    },
  });

  useEffect(() => {
    // Do not check the status if the user is not logged in
    if (!isLoggedIn) { return; }

    const authToken = getAuthenticationToken() ?? '';
    const isNewSession = authToken !== CompromisedCredentialsStore.instance.getLinkedSessionToken();

    // Clear the store if the session is new and link it to the current session.
    if (isNewSession) {
      CompromisedCredentialsStore.instance.clear();
      CompromisedCredentialsStore.instance.setLinkedSessionToken(authToken);
    }

    // Do not check the status and show the alert if the user is already attracted to it
    const userAlreadyAttractedToAlert = getUserAttractedToAlertFromStore();
    if (userAlreadyAttractedToAlert) { return; }

    // Get the status of the user's password
    requestStatus();
  }, [isLoggedIn]);
};
