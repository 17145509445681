import { useMemo } from 'react';

import {
  EligibilityMenuItems,
  useEligibilityMenuContext,
} from '@app/core/contexts/eligibilityMenuContext/EligibilityMenuItemsContext';

import { EligibilityCheckTag } from '@app/queryTyping';

import { useGetMenuQuery } from '@app/common/types/queries/queryTyping/getNavigationMenu';

/**
 * Common menu item
 */
export interface MenuItem {
  readonly id: string;
  readonly linkName: string;
  readonly icon?: string;
  readonly menuItems?: LinkMenuItem[];
  readonly isEligibilityCheckRequired?: boolean;
  readonly eligibilityCheckTag?: EligibilityCheckTag | null;
  readonly isVisible?: boolean;
}

/**
 * Link menu item
 */
export interface LinkMenuItem extends MenuItem {
  readonly url?: string | null;
}

/**
 * Common menus
 */
export interface Menus {
  readonly menuItems?: MenuItem[];
}

/**
 * Returns true when menu item is not empty
 * @param item menu item
 */
const notEmptyParentMenuItem = (item: MenuItem) => (
  item.linkName && item.menuItems && item.menuItems.length > 0
);

/**
 * Returns true when link menu item is filled
 * @param linkItem
 */
const notEmptyLeafLinkMenuItem = (linkItem: LinkMenuItem) => (
  linkItem.linkName && linkItem.url
);

const setMenuItemVisibility = (linkItem: MenuItem, eligibilityMenuItems: EligibilityMenuItems) => {
  const extendedLinkItem = JSON.parse(JSON.stringify(linkItem));
  if (extendedLinkItem?.id) {
    Object.assign(extendedLinkItem, {
      isVisible: !extendedLinkItem?.isEligibilityCheckRequired
        ? true
        : Boolean(eligibilityMenuItems[extendedLinkItem.id]?.isEligible),
    });
  }

  return extendedLinkItem;
};

export const useMainMenuQuery = (ids: string = 'dashboard-main-menu') => {
  const {
    eligibilityMenuItems,
  } = useEligibilityMenuContext();
  const { loading, error: innerError, data } = useGetMenuQuery({
    variables: {
      ids,
    },
  });

  const notEmptyCategories: MenuItem[] = [];
  const allMenuItemsFlatList: LinkMenuItem[] = [];

  return useMemo(() => {
    // eslint-disable-next-line no-undef-init
    let error: Error | undefined = undefined;

    if (innerError) {
      error = innerError;
    }

    if (!loading) {
      // Filter any empty menu items on each level
      if (data?.menu && data.menu.length > 0 && data.menu[0]!.menuItems) {
        data.menu[0]!.menuItems.forEach((category) => {
          if (category && notEmptyParentMenuItem(category as any)) {
            const notEmptySubCategories: MenuItem[] = [];
            category.menuItems?.forEach((subCategory) => {
              if (notEmptyParentMenuItem(subCategory as any)) {
                const notEmptyMenuItems: LinkMenuItem[] = [];
                subCategory!.menuItems?.forEach((linkItem) => {
                  if (notEmptyLeafLinkMenuItem(linkItem as MenuItem)) {
                    const extendedLinkItem = setMenuItemVisibility(linkItem as MenuItem, eligibilityMenuItems);
                    notEmptyMenuItems.push(extendedLinkItem);
                    allMenuItemsFlatList.push(extendedLinkItem);
                  }
                });

                if (notEmptyMenuItems.length > 0) {
                  const extendedLinkItem = setMenuItemVisibility({
                    id: subCategory!.id!,
                    icon: subCategory!.icon || undefined,
                    linkName: subCategory!.linkName!,
                    menuItems: notEmptyMenuItems,
                    eligibilityCheckTag: subCategory?.eligibilityCheckTag,
                    isEligibilityCheckRequired: Boolean(category.isEligibilityCheckRequired),
                  }, eligibilityMenuItems);
                  notEmptySubCategories.push(extendedLinkItem);
                }
              }
            });

            if (notEmptySubCategories.length > 0) {
              const extendedLinkItem = setMenuItemVisibility({
                id: category.id!,
                icon: category!.icon || undefined,
                linkName: category.linkName!,
                menuItems: notEmptySubCategories,
                eligibilityCheckTag: category?.eligibilityCheckTag,
                isEligibilityCheckRequired: Boolean(category.isEligibilityCheckRequired),
              }, eligibilityMenuItems);

              notEmptyCategories.push(extendedLinkItem);
            }
          }
        });
      }
    }

    return {
      loading,
      error,
      categories: notEmptyCategories,
      allMenuItems: allMenuItemsFlatList,
    };
  }, [loading, innerError, data, eligibilityMenuItems]);
};
