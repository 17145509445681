export enum ZoneName {
  HeaderBanner = 'HeaderBanner',
  FooterBanner = 'FooterBanner',
  Footer = 'Footer',
  Header = 'Header',
  RightSide = 'RightSide',
  Middle = 'Middle',
  PreMiddle = 'PreMiddle',
  PostMiddle = 'PostMiddle',
}
