import React from 'react';

import { getCorrSessionId } from '@app/core/authentication/customAuthHeaders/corrSessionId';
import { getDSSessionId } from '@app/core/authentication/customAuthHeaders/dsSessionId';

import styles from './MetaInfo.pcss';
import {t} from "@app/core/i18n";

export const MetaInfo = () => {
  const sessionId = getDSSessionId();
  const { traceId } = getCorrSessionId();

  return (
    <p className={styles.wrapper} data-stable-name="MetaInfo">
      {
        traceId && (
        <span data-stable-name="MetaInfoCorrelationId">
          {t('server-error.correlation-id|Correlation ID', 'Correlation ID')}
          :
          {' '}
          <code>
            { traceId }
          </code>
        </span>
        )
      }
      <br />
      {
        sessionId && (
        <span data-stable-name="MetaInfoSessionId">
          {t('server-error.session-id|Session ID', 'Session ID')}
          :
          {' '}
          <code>
            { sessionId }
          </code>
        </span>
        )
      }
    </p>
  );
};
