import { isObject, isString } from '@app/common/utils';

import { SubscriptionInfo } from '../types';

/**
 * Checks if the provided value is {@link SubscriptionInfo}.
 */
export function isSubscriptionInfo(value: unknown): value is SubscriptionInfo {
  return (
    isObject(value)

    && 'subscriptionId' in value
    && isString(value.subscriptionId)
  );
}
