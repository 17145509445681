import { isUndefined, throttle } from '@app/common/utils';
import { Listenable } from '@app/common/utils/listenable';
import { UserActivityInterpreter } from '../../../userActivityInterpreter/UserActivityInterpreter';
import { UserAutoLogoutInterpreterEvents } from './types';
import { isAutoLogoutApplicable } from './utils';

const SECOND_IN_MS = 1000;

interface UserAutoLogoutInterpreterArgs {
  readonly userActivityInterpreter: UserActivityInterpreter;
}

export class UserAutoLogoutInterpreter
  extends Listenable<UserAutoLogoutInterpreterEvents> {
  private static instance: UserAutoLogoutInterpreter;

  private logoutCountdownInterval?: number;

  private userActivityInterpreter?: UserActivityInterpreter;

  private constructor() {
    super(
      new Map<keyof UserAutoLogoutInterpreterEvents, Set<Function>>([
        // eslint-disable-next-line i18next/no-literal-string
        ['onautologoutcountdown', new Set()],
        // eslint-disable-next-line i18next/no-literal-string
        ['onautologout', new Set()],
      ]),
    );
  }

  /**
   * Returns the single class instance.
   * @TODO add ability to remove listeners from the activity-interpreter before initializing new ones
   */
  public static getInstance(): UserAutoLogoutInterpreter {
    if (!UserAutoLogoutInterpreter.instance) {
      UserAutoLogoutInterpreter.instance = new UserAutoLogoutInterpreter();
    }

    return UserAutoLogoutInterpreter.instance;
  }

  public init(args: UserAutoLogoutInterpreterArgs) {
    let settingsChanged = false;

    if (this.userActivityInterpreter !== args.userActivityInterpreter) {
      this.userActivityInterpreter = args.userActivityInterpreter;
      settingsChanged = true;
    }

    if (settingsChanged) {
      this.startInterpret();
    }
  }

  private startInterpret() {
    if (isUndefined(this.userActivityInterpreter)) { return; }

    // on user active handler
    this.userActivityInterpreter.addListener(
      'user-active',
      // add some throttling to not notify listeners too frequent
      // so it's' not more that 1 notification in 100 ms
      throttle(() => {
        this.clearLogoutCountdownInterval();
        this.notifyListeners('onautologout', { isAutologout: false });
        this.notifyListeners('onautologoutcountdown', { msToLogout: null });
      }, 100),
    );

    // on user away handler
    this.userActivityInterpreter.addListener(
      'user-away',
      ({ msToLogout }: { msToLogout: number }) => {
        let actualMsToLogout = msToLogout;

        this.logoutCountdownInterval = window.setInterval(() => {
          if (!isAutoLogoutApplicable()) {
            return;
          }

          if ((actualMsToLogout - SECOND_IN_MS) > 0) { // if it's still countdown
            actualMsToLogout -= SECOND_IN_MS;
            this.notifyListeners('onautologoutcountdown', { msToLogout: actualMsToLogout });
          } else { // if time to logout
            this.clearLogoutCountdownInterval();
            this.notifyListeners('onautologoutcountdown', { msToLogout: 0 });
            this.notifyListeners('onautologout', { isAutologout: true });
          }
        }, SECOND_IN_MS);
      },
    );
  }

  private clearLogoutCountdownInterval() {
    window.clearInterval(this.logoutCountdownInterval);
  }
}
