export enum CountryCodes {
  AF = 'af',
  AL = 'al',
  DZ = 'dz',
  AS = 'as',
  AD = 'ad',
  AO = 'ao',
  AI = 'ai',
  AG = 'ag',
  AR = 'ar',
  AM = 'am',
  AW = 'aw',
  AC = 'ac',
  AU = 'au',
  AT = 'at',
  AZ = 'az',
  BS = 'bs',
  BH = 'bh',
  BD = 'bd',
  BB = 'bb',
  BY = 'by',
  BE = 'be',
  BZ = 'bz',
  BJ = 'bj',
  BM = 'bm',
  BT = 'bt',
  BO = 'bo',
  BA = 'ba',
  BW = 'bw',
  BR = 'br',
  IO = 'io',
  VG = 'vg',
  BN = 'bn',
  BG = 'bg',
  BF = 'bf',
  BI = 'bi',
  KH = 'kh',
  CM = 'cm',
  CA = 'ca',
  CV = 'cv',
  BQ = 'bq',
  KY = 'ky',
  CF = 'cf',
  TD = 'td',
  CL = 'cl',
  CN = 'cn',
  CX = 'cx',
  CC = 'cc',
  CO = 'co',
  KM = 'km',
  CD = 'cd',
  CG = 'cg',
  CK = 'ck',
  CR = 'cr',
  CI = 'ci',
  HR = 'hr',
  CU = 'cu',
  CW = 'cw',
  CY = 'cy',
  CZ = 'cz',
  DK = 'dk',
  DJ = 'dj',
  DM = 'dm',
  DO = 'do',
  EC = 'ec',
  EG = 'eg',
  SV = 'sv',
  GQ = 'gq',
  ER = 'er',
  EE = 'ee',
  SZ = 'sz',
  ET = 'et',
  FK = 'fk',
  FO = 'fo',
  FJ = 'fj',
  FI = 'fi',
  FR = 'fr',
  GF = 'gf',
  PF = 'pf',
  GA = 'ga',
  GM = 'gm',
  GE = 'ge',
  DE = 'de',
  GH = 'gh',
  GI = 'gi',
  GR = 'gr',
  GL = 'gl',
  GD = 'gd',
  GP = 'gp',
  GU = 'gu',
  GT = 'gt',
  GG = 'gg',
  GN = 'gn',
  GW = 'gw',
  GY = 'gy',
  HT = 'ht',
  HN = 'hn',
  HK = 'hk',
  HU = 'hu',
  IS = 'is',
  IN = 'in',
  ID = 'id',
  IR = 'ir',
  IQ = 'iq',
  IE = 'ie',
  IM = 'im',
  IL = 'il',
  IT = 'it',
  JM = 'jm',
  JP = 'jp',
  JE = 'je',
  JO = 'jo',
  KZ = 'kz',
  KE = 'ke',
  KI = 'ki',
  XK = 'xk',
  KW = 'kw',
  KG = 'kg',
  LA = 'la',
  LV = 'lv',
  LB = 'lb',
  LS = 'ls',
  LR = 'lr',
  LY = 'ly',
  LI = 'li',
  LT = 'lt',
  LU = 'lu',
  MO = 'mo',
  MG = 'mg',
  MW = 'mw',
  MY = 'my',
  MV = 'mv',
  ML = 'ml',
  MT = 'mt',
  MH = 'mh',
  MQ = 'mq',
  MR = 'mr',
  MU = 'mu',
  YT = 'yt',
  MX = 'mx',
  FM = 'fm',
  MD = 'md',
  MC = 'mc',
  MN = 'mn',
  ME = 'me',
  MS = 'ms',
  MA = 'ma',
  MZ = 'mz',
  MM = 'mm',
  NA = 'na',
  NR = 'nr',
  NP = 'np',
  NL = 'nl',
  NC = 'nc',
  NZ = 'nz',
  NI = 'ni',
  NE = 'ne',
  NG = 'ng',
  NU = 'nu',
  NF = 'nf',
  KP = 'kp',
  MK = 'mk',
  MP = 'mp',
  NO = 'no',
  OM = 'om',
  PK = 'pk',
  PW = 'pw',
  PS = 'ps',
  PA = 'pa',
  PG = 'pg',
  PY = 'py',
  PE = 'pe',
  PH = 'ph',
  PL = 'pl',
  PT = 'pt',
  PR = 'pr',
  QA = 'qa',
  RE = 're',
  RO = 'ro',
  RU = 'ru',
  RW = 'rw',
  BL = 'bl',
  SH = 'sh',
  KN = 'kn',
  LC = 'lc',
  MF = 'mf',
  PM = 'pm',
  VC = 'vc',
  WS = 'ws',
  SM = 'sm',
  ST = 'st',
  SA = 'sa',
  SN = 'sn',
  RS = 'rs',
  SC = 'sc',
  SL = 'sl',
  SG = 'sg',
  SX = 'sx',
  SK = 'sk',
  SI = 'si',
  SB = 'sb',
  SO = 'so',
  ZA = 'za',
  KR = 'kr',
  SS = 'ss',
  ES = 'es',
  LK = 'lk',
  SD = 'sd',
  SR = 'sr',
  SJ = 'sj',
  SE = 'se',
  CH = 'ch',
  SY = 'sy',
  TW = 'tw',
  TJ = 'tj',
  TZ = 'tz',
  TH = 'th',
  TL = 'tl',
  TG = 'tg',
  TK = 'tk',
  TO = 'to',
  TT = 'tt',
  TN = 'tn',
  TR = 'tr',
  TM = 'tm',
  TC = 'tc',
  TV = 'tv',
  VI = 'vi',
  UG = 'ug',
  UA = 'ua',
  AE = 'ae',
  GB = 'gb',
  US = 'us',
  UY = 'uy',
  UZ = 'uz',
  VU = 'vu',
  VA = 'va',
  VE = 've',
  VN = 'vn',
  WF = 'wf',
  EH = 'eh',
  YE = 'ye',
  ZM = 'zm',
  ZW = 'zw',
  AX = 'ax',
}

export interface Country {
  readonly name: string;
  readonly countryCode: CountryCodes;
  readonly ISO3CountryCodes?: ISO3CountryCodes;
  readonly dialCode: string;
  readonly priority: number;
  readonly areaCodes?: string[];
}

export enum ISO3CountryCodes {
  AFG = 'AFG',
  ALB = 'ALB',
  DZA = 'DZA',
  ASM = 'ASM',
  AND = 'AND',
  AGO = 'AGO',
  AIA = 'AIA',
  ATG = 'ATG',
  ARG = 'ARG',
  ARM = 'ARM',
  ABW = 'ABW',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BHS = 'BHS',
  BHR = 'BHR',
  BGD = 'BGD',
  BRB = 'BRB',
  BLR = 'BLR',
  BEL = 'BEL',
  BLZ = 'BLZ',
  BEN = 'BEN',
  BMU = 'BMU',
  BTN = 'BTN',
  BOL = 'BOL',
  BIH = 'BIH',
  BWA = 'BWA',
  BRA = 'BRA',
  IOT = 'IOT',
  VGB = 'VGB',
  BRN = 'BRN',
  BGR = 'BGR',
  BFA = 'BFA',
  BDI = 'BDI',
  KHM = 'KHM',
  CMR = 'CMR',
  CAN = 'CAN',
  CPV = 'CPV',
  BES = 'BES',
  CYM = 'CYM',
  CAF = 'CAF',
  TCD = 'TCD',
  CHL = 'CHL',
  CHN = 'CHN',
  CXR = 'CXR',
  CCK = 'CCK',
  COL = 'COL',
  COM = 'COM',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  CRI = 'CRI',
  CIV = 'CIV',
  HRV = 'HRV',
  CUB = 'CUB',
  CUW = 'CUW',
  CYP = 'CYP',
  CZE = 'CZE',
  DNK = 'DNK',
  DJI = 'DJI',
  DMA = 'DMA',
  DOM = 'DOM',
  ECU = 'ECU',
  EGY = 'EGY',
  SLV = 'SLV',
  GNQ = 'GNQ',
  ERI = 'ERI',
  EST = 'EST',
  SWZ = 'SWZ',
  ETH = 'ETH',
  FLK = 'FLK',
  FRO = 'FRO',
  FJI = 'FJI',
  FIN = 'FIN',
  FRA = 'FRA',
  GUF = 'GUF',
  PYF = 'PYF',
  GAB = 'GAB',
  GMB = 'GMB',
  GEO = 'GEO',
  DEU = 'DEU',
  GHA = 'GHA',
  GIB = 'GIB',
  GRC = 'GRC',
  GRL = 'GRL',
  GRD = 'GRD',
  GLP = 'GLP',
  GUM = 'GUM',
  GTM = 'GTM',
  GGY = 'GGY',
  GIN = 'GIN',
  GNB = 'GNB',
  GUY = 'GUY',
  HTI = 'HTI',
  HND = 'HND',
  HKG = 'HKG',
  HUN = 'HUN',
  ISL = 'ISL',
  IND = 'IND',
  IDN = 'IDN',
  IRN = 'IRN',
  IRQ = 'IRQ',
  IRL = 'IRL',
  IMN = 'IMN',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JPN = 'JPN',
  JEY = 'JEY',
  JOR = 'JOR',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KIR = 'KIR',
  XKX = 'XKX',
  KWT = 'KWT',
  KGZ = 'KGZ',
  LAO = 'LAO',
  LVA = 'LVA',
  LBN = 'LBN',
  LSO = 'LSO',
  LBR = 'LBR',
  LBY = 'LBY',
  LIE = 'LIE',
  LTU = 'LTU',
  LUX = 'LUX',
  MAC = 'MAC',
  MDG = 'MDG',
  MWI = 'MWI',
  MYS = 'MYS',
  MDV = 'MDV',
  MLI = 'MLI',
  MLT = 'MLT',
  MHL = 'MHL',
  MTQ = 'MTQ',
  MRT = 'MRT',
  MUS = 'MUS',
  MYT = 'MYT',
  MEX = 'MEX',
  FSM = 'FSM',
  MDA = 'MDA',
  MCO = 'MCO',
  MNG = 'MNG',
  MNE = 'MNE',
  MSR = 'MSR',
  MAR = 'MAR',
  MOZ = 'MOZ',
  MMR = 'MMR',
  NAM = 'NAM',
  NRU = 'NRU',
  NPL = 'NPL',
  NLD = 'NLD',
  NCL = 'NCL',
  NZL = 'NZL',
  NIC = 'NIC',
  NER = 'NER',
  NGA = 'NGA',
  NIU = 'NIU',
  NFK = 'NFK',
  PRK = 'PRK',
  MKD = 'MKD',
  MNP = 'MNP',
  NOR = 'NOR',
  OMN = 'OMN',
  PAK = 'PAK',
  PLW = 'PLW',
  PSE = 'PSE',
  PAN = 'PAN',
  PNG = 'PNG',
  PRY = 'PRY',
  PER = 'PER',
  PHL = 'PHL',
  POL = 'POL',
  PRT = 'PRT',
  PRI = 'PRI',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  BLM = 'BLM',
  SHN = 'SHN',
  KNA = 'KNA',
  LCA = 'LCA',
  MAF = 'MAF',
  SPM = 'SPM',
  VCT = 'VCT',
  WSM = 'WSM',
  SMR = 'SMR',
  STP = 'STP',
  SAU = 'SAU',
  SEN = 'SEN',
  SRB = 'SRB',
  SYC = 'SYC',
  SLE = 'SLE',
  SGP = 'SGP',
  SXM = 'SXM',
  SVK = 'SVK',
  SVN = 'SVN',
  SLB = 'SLB',
  SOM = 'SOM',
  ZAF = 'ZAF',
  KOR = 'KOR',
  SSD = 'SSD',
  ESP = 'ESP',
  LKA = 'LKA',
  SDN = 'SDN',
  SUR = 'SUR',
  SJM = 'SJM',
  SWE = 'SWE',
  CHE = 'CHE',
  SYR = 'SYR',
  TWN = 'TWN',
  TJK = 'TJK',
  TZA = 'TZA',
  THA = 'THA',
  TLS = 'TLS',
  TGO = 'TGO',
  TKL = 'TKL',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TKM = 'TKM',
  TCA = 'TCA',
  TUV = 'TUV',
  VIR = 'VIR',
  UGA = 'UGA',
  UKR = 'UKR',
  ARE = 'ARE',
  GBR = 'GBR',
  USA = 'USA',
  URY = 'URY',
  UZB = 'UZB',
  VUT = 'VUT',
  VAT = 'VAT',
  VEN = 'VEN',
  VNM = 'VNM',
  WLF = 'WLF',
  ESH = 'ESH',
  YEM = 'YEM',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
  ALA = 'ALA',
}
