import React from 'react';

import { SsoWidget } from '@app/queryTyping';

import { hasOwnPropertyOfType, isString } from '@app/common/utils';

import { ZoneName } from '@app/core/components/WidgetZone/constants';

import { WidgetRenderer, WidgetType } from './types';

export function isSSOWidget(
  widget: WidgetType,
): widget is Pick<SsoWidget, 'providerId' | 'width' | 'height'> {
  return (
  // eslint-disable-next-line no-underscore-dangle
    widget.__typename === 'SsoWidget'
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'providerId', isString)
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'width', isString)
      // eslint-disable-next-line i18next/no-literal-string
      && hasOwnPropertyOfType(widget, 'height', isString)
  );
}

const WidgetComponent = React.lazy(() => import(
  // webpackChunkName: "single-sign-on"
  '@app/widgets/single-sign-on'
));

export const SSOWidgetRenderer: WidgetRenderer<SsoWidget> = ({ widget, zoneName }) => (
  <div>
    <WidgetComponent
      data={{
        providerId: widget.providerId!,
        width: widget.width!,
        height: widget.height!,
        aside: zoneName !== ZoneName.Middle ? 'true' : 'false',
      }}
    />
  </div>
);
