import React, {
  cloneElement, FunctionComponent, HTMLAttributes, ReactElement, useState,
} from 'react';

import classNames from 'classnames';

import Empty from '@ast/magma/components/icon/icons/Empty';

import { TextItem } from '@ast/magma/components/list/textitem/textitem';
import { Menu, MenuItem } from '@ast/magma/components/menu';

import { LinkMenuItem } from '@app/common/types/useMainMenuQuery';
import { getMenuIcon } from '@app/common/utils/iconsProvider';

import { MenuItemEligibility } from '@app/core/components/MenuNavigation/MenuItemEligibility';
import { useSelectedMainMenuItemContext } from '@app/core/contexts/selectedMainMenuItem/SelectedMainMenuItemContext';

import styles from './mainnavsection.pcss';

/**
 * MainNavSection properties.
 */
export interface MainNavSectionProps {
  /**
   * On item selected handler.
   */
  readonly onSelectLink?: (data: LinkMenuItem) => void;
  readonly menuId: string;
}

export const MainNavSection: FunctionComponent<LinkMenuItem
& MainNavSectionProps
& HTMLAttributes<HTMLDivElement>> = ({
  menuId,
  icon,
  linkName,
  menuItems,
  className,
  onSelectLink,
  ...divProps
}) => {
  const [, setEligibleItemsList] = useState<Record<string, boolean>>({});

  const selectedMainMenuItem = useSelectedMainMenuItemContext();
  const styleMenuIcon = (menuIcon:ReactElement):ReactElement => (
    menuIcon && cloneElement(menuIcon, { className: styles.icon })
  );

  const onAfterStatusCheck = (id: string, isEligible: boolean) => {
    setEligibleItemsList((prev) => ({ ...prev, [id]: isEligible }));
  };

  return (
    <div className={classNames(styles.navSectionContainer, className)} {...divProps}>
      <TextItem
        className={styles.title}
        getItemIcon={() => styleMenuIcon(icon ? getMenuIcon(icon)! : <Empty />)}
        data={linkName}
        data-stable-name="MainNavSectionTitle"
      />
      <Menu
        data-stable-name="MenuItemList"
        className={styles.menu}
      >
        {
          menuItems!.map((menuItem:LinkMenuItem) => (
            <MenuItemEligibility
              key={menuItem.id}
              menuItem={menuItem}
              onAfterStatusCheck={onAfterStatusCheck}
            >
              <MenuItem
                data-stable-name={`MainMenuItem-${menuItem?.id}`}
                className={styles.menuItem}
                key={menuItem.linkName}
                data={menuItem}
                getItemIcon={((data:LinkMenuItem) => (
                  styleMenuIcon(data.icon ? getMenuIcon(data.icon)! : <Empty />))
                )}
                getItemLabel={(data:LinkMenuItem) => data.linkName}
                onSelect={onSelectLink}
                aria-selected={menuItem.url === selectedMainMenuItem?.url}
              />
            </MenuItemEligibility>
          ))
        }
      </Menu>
    </div>
  );
};

MainNavSection.defaultProps = {};
