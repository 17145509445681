/* eslint-disable class-methods-use-this */
import { ApolloClient } from '@apollo/client/core/ApolloClient';

import {
  GetBrandingVariablesDocument,
  GetBrandingVariablesQuery,
  GetBrandingVariablesQueryVariables,
} from '@app/core/styles/initializeBranding/queries/queryTyping/getBrandingVariables';

import { AgileSDKUnexpectedError } from '../../errors/AgileSDKUnexpectedError';
import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetBrandingInfoRequestMessage } from './assertions/isGetBrandingInfoRequestMessage';

import { GET_BRANDING_INFO_REQUEST_MESSAGE_TYPE } from './constants';
import { GetBrandingInfoResponseMessage } from './types';

/**
 * Branding information request handler service.
 */
export class BrandingInfoAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly client: ApolloClient<unknown>;

  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    client: ApolloClient<unknown>,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      GET_BRANDING_INFO_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.client = client;
    this.sender = sender;
  }

  async handle(request: AgileSDKRequest): Promise<boolean> {
    const { message, sender } = request;

    if (!isGetBrandingInfoRequestMessage(message)) {
      return false;
    }

    const { data, error } = await this.client.query<GetBrandingVariablesQuery, GetBrandingVariablesQueryVariables>({
      query: GetBrandingVariablesDocument,
      fetchPolicy: 'cache-first',
      variables: {},
    });

    if (error) {
      throw new AgileSDKUnexpectedError(error.message);
    }

    const payload = data.branding;
    if (!payload) {
      // eslint-disable-next-line i18next/no-literal-string
      throw new AgileSDKUnexpectedError('No payload received.');
    }

    const response: GetBrandingInfoResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {
        darkThemeVariables: payload.darkThemeVariables.reduce((acc, { name, value }) => {
          acc[name] = value;
          return acc;
        }, {} as Record<string, string>),
        lightThemeVariables: payload.lightThemeVariables.reduce((acc, { name, value }) => {
          acc[name] = value;
          return acc;
        }, {} as Record<string, string>),
      },
    };

    this.sender(response, sender);

    return true;
  }
}
