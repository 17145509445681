import * as React from 'react';
import { createGenericContext } from '../../../common/contexts/createGenericContext';
import { FiSettingsFragment, useGetFiSettingsQuery } from './queries/queryTyping/getFISettings';

type FiSettingsExtended = FiSettingsFragment & {
  /** Returns property if it's configured */
  getIfConfigured: <T extends keyof FiSettingsFragment>(prop: T) => FiSettingsFragment[T] | null
};

// Generate context
const [useFISettingsContext, FISettingsContextProvider] = createGenericContext<FiSettingsExtended | null>();

// Generate provider
const FISettingsProvider: React.FC = ({ children }) => {
  const { data, error } = useGetFiSettingsQuery();
  let value: FiSettingsExtended | null = null;

  if (error) throw error;

  if (data && data.fiSettings) {
    const { fiSettings } = data;
    value = {
      ...fiSettings,
      getIfConfigured: (prop) => (
        (value![prop] !== 'NOT-CONFIGURED' && value![prop]) || null
      ),
    };
  }

  return (
    <FISettingsContextProvider value={value}>
      {children}
    </FISettingsContextProvider>
  );
};

export { useFISettingsContext, FISettingsProvider };
