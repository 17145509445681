import { isWindowNameSet } from '@app/core/windowName';

import { isNavigationFromTetheredLogin } from '@app/common/utils/isNavigationFromTetheredLogin/isNavigationFromTetheredLogin';
import { isPageReloaded } from '@app/common/utils/isPageReloaded';

import { isEmbeddedMode } from '../../embeddedMode/embeddedMode';
import { isLoggedIn, isLoginPage } from '../../login/isLoggedIn';
import { isScsoMode } from '../../scsoMode/scsoMode';

export const needForceLogout = () => [
  !isWindowNameSet(),
  !isNavigationFromTetheredLogin(),
  !isPageReloaded(),
  !isEmbeddedMode(),
  !isScsoMode(),
  isLoggedIn(),
  !isLoginPage(),
].every(Boolean);
