/* eslint-disable i18next/no-literal-string */

import { GeneratePasskeyAuthOptionsQuery } from '@app/widgets/user-login/LoginWizard/steps/UsernamePassword/queries/queryTyping/generate-passkey-auth-options';

/**
 * An attempt to communicate that this isn't just any string, but a Base64URL-encoded string
 */
declare type Base64URLString = string;

interface PublicKeyCredentialDescriptorJSON extends Omit<PublicKeyCredentialDescriptor, 'id' | 'type' | 'transports'> {
  id: Base64URLString;
  transports: string[];
}

export function bufferDecode(baseurl64String: string) {
  // Base64url to Base64
  const padding = '=='.slice(0, (4 - (baseurl64String.length % 4)) % 4);
  const base64String = baseurl64String.replace(/-/g, '+').replace(/_/g, '/') + padding;

  // Base64 to binary string
  const str = atob(base64String);

  // Binary string to buffer
  const buffer = new ArrayBuffer(str.length);
  const byteView = new Uint8Array(buffer);

  for (let i = 0; i < str.length; i++) {
    byteView[i] = str.charCodeAt(i);
  }
  return buffer;
}

export function bufferEncode(buffer: any) {
  // Buffer to binary string
  const byteView = new Uint8Array(buffer);

  const str = Array.from(byteView).map((byte) => String.fromCharCode(byte)).join('');

  // Binary string to base64
  const base64String = btoa(str);

  // Base64 to base64url
  // We assume that the base64url string is well-formed.
  const base64urlString = base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return base64urlString;
}

export function convertCreateOptions({
  challenge,
  user,
  excludeCredentials,
  authenticatorSelection,
  ...other
}: any) {
  return {
    ...other,
    challenge: bufferDecode(challenge),
    user: { ...user, id: bufferDecode(user.id) },
    excludeCredentials: excludeCredentials.map((credential: any) => ({
      ...credential,
      id: bufferDecode(credential.id),
    })),
    authenticatorSelection: {
      residentKey: authenticatorSelection.residentKey,
      requireResidentKey: authenticatorSelection.requireResidentKey,
      userVerification: authenticatorSelection.userVerification,
    },
  };
}

export function convertCreateCredential(credential: Credential) {
  const {
    // @ts-ignore
    id, rawId, response, type, authenticatorAttachment,
  } = credential;

  let userHandle;
  if (response.userHandle) {
    userHandle = bufferEncode(response.userHandle);
  }

  // Convert values to base64 to make it easier to send back to the server
  return {
    id,
    rawId: bufferEncode(rawId),
    response: {
      attestationObject: bufferEncode(response.attestationObject),
      clientDataJSON: bufferEncode(response.clientDataJSON),
      // signature: bufferEncode(response.signature),
      userHandle,
      transports: response.getTransports ? response.getTransports() : [],
    },
    authenticatorAttachment,
    type,
  };
}

export function convertGetOptions(
  { challenge, allowCredentials, ...other }: GeneratePasskeyAuthOptionsQuery['generateAuthOptions'],
) {
  return {
    ...other,
    challenge: bufferDecode(challenge),
    allowCredentials: allowCredentials.map((credential: PublicKeyCredentialDescriptorJSON) => ({
      ...credential,
      transports: credential.transports,
      id: bufferDecode(credential.id),
      type: 'public-key' as PublicKeyCredentialType,
    })),
    userVerification: other.userVerification as UserVerificationRequirement,
  };
}

export function convertGetCredential(credential: Credential) {
  const {
    // @ts-ignore
    id, rawId, response, type, authenticatorAttachment,
  } = credential;

  // Convert values to base64 to make it easier to send back to the server
  return {
    id,
    rawId: bufferEncode(rawId),
    response: {
      authenticatorData: bufferEncode(response.authenticatorData),
      clientDataJSON: bufferEncode(response.clientDataJSON),
      signature: bufferEncode(response.signature),
      userHandle: bufferEncode(response.userHandle),
    },
    type,
    authenticatorAttachment,
  };
}
