/**
 * The time interval when the auto-logout countdown should start and till to the user disconnection
 * @default 1 min
 */
export const FROM_AWAY_TO_AUTOLOGOUT_INTERVAL_IN_MS = 60000;

/**
 * If for some case we can't get backend value use frontend fallback to not broke the autologout
 * @default 6 minutes in ms (6 min * 60 sec * 1000 ms)
 */
export const SESSION_EXPIRATION_FALLBACK_IN_MS = 6 * 60 * 1000;
