import { isApolloError, ApolloError } from '@apollo/client';
import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import {  logoutClear } from '@app/core/authentication/logout/logout';
import { requestNativeApp } from '@app/core/nativeApps/events/requestNativeApp';
import { PageURL } from '@app/core/widgets/pages';

import { ErrorCodes } from '@app/queryTyping';

import { TokenExpiredError } from '../TokenExpiredError';

export const USER_TOKEN_EXPIRED_REQUEST = 'ast.userTokenExpired';
export const USER_TOKEN_EXPIRED_RESPONSE = 'ast.userTokenExpiredResult';

/**
 * Dispatches the error event for window.
 * Sends event to native apps.
 */
export const dispatchUserTokenExpiredToNativeApp = async () => {
  await requestNativeApp({
    eventId: '',
    requestPayload: {},
    requestEventType: USER_TOKEN_EXPIRED_REQUEST,
    responseEventType: USER_TOKEN_EXPIRED_RESPONSE,
  });
};

/**
 * Process the receieved error.
 * Log out user of web apps, informs native apps.
 */
export const processUserTokenExpiredError = () => {
  if (isEmbeddedMode()) {
    dispatchUserTokenExpiredToNativeApp();
  }

  logoutClear();
  // redirect to login page
  window?.location?.replace(PageURL.USER_LOGIN);
};

/**
 * User session expired session error processor
 * @param error any error.
 */
// eslint-disable-next-line arrow-body-style
export const userTokenExpiredErrorProcessor = (error:Error):Error => {
  if (isApolloError(error)) {
    const { graphQLErrors } = error as ApolloError;

    if (graphQLErrors.length > 0 && graphQLErrors[0].extensions?.code === ErrorCodes.FORCE_USER_INFO_RESET) {
      processUserTokenExpiredError();
      return new TokenExpiredError();
    }
  }

  return error;
};
