import { Migration } from '@app/core/versionedSerializer/versionedSerializer';

import { proxiedWindow } from './proxiedStorage';
import { StorageDocument } from './StorageDocument';

/**
 * Abstract class to save\load data structures from\to session storage.
 * All decendance must implement public data properties and
 * methods how to turn these properties into saved data and vise versa.
 * T - class instance to be serialized/deserialized. Should only contain basic type properties.
 */
export abstract class SessionStorageDocument<T> extends StorageDocument<T> {
  /**
   * Class constrcutor.
   * @param node session storage node key to store data into.
   * @param version latest version of saved data.
   * @param migrations array of migrations to be applied to data saved in previous versions.
   * For example: current version is 5 and data saved in version 3. In this case
   * saved data must be passed to migrations 4 and 5 to convert data to latest version.
   */
  constructor(node: string, version: number, migrations: Migration[] = []) {
    super(proxiedWindow.sessionStorage, node, version, migrations);
  }
}
