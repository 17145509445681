import { ValueNotifier } from '@app/common/utils/notifier/ValueNotifier';
import { VoidCallback } from '@app/common/utils/types';

import { USER_LOGGED_OUT_EVENT } from '../logout/UserLoggedOutEvent';

import { isLoggedIn } from './isLoggedIn';
import { USER_LOGGED_IN_EVENT } from './UserLoggedInEvent';

/**
 * Login notifier interface.
 *
 * Can be used for dependency injection.
 */
export interface ILoginNotifier extends ValueNotifier<boolean> {}

/**
 * A notifier that tracks the user login state.
 *
 * Notifies subscribers when the user logs in or out.
 *
 * Usage:
 * ```ts
 * // Create a notifier instance.
 * const notifier = new LoginNotifier();
 * // Read the current value.
 * console.log('User logged in:', notifier.value);
 * // Subscribe to notifications.
 * const unsubscribe = notifier.subscribe((value) => {
 *  console.log('User logged in:', value);
 * });
 * // Unsubscribe when no longer needed.
 * unsubscribe();
 * // Dispose the notifier when no longer needed.
 * notifier.dispose();
 * ```
 *
 * See {@link ValueNotifier} for more details.
 */
export class LoginNotifier extends ValueNotifier<boolean> {
  protected eventListener: VoidCallback;

  constructor() {
    super(isLoggedIn());

    this.eventListener = this.handleEvent.bind(this);
  }

  init() {
    window.addEventListener(USER_LOGGED_IN_EVENT, this.eventListener);
    window.addEventListener(USER_LOGGED_OUT_EVENT, this.eventListener);
  }

  dispose() {
    super.dispose();

    window.removeEventListener(USER_LOGGED_IN_EVENT, this.eventListener);
    window.removeEventListener(USER_LOGGED_OUT_EVENT, this.eventListener);
  }

  protected handleEvent() {
    this.value = isLoggedIn();
  }
}
