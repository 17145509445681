import { AgileSDKMessageSender } from './types';

/**
 * Default Agile SDK messages sender.
 *
 * Uses the `postMessage` method to send messages to the target window.
 */
export const sendAgileSDKMessage: AgileSDKMessageSender = (message, target) => {
  target.source.postMessage(message, target.origin);
};
