import React, {
  FC, useCallback, useState,
} from 'react';

import classNames from 'classnames';

import { CenterAlignContainer } from '@app/common/components/CenterAlignContainer/CenterAlignContainer';
import {
  HorizontalBannerWidgetZone,
} from '@app/common/components/HorizontalBannerWidgetZone/HorizontalBannerWidgetZone';

import { ZoneName } from '@app/core/components/WidgetZone/constants';

import styles from './FullscreenLayoutHorizontalZone.pcss';

/**
 * Horizontal ads zone component properties
 */
export interface FullscreenLayoutHorizontalZoneProps {
  readonly className?: string;
  readonly zoneName: ZoneName.HeaderBanner | ZoneName.FooterBanner | ZoneName.PreMiddle | ZoneName.PostMiddle;
}

/**
 * Horizontal ads zone to be placed inside page above and below FullscreenLayout.
 */
// eslint-disable-next-line import/prefer-default-export
export const FullscreenLayoutHorizontalZone:FC<FullscreenLayoutHorizontalZoneProps> = (
  { className, zoneName },
) => {
  const [isEmpty, setEmpty] = useState<Boolean>(true);
  const onRenderWidgetsInner = useCallback((widgetCount:number) => {
    setEmpty(widgetCount === 0);
  }, [zoneName]);
  return (
    <CenterAlignContainer className={classNames(
      className,
      styles.layout,
      isEmpty && styles.empty,
    )}
    >
      <HorizontalBannerWidgetZone
        className={classNames(
          styles.bannerInner,
        )}
        zoneName={zoneName}
        onRenderWidgets={onRenderWidgetsInner}
      />
    </CenterAlignContainer>
  );
};
