import React from 'react';

import { ConnectedWizardStep } from '@app/common/configurable-wizards';
import { DeliveryChannelTypesFieldLocator } from '@app/common/configurable-wizards/fields/DeliveryChannelTypes';
import { useWizardStepStateContext } from '@app/common/configurable-wizards/WizardStateStateContext';

import { LayeredAuthenticationWizardButtons } from '../../../../LayeredAuthenticationWizardButtons';
import { LayeredAuthenticationWizardStep } from '../../../../LayeredAuthenticationWizardStep';

export const SelectOneTimePinChannel: React.FC<ConnectedWizardStep> = ({
  locators,
  ...props
}) => {
  const stepLocators = [DeliveryChannelTypesFieldLocator, ...(locators || [])];
  const { allowBackRedirect } = useWizardStepStateContext();

  return (
    <LayeredAuthenticationWizardStep
      {...props}
      locators={stepLocators}
      buttons={({ cancel, back }) => (
        <LayeredAuthenticationWizardButtons
          showBackButton={allowBackRedirect}
          back={back}
          cancel={cancel}
          showContinueButton={false}
        />
      )}
    />
  );
};
