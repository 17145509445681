import React, { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { isErrorNonRenderable } from '../errorTypeChecker';
import { genericErrorHandler } from '../genericErrorHandler';
import { UserError } from '../UserError';

/**
 * Error boundary properties.
 */
export type GenericErrorBoundaryProps = {
  resetKeys?: any[];
  children: React.ReactNode
  fallback: (userError:UserError) => ReactElement;
};

/**
 * Generic error boundary component.
 * Intercepts all incoming exceptions from underliying children and call fallback to show error component.
 */
export const GenericErrorBoundary = (props:GenericErrorBoundaryProps) => {
  const {
    fallback,
    children,
    resetKeys,
  } = props;

  // Look on route location path to reset error on path change
  const location = useLocation();

  /** Check error status and ignore fallback render
   * example: TokenExpiredError on session expire, redirect with no error card rendering
   * @param {Error} error - error thrown form processors
   * @returns {ReactElement | null} Returns error component render, or null if rendering avoided
   */
  const fallbackRender = ({ error }: { error: Error }) => {
    if (isErrorNonRenderable(genericErrorHandler(error))) { 
      return null; 
    }
    return fallback(genericErrorHandler(error));
  };

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      resetKeys={[...(resetKeys || []), location.pathname]}
    >
      { children }
    </ErrorBoundary>
  );
};
