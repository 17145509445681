import { isOrpeusWidget } from '@app/core/widgets/react/orpheusWidget';
import { WidgetType } from '@app/core/widgets/react/types';

/**
 * Splits widgets into three zones: premiddle, middle, and postmiddle.
 */
export const getMiddleZones = (widgets: WidgetType[] = []) => {
  const orpheusWidgetIndex = widgets.findIndex(isOrpeusWidget);

  if (orpheusWidgetIndex === -1) {
    return {
      premiddle: [],
      middle: widgets,
      postmiddle: [],
    };
  }

  return {
    premiddle: widgets.slice(0, orpheusWidgetIndex),
    middle: [widgets[orpheusWidgetIndex]],
    postmiddle: widgets.slice(orpheusWidgetIndex + 1, widgets.length),
  };
};
