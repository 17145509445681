import { ISource } from '@ast/shared-message-receiver';

import { AgileSDKService } from '../../utils/abstract/AgileSDKService';
import { handleMessage } from './utils/handleMessage';
import { RequestHandler } from './utils/handlers/types';
import { isLegacyRequestMessage } from './utils/isLegacyRequestMessage';

/**
 * Agile SDK service for the legacy Agile SDK messages.
 *
 * Presented for the backward compatibility with the legacy Agile SDK messages.
 */
export class LegacyAgileSDKService extends AgileSDKService {
  private readonly allowedOrigins: Set<string>;

  private readonly handlers: Record<string, RequestHandler>;

  private readonly listener: (message: MessageEvent<unknown>) => Promise<boolean>;

  constructor(
    allowedOrigins: Set<string>,
    handlers: Record<string, RequestHandler>,
  ) {
    super();

    this.allowedOrigins = allowedOrigins;
    this.handlers = handlers;

    this.listener = (message) => this.handle(message);
  }

  async init(): Promise<void> {
    window.addEventListener('message', this.listener);
  }

  async dispose(): Promise<void> {
    window.removeEventListener('message', this.listener);
  }

  async handle(message: MessageEvent<unknown>): Promise<boolean> {
    const { source, origin, data } = message;

    if (!this.allowedOrigins.has(origin)) {
      return false;
    }

    if (!isLegacyRequestMessage(data)) {
      return false;
    }

    const result = await handleMessage({ data, origin, source: source as ISource }, this.handlers);

    return result;
  }
}
