/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { WizardNextStepFragment } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-result-next-step';
import { gql } from '@apollo/client';
import { WizardNextStepFragmentDoc } from '../../../../../common/configurable-wizards/queries/queryTyping/wizard-result-next-step';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type PostTriggeredEventStepMutationVariables = Types.Exact<{
  stepParams: Types.SubmitTriggeredEventParams;
}>;


export type PostTriggeredEventStepMutation = (
  { __typename?: 'Mutation' }
  & { triggeredEventStep: Types.Maybe<(
    { __typename?: 'NextStep' }
    & WizardNextStepFragment
  ) | (
    { __typename: 'TriggeredEventFinished' }
    & Pick<Types.TriggeredEventFinished, 'wizardFinished'>
  )> }
);


export const PostTriggeredEventStepDocument = gql`
    mutation PostTriggeredEventStep($stepParams: SubmitTriggeredEventParams!) {
  triggeredEventStep(submitTriggeredEventParams: $stepParams) {
    ...WizardNextStep
    ... on TriggeredEventFinished {
      __typename
      wizardFinished
    }
  }
}
    ${WizardNextStepFragmentDoc}`;
export type PostTriggeredEventStepMutationFn = Apollo.MutationFunction<PostTriggeredEventStepMutation, PostTriggeredEventStepMutationVariables>;

/**
 * __usePostTriggeredEventStepMutation__
 *
 * To run a mutation, you first call `usePostTriggeredEventStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostTriggeredEventStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postTriggeredEventStepMutation, { data, loading, error }] = usePostTriggeredEventStepMutation({
 *   variables: {
 *      stepParams: // value for 'stepParams'
 *   },
 * });
 */
export function usePostTriggeredEventStepMutation(baseOptions?: Apollo.MutationHookOptions<PostTriggeredEventStepMutation, PostTriggeredEventStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostTriggeredEventStepMutation, PostTriggeredEventStepMutationVariables>(PostTriggeredEventStepDocument, options);
      }
export type PostTriggeredEventStepMutationHookResult = ReturnType<typeof usePostTriggeredEventStepMutation>;
export type PostTriggeredEventStepMutationResult = Apollo.MutationResult<PostTriggeredEventStepMutation>;
export type PostTriggeredEventStepMutationOptions = Apollo.BaseMutationOptions<PostTriggeredEventStepMutation, PostTriggeredEventStepMutationVariables>;