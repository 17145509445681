import React, { FC } from 'react';

import classNames from 'classnames';

import { ZoneName } from '@app/core/components/WidgetZone/constants';
import { WidgetZone } from '@app/core/components/WidgetZone/WidgetZone';

import styles from './HorizontalBannerWidgetZone.pcss';

/**
 * Horizontal banner widget zone properties
 */
export interface HorizontalBannerWidgetZoneProps {
  readonly className?: string;
  readonly zoneName: ZoneName.HeaderBanner | ZoneName.FooterBanner | ZoneName.PreMiddle | ZoneName.PostMiddle;
  readonly onRenderWidgets?: (widgetCount:number) => void;
}

/**
 * Horizontal banner widget zone to be placed inside page main and fullscreen layout
 */
// eslint-disable-next-line import/prefer-default-export
export const HorizontalBannerWidgetZone:FC<HorizontalBannerWidgetZoneProps> = (
  { className, zoneName, onRenderWidgets },
// eslint-disable-next-line arrow-body-style
) => {
  return (
    <WidgetZone
      className={classNames(
        className,
        styles.bannerContent,
        zoneName === ZoneName.HeaderBanner && styles.headerZone,
        zoneName === ZoneName.FooterBanner && styles.footerZone,
        zoneName === ZoneName.PostMiddle && styles.postMiddleZone,
        zoneName === ZoneName.PreMiddle && styles.preMiddleZone,
      )}
      zoneName={zoneName}
      onRenderWidgets={onRenderWidgets}
    />
  );
};
