import * as React from 'react';

import { SessionSettings } from '@app/queryTyping';

import { createGenericContext } from '@app/common/contexts/createGenericContext';
import { useGetSessionSettingsQuery } from './queries/queryTyping/getSessionSettings';

const [useSessionSettingsContext, SessionSettingsContextProvider] = createGenericContext<SessionSettings | null>('SessionSettingsContext');

/**
 * Represents the user session settings information
 */
const SessionSettingsProvider: React.FC = ({ children }) => {
  const { data, error } = useGetSessionSettingsQuery({
    fetchPolicy: 'no-cache',
  });

  let value: SessionSettings | null = null;

  if (error) throw error;

  if (data?.sessionSettings) {
    value = data?.sessionSettings;
  }

  return (
    <SessionSettingsContextProvider value={value}>
      {children}
    </SessionSettingsContextProvider>
  );
};

export { useSessionSettingsContext, SessionSettingsProvider };
