/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetMenuQueryVariables = Types.Exact<{
  ids: Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>;
}>;


export type GetMenuQuery = (
  { __typename?: 'Query' }
  & { menu: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'MenuRoot' }
    & Pick<Types.MenuRoot, 'id'>
    & { menuItems: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'MenuItem' }
      & Pick<Types.MenuItem, 'id' | 'linkName' | 'icon' | 'url' | 'isEligibilityCheckRequired'>
      & { eligibilityCheckTag: Types.Maybe<(
        { __typename?: 'EligibilityCheckTag' }
        & Pick<Types.EligibilityCheckTag, 'serviceType' | 'serviceName'>
      )>, menuItems: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'MenuItem' }
        & Pick<Types.MenuItem, 'id' | 'linkName' | 'icon' | 'url' | 'isEligibilityCheckRequired'>
        & { eligibilityCheckTag: Types.Maybe<(
          { __typename?: 'EligibilityCheckTag' }
          & Pick<Types.EligibilityCheckTag, 'serviceType' | 'serviceName'>
        )>, menuItems: Types.Maybe<Array<Types.Maybe<(
          { __typename?: 'MenuItem' }
          & Pick<Types.MenuItem, 'id' | 'linkName' | 'icon' | 'url' | 'isEligibilityCheckRequired'>
          & { eligibilityCheckTag: Types.Maybe<(
            { __typename?: 'EligibilityCheckTag' }
            & Pick<Types.EligibilityCheckTag, 'serviceType' | 'serviceName'>
          )> }
        )>>> }
      )>>> }
    )>>> }
  )>>> }
);


export const GetMenuDocument = gql`
    query GetMenu($ids: [String]!) {
  menu(ids: $ids) {
    id
    menuItems {
      ... on MenuItem {
        id
        linkName
        icon
        url
        isEligibilityCheckRequired
        eligibilityCheckTag {
          serviceType
          serviceName
        }
        menuItems {
          ... on MenuItem {
            id
            linkName
            icon
            url
            isEligibilityCheckRequired
            eligibilityCheckTag {
              serviceType
              serviceName
            }
            menuItems {
              ... on MenuItem {
                id
                linkName
                icon
                url
                isEligibilityCheckRequired
                eligibilityCheckTag {
                  serviceType
                  serviceName
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
      }
export function useGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, options);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;