import { ApolloError, isApolloError } from '@apollo/client';

export const TE_CANCELED_ERROR_CODE = 'TE_CHALLENGE_CANCELED';

export const isTriggeredEventCanceledApolloError = (error: any): error is TriggeredEventCanceledApolloError => (
  isApolloError(error) && (
    error.extraInfo?.code === TE_CANCELED_ERROR_CODE
    // todo: temporary solution reaised by apollo client bug
    || (error.networkError as any)?.extraInfo?.code === TE_CANCELED_ERROR_CODE
  )
);

export class TriggeredEventCanceledApolloError extends ApolloError {
  /*
  * Workaround for distinguish TriggeredEventCanceledApolloError from ApolloError
  * in inference of isTriggeredEventCanceledApolloError.
  */
  readonly className = 'TriggeredEventCanceledApolloError';

  constructor() {
    // eslint-disable-next-line i18next/no-literal-string
    super({ errorMessage: 'Triggered event canceled by a user', extraInfo: { code: TE_CANCELED_ERROR_CODE } });
  }
}
