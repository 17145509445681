import React, {
  FC, forwardRef, Ref,
  RefAttributes,
} from 'react';

import classNames from 'classnames';

import { ZoneName } from '@app/core/components/WidgetZone/constants';
import { WidgetZone } from '@app/core/components/WidgetZone/WidgetZone';

import styles from './VerticalBannerWidgetZone.pcss';

/**
 * Vertical banner widget zone properties
 */
export interface VerticalBannerWidgetZoneProps {
  readonly className?: string;
  readonly onRenderWidgets?: (widgetCount:number) => void;
}

/**
 * Vertical banner widget zone to be placed inside page main and fullscreen layout
 */
export const VerticalBannerWidgetZone:FC<VerticalBannerWidgetZoneProps & RefAttributes<HTMLDivElement>> = forwardRef((
  { className, onRenderWidgets },
  ref:Ref<HTMLDivElement>,
) => (
  <WidgetZone
    ref={ref}
    className={classNames(
      className,
      styles.bannerContent,
      styles.rightSize,
    )}
    zoneName={ZoneName.RightSide}
    onRenderWidgets={onRenderWidgets}
  />
));
