import { FieldContext, FieldLocator, isSingleChoiceField } from '@app/common/configurable-wizards';
import {
  SingleChoiceRadioButtonsFieldComponent,
} from './SingleChoice/RadioButtons/SingleChoiceRadioButtonsField';

const isDeliveryAddressIdFieldLocator = (fieldContext: FieldContext) => (
  isSingleChoiceField(fieldContext.field)
  && fieldContext.field.id === 'DeliveryAddressId'
);

export const DeliveryAddressIdFieldLocator: FieldLocator = (context) => (
  isDeliveryAddressIdFieldLocator(context)
    ? SingleChoiceRadioButtonsFieldComponent
    : undefined
);
