import { AgileSDKFrameSetSizeEvent } from '../../components/AgileSDKFrame/events/AgileSDKFrameSetSizeEvent';
import { RequestHandlerAgileSDKService } from '../../utils/abstract/RequestHandlerAgileSDKService';
import { AgileSDKRequestListener } from '../../utils/AgileSDKRequestListener';
import { AgileSDKMessageSender, AgileSDKRequest } from '../../utils/types';
import { isGetViewportRequestMessage } from './assertions/isGetViewportRequestMessage';
import { findFrameByWindow } from './utils/findFrameByWindow';

import { SET_VIEWPORT_SIZE_REQUEST_MESSAGE_TYPE } from './constants';
import { SetViewportSizeResponseMessage } from './types';

/**
 * Service for controlling the viewport (iframe) that contains the client.
 *
 * For instance, allows the client to request a new size for the viewport.
 */
export class ViewportAgileSDKService extends RequestHandlerAgileSDKService {
  private readonly sender: AgileSDKMessageSender;

  constructor(
    listener: AgileSDKRequestListener,
    sender: AgileSDKMessageSender,
  ) {
    const messageTypes = new Set([
      SET_VIEWPORT_SIZE_REQUEST_MESSAGE_TYPE,
    ]);

    super(listener, messageTypes);

    this.sender = sender;
  }

  async handle(request: AgileSDKRequest) {
    const { message, sender } = request;

    if (!isGetViewportRequestMessage(message)) {
      return false;
    }

    // Skip messages from the window itself.
    if (sender.source === window) {
      return false;
    }

    // Find the iframe that sent the message.
    const iframe = findFrameByWindow(sender.source);
    if (!iframe) {
      return false;
    }

    // Extract the `uid` of the iframe to send the message to.
    const { uid } = iframe.dataset;
    if (!uid) {
      return false;
    }

    // Create a new event to notify the iframe about the new size.
    const event = new AgileSDKFrameSetSizeEvent({
      uid,
      size: { ...message.payload },
    });

    // Notify the iframe about the new desired size, so it can adjust itself.
    window.dispatchEvent(event);

    const response: SetViewportSizeResponseMessage = {
      id: message.id,
      version: '1.0',
      payloadVersion: '1.0',
      payload: {},
    };

    this.sender(response, sender);

    return true;
  }
}
