import { isObject, isString } from '@app/common/utils/assertion';

import { LocationData } from '../types';

/**
 * Checks if the value is a {@link LocationData}.
 */
export function isLocationData(value: unknown): value is LocationData {
  return (
    isObject(value)

    && 'href' in value
    && isString(value.href)
  );
}
