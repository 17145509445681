import { isObject, isString } from '@app/common/utils/assertion';

import { GET_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE } from '../constants';
import { GetUserCustomDataRequestMessage } from '../types';

import { AgileSDKRequestMessage } from '../../../utils/types';

function isGetUserCustomDataParameters(payload: unknown): boolean {
  return isObject(payload)
    && 'scope' in payload
    && isString(payload.scope);
}

/**
 * Checks if the value is a {@link GetUserCustomDataRequestMessage}.
 */
// eslint-disable-next-line max-len
export function isGetUserCustomDataRequestMessage(value: AgileSDKRequestMessage<unknown>): value is GetUserCustomDataRequestMessage {
  return value.messageType === GET_USER_CUSTOM_DATA_REQUEST_MESSAGE_TYPE
  && isGetUserCustomDataParameters(value.payload);
}
