import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Path } from 'history';

import { LocalizationSelector } from '@app/common/components/LocalizationSelector/LocalizationSelector';

import { isEmbeddedMode } from '@app/core/authentication/embeddedMode/embeddedMode';
import { useIsLoggedIn } from '@app/core/authentication/login/isLoggedIn';
import {
  extractInitParametersFromLocalStorage,
} from '@app/core/authentication/parametrizedLogin/sessionParametersStorage';
import { useFISettingsContext } from '@app/core/contexts/fiSettings/FISettingsContext';
import {
  DEFAULT_FALLBACK_LANGUAGE_CULTURE,
  SEARCH_LANGUAGE_CULTURE_PARAM,
} from '@app/core/contexts/localizationContext/constants';
import {
  getLanguageFromCulture,
  useLocalizationContext,
} from '@app/core/contexts/localizationContext/LocalizationContext';
import {
  useSetPreferredCultureMutation,
} from '@app/core/contexts/localizationContext/queries/queryTyping/setPreferredCulture';

import { getCultureFromURL, removeCultureFromURL } from './utils';

interface LocalizationSelectorWrapperProps {
  preferredCulture?: string | undefined | null
  isNotVisible?: boolean
}

export const LocalizationSelectorWrapper: React.FC<LocalizationSelectorWrapperProps> = ({
  preferredCulture,
  isNotVisible,
}) => {
  const tempPreferredCulture = preferredCulture ? getLanguageFromCulture(preferredCulture) : null;

  const [
    setPreferredCultureMutation,
    { loading, error },
  ] = useSetPreferredCultureMutation();

  const history = useHistory();
  const loggedIn = useIsLoggedIn();
  const fiSettings = useFISettingsContext();

  const {
    currentLanguageCulture,
    setCurrentLanguageCulture,
  } = useLocalizationContext();

  let currentCultureCode = tempPreferredCulture || currentLanguageCulture;
  if (isEmbeddedMode()) {
    currentCultureCode = extractInitParametersFromLocalStorage().cultureId || DEFAULT_FALLBACK_LANGUAGE_CULTURE;
  }

  const languageCulturesList = fiSettings
    ?.fiSupportedCultures
    ?.list?.map((item) => ({
      label: item?.name,
      value: item?.code,
    })) ?? [];

  const pushHistoryWithCurrentState = (path: Path) => {
    history.push(path, {
      // prevent losing current state when language selector reloads the page
      ...(history.location.state as any) || {},
    });
  };

  const updateCultureAndReload = (value: string) => {
    setCurrentLanguageCulture(value);
    window.location.reload();
  };

  const onCurrentItemChange = async (value?: string) => {
    if (!value) return;
    if (loggedIn) {
      await setPreferredCultureMutation({
        variables: {
          preferredCulture: value,
        },
      });
    } else {
      pushHistoryWithCurrentState(`?${SEARCH_LANGUAGE_CULTURE_PARAM}=${value}`);
    }
    updateCultureAndReload(value);
  };

  useEffect(() => {
    if (languageCulturesList.length > 0) {
      const currentCode = languageCulturesList
        .find((item) => {
          const code = item.value?.toLowerCase();
          return code === currentLanguageCulture?.toLowerCase();
        });

      const currentNearestCode = languageCulturesList
        .find((item) => {
          const code = item.value?.toLowerCase();
          return code === (currentCultureCode && getLanguageFromCulture(currentCultureCode)?.toLowerCase());
        });

      if (!loggedIn && !currentCode?.value && currentNearestCode?.value) {
        pushHistoryWithCurrentState(`?${SEARCH_LANGUAGE_CULTURE_PARAM}=${currentNearestCode.value}`);
        updateCultureAndReload(currentNearestCode.value);
      }

      if (!currentCode) {
        setCurrentLanguageCulture(DEFAULT_FALLBACK_LANGUAGE_CULTURE);
        return;
      }

      if (loggedIn && getCultureFromURL(window.location.search)) {
        removeCultureFromURL(window);
      }

      if (tempPreferredCulture
        // && !loggedIn
        && currentCode
        && currentCode.value !== tempPreferredCulture
        && languageCulturesList.length > 1) {
        setTimeout(() => {
          updateCultureAndReload(tempPreferredCulture);
        }, 1000);
      }
    }
  }, [preferredCulture, languageCulturesList, loggedIn]);

  if (error) {
    throw error;
  }

  if (languageCulturesList.length === 0 || languageCulturesList.length === 1) {
    return null;
  }

  if (isNotVisible) {
    return null;
  }

  return (
    <LocalizationSelector
      loading={loading}
      items={languageCulturesList}
      currentCulture={currentCultureCode}
      onCurrentItemChange={(item) => onCurrentItemChange(item.value)}
    />
  );
};
