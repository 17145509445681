import React, { forwardRef, Ref } from 'react';
import { Input } from '@ast/magma/components/input';

export type InputType = typeof Input;
export type InputProps = React.ComponentProps<InputType>;
export type InputRef= Ref<React.ElementRef<InputType>>;

export const PasswordControl: React.FC<InputProps> = forwardRef((props: InputProps, ref: InputRef) => (
  <Input
    type="password"
    ref={ref}
    {...props}
  />
));
