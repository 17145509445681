import { ColorTheme } from '@app/common/types/ColorTheme';

/**
 * Color theme changed event name.
 */
export const COLOR_THEME_CHANGED_EVENT = 'ast.colorThemeChanged';

/**
 * Event fired when the color theme changes.
 *
 * Contains new color theme name as a payload.
 */
export class ColorThemeChangedEvent extends CustomEvent<ColorTheme> {
  constructor(value: ColorTheme) {
    super(COLOR_THEME_CHANGED_EVENT, {
      detail: value,
    });
  }
}

declare global {
  interface WindowEventMap {
    [COLOR_THEME_CHANGED_EVENT]: ColorThemeChangedEvent;
  }
}
