import { WizardStepLocatorsMap } from '@app/common/configurable-wizards';
import { WizardSteps } from '../constants';
import { SelectOneTimePinAddress } from './SelectOneTimePinAddress/SelectOneTimePinAddress';
import { SelectOneTimePinChannel } from './SelectOneTimePinChannel/SelectOneTimePinChannel';
import { ValidateOneTimePin } from './ValidateOneTimePin/ValidateOneTimePin';

export const steps: WizardStepLocatorsMap<WizardSteps> = new Map([
  [WizardSteps.SelectOneTimePinChannel, SelectOneTimePinChannel],
  [WizardSteps.SelectOneTimePinAddress, SelectOneTimePinAddress],
  [WizardSteps.ValidateOneTimePin, ValidateOneTimePin],
]);
