import { Migration } from '@app/core/versionedSerializer/versionedSerializer';
import { SessionStorageDocument } from '@app/core/storage/browser/SessionStorageDocument';

const CURRENT_VERSION = 1;
const USER_LOGIN_SESSION_STORAGE_NODE = 'login-settings';

type AutoLogoutRedirection = { lastPage: string; userUid?: string; } | null;

/**
 * Inner serialization interface for data
 */
interface IUserLoginSessionStorage {
  autoLogoutRedirection: AutoLogoutRedirection
}

/**
 * Singletone class for managing user login settings stored in session storage.
 */
export class UserLoginSessionStorage extends SessionStorageDocument<IUserLoginSessionStorage> {
  private static inner: UserLoginSessionStorage;

  // we use "definite assignment assertion operator" because this member would be initialized at the superclass
  private autoLogoutRedirection!: AutoLogoutRedirection;

  /**
   * Constructor must describe latest data version and provide migrations from previous versions of saved data.
   * @param node string node key to storage data in local storage.
   * @param version latest version.
   * @param migrations migrations from previous versions to latest.
   */
  private constructor(node: string, version: number, migrations: Migration[] = []) {
    super(node, version, migrations);
  }

  public static getInstance(): UserLoginSessionStorage {
    if (!this.inner) {
      this.inner = new UserLoginSessionStorage(USER_LOGIN_SESSION_STORAGE_NODE, CURRENT_VERSION);
    }

    return this.inner;
  }

  public getAutoLogoutRedirection(): AutoLogoutRedirection {
    return this.autoLogoutRedirection;
  }

  public setAutoLogoutRedirection(value: AutoLogoutRedirection) {
    this.autoLogoutRedirection = value;
    this.save();
  }

  /**
   * Save public data properties into serializable data stracture.
   * @returns data structure which can be saved in local storage.
   */
  protected serialize(): IUserLoginSessionStorage {
    return {
      autoLogoutRedirection: this.autoLogoutRedirection,
    };
  }

  /**
   * Init public data properties with saved data.
   * @param deserialized saved data.
   */
  protected deserialize(deserialized: IUserLoginSessionStorage) {
    this.autoLogoutRedirection = deserialized.autoLogoutRedirection;
  }
}
