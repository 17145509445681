import { ListenableInterface } from './types';

type ArgumentedOrNotFn<K> = K extends undefined | null
  ? () => void
  : (data: K) => void;

export type Listener<K> = ArgumentedOrNotFn<K>;

/**
 * Represents a class that lends itself to listening by external listeners that may subscribe/unsubscribe to some events
 */
export class Listenable<T extends Record<string, any>> implements ListenableInterface<T> {
  protected listeners: Map<keyof T, Set<Function>>;

  constructor(callbacks: Map<keyof T, Set<Function>>) {
    this.listeners = callbacks;
  }

  /**
   * @returns listener function
   */
  addListener<K extends keyof T>(event: K, listener: Listener<T[K]>): Listener<T[K]> {
    this.listeners.get(event)?.add(listener);
    return listener;
  }

  removeListener<K extends keyof T>(event: K, listener: Function) {
    this.listeners.get(event)?.delete(listener);
  }

  notifyListeners<K extends keyof T>(event: K, data: T[K]) {
    this.listeners.get(event)?.forEach(
      (listener) => listener(data),
    );
  }
}
