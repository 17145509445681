import { ColorTheme } from '@app/common/types/ColorTheme';

import { COLOR_THEME_ATTRIBUTE_NAME } from './constants';

/**
 * Reads color theme from the document body attribute.
 */
export function readColorTheme() {
  const theme = document.body.getAttribute(COLOR_THEME_ATTRIBUTE_NAME) as ColorTheme;

  return theme;
}
