import React, {
  HTMLAttributes, FunctionComponent,
} from 'react';
import { useHistory } from 'react-router-dom';

import Day from '@ast/magma/components/icon/icons/Day';
import Night from '@ast/magma/components/icon/icons/Night';
import SignOut from '@ast/magma/components/icon/icons/SignOut';
import UserCircle from '@ast/magma/components/icon/icons/UserCircle';

import { Button } from '@ast/magma/components/button';

import { LocalizationSelectorWrapper } from '@app/common/components/LocalizationSelector/LocalizationSelectorWrapper';
import { ColorTheme } from '@app/common/types/ColorTheme';

import { logout } from '@app/core/authentication/logout/logout';
import { useColorThemeContext } from '@app/core/contexts/colorTheme/ColorThemeContext';
import { useCurrentUserInfoContext } from '@app/core/contexts/currentUserInfo/CurrentUserInfoContext';
import { useTranslation } from '@app/core/i18n';
import { PageURL } from '@app/core/widgets/pages';

import { MessageNotificationMenu } from '../MessageNotification/MessageNotificationMenu';

import styles from './userbar.pcss';

export interface UserBarProps
  extends HTMLAttributes<HTMLDivElement> {}

export const UserBar: FunctionComponent<UserBarProps> = () => {
  const { t } = useTranslation('header');
  const { colorTheme, changeColorTheme } = useColorThemeContext();
  const currentUserInfo = useCurrentUserInfoContext();
  const history = useHistory();

  const toggleColorTheme = () => {
    changeColorTheme(colorTheme === ColorTheme.light
      ? ColorTheme.dark
      : ColorTheme.light);
  };

  const handleLogOut = async () => {
    await logout();
  };

  return (
    <div className={styles.userBar} data-stable-name="UserBar">
      <Button
        look="link"
        icon={<UserCircle className={styles.icon} />}
        onClick={() => history.push(`${PageURL.CONTACTS_AND_SETTINGS}/profile-security/`)}
        className={styles.preferredName}
        data-stable-name="UserNameButton"
        title={t('user-bar.user-name-button.title|Title for user profile button in header', 'User Profile')}
      >
        {currentUserInfo?.preferredName}
      </Button>
      <LocalizationSelectorWrapper preferredCulture={currentUserInfo?.preferredCulture} />
      <MessageNotificationMenu />
      <Button
        look="icon"
        className={styles.icon}
        data-stable-name="ThemeToggleButton"
        icon={(colorTheme === ColorTheme.light) ? <Night /> : <Day />}
        title={t(
          'user-bar.color-mode-button.aria-label|Accessible text for color mode button in header',
          'Theme toggle',
        )}
        aria-label={(colorTheme === ColorTheme.light)
          ? t('user-bar.theme.light.enabled|Message for enabling light theme', 'light theme is enabled')
          : t('user-bar.theme.dark.enabled|Message for enabling dark theme', 'dark theme is enabled')}
        onClick={toggleColorTheme}
      />
      <Button
        look="icon"
        className={styles.icon}
        data-stable-name="SignOutButton"
        icon={<SignOut />}
        title={t('user-bar.logout-button.title|Title for sign out button in header', 'Sign out')}
        aria-label={t(
          'user-bar.logout-button.aria-label|Accessible text for sign out button in header',
          'Sign Out',
        )}
        onClick={handleLogOut}
      />
    </div>
  );
};
