/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type VerifyPasskeyAuthenticationMutationVariables = Types.Exact<{
  verifyAuthenticationParams: Types.VerifyAuthenticationParams;
}>;


export type VerifyPasskeyAuthenticationMutation = (
  { __typename?: 'Mutation' }
  & { verifyAuthentication: Types.Maybe<(
    { __typename?: 'VerifyAuthenticationResult' }
    & Pick<Types.VerifyAuthenticationResult, 'success' | 'message'>
  )> }
);


export const VerifyPasskeyAuthenticationDocument = gql`
    mutation VerifyPasskeyAuthentication($verifyAuthenticationParams: VerifyAuthenticationParams!) {
  verifyAuthentication(verifyAuthenticationParams: $verifyAuthenticationParams) {
    success
    message
  }
}
    `;
export type VerifyPasskeyAuthenticationMutationFn = Apollo.MutationFunction<VerifyPasskeyAuthenticationMutation, VerifyPasskeyAuthenticationMutationVariables>;

/**
 * __useVerifyPasskeyAuthenticationMutation__
 *
 * To run a mutation, you first call `useVerifyPasskeyAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasskeyAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPasskeyAuthenticationMutation, { data, loading, error }] = useVerifyPasskeyAuthenticationMutation({
 *   variables: {
 *      verifyAuthenticationParams: // value for 'verifyAuthenticationParams'
 *   },
 * });
 */
export function useVerifyPasskeyAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPasskeyAuthenticationMutation, VerifyPasskeyAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPasskeyAuthenticationMutation, VerifyPasskeyAuthenticationMutationVariables>(VerifyPasskeyAuthenticationDocument, options);
      }
export type VerifyPasskeyAuthenticationMutationHookResult = ReturnType<typeof useVerifyPasskeyAuthenticationMutation>;
export type VerifyPasskeyAuthenticationMutationResult = Apollo.MutationResult<VerifyPasskeyAuthenticationMutation>;
export type VerifyPasskeyAuthenticationMutationOptions = Apollo.BaseMutationOptions<VerifyPasskeyAuthenticationMutation, VerifyPasskeyAuthenticationMutationVariables>;