import { t } from '@app/core/i18n';

import { isErrorNonRenderable } from '../errorTypeChecker';
import { UserError } from '../UserError';

import { ErrorProcessor } from './types';

/**
 * Webpack chunk error required properties.
 */
export type ChunkLoadError = Error & {
  code: string;
};

/**
 * Check if error is chunk load error.
 * */
export function isChunkLoadError(error: Error): error is ChunkLoadError {
  return error.name === 'ChunkLoadError' || (error as ChunkLoadError).code === 'CSS_CHUNK_LOAD_FAILED';
}

export const chunkErrorProcessor:ErrorProcessor = (error:Error):Error => {
  if (!isErrorNonRenderable(error) && isChunkLoadError(error)) {
    return new UserError({
      critical: true,
      message: t(
        'error-message.unexpected-communication|Unexpected communication error message',
        'Unexpected communication error occurred.',
      ),
      title: t('error-message.network|Network error message', 'Network error'),
      originalError: error,
    });
  }

  return error;
};
