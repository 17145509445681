import { AsyncNotificationsActions } from '../types';

export type AsyncNotification = typeof initialState;

export const initialState = {
  TotalUnreadMessages: 0,
  Messages: 0,
  Alerts: 0,
};

export const asyncNotificationsReducer = (
  state: AsyncNotification,
  action: AsyncNotificationsActions<AsyncNotification>,
) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...action.payload,
      };
    default:
      // eslint-disable-next-line i18next/no-literal-string
      throw new Error('Unsupported action type of async notifications reducer');
  }
};
