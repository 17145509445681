import React from 'react';

import { GenericServerErrors } from '@app/common/types/validation';

import { parseHtml } from '@app/core/stringResources/parseHtml';

import { WizardStepAlertMessage } from '../types';

/** converts server generic errors to an error-alert */
export const errorsToAlert = (errors: GenericServerErrors): WizardStepAlertMessage | null => {
  if (errors && errors.length) {
    return {
      type: 'error',
      content: errors.map(({ message }) => (
        <div key={message}>
          {/* Sometimes server returns html in error message, so we need to sanitize it */}
          {parseHtml(message)}
        </div>
      )),
    };
  }

  return null;
};
