/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GeneratePasskeyAuthOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GeneratePasskeyAuthOptionsQuery = (
  { __typename?: 'Query' }
  & { generateAuthOptions: (
    { __typename?: 'GenerateAuthOptions' }
    & Pick<Types.GenerateAuthOptions, 'rpId' | 'userVerification' | 'challenge' | 'timeout' | 'hints'>
    & { allowCredentials: Array<(
      { __typename?: 'PublicKeyCredentialDescriptor' }
      & Pick<Types.PublicKeyCredentialDescriptor, 'id' | 'type' | 'transports'>
    )> }
  ) }
);


export const GeneratePasskeyAuthOptionsDocument = gql`
    query GeneratePasskeyAuthOptions {
  generateAuthOptions {
    rpId
    userVerification
    challenge
    timeout
    allowCredentials {
      id
      type
      transports
    }
    hints
  }
}
    `;

/**
 * __useGeneratePasskeyAuthOptionsQuery__
 *
 * To run a query within a React component, call `useGeneratePasskeyAuthOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePasskeyAuthOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePasskeyAuthOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGeneratePasskeyAuthOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>(GeneratePasskeyAuthOptionsDocument, options);
      }
export function useGeneratePasskeyAuthOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>(GeneratePasskeyAuthOptionsDocument, options);
        }
export type GeneratePasskeyAuthOptionsQueryHookResult = ReturnType<typeof useGeneratePasskeyAuthOptionsQuery>;
export type GeneratePasskeyAuthOptionsLazyQueryHookResult = ReturnType<typeof useGeneratePasskeyAuthOptionsLazyQuery>;
export type GeneratePasskeyAuthOptionsQueryResult = Apollo.QueryResult<GeneratePasskeyAuthOptionsQuery, GeneratePasskeyAuthOptionsQueryVariables>;