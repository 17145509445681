import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { Input, InputRef } from '@ast/magma/components/input';

import { ConstrainedTextField } from '@app/common/configurable-wizards';

import { CFFormField } from '../common/CFFormField';

export type ConstrainedTextInputProps = Omit<UseFormRegisterReturn, 'ref'> & {
  readonly field: ConstrainedTextField;
  readonly error: string;
  readonly onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const ConstrainedTextInput = React.forwardRef(({
  field,
  error,
  onBlur,
  onChange,
  onChangeHandler,
  ...props
}: ConstrainedTextInputProps, ref: React.Ref<InputRef>) => (
  <CFFormField error={error}>
    <Input
      ref={ref}
      minLength={field.minLength}
      maxLength={field.maxLength}
      label={field.label || ''}
      aria-invalid={!!error}
      data-stable-name={`${field.id}Input`}
      defaultValue={field.sValue ?? ''}
      onChange={(e) => {
        if (onChangeHandler) {
          onChangeHandler(e);
        }
        onChange(e);
      }}
      onBlur={(e) => {
        // trim spaces before and after the value
        e.target.value = e.target.value.trim();

        onBlur(e);
      }}
      {...props}
    />
  </CFFormField>
));
