/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../../queryTyping';

import { CurrentWizardStepFragment } from '../../../../../common/configurable-wizards/queries/queryTyping/current-wizard-step';
import { gql } from '@apollo/client';
import { CurrentWizardStepFragmentDoc } from '../../../../../common/configurable-wizards/queries/queryTyping/current-wizard-step';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetLoginStepQueryVariables = Types.Exact<{
  stateParams: Types.GetWizardStateParams;
}>;


export type GetLoginStepQuery = (
  { __typename?: 'Query' }
  & { loginStep: Types.Maybe<(
    { __typename?: 'CurrentWizardStep' }
    & CurrentWizardStepFragment
  )> }
);


export const GetLoginStepDocument = gql`
    query GetLoginStep($stateParams: GetWizardStateParams!) {
  loginStep(getWizardStateParams: $stateParams) {
    ...CurrentWizardStep
  }
}
    ${CurrentWizardStepFragmentDoc}`;

/**
 * __useGetLoginStepQuery__
 *
 * To run a query within a React component, call `useGetLoginStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginStepQuery({
 *   variables: {
 *      stateParams: // value for 'stateParams'
 *   },
 * });
 */
export function useGetLoginStepQuery(baseOptions: Apollo.QueryHookOptions<GetLoginStepQuery, GetLoginStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoginStepQuery, GetLoginStepQueryVariables>(GetLoginStepDocument, options);
      }
export function useGetLoginStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginStepQuery, GetLoginStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoginStepQuery, GetLoginStepQueryVariables>(GetLoginStepDocument, options);
        }
export type GetLoginStepQueryHookResult = ReturnType<typeof useGetLoginStepQuery>;
export type GetLoginStepLazyQueryHookResult = ReturnType<typeof useGetLoginStepLazyQuery>;
export type GetLoginStepQueryResult = Apollo.QueryResult<GetLoginStepQuery, GetLoginStepQueryVariables>;