/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import * as Types from '../../../../queryTyping';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type InvokeExternalApiIntegrationQueryVariables = Types.Exact<{
  integrationName: Types.Scalars['String'];
  arguments: Types.Maybe<Array<Types.ExternalApiIntegrationArgumentInput> | Types.ExternalApiIntegrationArgumentInput>;
}>;


export type InvokeExternalApiIntegrationQuery = (
  { __typename?: 'Query' }
  & { invokeExternalApiIntegration: (
    { __typename?: 'InvokeExternalApiIntegrationResult' }
    & Pick<Types.InvokeExternalApiIntegrationResult, 'result' | 'successful'>
    & { errors: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, warnings: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>>, infos: Types.Maybe<Array<(
      { __typename?: 'ValidationItem' }
      & Pick<Types.ValidationItem, 'context' | 'field' | 'messages' | 'faultType'>
    )>> }
  ) }
);


export const InvokeExternalApiIntegrationDocument = gql`
    query InvokeExternalApiIntegration($integrationName: String!, $arguments: [ExternalApiIntegrationArgumentInput!]) {
  invokeExternalApiIntegration(
    integrationName: $integrationName
    arguments: $arguments
  ) {
    result
    successful
    errors {
      context
      field
      messages
      faultType
    }
    warnings {
      context
      field
      messages
      faultType
    }
    infos {
      context
      field
      messages
      faultType
    }
  }
}
    `;

/**
 * __useInvokeExternalApiIntegrationQuery__
 *
 * To run a query within a React component, call `useInvokeExternalApiIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvokeExternalApiIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvokeExternalApiIntegrationQuery({
 *   variables: {
 *      integrationName: // value for 'integrationName'
 *      arguments: // value for 'arguments'
 *   },
 * });
 */
export function useInvokeExternalApiIntegrationQuery(baseOptions: Apollo.QueryHookOptions<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>(InvokeExternalApiIntegrationDocument, options);
      }
export function useInvokeExternalApiIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>(InvokeExternalApiIntegrationDocument, options);
        }
export type InvokeExternalApiIntegrationQueryHookResult = ReturnType<typeof useInvokeExternalApiIntegrationQuery>;
export type InvokeExternalApiIntegrationLazyQueryHookResult = ReturnType<typeof useInvokeExternalApiIntegrationLazyQuery>;
export type InvokeExternalApiIntegrationQueryResult = Apollo.QueryResult<InvokeExternalApiIntegrationQuery, InvokeExternalApiIntegrationQueryVariables>;