import { NativeAppRequest } from './types';

/**
 * Dispatch Native App Request Event function params.
 */
export interface NativeAppRequestEventParams<T> {
  /**
   * Request event type.
   */
  type: string,
  /**
   * Request event payload.
   */
  detail: T,
}

/**
 * Dispatch Native App Request Event function.
 *
 * This function dispatches a custom event with the provided {@link NativeAppRequest}
 * as the event payload.
 */
export function dispatchNativeAppRequestEvent<T extends NativeAppRequest>({
  type,
  detail,
}: NativeAppRequestEventParams<T>) {
  window.dispatchEvent(
    new CustomEvent(type, { detail }),
  );
}
